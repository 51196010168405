import { CompanySize } from "./company-size-enum";
import { IncreaseDifficultType } from "./Increase-difficult-type-enum";
import { DevelopmentPhase } from "./development-phase-enum";
import { GrowthPhase } from "./growth-phase-enum";

export class ICompany {
  id: number;
  cognitoId: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber?: string;
  contactEmail: string;
  appliedStandards: {
    appliedStandardId?: number;
    hasDescription?: boolean | undefined;
    description?: string;
  }[];
  export: boolean;
  exportRegions: { id: number; name: string }[];
  incomePerYear: string;
  increaseDifficultType: IncreaseDifficultType;
  developmentPhase: DevelopmentPhase;
  growthPhase: GrowthPhase;
  industries: { id: number; name: string }[];
  otherServiceActivities: string;
  expertise: {
    id: number;
    name: string;
  }[];
  otherExpertise: string;
  location: { id: number; name: string };
  address: string;
  postalCodeAndCity: string;
  name: string;
  companySize: CompanySize;
  companyEmail: string;
  repeatCompanyEmail: string;
  percentageProduction: number;
  percentageOfExport: number;
  preferenceForRegionExpanding: boolean;
  regions: { id: number; name: string }[];
  startup: boolean;
  problemsThatServiceAddresses: string;
  innovativenessOfTheService: string;
  typeOfClientsTargeting: string;
  serviceQualityAssuranceSystem: boolean;
  supportTypes: { id: number; name: string }[];
  technologyEquipmentId: number;
  typeServicesProvided: string;
  webLink: string;
  yearsOfOperation: number;
  expectationsFromExpertSupport: string;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
  matchedExpert: any;
  dateCreated: string;
  lastModified: string;
  collaborationStartDate: string;
}

export class Company implements ICompany {
  public constructor(init?: Partial<Company>) {
    Object.assign(this, init);
  }
  id: 0;
  cognitoId: "";
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber?: string;
  contactEmail: string;
  appliedStandards: {
    appliedStandardId?: number;
    hasDescription?: boolean | undefined;
    description?: string;
  }[];
  export: false;
  exportRegions: { id: number; name: string }[];
  incomePerYear: string;
  increaseDifficultType: IncreaseDifficultType;
  developmentPhase: DevelopmentPhase;
  growthPhase: GrowthPhase;
  industries: { id: number; name: string }[];
  otherServiceActivities: string;
  expertise: {
    id: number;
    name: string;
  }[];
  otherExpertise: string;
  location: { id: number; name: string };
  address: string;
  postalCodeAndCity: string;
  name: string;
  supportTypes: { id: number; name: string }[];
  companySize: CompanySize;
  companyEmail: string;
  repeatCompanyEmail: string;
  percentageProduction: 0;
  percentageOfExport: 0;
  preferenceForRegionExpanding: false;
  regions: { id: number; name: string }[];
  startup: boolean;
  problemsThatServiceAddresses: string;
  innovativenessOfTheService: string;
  typeOfClientsTargeting: string;
  serviceQualityAssuranceSystem: false;
  technologyEquipmentId: 0;
  typeServicesProvided: string;
  webLink: string;
  yearsOfOperation: 0;
  expectationsFromExpertSupport: string;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
  matchedExpert: any;
  dateCreated: string;
  lastModified: string;
  collaborationStartDate: string;
}
