import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { withTranslation } from "react-i18next";
import Form from "react-validation/build/form";
import compose from "recompose/compose";
import { ITab } from "./definitions/tabs-spec";
import {
  profileTabNavigation,
  TabNavigationEnum,
} from "../../../models/company/profile-tab-navigation-enum";
import { ConfigurableInput } from "../../formComponents/configurable-input";
import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  NumberInputEvent,
  TextInputEvent,
  SelectInputEvent,
} from "../../formComponents/definitions/InputConfigurationSpec";
import { companyToGeneralTabForm } from "../../../utils/mappers/company/companyToGeneralTabForm";
import { InputTypeEnum } from "../../../models/forms/input-types-enum";
import { generalData } from "../../../models/general/general-data";

interface IGeneralInfoTab extends ITab, StateProps, DispatchProps {}

const GeneralInfoTab = (props: IGeneralInfoTab) => {
  const { companyState } = props;
  const {
    data,
    active,
    onChange,
    validateTab,
    onTabValidation,
    formWorkMode,
  } = props;

  const { staticData } = useSelector((state: IRootState) => state.company);

  const [formInputs, setFormInputs] = useState<ConfigurableInputProps[]>([]);
  const formData = useRef<ICompany>({ ...data });

  useEffect(() => {
    const formInpComponents = companyToGeneralTabForm(
      data,
      staticData,
      generalData,
    );
    setFormInputs(formInpComponents);
    formData.current = { ...data };
  }, [data]);

  useEffect(() => {
    if (validateTab === profileTabNavigation[TabNavigationEnum.General]) {
      const isValid = validate();
      onTabValidation(validateTab, isValid);
    }
  }, [validateTab]);

  useEffect(() => {
    const formInpComponents = companyToGeneralTabForm(
      data,
      staticData,
      generalData,
      true,
    );
    setFormInputs(formInpComponents);
  }, [companyState.cancelDataChanges]);

  /**
   * Validate the form for error
   */
  const validate = (): boolean => {
    let isFormValid = true;
    formInputs.forEach((input) => {
      // will trigger error message for error
      const inputIsValid = input.inputConfig.validate();
      if (!inputIsValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  /**
   * EVENTS SECTION
   * @param event
   */
  const handleTextInput = (event: TextInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current });
  };

  const handleNumberInput = (event: NumberInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current });
  };

  const handleSelectEventInput = (event: SelectInputEvent) => {
    if (event.name === "companySize") {
      formData.current[event.modelValue] = event.selectedOption.value;
    } else if (event.name === "location") {
      formData.current[event.modelValue] = {
        id: event.selectedOption.value,
        name: event.selectedOption.label,
      };
    } else {
      formData.current[event.modelValue] = event.selectedMultiOptions.map(
        (item) => {
          return {
            id: item.value,
            name: item.label,
          };
        },
      );
    }

    onChange({ ...formData.current });
  };

  const handleEvent = (component: ConfigurableInputProps) => {
    switch (component.inputConfig.inputType) {
      case InputTypeEnum.Number:
        component.onInputChange = handleNumberInput;
        break;
      case InputTypeEnum.Text:
        component.onInputChange = handleTextInput;
        break;
      case InputTypeEnum.Select:
        component.onInputChange = handleSelectEventInput;
        break;
    }
  };

  const renderComponents = () => {
    return (
      <>
        {formInputs.map((component) => {
          if (component) {
            handleEvent(component);
          }
          return (
            <>
              {component && (
                <ConfigurableInput
                  key={"comp-" + component.uniqueKey}
                  {...component}
                />
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {active === profileTabNavigation[TabNavigationEnum.General] && (
        <div className="profile-card-body">
          <Form className="profile-form-info">{renderComponents()}</Form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  companyState: storeState.company,
});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("profile"),
  connect(mapStateToProps, mapDispatchToProps),
)(GeneralInfoTab);
