import React, { useState, useEffect } from "react";
import {
  RadioButtonsListConfig,
  RadioButtonEvent,
} from "./definitions/InputConfigurationSpec";
import { generateUniqId } from "../../utils/helpers/general-helper";

export default function MultiRadioButtons(props: {
  inputConfig: RadioButtonsListConfig;
  onSelect: (event: RadioButtonEvent) => void;
  errorMsg: string;
}) {
  const { inputConfig, onSelect, errorMsg } = props;
  const [checkedValue, setCheckedValue] = useState(
    inputConfig.selectedOption || { value: null, label: null },
  );
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMsg);
  const [isDisabled, setIsDisabled] = useState(inputConfig.isDisabled || false);

  inputConfig.setDisabled = disabled => {
    setIsDisabled(disabled);
    inputConfig.isDisabled = disabled;
    if (hasError) {
      setHasError(false);
    }
  };

  useEffect(() => {
    setIsDisabled(inputConfig.isDisabled);
    if (inputConfig.isDisabled) {
      if (hasError) {
        setHasError(false);
      }
    }
  }, [inputConfig.isDisabled]);

  const handleChange = (item: { value: string | number; label: string }) => {
    if (hasError) {
      setHasError(false);
    }

    const event = new RadioButtonEvent({
      name: inputConfig.name,
      modelValue: inputConfig.modelValue,
      selectedOption: item,
    });

    if (typeof onSelect === "function") {
      onSelect(event);
    }

    setCheckedValue(item);
  };

  inputConfig.validate = () => {
    // do not check for validation if input is disabled
    if (inputConfig.isDisabled) {
      return true;
    }

    if (inputConfig.isRequired) {
      if (checkedValue.value === undefined || checkedValue.value === null) {
        setHasError(true);
        setError(error);
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <>
      <div className="multi-radio-buttons">
        {inputConfig.list.map((item, index) => {
          return (
            <div
              key={"rb_" + inputConfig.group + index}
              className={"radio-button-divider"}
            >
              <label className={"radio"}>
                <input
                  type="radio"
                  name={inputConfig.group || item.label}
                  checked={item.value === checkedValue.value}
                  id={inputConfig.modelValue + item.value}
                  className={"radio-hidden"}
                  disabled={isDisabled}
                  onChange={() => handleChange(item)}
                />
                <span className="label"></span>
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
      {hasError && <div className={"alert error"}>{error}</div>}
    </>
  );
}
