import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../utilities/root-reducer";
import {
  Link,
  RouteComponentProps,
  Redirect,
  withRouter,
  useHistory,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  email,
  password,
  required,
  isEqual,
} from "../../../utilities/validation";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { ValidationError } from "../../../utilities/validationError";
import { deleteError, resetPasswordAction } from "../reset-password-actions";

import "./../../loginFormComponent/auth.scss";
import Spinner from "../../common/spinner/spinner";
import { Paper, Typography } from "@material-ui/core";
import AuthBackground from "../../authBgComponent/authBackground";

const ResetPasswordForm = () => {
  const { t } = useTranslation("forgot-password");
  const dispatch = useDispatch();
  const { errorMessage, loading, status, passwordSubmitSuccess } = useSelector(
    (state: IRootState) => state.resetPassword,
  );

  let history = useHistory();

  const handleQuery = () => {
    const query = new URLSearchParams(history.location.search);
    const userId = query.get("userId");
    const code = query.get("code");

    return [userId, code];
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    let data = handleQuery();
    data.push(event.target.password.value);
    dispatch(resetPasswordAction(data[0], data[1], data[2]));
  };

  const errorMessageCleanUp = () => {
    dispatch(deleteError());
  };

  return (
    <>
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("resetPassword")}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("newPasswordSubtitle")}
        </Typography>
      </AuthBackground>
      <Paper className="auth-form-container forgot-password-form-container">
        <Form
          className="auth-form"
          onSubmit={handleResetPassword}
          onChange={errorMessageCleanUp}
        >
          <div className="auth-input-container">
            <Input
              className="auth-input"
              id="NewPassword"
              type="password"
              name="password"
              placeholder={t("newPassword")}
              validations={[required, isEqual, password]}
            />
          </div>
          <div className="auth-input-container">
            <Input
              className="auth-input"
              id="ConfirmNewPassword"
              type="password"
              name="confirmPassword"
              placeholder={t("confirmPassword")}
              validations={[required, isEqual]}
            />
          </div>
          {loading && <Spinner />}
          <div className="auth-input-container">
            <Button className="auth-submit-button">
              {t("changePassword")}
            </Button>
          </div>
          {errorMessage && <ValidationError emailValidation={errorMessage} />}
        </Form>
      </Paper>
    </>
  );
};
export default ResetPasswordForm;
