import { Actions } from "../../utilities/action-payload";
import { ISignupForm } from "./signup-form-entity";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";
import { SUBMIT_SIGNUP, SIGNUP_DELETE_ERROR } from "./signup-form-types";

const initialState: ISignupForm = {
  fullName: "",
  email: "",
  password: "",
  profileType: "",
  errorMessage: null,
  confirmPassword: "",
  signupSuccess: null,
  loading: false,
  status: null,
};

export type SignupFormState = Readonly<typeof initialState>;

export default (
  state: SignupFormState = initialState,
  action: Actions,
): SignupFormState => {
  switch (action.type) {
    case REQUEST(SUBMIT_SIGNUP):
      return {
        ...state,
        errorMessage: null,
        signupSuccess: false,
        loading: true,
      };
    case FAILURE(SUBMIT_SIGNUP):
      return {
        ...state,
        errorMessage: action.payload,
        signupSuccess: false,
        loading: false,
      };
    case SUCCESS(SUBMIT_SIGNUP):
      return {
        ...state,
        status: "Logged in" + action.payload,
        signupSuccess: true,
        loading: false,
      };
    case SIGNUP_DELETE_ERROR:
      return {
        ...state,
        signupSuccess: false,
        loading: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};
