import { Actions } from "../../utilities/action-payload";
import { IResetPassword } from "./reset-password-entity";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";
import { ForgotPasswordError } from "../../models/account/resetPassword/forgot-password-error-enum";
import { RESET_PASSWORD, RESET_PASSWORD_CLEANUP } from "./reset-password-types";

const initialState: IResetPassword = {
  errorMessage: ForgotPasswordError.None,
  loading: false,
  status: "",
  passwordSubmitSuccess: false,
};

export type ResetPasswordState = Readonly<typeof initialState>;

export default (
  state: ResetPasswordState = initialState,
  action: Actions,
): ResetPasswordState => {
  switch (action.type) {
    case REQUEST(RESET_PASSWORD):
      return {
        ...state,
        status: action.payload,
        loading: true,
        errorMessage: ForgotPasswordError.NewPassword,
      };

    case SUCCESS(RESET_PASSWORD):
      return {
        ...state,
        status: "Changed password" + action.payload,
        errorMessage: ForgotPasswordError.PasswordChanged,
        passwordSubmitSuccess: true,
      };

    case FAILURE(RESET_PASSWORD):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case RESET_PASSWORD_CLEANUP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
