export enum CompanyFormWorkMode {
  // from is not visible to the user
  Hidden = 0,
  // User in process of filling the form tab by tab with stepper
  New,
  // User edit the form. User can edit in one tab at a time. User can not change tabs until click save or cancel
  Edit,
  // form is read only for the user
  View,
  // summary page is being show
  Summary,
  //Done
  Done,
}
