import { InputTypeEnum } from "../../../models/forms/input-types-enum";
import { IValidation } from "./validation-spec";

/**
 * Base input properties that every custom input must have
 */
export abstract class InputConfiguration {
  public constructor(init?: Partial<InputConfiguration>) {
    Object.assign(this, init);
  }
  /**
   * Index number of the input in the form so user can go with "tab" button
   */
  index?: number;
  /**
   * Represents input width on the form half or full
   */
  public classModifier: "half" | "full";
  /**
   * Represent label/question/description of the whole input/section
   * Set translation key as string - enter that ke into profile.json file
   */
  public tLabel: string;
  public inputType: InputTypeEnum;
  /**
   * Storage/entity value
   */
  public modelValue?: string;
  public validations?: IValidation[];
  public isDisabled: boolean;
  public isRequired: boolean;
  public placeholder: string;
  public name?: string;
  public initValue?: string | number;
  /**
   * Call to check if component pass the validation criteria.
   * This method will trigger error message to be visible
   */
  validate: () => boolean;
  setDisabled: (disabled: boolean) => void;
}

/**
 * Represent base event for the inputs
 */
export abstract class InputEvent {
  name?: string;
  modelValue?: string;
}

/**
 * SELECT COMPONENT
 */
export class SelectInputConfig extends InputConfiguration {
  public constructor(init?: Partial<SelectInputConfig>) {
    super();
    Object.assign(this, init);
  }
  public inputType = InputTypeEnum.Select;
  /**
   * Define max selectable options. Note: works only for multi optional selection
   */
  public maxSelectedOptions?: number;
  /**
   * Set to true if you want to select multiple values
   */
  public isMulti: boolean;
  public placeholder: string;
  public options: { value: string | number; label: string }[];
  /**
   * Initialized selected option
   */
  public selectedOption?: { value: string | number; label: string };
  /**
   * Initialized multiple options selected. NOTE! works only if isMulti is enabled(set to true)
   */
  public selectedMultiOptions?: { value: string | number; label: string }[];
}

export class SelectInputEvent extends InputEvent {
  public constructor(init?: Partial<SelectInputEvent>) {
    super();
    Object.assign(this, init);
  }
  /**
   * Handle when is single select
   */
  public selectedOption?: { value: string | number; label: string };
  /**
   * Handle when input is multiselect
   */
  public selectedMultiOptions?: { value: string | number; label: string }[];
  public isMulti: boolean;
}

/**
 * RADIO BUTTON/LIST COMPONENT
 */
export class RadioButtonEvent extends InputEvent {
  public constructor(init?: Partial<SelectInputEvent>) {
    super();
    Object.assign(this, init);
  }
  /**
   * Initialized selected option
   */
  public selectedOption: { value: string | number; label: string };
}

export class RadioButtonsListConfig extends InputConfiguration {
  public constructor(init?: Partial<RadioButtonsListConfig>) {
    super();
    Object.assign(this, init);
  }
  public inputType = InputTypeEnum.RadioButtonsList;
  public list: { name?: string; value: number | string; label: string }[];
  public group: string;
  /**
   * Default selected option
   */
  public selectedOption?: { value: string | number; label?: string };
}

/**
 * CHECKBOX/LIST COMPONENT
 */
export class SingleCheckbox {
  public constructor(init?: Partial<SingleCheckbox>) {
    Object.assign(this, init);
  }
  public isSelected: boolean;
  public name?: string;
  public value: string | number;
  public description?: string;
  public label: string;
  public showDescriptionInput: boolean;
}

export class CheckboxListConfig extends InputConfiguration {
  public constructor(init?: Partial<CheckboxListConfig>) {
    super();
    Object.assign(this, init);
  }
  public inputType = InputTypeEnum.CheckboxList;
  public group: string;
  public list: SingleCheckbox[];
  public selectedOptions: {
    value: number | string;
    hasDescription: boolean;
    description?: string;
  }[];
}

export class CheckboxSelectEvent extends InputEvent {
  public constructor(init?: Partial<CheckboxSelectEvent>) {
    super();
    Object.assign(this, init);
  }
  public modelValue: string;
  /**
   * List of all selected checkboxses
   */
  public data: {
    value: string | number;
    description?: string;
    hasDescription: boolean;
  }[];
  /**
   * Current checked/unchecked checkbox
   */
  public current: {
    isSelected: boolean;
    value: string | number;
    hasDescription: boolean;
    description?: string;
  };
}

/**
 * YES/NO ANSWER
 */
export class YesNoRadioButtonEvent extends InputEvent {
  public constructor(init?: Partial<YesNoRadioButtonEvent>) {
    super();
    Object.assign(this, init);
  }
  public inputType = InputTypeEnum.YesNoRadioButton;
  public answer: boolean;
}

export class YesNoRadioButtonConfig extends InputConfiguration {
  public constructor(init?: Partial<YesNoRadioButtonConfig>) {
    super();
    Object.assign(this, init);
  }
  public inputType = InputTypeEnum.YesNoRadioButton;
  public initAnswer: boolean;
}

/**
 * TEXT INPUT
 */
export class TextInputEvent extends InputEvent {
  public constructor(init?: Partial<TextInputEvent>) {
    super();
    Object.assign(this, init);
  }
  public value: string;
}

export class TextInputConfig extends InputConfiguration {
  public constructor(init?: Partial<TextInputConfig>) {
    super();
    Object.assign(this, init);
  }
  // component name
  public inputType = InputTypeEnum.Text;
  public initValue: string;
}

/**
 * NUMBER INPUT
 */
export class NumberInputEvent extends InputEvent {
  public constructor(init?: Partial<NumberInputEvent>) {
    super();
    Object.assign(this, init);
  }
  public value: number;
}

export class NumberInputConfig extends InputConfiguration {
  public constructor(init?: Partial<NumberInputConfig>) {
    super();
    Object.assign(this, init);
  }
  public name: string;
  public inputType = InputTypeEnum.Number;
  public range: { from?: number; to?: number };
  // public initValue: number;
}

/**
 * COMPONENT PROPS
 */
export class ConfigurableInputProps {
  public constructor(init?: Partial<ConfigurableInputProps>) {
    Object.assign(this, init);
  }
  public onInputChange?: (event: InputEvent) => void;
  public inputConfig: InputConfiguration;
  public uniqueKey?: string;
  public errorMsg: string;
}
