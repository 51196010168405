import { Actions } from "./action-payload";
import { SUCCESS } from "./action-types";
import { saveUser } from "./auth";

export interface IUser {
  fullName: string;
  accessToken: string;
  profileType: string;
  refreshToken: string;
  dropdownShow: boolean;
}

const initialState: IUser = {
  fullName: "",
  accessToken: "",
  profileType: "",
  refreshToken: "",
  dropdownShow: false,
};

export type UserState = Readonly<typeof initialState>;

export default (
  state: UserState = initialState,
  action: Actions,
): UserState => {
  switch (action.type) {
    case SUCCESS("LOGIN_SUBMIT"):
      const user = {
        name: action.payload.attributes["custom:fullname"],
        profileType: action.payload.attributes["custom:profileType"],
        accessToken: action.payload.signInUserSession.idToken.jwtToken,
        refreshToken: action.payload.signInUserSession.refreshToken.token,
      };
      saveUser(user);
      return {
        ...state,
        fullName: user.name,
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
        profileType: user.profileType,
      };
    case "LOGOUT_DROPDOWN_TOGGLE":
      return {
        ...state,
        dropdownShow: !state.dropdownShow,
      };
    default:
      return state;
  }
};
