import axios from "axios";
import {
  EXPERT_FETCH_ALL,
  EXPERT_FETCH_ALL_WITH_NO_PAGINATION,
  EXPERT_GET,
  EXPERT_CLEANUP,
  EXPERT_SEARCH,
  EXPERT_GET_CLEANUP,
  EXPERT_DELETE,
  EXPERT_EXPORT,
  EXPERT_DELETE_CLEANUP,
} from "./expertsTableTypes";
import { downloadDocument } from "../../../utilities/download-doc";

export const fetchAllExperts = (pageNumber, pageSize) => {
  return {
    type: EXPERT_FETCH_ALL,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/expert?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    ),
  };
};

export const fetchAllExpertsWitnNoPagination = () => {
  return {
    type: EXPERT_FETCH_ALL_WITH_NO_PAGINATION,
    payload: axios.get(`${process.env.REACT_APP_API_BASE_URL}/expert/all`),
  };
};

export const getExpert = (id: number) => {
  return {
    type: EXPERT_GET,
    payload: axios.get(`${process.env.REACT_APP_API_BASE_URL}/expert/${id}`),
  };
};

export const selectedExpertCleanup = () => {
  return {
    type: EXPERT_GET_CLEANUP,
  };
};

export const deleteExpert = (cognitoId: string) => {
  return {
    type: EXPERT_DELETE,
    payload: axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/expert/delete/${cognitoId}`,
    ),
  };
};

export const deleteExpertCleanup = () => {
  return {
    type: EXPERT_DELETE_CLEANUP,
  };
};

export const searchExpert = (name: string) => {
  return {
    type: EXPERT_SEARCH,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/expert/name/${name}`,
    ),
  };
};

export const expertExport = () => {
  return {
    type: EXPERT_EXPORT,
    payload: axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/expert/export`)
      .then((response) => downloadDocument(response, "experts.csv")),
  };
};

export const expertsCleanup = () => {
  return {
    type: EXPERT_CLEANUP,
  };
};
