import React from "react";
import { useTranslation } from "react-i18next";
import { ExpertFormWorkMode } from "../../models/expert/expert-form-work-mode";
import { IRootState } from "../../utilities/root-reducer";
import { useSelector } from "react-redux";
import Spinner from "../common/spinner/spinner";

interface IExpertProfileToolbarProps {
  formWorkMode: ExpertFormWorkMode;
  onSaveClick: () => void;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onEditClick: () => void;
}

/**
 * Represent toolbar with actions for handling the forms
 */
export default function ExpertProfileToolbar(
  props: IExpertProfileToolbarProps,
) {
  const { t } = useTranslation("expert");
  const { onSaveClick, onCancelClick, formWorkMode } = props;
  const { updateLoading } = useSelector((state: IRootState) => state.expert);
  return (
    <>
      <div className="control-button-container">
        {formWorkMode === ExpertFormWorkMode.Edit && (
          <div className="control-button-real-container">
            <button
              className="cancel"
              disabled={updateLoading}
              onClick={() => onCancelClick()}
            >
              {t("expert_cancel_changes")}
            </button>

            <button disabled={updateLoading} onClick={() => onSaveClick()}>
              {updateLoading && <Spinner />}
              {t("expert_save_profile")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
