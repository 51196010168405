export interface ISidebarTab {
  content: { name: string; value: string; isFile?: boolean; url?: string }[];
  id: number;
  name: string;
  /**
   * translation key
   */
  tlabel: string;
}

export interface ISidebar {
  title: string;
  subtitle: string;
  tabs: ISidebarTab[];
}

export class SidebarView implements ISidebar {
  public constructor(init?: Partial<SidebarView>) {
    this.tabs = init && init.tabs ? init.tabs : [];
    Object.assign(this, init);
  }
  title: string;
  subtitle: string;
  tabs: ISidebarTab[];
}

export class SidebarTab implements ISidebarTab {
  public constructor(init?: Partial<SidebarTab>) {
    Object.assign(this, init);
  }
  content: { name: string; value: string }[];
  id: number;
  name: string;
  tlabel: string;
}
