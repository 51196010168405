export const termsEN = [
  {
    title: "1. GENERAL REGULATIONS",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "1.1 Before using the “Connect2Mk” Platform i.e. “The Platform” be advised to carefully read the General Work Terms (“General Terms”).",
    class: "indent-first",
  },
  {
    title:
      "1.2 Accessing and using the Services of the Platform simultaneously signify accepting and agreeing to the General Terms. ",
    class: "indent-first",
  },
  {
    title: "1.3 ",
    class: "indent-first",
  },
  {
    title:
      "1.3.1 The General Terms apply to all subjects that will be using the Services of the Platform. If you do not agree with the General Terms you will not be allowed access to the Services of the Platform.",
    class: "indent-second",
  },
  {
    title:
      "1.3.2 The Platform Development will take place in 2 (two) phases. The first (Beginning Phase) will take part through direct participation and coordination of the collaboration between the Users of the Platform via the Administrator of Macedonia2025 (Makedonija2025). The Second (Advanced Phase) of the Platform will take place completely digitally i.e. through online communication of the Users of the Platform.",
    class: "indent-second",
  },
  {
    title:
      "1.3.3 If need occurs to make any changes, additions, further develop or completely change the General Terms, due to the advancement and operation of the Platform, to enable further usage of the Services of the Platform, its Users are obliged to accept the updated General Terms.  Contrary to it, their Users Profile shall be deleted. A distinctive notification will be pointed out in order to notify the Users of any changes in the General Terms.",
    class: "indent-second",
  },
  {
    title:
      "1.4 The aim of this Platform is to mediate between Creditors of Services (Trade Associations) and Suppliers of Services (Experts) , with the sole and exclusive purpose of their merger / collaboration and exchange of 3 (three) types of Services: Mentorship, Partnership and Investments through the Mediator. ",
    class: "indent-first",
  },
  {
    title:
      "1.5 The Founder and the Owner of the “Connect2Mk” Platform is Macedonia2025 Branch in The Republic of Macedonia. The Founder/Owner has the exclusive right to organize The General Work Terms and establish The Usage Terms of the Platform.",
    class: "indent-first",
  },
  {
    title:
      "1.6 Any type of misuse of the Services of the Platform is strictly forbidden. Harmful conduct of any of the Users of the Platform or actions contrary to the positive law regulations of the Republic of North Macedonia is prohibited. ",
    class: "indent-first",
  },
  {
    title: "2. DEFINITIONS",
    class: "indent-none font-weight--bold underlined",
  },
  {
    title:
      "2.1 Connect2Mk is a Platform that offers certain Services in the form of mediation between Creditors of Services (companies) and Suppliers of Services (experts).",
    class: "indent-first",
  },
  {
    title:
      "2.2 The role of the “Mediator” will be taken by Macedonia2025 which will advocate the collaboration between the Creditors of Services and the Suppliers of Services registered at the Platform. ",
    class: "indent-first",
  },
  {
    title:
      "2.3 Services” refers to the Services of Mentorship, Partnership Services and Services of Investments, which shall be established and/or take place between the Users of the Platform by mediation of the Mediator.",
    class: "indent-first",
  },
  {
    title:
      "2.4 “Supplier of Services” is a physical person – expert, who has not been convicted and enjoys moral reputation. ",
    class: "indent-first",
  },
  {
    title: "2.5 “Creditor of Services” is any micro, small, middle or large trade association with a prevailing Macedonian ownership, which is not in bankruptcy or liquidation.",
    class: "indent-first",
  },
  {
    title:
      "2.6 The Creditor and The Supplier both shall be called “User”.",
    class: "indent-first",
  },
  {
    title:
      "2.7 “User” is any Creditor or Supplier, user of the Services of the “Connect2Mk” Platform.",
    class: "indent-first",
  },
  {
    title: "3. TERMS OF USING THE SERVICES AND REGISTRATION",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "3.1 The Users of the Platform are obliged to register to the Platform by creating a User’s Profile.",
    class: "indent-first",
  },
  {
    title:
      "3.2 When creating the profile, the Users are obligated to state their basic information for personal identification.",
    class: "indent-first",
  },
  {
    title: "3.3 ",
    class: "indent-first",
  },
  {
    title:
      "3.3.1 The status of Creditor of Services at the Platform can solely and exclusively be obtained by a legal entity, established and registered in the territory of The Republic of North Macedonia, with a prevailing Macedonian ownership.",
    class: "indent-second",
  },
  {
    title:
      "3.3.2 The minimal necessary requirements for a Creditor of Services to register a profile, are to represent any form of trade association in terms with the Macedonian positive regulations with an exclusively prevailing Macedonian ownership, as well as not to be in bankruptcy or liquidation proceedings.",
    class: "indent-second",
  },
  {
    title:
      "3.3.3 During the registration of the profile, the Creditor of Services is obliged to send i.e. attach a current account assets (not older than 6 (six) months up to the day of attachment) to the administrator of the Platform of the Mediator. ",
    class: "indent-second",
  },
  {
    title:
      "3.3.4 If established necessary, the Mediator withholds the right to demand additional information and documents from the Creditor of Services.",
    class: "indent-second",
  },
  {
    title: "3.4 ",
    class: "indent-first",
  },
  {
    title:
      "3.4.1 The status of Supplier of Services (expert) can solely and exclusively be obtained by a physical person, in possession of scientific knowledge of a certain area or acquired experience of a particular area.",
    class: "indent-second",
  },
  {
    title:
      "3.4.2 The minimal necessary requirement for a Supplier of Services to register a profile is to be exclusively an adult who has not been convicted and enjoys a moral reputation.",
    class: "indent-second",
  },
  {
    title:
      "3.4.3 During the registration of the profile, the Supplier is obliged to attach a “CV” with credible information in a “PDF” format, written either in Macedonian or English language, particularly containing information about their education and work experience. ",
    class: "indent-second",
  },
  {
    title:
      "3.4.4 If deemed necessary, The Mediator withholds the right to demand additional information and documents from the Supplier of Services. ",
    class: "indent-second",
  },
  {
    title:
      "3.5 During the registration of the profiles, the Users are obliged to provide true and credible information and act in accordance with the established General Terms. ",
    class: "indent-first",
  },
  {
    title:
      "3.6 The Mediator has the authorization to exercise control over the registered Users and if a violation of the General Terms is determined, to possibly delete or suspend a profile. ",
    class: "indent-first",
  },
  {
    title:
      "3.7 Moreover, the Mediator withholds the right to demand additional documentation from the Users if believed necessary. On the contrary, if the necessary and requested information and/or documents are not supplied, the Mediator has the right to suspend or delete the User’s Profile. ",
    class: "indent-first",
  },
  {
    title:
      "3.8 Every 6 (six) months, the Mediator shall deliver a Questionnaire of Platform Usage to every User. The Supplier of Services and the Mediator of Services are obliged, within 1 (one) month from the day of admission of the Questionnaire, to respond to it on time, true and accurately.  If any of the Users does not respond on time, true and accurately, the Mediator has the right to suspend or delete the profile of this User. ",
    class: "indent-first",
  },
  {
    title: "4. PLATFORM USAGE FEE",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title: "4.1 Registration for the Service provider at the Platform is free.In order for Supplier of Services to use the Platform, all they need to do is to register an  account at the Platform.",
    class: "indent-first",
  },
  {
    title: "4.2 The registration is free for all Creditor of Services who will register an account earlier than 31.01.2021. The free registration is valid for a period of 1 year, from the moment of the registration.",
    class: "indent-first",
  },
  {
    title:
      " After 31.01.2021 The Creditor of Services are obliged to pay an annual fee for using the Platform in the amount of:",
    class: "indent-second",
  },
  {
    title:
      "• 3.000,00 denars, for basic usage level,",
    class: "indent-third",
  },
  {
    title:
      "• 6.000,00 denars, for advanced usage level, ",
    class: "indent-third",
  },
  {
    title:
      " • 12.000,00 denars, for premium Usage.",
    class: "indent-third",
  },
  {
    title:
      "At the basic level is included the usage of all functionalities of the Platform and the connection with Supplier of Services.	",
    class: "indent-second",
  },
  {
    title:
      "At the advanced usage level, the Creditor of Services can use all functionalities of the Platform, and receive a special advertising space on the home page (registration page) of the Platform.",
    class: "indent-second",
  },
  {
    title:
      "At the premium usage level, Creditor of Services have all the privileges of advanced use, and in addition they would be specially presented as a separate company in one of the monthly information materials of Macedonia 2025 which are sent to 3,000 people and companies in the country and abroad.",
    class: "indent-second",
  },
  {
    title:
      "Creditor Services are obliged to renew the registration at the Platform within 15 days from the expiration of the subscription deadline and receipt of the invoice issued by Macedonia2025.",
    class: "indent-second",
  },
  {
    title: "5. TYPES OF SERVICES",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "5.1 The Platform offers 3 (three) types of Services: Mentorship, Partnership and Investments.",
    class: "indent-first",
  },
  {
    title:
      "5.2 The Service – Mentorship covers providing support in the form of transferring of knowledge, experiences, advice and/or skills from the part of the Supplier of Services to the Creditor of Services. ",
    class: "indent-first",
  },
  {
    title:
      "5.3 The Service – Partnership covers assisting in the form of finding partners abroad for sales or further development of the company, or for the very same expert to become a partner of the Macedonian company. ",
    class: "indent-first",
  },
  {
    title:
      "5.4 The Service – Investments covers help acquiring investors from abroad or for the very same expert to become an investor. ",
    class: "indent-first",
  },
  {
    title: "6. MEDIATOR’S RIGHTS AND OBLIGATIONS",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "6.1 After the Creditor of Services and the Supplier of Services come together for collaboration in terms of the established Services, as a result of the mediation of the Platform, the way in which their cooperation will be regulated is determined among the Creditor of Services and the Supplier of Services, independently from the Mediator. ",
    class: "indent-first",
  },
  {
    title:
      "6.2 The Creditor of Services and the Supplier of Services are not obligated to conclude a collaboration agreement, depending on their mutual communication. However, Macedonia2025 advocates strongly towards it, with the aim of higher legal protection.",
    class: "indent-first",
  },
  {
    title:
      "6.3 If an аgreement is concluded between the Creditor of Services and the Supplier of Services, both are not obliged to pay fee to the Mediator for their mediation services. ",
    class: "indent-first",
  },
  {
    title:
      "6.4 The Users of the Platform, can donate a certain amount on a voluntary basis at their own will. Your donation will help the platform to become self-sustaining and will enable us to help more businesses from Macedonia. Each donation will be clearly indicated in our annual reports and on our website.",
    class: "indent-first",
  },
  {
    title:
      "6.5 The Creditor of Services and the Supplier of Services are obliged to implement the following regulation in their collaboration agreement: …”Any donation from the concluding parties is welcomed, for which Macedonia2025 encourages the contracting parties to donate a mutually determined amount”…",
    class: "indent-first",
  },
  {
    title:
      "6.6 The Mediator has the right to administer control over the User’s profiles and on the basis of their actions in the case of a violation of the General Terms, the Mediator has the right to suspend or delete the particular User’s profile. ",
    class: "indent-first",
  },
  {
    title:
      "6.7 The Mediator is obliged to keep safe all the classified information, i.e. all data which in their own nature is considered classified information, that are established as confidential information and/or which would reasonably be considered as confidential information of the Creditor of Services and the Supplier of Services, that shall be known as a result of enabling the Services, as defined in these General Terms. ",
    class: "indent-first",
  },
  {
    title: "7. CREDITOR OF SERVICES’ RIGHTS AND OBLIGATIONS",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "7.1  If the Creditor of Services (the trade association) that has an orderly registered profile, does not establish a collaboration with a Supplier of Services longer than 6 (six) months from the moment of the initiation of the cooperation, the Mediator shall demand additional information from the Creditor of Services.",
    class: "indent-first",
  },
  {
    title:
      "7.2 In the event of any possible change at the Creditor of Services, they are obliged to inform the Mediator not later than 1 (one) month from the occurred change. On the contrary, their profile shall be suspended or deleted. ",
    class: "indent-first",
  },
  {
    title:
      "7.3 If the Creditor of Services’ User Profile is deleted by the Mediator as a result of an established fault on the part of the Creditor of Services, due to a violation of the confirmed General Terms, the Creditor of Services has no right of a refund of the paid amount for the yearly Platform Usage Fee.",
    class: "indent-first",
  },
  {
    title:
      "7.4 The Creditor of Services shall notify the Mediator about all details concerning the received and completed Services through answering the Questionnaire, as determined in these General Terms. ",
    class: "indent-first",
  },
  {
    title:
      "7.5 The Creditor of Services is obligated to keep safe all the confidential information, i.e. all data that in their own nature are considered classified, which are established as confidential information and/or that would reasonably be considered as confidential information of the Creditor of Services and the Supplier of Services, which shall be known as a result of the enabling of the Services, as defined in these General Terms.",
    class: "indent-first",
  },
  {
    title: "8. SUPPLIER OF SERVICES’ RIGHTS AND OBLIGATIONS",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "8.1 The Supplier of Services and the Creditor of Services exclusively by mutual agreement and will shall determine if the Creditor of Services is going to pay a reward to the Supplier of Services for the completed Services, the type of reward, the amount of the reward, the manner of payment and all other information regarding this right. The Mediator is not a part, does not participate in any way in the negotiation and organization of these relations between the Supplier of Services and the Creditor of Services and takes no responsibility in terms of the fulfillment of the so agreed relations. ",
    class: "indent-first",
  },
  {
    title:
      "8.2 The Supplier of Services has the right to freely arrange other material interest from the Creditor of Services, in the form of acquiring shares, stocks, part of the reward etc. The Mediator is not a part and shall not participate in any way in the negotiation and organization of these relations between the Supplier of Services and the Creditor of Services and takes no responsibility in the fulfillment of the so agreed relations.",
    class: "indent-first",
  },
  {
    title:
      "8.3 The Supplier of Services is obliged to notify the Mediator only and solely about the possibly agreed reward and/or other material interest that was concluded between the Supplier of Services and the Creditor of Services as a result of the established collaboration among the two parties. ",
    class: "indent-first",
  },
  {
    title:
      "8.4 If the Supplier of Services denies collaboration with the Creditor of Services 3 (three) times consequently, or 5 (five) times inconsequently, in the period of 1 (one) year, the Mediator has the right to delete the Supplier of Services’ User Profile from the Platform. ",
    class: "indent-first",
  },
  {
    title:
      "8.5 The time frame and the manner in which the Supplier of Services should be available and respond to the demands of the Creditor of Services, with the purpose of establishing and organizing a collaboration between both sides, shall be a subject of a solely mutual regulation among the Supplier of Services and the Creditor of Services. ",
    class: "indent-first",
  },
  {
    title:
      "The Mediator is not a part and will not participate in the establishing of these relations between the Supplier of Services and the Creditor of Services and takes no responsibility in the fulfillment of the arranged relations. ",
    class: "indent-first",
  },
  {
    title:
      "8.6 The Supplier of Services shall notify the Mediator of Services about all the details concerning the provided Services by answering the Questionnaire, as determined in these General Terms. ",
    class: "indent-first",
  },
  {
    title:
      "8.7 The Supplier of Services is obliged to keep safe all the classified information, i.e. all the date that in their own nature is considered classified information, which is established as confidential information and/or would reasonably be considered as confidential information of the Creditor of Services and the Supplier of Services, that shall become known as a result of the enabling of the Services, as defined in these General Terms.",
    class: "indent-first",
  },
  {
    title: "9. RESPONSIBILITY",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "9.1 By accepting these General Terms, You accept that our responsibility is limited and agree to make none and/or any demands against us or our employees concerning any losses that you might suffer connected to using the Platform.",
    class: "indent-first",
  },
  {
    title: "9.2",
    class: "indent-first",
  },
  {
    title:
      "9.2.1 If your profile is suspended or deleted due to your fault, You have no right to take legal actions against the Mediator and/or any other person that is directly and/or indirectly connected to the Mediator. ",
    class: "indent-second",
  },
  {
    title:
      "9.2.2 Your profile may be suspended or deleted due to your fault if You act against and violate the General Terms of the Platform. ",
    class: "indent-second",
  },
  {
    title: "9.3",
    class: "indent-first",
  },
  {
    title:
      "9.3.1 The Mediator takes no responsibility towards the Creditor of Services and the Supplier of Services in terms of any and/or every business losses, harmful consequences and/or any possible material or non-material damage that would and/or might occur as a result of the usage of this Platform. ",
    class: "indent-second",
  },
  {
    title:
      "9.3.2 The Mediator takes no responsibility and is not accountable for any misuse on the part of the Creditor of Services and/or the Supplier of Services, unless they are informed that such misuse has occurred by a Platform User, in which case they have the right to suspend or delete the profile of the perpetrator of the misuse. ",
    class: "indent-second",
  },
  {
    title:
      "9.3.3 No responsibility shall be taken by the Mediator towards the contracting parties for the content and fulfillment of the possibly arranged relations formed exclusively between the Supplier of Services and the Creditor of Services, as well as the development of their mutual collaboration. ",
    class: "indent-second",
  },
  {
    title:
      "9.3.4 The Mediator takes no responsibility if the Creditor of Services does not manage to establish collaboration with the Supplier of Services.",
    class: "indent-second",
  },
  {
    title:
      "9.3.5 No responsibility shall be taken by the Mediator if the Supplier of Services does not provide the Creditor of Services with the suitable Services. ",
    class: "indent-second",
  },
  {
    title: "9.4",
    class: "indent-first",
  },
  {
    title:
      "9.4.1 The Supplier of Services is accountable solely and exclusively towards the Creditor of Services for what has been mutually agreed between the two parties. ",
    class: "indent-second",
  },
  {
    title:
      "9.4.2 The Supplier of Services takes no responsibility towards the Mediator concerning the content of the contract and is accountable solely towards the Creditor of Services in the framework of what has been mutually agreed. ",
    class: "indent-second",
  },
  {
    title: "9.5",
    class: "indent-first",
  },
  {
    title:
      "9.5.1 The Creditor of Services is accountable solely and exclusively towards the Supplier of Services concerning what has been mutually agreed by both parties. ",
    class: "indent-second",
  },
  {
    title:
      "9.5.2 The Creditor of Services takes no responsibility towards the Mediator for the content of the contract and is accountable solely towards the Supplier of Services in the framework of what has been mutually agreed. ",
    class: "indent-second",
  },
];
