import { Auth } from "aws-amplify";
import { poolData } from "./environment";
import jwt, { decode } from "jwt-simple";
Auth.configure(poolData);
export const cognitoAuth = Auth;

export const saveUser = (user) => {
  localStorage.setItem("fullName", user.name);
  localStorage.setItem("accessToken", user.accessToken);
  localStorage.setItem("refreshToken", user.refreshToken);
  localStorage.setItem("profileType", user.profileType);
};

export const updateToken = (token) => {
  localStorage.setItem("accessToken", token);
};

export const logoutUser = () => {
  localStorage.removeItem("fullName");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("profileType");
};

export const isAuth = () => {
  return localStorage.getItem("accessToken");
};

export const changeLanguage = (lang) => {
  localStorage.setItem("i18nextLng", lang);
};

export const getActiveLanguage = () => {
  return localStorage.getItem("i18nextLng") || "en";
};

export const getProfileType = () => {
  return localStorage.getItem("profileType");
};

export const getFullName = () => {
  return localStorage.getItem("fullName");
};

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const getCognitoId = () => {
  // get incognito id

  const accessToken = getAccessToken();

  const decodedObject = jwt.decode(accessToken, "", true) as any;
  if (decodedObject.sub) {
    return decodedObject.sub;
  }
  return null;
};
