import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "./delete-document-modal.scss";
import { useTranslation } from "react-i18next";
import Spinner from "../../../common/spinner/spinner";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../utilities/root-reducer";

interface IDeleteProfileProps {
  open: boolean;
  onDeleteSuccess: () => void;
  onCancel: () => void;
}

export default function DeleteDocumentModal(props: IDeleteProfileProps) {
  const { open, onDeleteSuccess, onCancel } = props;
  const { t } = useTranslation("profile");
  const { deleteLoading, deleteStatus } = useSelector(
    (state: IRootState) => state.company,
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <ReactModal
      isOpen={open}
      role={"dialog"}
      //arieHideApp is set to false to remove a warning in the console
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      style={modalStyle}
      className="responsive-modal-container"
    >
      <div className={"delete-modal-content"}>
        <h4>{t("document-delete-modal-title")}</h4>
        <br></br>
        <div className={"control-button-container "}>
          <button
            disabled={deleteLoading}
            className={"button-with-loading"}
            onClick={onDeleteSuccess}
          >
            {deleteStatus && <Spinner />}
            {t("profile-ok")}
          </button>
          <button
            disabled={deleteLoading}
            onClick={onCancel}
            className={"cancel"}
          >
            {t("profile-cancel")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
