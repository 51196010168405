import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { notifyError } from "../../../utils/notifications/app-notification";
import { store } from "react-notifications-component";
import { CompanyFormWorkMode } from "../../../models/company/company-form-work-mode";

import "./company-profile-summary.scss";
import { useDispatch } from "react-redux";
import { getCompany } from "../company-profile-action";

interface IProfileSummary {
  show?: boolean;
  newStatus?: boolean;
  newError?: any;
  setWorkModeView: (value: CompanyFormWorkMode) => void;
}

export const CompanyProfileSummary = (props: IProfileSummary) => {
  const [showSummary, setShowSummary] = useState(true);
  const { show, newStatus, newError, setWorkModeView } = props;
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!newStatus && newError) {
      notifyError(
        store,
        t("company-profile-create-title"),
        t("company-profile-create-error"),
      );
    }
  }, [newStatus]);

  const handleClick = () => {
    dispatch(getCompany());
    setShowSummary(!showSummary);
    setWorkModeView(CompanyFormWorkMode.View);
  };
  return (
    <>
      <div className="profile-summary-container">
        <div className="profile-summary-inner-container">
          <div className="profile-summary-image"></div>
          <h3 className="profile-summary-title">
            {t("profile-summary-title")}
          </h3>
          <button
            className="profile-summary-button"
            type="button"
            onClick={handleClick}
          >
            {t("profile-summary-button")}
          </button>
          <p className="profile-summary-info">
            {t("profile-summary-info")}
            <a href="mailto:connect2mk@macedonia2025.com">
              connect2mk@macedonia2025.com
            </a>
            <span>{t("profile-summary-info-2")}</span>
          </p>
        </div>
      </div>
    </>
  );
};
