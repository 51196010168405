export const COMPANY_NEW = "COMPANY_NEW";
export const COMPANY_UPDATE = "COMPANY_DRAFT_UPDATE";
export const COMPANY_GET_DATA = "COMPANY_GET_DATA";
export const COMPANY_GET_STATIC_DATA = "COMPANY_GET_STATIC_DATA";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_STATE_DATA";
export const COMPANY_DATA_CLEANUP = "COMPANY_DATA_CLEANUP";
export const COMPANY_DELETE = "COMPANY_DELETE";
export const COMPANY_DELETE_CLEANUP = "COMPANY_DELETE_CLEANUP";
export const COMPANY_CANCEL_CHANGES = "COMPANY_CANCEL_CHANGES";
export const COMPANY_DOCUMENT_UPLOAD = "COMPANY_DOCUMENT_UPLOAD";
export const COMPANY_ALL_DOCUMENTS_DOWNLOAD = "COMPANY_ALL_DOCUMENTS_DOWNLOAD";
export const COMPANY_DOCUMENT_DOWNLOAD = "COMPANY_DOCUMENT_DOWNLOAD";
export const COMPANY_DOCUMENT_DELETE = "COMPANY_DOCUMENT_DELETE";
export const COMPANY_DOCUMENT_CLEANUP = "COMPANY_DOCUMENT_CLEANUP";
export const COMPANY_CHANGE_MODE = "COMPANY_CHANGE_MODE";
export const COMPANY_SAVE_CHANGES = "COMPANY_SAVE_CHANGES";
export const COMPANY_DOCUMENT_UPLOAD_SAVE = "COMPANY_DOCUMENT_UPLOAD_SAVE";
export const COMPANY_FILE_UPLOAD = "COMPANY_FILE_UPLOAD";
