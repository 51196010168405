import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect, useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { withTranslation, useTranslation } from "react-i18next";
import Form from "react-validation/build/form";
import compose from "recompose/compose";
import { ITab } from "./definitions/tabs-spec";
import { ConfigurableInput } from "../../formComponents/configurable-input";
import {
  ConfigurableInputProps,
  TextInputEvent,
  SelectInputEvent,
} from "../../formComponents/definitions/InputConfigurationSpec";
import { InputTypeEnum } from "../../../models/forms/input-types-enum";
import { IExpert } from "../../../models/expert/expert";
import { expertsToGeneralTabForm } from "../../../utils/mappers/experts/expertsToGeneralTabForm";
import {
  expertTabNavigation,
  ExpertTabNavigationEnum,
} from "../../../models/expert/expert-tab-navigation-enum";

interface IGeneralInfoTab extends ITab, StateProps, DispatchProps {}

const GeneralInfoTab = (props: IGeneralInfoTab) => {
  const {
    data,
    active,
    onChange,
    validateTab,
    onTabValidation,
    formWorkMode,
    expertState,
  } = props;

  const [formInputs, setFormInputs] = useState<ConfigurableInputProps[]>([]);
  const formData = useRef<IExpert>({ ...data });

  const { staticData } = useSelector((state: IRootState) => state.expert);

  useEffect(() => {
    const formInpComponents = expertsToGeneralTabForm(data, staticData);
    setFormInputs(formInpComponents);
  }, [data]);

  useEffect(() => {
    if (validateTab === expertTabNavigation[ExpertTabNavigationEnum.General]) {
      const isValid = validate();
      onTabValidation(validateTab, isValid);
    }
  }, [validateTab]);

  useEffect(() => {
    const formInpComponents = expertsToGeneralTabForm(data, staticData, true);
    setFormInputs(formInpComponents);
  }, [expertState.cancelDataChanges]);

  /**
   * Validate the form for error
   */
  const validate = (): boolean => {
    let isFormValid = true;

    formInputs.forEach((input) => {
      // will trigger error message for error
      const inputIsValid = input.inputConfig.validate();
      if (!inputIsValid) {
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  /**
   * EVENTS SECTION
   * @param event
   */
  const handleTextInput = (event: TextInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current });
  };

  const handleSelectInput = (event: SelectInputEvent) => {
    formData.current[event.modelValue] = {
      id: event.selectedOption.value,
      name: event.selectedOption.label,
      code: "",
    };

    onChange({ ...formData.current });
  };

  const handleEvent = (component: ConfigurableInputProps) => {
    switch (component.inputConfig.inputType) {
      case InputTypeEnum.Text:
        component.onInputChange = handleTextInput;
        break;
      case InputTypeEnum.Select:
        component.onInputChange = handleSelectInput;
        break;
    }
  };

  const renderComponents = () => {
    return (
      <>
        {formInputs.map((component) => {
          if (component) {
            handleEvent(component);
          }
          return (
            <>
              {component && (
                <ConfigurableInput
                  key={"comp-" + component.uniqueKey}
                  {...component}
                />
              )}
            </>
          );
        })}
      </>
    );
  };
  return (
    <>
      {active === expertTabNavigation[ExpertTabNavigationEnum.General] && (
        <div className="profile-card-body">
          <Form className="profile-form-info">{renderComponents()}</Form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  expertState: storeState.expert,
});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("expert"),
  connect(mapStateToProps, mapDispatchToProps),
)(GeneralInfoTab);
