import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import Button from "react-validation/build/button";

import "./../../loginFormComponent/auth.scss";
import { Paper, Typography } from "@material-ui/core";
import AuthBackground from "../../authBgComponent/authBackground";

const RegistrationConfirmSuccess = () => {
  const { t } = useTranslation("signup-layout");

  const history = useHistory();

  useState(() => {
    setTimeout(() => {
      history.push("/login");
    }, 3000);
  });

  return (
    <>
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("confirmUserTitle")}{" "}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("confirmUserSubtitle")}{" "}
        </Typography>
      </AuthBackground>
      <Paper elevation={1} className="auth-form-container">
        <Form className="auth-form">
          <div className="rp-shield"></div>

          <div className="auth-input-container">
            <Link to="/login" className="auth-link">
              <Button className="auth-submit-button">{t("toLogin")}</Button>
            </Link>
          </div>
        </Form>
      </Paper>
    </>
  );
};

export default RegistrationConfirmSuccess;
