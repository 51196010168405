import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import { useHistory } from "react-router-dom";
import "./../loginFormComponent/auth.scss";
import RegistrationConfirmSuccess from "./components/registrationConfirmSuccess";
import { confirmRegistration } from "./registrationConfirmActions";
import RegistrationConfirmFailure from "./components/registrationConfirmFailure";
import RegistrationConfirmSpinner from "./components/registrationConfirmSpinner";

const RegistrationConfirm = () => {
  const { verified, errorMessage, loading } = useSelector(
    (state: IRootState) => state.registerConfirm,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handleQuery = (query) => {
    const userId = query.get("userId");
    const code = query.get("code");

    if (userId && code) {
      dispatch(confirmRegistration(userId, code));
    }
  };

  useEffect(() => {
    handleQuery(new URLSearchParams(history.location.search));
  }, []);

  return (
    <div className="auth-form-wrapper">
      {loading && <RegistrationConfirmSpinner />}
      {verified && errorMessage === 2 && <RegistrationConfirmSuccess />}
      {errorMessage === 3 && <RegistrationConfirmFailure />}
    </div>
  );
};

export default RegistrationConfirm;
