import React from "react";
import ReactLoading from "react-loading";
import "./page-loading.scss";

export interface IPageLoading {
  loadingText?: string;
  color?: string;
  type?:
    | "blank"
    | "balls"
    | "bars"
    | "bubbles"
    | "cubes"
    | "cylon"
    | "spin"
    | "spinningBubbles"
    | "spokes";
}

export default function PageLoading(props: IPageLoading) {
  const { loadingText, color = "#a41e24", type } = props;
  return (
    <div className="page-loading">
      <div className="page-loading-container">
        <div className="page-loading-icon">
          <ReactLoading type={type} color={color} />
        </div>
        {loadingText !== undefined && (
          <div className={"page-loading-text"}>{loadingText}</div>
        )}
      </div>
    </div>
  );
}
