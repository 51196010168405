import React from "react";
import { useTranslation } from "react-i18next";
import {
  expertTabNavigation,
  ExpertTabNavigationEnum,
} from "../../../models/expert/expert-tab-navigation-enum";
import { ITab } from "./definitions/tabs-spec";
import { ExpertDocumentUpload } from "../../documentUpload/expertDocumentUpload/expert-document-upload";
import { ExpertFormWorkMode } from "../../../models/expert/expert-form-work-mode";

interface IDocumentsTabProps extends ITab {
  onNewDocumentUploadSuccess: any;
  onEditDocumentUploadSuccess: any;
  showDocumentUploadRequired: boolean;
  setShowDocumentUploadRequired: (value: boolean) => void;
  setFormWorkMode: (value: ExpertFormWorkMode) => void;
}

export function DocumentsTab(props: IDocumentsTabProps) {
  const { t } = useTranslation("profile");

  const {
    active,
    data,
    onChange,
    formWorkMode,
    onNewDocumentUploadSuccess,
    onEditDocumentUploadSuccess,
    showDocumentUploadRequired,
    setShowDocumentUploadRequired,
    setFormWorkMode,
  } = props;

  return (
    <>
      {active ===
        expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo] && (
        <div className="profile-card-body">
          <ExpertDocumentUpload
            t={t}
            onChange={onChange}
            formWorkMode={formWorkMode}
            data={data}
            active={active}
            onNewDocumentUploadSuccess={onNewDocumentUploadSuccess}
            onEditDocumentUploadSuccess={onEditDocumentUploadSuccess}
            showDocumentUploadRequired={showDocumentUploadRequired}
            setShowDocumentUploadRequired={setShowDocumentUploadRequired}
            setFormWorkMode={setFormWorkMode}
          />
        </div>
      )}
    </>
  );
}

export default DocumentsTab;
