import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  TextInputConfig,
  SelectInputConfig,
  NumberInputConfig,
} from "../../../components/formComponents/definitions/InputConfigurationSpec";
import { IGeneralData } from "../../../models/general/general-data";
import { TextValidation } from "../../../components/formComponents/definitions/validation-spec";
import { generateUniqId } from "../../helpers/general-helper";
import CryptoJS from "crypto-js";
import { CompanySize } from "../../../models/company/company-size-enum";

export const companyToGeneralTabForm = (
  company: ICompany,
  staticData: any,
  generalData: IGeneralData,
  hasUniqueKey?: boolean,
): ConfigurableInputProps[] => {
  const otherServiceActivities = company.industries?.some((el) => el.id === 18);
  const otherExpertise = company.expertise?.some((el) => el.id === 20);

  // Definition
  const components = {
    name: null,
    location: null,
    industries: null,
    otherServiceActivities: null,
    expertise: null,
    otherExpertise: otherExpertise ? null : undefined,
    address: null,
    postalCodeAndCity: null,
    companySize: null,
    incomePerYear: null,
    yearsOfOperation: null,
    webLink: null,
    typeServicesProvided: null,
    companyEmail: null,
    repeatCompanyEmail: null,
  };

  const handleSelectedOptionEnum = (enumString: string) => {
    switch (enumString) {
      case CompanySize.Micro:
        return {
          value: "0-9 (micro)",
          label: "0-9 (micro)",
        };
      case CompanySize.Small:
        return {
          value: "10-49 (small)",
          label: "10-49 (small)",
        };
      case CompanySize.Medium:
        return {
          value: "50-249 (medium)",
          label: "50-249 (medium)",
        };
      case CompanySize.Large:
        return {
          value: "250+ (large)",
          label: "250+ (large)",
        };
    }
  };

  if (company) {
    components.name = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 1,
        isRequired: true,
        name: "name",
        validations: [new TextValidation({ maxLength: 50 })],
        modelValue: "name",
        classModifier: "half",
        tLabel: "companyName",
        initValue: company.name,
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("companyName"),
      errorMsg: "Field is required",
    });

    components.location = new ConfigurableInputProps({
      inputConfig: new SelectInputConfig({
        index: 2,
        isRequired: true,
        name: "location",
        modelValue: "location",
        classModifier: "half",
        tLabel: "companyLocation",
        options: staticData?.locations?.map(({ id, name }) => {
          return { value: id, label: name };
        }),
        selectedOption: company.location && {
          value: company.location.id,
          label: company.location.name,
        },
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("location"),
      errorMsg: "Location is not selected",
    });

    components.address = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 3,
        name: "address",
        modelValue: "address",
        validations: [new TextValidation({ maxLength: 255 })],
        classModifier: "half",
        tLabel: "companyAddress",
        initValue: company.address || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("companyAddress"),
    });

    components.postalCodeAndCity = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 4,
        isRequired: true,
        name: "postalCodeAndCity",
        validations: [new TextValidation({ maxLength: 255 })],
        modelValue: "postalCodeAndCity",
        classModifier: "half",
        tLabel: "companyPostalCodeAndCity",
        initValue: company.postalCodeAndCity || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("postalCodeAndCity"),
      errorMsg: "Field is required",
    });

    components.industries = new ConfigurableInputProps({
      inputConfig: new SelectInputConfig({
        index: 5,
        isRequired: true,
        isMulti: true,
        placeholder: "Select all that apply",
        name: "industries",
        modelValue: "industries",
        classModifier: "full",
        tLabel: "companyIndustry",
        options: staticData?.industries?.map(({ id, name }) => {
          return { value: id, label: name };
        }),
        selectedMultiOptions: company.industries
          ? company.industries.map((item) => {
              return { value: item.id, label: item.name };
            })
          : [],
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("industriesCompany"),
      errorMsg: "Please select at least one industry",
    });

    if (otherServiceActivities) {
      components.otherServiceActivities = new ConfigurableInputProps({
        inputConfig: new TextInputConfig({
          index: 6,
          name: "otherServiceActivities",
          validations: [new TextValidation({ maxLength: 255 })],
          modelValue: "otherServiceActivities",
          isRequired: true,
          classModifier: "full",
          tLabel: "please_specify_which_activities",
          initValue: company.otherServiceActivities || "",
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("otherServiceActivitiesCompany"),
        errorMsg: "Field is required",
      });
    }

    components.expertise = new ConfigurableInputProps({
      inputConfig: new SelectInputConfig({
        index: 7,
        isRequired: true,
        isDisabled: false,
        isMulti: true,
        placeholder: "Select all that apply",
        name: "expertise",
        modelValue: "expertise",
        classModifier: "full",
        tLabel: "company-expertise",
        options: staticData?.expertise?.map(({ id, name }) => {
          return { value: id, label: name };
        }),
        selectedMultiOptions: company.expertise
          ? company.expertise.map((item) => {
              return { value: item.id, label: item.name };
            })
          : [],
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("expertise"),
      errorMsg: "Please select at least one area of expertise",
    });

    if (otherExpertise) {
      components.otherExpertise = new ConfigurableInputProps({
        inputConfig: new TextInputConfig({
          index: 8,
          name: "otherExpertise",
          validations: [new TextValidation({ maxLength: 255 })],
          modelValue: "otherExpertise",
          isRequired: true,
          classModifier: "full",
          tLabel: "please_specify_expertise",
          initValue: company.otherExpertise || "",
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("otherExpertise"),
        errorMsg: "Field is required",
      });
    }

    components.companySize = new ConfigurableInputProps({
      inputConfig: new SelectInputConfig({
        index: 9,
        isRequired: true,
        name: "companySize",
        modelValue: "companySize",
        classModifier: "half",
        tLabel: "numberOfEmployees",
        options: generalData.companySize.map((item) => {
          return { value: item.value, label: item.label };
        }),
        selectedOption: handleSelectedOptionEnum(company.companySize),
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("companySize"),
      errorMsg: "Please select one option",
    });

    components.incomePerYear = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 10,
        isRequired: true,
        name: "incomePerYear",
        modelValue: "incomePerYear",
        classModifier: "half",
        tLabel: "companyTurnover",
        initValue: company.incomePerYear?.toLocaleString().replaceAll(",", "."),
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("incomePerYear"),
      errorMsg: "Field is required",
    });

    components.yearsOfOperation = new ConfigurableInputProps({
      inputConfig: new NumberInputConfig({
        index: 11,
        name: "yearsOfOperation",
        isRequired: true,
        modelValue: "yearsOfOperation",
        classModifier: "full",
        tLabel: "yearsOfOperation",
        initValue: company.yearsOfOperation,
        range: { from: 0 },
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("yearsOfOperation"),
      errorMsg: "Field is required",
    });

    components.webLink = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 12,
        name: "webLink",
        isRequired: true,
        modelValue: "webLink",
        classModifier: "half",
        tLabel: "companyWebsite",
        initValue: company.webLink,
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("webLink"),
      errorMsg: "Field is required",
    });

    components.typeServicesProvided = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 13,
        name: "typeServicesProvided",
        isRequired: true,
        validations: [new TextValidation({ maxLength: 500 })],
        modelValue: "typeServicesProvided",
        classModifier: "half",
        tLabel: "typeOfProducts",
        initValue: company.typeServicesProvided,
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("typeServicesProvided"),
      errorMsg: "Field is required",
    });

    components.companyEmail = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 14,
        validations: [new TextValidation({ isEmail: true, maxLength: 50 })],
        name: "companyEmail",
        modelValue: "companyEmail",
        isRequired: true,
        classModifier: "half",
        tLabel: "company_email",
        initValue: company.companyEmail || "",
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("companyEmail"),
      errorMsg: "Field is required",
    });

    components.repeatCompanyEmail = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 15,
        validations: [
          new TextValidation({
            areEmailsEqual:
              company.companyEmail?.trim() !==
              company.repeatCompanyEmail?.trim(),
          }),
        ],
        name: "repeatCompanyEmail",
        modelValue: "repeatCompanyEmail",
        isRequired: true,
        classModifier: "half",
        tLabel: "company_repeat_email",
        initValue: company.repeatCompanyEmail || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("repeatCompanyEmail"),
      errorMsg: "Field is required",
    });
  }

  const allComponents = [
    components.name,
    components.location,
    components.address,
    components.postalCodeAndCity,
    components.industries,
    components.expertise,
    components.companySize,
    components.incomePerYear,
    components.yearsOfOperation,
    components.webLink,
    components.typeServicesProvided,
    components.companyEmail,
    components.repeatCompanyEmail,
  ];

  otherServiceActivities &&
    //otherServiceActivities component should be shown after the fifth field
    allComponents.splice(5, 0, components.otherServiceActivities);

  otherExpertise &&
    otherServiceActivities &&
    allComponents.splice(7, 0, components.otherExpertise);
  otherExpertise &&
    !otherServiceActivities &&
    allComponents.splice(6, 0, components.otherExpertise);

  return [...allComponents];
};
