import React, { useEffect, useState } from "react";
import "./company-profile-landing.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { getCognitoId } from "../../../utilities/auth";
import { CompanyProfileSummary } from "./../Summary/company-profile-summary";
import { CompanyFormWorkMode } from "../../../models/company/company-form-work-mode";
import { notifyError } from "../../../utils/notifications/app-notification";
import { store } from "react-notifications-component";
import PageLoading from "../../pageLoading/page-loading";

interface ICompanyProfileLandingProps {
  onCreateProfileClick: () => void;
  showLoadingBar: boolean;
  setWorkModeView: (value: CompanyFormWorkMode) => void;
}

export default function CompanyProfileLanding(
  props: ICompanyProfileLandingProps,
) {
  const { t } = useTranslation("profile");
  const {
    getLoading,
    getError,
    newStatus,
    newLoading,
    newError,
    documentLoading,
    documentUploadError,
    hasDocument,
  } = useSelector((state: IRootState) => state.company);

  const [internalFormWorkMode, setInternalFormWorkMode] = useState(
    CompanyFormWorkMode.Done,
  );
  const [showLoading, setShowLoading] = useState(props.showLoadingBar);
  const [documentIsUploading, setDocumentIsUploading] = useState(false);

  useEffect(() => {
    //NEW COMPANY LANDING
    if (!newLoading && !newStatus) {
      setInternalFormWorkMode(CompanyFormWorkMode.Hidden);
      setShowLoading(false);
      return;
    }

    //COMPANY CREATE AND DOCUMENT UPLOAD SUCCESS
    if (newStatus && !newLoading && !newError) {
      //File is uploading
      if (documentLoading) {
        setDocumentIsUploading(true);
      }
      if (documentIsUploading) {
        if (!hasDocument && !documentLoading) {
          setInternalFormWorkMode(CompanyFormWorkMode.Summary);
          setShowLoading(false);
          notifyError(
            store,
            t("document-upload-error-desc"),
            t("document-upload-error-summary"),
          );
          return;
        } else if (hasDocument && !documentLoading) {
          //File successfully uploaded
          setInternalFormWorkMode(CompanyFormWorkMode.Summary);
          setShowLoading(false);
        } else if (documentUploadError) {
          setInternalFormWorkMode(CompanyFormWorkMode.Summary);
          setShowLoading(false);
          notifyError(
            store,
            t("document-upload-error-desc"),
            t("document-upload-error-summary"),
          );
        }
      } else {
        setInternalFormWorkMode(CompanyFormWorkMode.Summary);
        setShowLoading(false);
      }
    }
    //SIGN UP ERROR
    if (!newStatus && !newLoading && newError) {
      setInternalFormWorkMode(CompanyFormWorkMode.Hidden);
      setShowLoading(false);
      notifyError(
        store,
        t("company-profile-update-title"),
        t("company-profile-create-error"),
      );
    }
  }, [
    newStatus,
    newLoading,
    documentLoading,
    documentIsUploading,
    documentUploadError,
    hasDocument,
  ]);

  return (
    <>
      {showLoading && <PageLoading type="bars" />}

      {internalFormWorkMode === CompanyFormWorkMode.Hidden && (
        <div className={"company-landing"}>
          <div className={"company-landing-cover"}>
            <div className={"company-box-layout"}>
              <div className={"company-box"}>
                <div className={"company-box-title"}>
                  {t("profile_landing_title")}
                </div>
                <hr></hr>
                <div className={"company-box-desc"}>
                  {t("profile_landing_desc")}
                </div>
                <div className={"control-button-container"}>
                  {!getLoading && getError ? (
                    <div className={"alert error"}>
                      {t("company-profile-problem-page-load")}
                    </div>
                  ) : (
                    <>
                      <button onClick={() => props.onCreateProfileClick()}>
                        {t("profile_landing_create_lbl")}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {internalFormWorkMode === CompanyFormWorkMode.Summary && (
        <CompanyProfileSummary setWorkModeView={props.setWorkModeView} />
      )}
    </>
  );
}
