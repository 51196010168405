import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import { useHistory } from "react-router-dom";
import { getProfileType } from "../../utilities/auth";

import ResetPasswordForm from "./components/reset-password-form";
import ResetPasswordSuccess from "./components/reset-password-success";

import "./../loginFormComponent/auth.scss";
import RegistrationConfirmSpinner from "../registrationConfirm/components/registrationConfirmSpinner";

export const ForgotPassword = () => {
  const { errorMessage, loading, status, passwordSubmitSuccess } = useSelector(
    (state: IRootState) => state.resetPassword,
  );

  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);

    const userId = query.get("userId");
    const code = query.get("code");

    if (userId === null || code === null) {
      history.push("/login");
    }
  }, []);

  return (
    <div className="auth-form-wrapper">
      {passwordSubmitSuccess ? <ResetPasswordSuccess /> : <ResetPasswordForm />}
    </div>
  );
};

export default ForgotPassword;
