import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./forgot-password.scss";
//import { loginFormAction, deleteError } from "./forgot-password-actions";
import { IRootState } from "../../utilities/root-reducer";
import {
  Link,
  RouteComponentProps,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { email, password, required } from "../../utilities/validation";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { ValidationError } from "../../utilities/validationError";
import compose from "recompose/compose";
import { getProfileType, isAuth } from "../../utilities/auth";
import "./../loginFormComponent/auth.scss";

import ForgotPasswordForm from "./components/forgot-password-form";
import ForgotPasswordSubmit from "./components/forgot-password-submit";
import Spinner from "../common/spinner/spinner";

export const ForgotPassword = () => {
  const { t } = useTranslation("forgot-password");
  const dispatch = useDispatch();
  const { errorMessage, loading, status, emailSubmitSuccess } = useSelector(
    (state: IRootState) => state.forgotPassword,
  );

  const [emailValue, setEmailValue] = useState("");

  const history = useHistory();

  useEffect(() => {
    const profileType = getProfileType();
    switch (profileType) {
      case "company":
        history.push("/company");
        break;
      case "expert":
        history.push("/expert");
        break;
      case "admin":
        history.push("/admin");
        break;
    }
  }, []);

  const getEmailValue = (value: string) => setEmailValue(value);

  return (
    <>
      <div className="auth-form-container">
        {emailSubmitSuccess ? (
          <ForgotPasswordSubmit emailWrittenByUser={emailValue} />
        ) : (
          <ForgotPasswordForm onGetEmailValue={getEmailValue} />
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
