import { IExpert } from "../../../models/expert/expert";
import {
  IExpertRequest,
  ExpertRequest,
} from "../../../models/expert/expert-request";

export const mapToExpertRequest = (expert: IExpert): IExpertRequest => {
  const expertRequest = new ExpertRequest();
  expertRequest.cognitoId = expert.cognitoId;
  expertRequest.documents = expert.documents;
  expertRequest.documentUrl = expert.documentUrl;
  expertRequest.companyName = expert.companyName;
  expertRequest.email = expert.email;
  expertRequest.repeatEmail = expert.repeatEmail;
  expertRequest.firstName = expert.firstName;
  expertRequest.lastName = expert.lastName;
  expertRequest.jobTitle = expert.jobTitle;
  expertRequest.phoneNumber = expert.phoneNumber;
  expertRequest.linkedinProfile = expert.linkedinProfile;
  expertRequest.fieldsOfInterest = expert.fieldsOfInterest;
  expertRequest.regions = expert.regions
    ? expert.regions.map((item) => {
        return { id: item.id };
      })
    : [];
  expertRequest.hasDocument = expert.hasDocument;

  expertRequest.workWithStartups = expert.workWithStartups || false;
  expertRequest.country = expert.country
    ? { id: expert.country.id }
    : { id: 0 };
  expertRequest.industries = expert.industries
    ? expert.otherServiceActivities === undefined
      ? expert.industries.map((industry) => {
          return { name: industry.name };
        })
      : [
          ...expert.industries
            .filter((item) => item.id !== 18)
            .map((industry) => {
              return { name: industry.name };
            }),
          { name: expert.otherServiceActivities },
        ]
    : [];
  expertRequest.expertise = expert.expertise
    ? expert.otherExpertise === undefined
      ? expert.expertise.map((expertise) => {
          return { name: expertise.name };
        })
      : [
          ...expert.expertise
            .filter((item) => item.id !== 20)
            .map((expertise) => {
              return { name: expertise.name };
            }),
          { name: expert.otherExpertise },
        ]
    : [];
  expertRequest.supportTypes = expert.supportTypes
    ? expert.supportTypes.map((item) => {
        return { id: item.id };
      })
    : [];
  expertRequest.numberOfCompanies = expert.numberOfCompanies;
  expertRequest.expectationsFromThePlatform =
    expert.expectationsFromThePlatform;

  return expertRequest;
};
