import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ExpertFormWorkMode } from "../../../models/expert/expert-form-work-mode";
import {
  expertTabNavigation,
  ExpertTabNavigationEnum,
} from "../../../models/expert/expert-tab-navigation-enum";
import { useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import Spinner from "../../common/spinner/spinner";

interface ITabsStepperNavigation {
  activeTab: string;
  onNext: (tabName: string) => void;
  onPrevious: (tabName: string) => void;
  formWorkMode: ExpertFormWorkMode;
  onFinish: (tabName: string) => void;
}

/**
 * @description
 * @param props
 */
export default function TabsStepperNavigation(props: ITabsStepperNavigation) {
  const { activeTab, onNext, onPrevious, onFinish, formWorkMode } = props;
  const { newLoading } = useSelector((state: IRootState) => state.expert);
  /**
   * GENERAL
   */
  const { t } = useTranslation("profile");
  useEffect(() => {}, [formWorkMode]);

  const handleNextClick = () => {
    if (typeof onNext === "function") {
      if (
        activeTab ===
        expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo]
      ) {
        onFinish(expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo]);
      } else {
        const index = expertTabNavigation.indexOf(activeTab);
        if (index !== -1) {
          const nextTabIndex = index + 1;
          onNext(expertTabNavigation[nextTabIndex]);
        }
      }
    }
  };

  const handlePreviousClick = () => {
    if (typeof onPrevious === "function") {
      const index = expertTabNavigation.indexOf(activeTab);
      if (index !== -1) {
        const prevTabIndex = index - 1;
        onPrevious(expertTabNavigation[prevTabIndex]);
      }
    }
  };

  return (
    <>
      {formWorkMode === ExpertFormWorkMode.New && (
        <div className={"tabs-steper control-button-container"}>
          {activeTab !==
            expertTabNavigation[ExpertTabNavigationEnum.General] && (
            <button
              disabled={newLoading}
              className={"cancel"}
              onClick={() => handlePreviousClick()}
            >
              {t("profile_tab_prev")}
            </button>
          )}
          <button disabled={newLoading} onClick={() => handleNextClick()}>
            {newLoading && <Spinner />}
            {activeTab ===
            expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo]
              ? t("profile_tab_finish")
              : t("profile_tab_next")}
          </button>
        </div>
      )}
    </>
  );
}
