import { Actions } from "../../../utilities/action-payload";
import { REQUEST, FAILURE, SUCCESS } from "../../../utilities/action-types";
import {
  FETCH_ALL_COMPANIES,
  FETCH_ALL_COMPANIES_NO_PAGINATION,
  COMPANY_CLEANUP,
  COMPANY_DELETE,
  COMPANY_DELETE_CLEANUP,
  COMPANY_SEARCH,
  COMPANY_GET,
  COMPANY_GET_CLEANUP,
  COMPANY_GET_AVALIABLE_EXPERTS,
  COMPANY_GET_AVALIABLE_EXPERTS_CLEANUP,
  COMPANY_MATCH_EXPERT,
  COMPANY_MATCH_EXPERT_CLEANUP,
  COMPANY_UNMATCH_EXPERT,
  COMPANY_UNMATCH_EXPERT_CLEANUP,
} from "./companyTableTypes";
import { ICompany } from "../../../models/company/company";
import { IPagination, Pagination } from "../../../models/general/pagination";

export interface AdminCompaniesState {
  data: IPagination;
  error: object;
  loading: boolean;
  deleteLoading: boolean;
  deleteStatus: boolean;
  getCompanyLoading: boolean;
  getCompanyStatus: boolean;
  availableExpertsLoading: boolean;
  company: ICompany;
  matchedExpertStatus: boolean;
  unmatchedExpertStatus: boolean;
  matchedExpertLoading: boolean;
  unmatchedExpertLoading: boolean;
  availableExperts: { id: number; firstName: string; lastName: string }[];
  allCompaniesNoPagination: any;
}

const initialState: AdminCompaniesState = {
  data: null,
  error: null,
  loading: true,
  deleteStatus: false,
  deleteLoading: false,
  getCompanyLoading: false,
  getCompanyStatus: false,
  availableExpertsLoading: false,
  company: null,
  matchedExpertStatus: false,
  unmatchedExpertStatus: false,
  matchedExpertLoading: false,
  unmatchedExpertLoading: false,
  availableExperts: null,
  allCompaniesNoPagination: [],
};

export default (
  state: AdminCompaniesState = initialState,
  action: Actions,
): AdminCompaniesState => {
  switch (action.type) {
    case REQUEST(FETCH_ALL_COMPANIES):
      return {
        ...state,
        data: new Pagination(),
        error: null,
        loading: true,
      };
    case FAILURE(FETCH_ALL_COMPANIES):
      return {
        ...state,
        data: new Pagination(),
        error: action.payload,
        loading: false,
      };
    case SUCCESS(FETCH_ALL_COMPANIES):
      return {
        ...state,
        data: action.payload.data || new Pagination(),
        error: null,
        loading: false,
      };

    case REQUEST(COMPANY_DELETE):
      return {
        ...state,
        deleteStatus: false,
        deleteLoading: true,
        error: null,
      };
    case FAILURE(COMPANY_DELETE):
      return {
        ...state,
        deleteStatus: false,
        error: action.payload,
        deleteLoading: false,
      };
    case SUCCESS(COMPANY_DELETE):
      return {
        ...state,
        deleteStatus: true,
        error: null,
        loading: false,
        deleteLoading: false,
      };

    case COMPANY_DELETE_CLEANUP:
      return {
        ...state,
        data: new Pagination(),
        deleteStatus: false,
        error: null,
        loading: false,
        deleteLoading: false,
      };

    case REQUEST(COMPANY_SEARCH):
      return {
        ...state,
        data: new Pagination(),
        loading: true,
        error: null,
      };
    case FAILURE(COMPANY_SEARCH):
      return {
        ...state,
        data: new Pagination(),
        loading: false,
        error: action.payload,
      };
    case SUCCESS(COMPANY_SEARCH):
      return {
        ...state,
        data:
          new Pagination({ content: [...action.payload.data] }) ||
          new Pagination(),
        loading: false,
        error: null,
      };

    case REQUEST(COMPANY_GET_AVALIABLE_EXPERTS):
      return {
        ...state,
        availableExperts: null,
        availableExpertsLoading: true,
        error: null,
      };
    case FAILURE(COMPANY_GET_AVALIABLE_EXPERTS):
      return {
        ...state,
        availableExperts: null,
        error: action.payload,
        availableExpertsLoading: false,
      };
    case SUCCESS(COMPANY_GET_AVALIABLE_EXPERTS):
      return {
        ...state,
        availableExperts: action.payload.data,
        error: null,
        availableExpertsLoading: false,
      };

    case COMPANY_GET_AVALIABLE_EXPERTS_CLEANUP:
      return {
        ...state,
        data: null,
        error: null,
        loading: false,
        availableExpertsLoading: false,
      };

    case REQUEST(COMPANY_MATCH_EXPERT):
      return {
        ...state,
        matchedExpertStatus: false,
        matchedExpertLoading: true,
        error: null,
      };
    case FAILURE(COMPANY_MATCH_EXPERT):
      return {
        ...state,
        matchedExpertStatus: false,
        error: action.payload,
        matchedExpertLoading: false,
      };
    case SUCCESS(COMPANY_MATCH_EXPERT):
      return {
        ...state,
        matchedExpertStatus: true,
        error: null,
        matchedExpertLoading: false,
      };

    case COMPANY_MATCH_EXPERT_CLEANUP:
      return {
        ...state,
        data: new Pagination(),
        matchedExpertStatus: false,
        error: null,
        matchedExpertLoading: false,
      };

    case REQUEST(COMPANY_UNMATCH_EXPERT):
      return {
        ...state,
        unmatchedExpertStatus: false,
        unmatchedExpertLoading: true,
        error: null,
      };
    case FAILURE(COMPANY_UNMATCH_EXPERT):
      return {
        ...state,
        unmatchedExpertStatus: false,
        error: action.payload,
        unmatchedExpertLoading: false,
      };
    case SUCCESS(COMPANY_UNMATCH_EXPERT):
      return {
        ...state,
        unmatchedExpertStatus: true,
        error: null,
        unmatchedExpertLoading: false,
      };

    case COMPANY_UNMATCH_EXPERT_CLEANUP:
      return {
        ...state,
        data: new Pagination(),
        unmatchedExpertStatus: false,
        error: null,
        unmatchedExpertLoading: false,
      };

    case REQUEST(COMPANY_GET):
      return {
        ...state,
        company: null,
        getCompanyLoading: true,
        getCompanyStatus: false,
        error: null,
      };
    case FAILURE(COMPANY_GET):
      return {
        ...state,
        company: null,
        getCompanyLoading: false,
        getCompanyStatus: false,
        error: action.payload,
      };
    case SUCCESS(COMPANY_GET):
      return {
        ...state,
        company: action.payload.data,
        getCompanyLoading: false,
        getCompanyStatus: true,
        error: null,
      };

    case COMPANY_GET_CLEANUP:
      return {
        ...state,
        company: null,
        getCompanyLoading: false,
        getCompanyStatus: false,
        error: null,
      };

    case REQUEST(FETCH_ALL_COMPANIES_NO_PAGINATION):
      return {
        ...state,
        allCompaniesNoPagination: null,
        loading: true,
        error: null,
      };
    case FAILURE(FETCH_ALL_COMPANIES_NO_PAGINATION):
      return {
        ...state,
        allCompaniesNoPagination: null,
        loading: false,
        error: action.payload,
      };
    case SUCCESS(FETCH_ALL_COMPANIES_NO_PAGINATION):
      return {
        ...state,
        allCompaniesNoPagination: action.payload.data,
        loading: false,
        error: null,
      };

    case COMPANY_CLEANUP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
