/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  CheckboxListConfig,
  CheckboxSelectEvent,
  SingleCheckbox,
} from "./definitions/InputConfigurationSpec";
import { useTranslation } from "react-i18next";

interface ISingleCheckboxProps {
  onSelect: (
    isSelected: boolean,
    value: string | number,
    hasDescription: boolean,
    description?: string,
  ) => void;
  onDescriptionChange: (
    isSelected: boolean,
    value: string | number,
    hasDescription: boolean,
    description?: string,
  ) => void;
  checkboxData: SingleCheckbox;
  group: string;
  isDisabled: boolean;
}

export const SingleCheckboxInput = (props: ISingleCheckboxProps) => {
  const { t } = useTranslation("profile");
  const {
    onSelect,
    checkboxData,
    group,
    isDisabled,
    onDescriptionChange,
  } = props;

  const [selected, setSelected] = useState<boolean>(checkboxData.isSelected);
  const [descriptionVal, setDescriptionVal] = useState<string>(
    checkboxData.description || "",
  );

  const [showDescription, setShowDescription] = useState<boolean>(
    checkboxData.showDescriptionInput && checkboxData.isSelected,
  );

  const [showErrorTextField, setShowErrorTextField] = useState(false);

  useEffect(() => {
    if (!descriptionVal) setShowErrorTextField(true);
    else setShowErrorTextField(false);
  }, [descriptionVal]);

  const handleCheckboxChange = (event) => {
    const isSelected = !selected;
    if (typeof onSelect === "function") {
      onSelect(
        isSelected,
        checkboxData.value,
        checkboxData.showDescriptionInput,
        descriptionVal,
      );
    }
    if (checkboxData.showDescriptionInput && isSelected) {
      setShowDescription(true);
    }
    setSelected(isSelected);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const inpText = e.currentTarget.value;
    if (typeof onDescriptionChange === "function") {
      onDescriptionChange(
        selected,
        checkboxData.value,
        checkboxData.showDescriptionInput,
        inpText,
      );
    }
    setDescriptionVal(inpText);
  };

  return (
    <div
      className="multi-checkbox"
      key={`${checkboxData.value}_${checkboxData.name}`}
    >
      <label className={"checkbox-wrapper " + (selected && "selected")}>
        <input
          type="checkbox"
          name={group || checkboxData.name}
          disabled={isDisabled}
          onChange={handleCheckboxChange}
          value={checkboxData.value}
        />
        <span className="custom-checkbox"></span>
        <span className="checkbox-label">{checkboxData.label}</span>
      </label>
      {showDescription && selected && (
        <>
          <input
            key={`cxb_inp_${checkboxData.value}_${checkboxData.name}`}
            type="text"
            disabled={isDisabled}
            onChange={handleInputChange}
            value={descriptionVal}
          />
          {showErrorTextField && (
            <span className="alert error">{t("company_please_specify")}</span>
          )}
        </>
      )}
    </div>
  );
};

/**
 * MULTIPLE COMPONENTS
 * @param props
 */
export default function MultiCheckbox(props: {
  inputConfig: CheckboxListConfig;
  onChange: (event: CheckboxSelectEvent) => void;
  errorMsg: string;
}) {
  const selectedValues = useRef<
    {
      value: string | number;
      hasDescription: boolean;
      description?: string;
    }[]
  >([]);

  const { inputConfig, onChange, errorMsg } = props;
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMsg);
  const [isDisabled, setIsDisabled] = useState(inputConfig.isDisabled || false);

  inputConfig.setDisabled = (disabled) => {
    setIsDisabled(disabled);
    inputConfig.isDisabled = disabled;
    if (hasError) {
      setHasError(false);
    }
  };

  useEffect(() => {
    let checkBoxListItems = [];
    if (inputConfig.selectedOptions.length > 0) {
      checkBoxListItems = inputConfig.list?.map((item) => {
        const selectedOption = inputConfig.selectedOptions.find(
          (selectedOption) => selectedOption.value === item.value,
        );
        if (selectedOption) {
          return {
            ...item,
            isSelected: true,
            value: selectedOption.value,
            showDescriptionInput: selectedOption.hasDescription,
            description: selectedOption.hasDescription
              ? selectedOption.description
              : "",
          };
        }

        return item;
      });
      selectedValues.current = [...inputConfig.selectedOptions];
      setCheckBoxList(checkBoxListItems);
    } else {
      setCheckBoxList([...inputConfig.list]);
    }
  }, []);

  useEffect(() => {
    setIsDisabled(inputConfig.isDisabled);
    if (inputConfig.isDisabled) {
      if (hasError) {
        setHasError(false);
      }
    }
  }, [inputConfig.isDisabled]);

  const handleCboxSelected = (
    isSelected: boolean,
    value: string | number,
    hasDescription: boolean,
    description?: string,
  ) => {
    if (hasError) {
      setHasError(false);
    }
    // set event
    const cBox = {
      value,
      hasDescription,
      description,
    };

    if (isSelected) {
      selectedValues.current.push(cBox);
    } else {
      const withoutUncheckedList = selectedValues.current.filter(
        (item) => item.value !== value,
      );
      selectedValues.current = [...withoutUncheckedList];
    }

    const allSelected = [...selectedValues.current];
    const event = new CheckboxSelectEvent({
      modelValue: inputConfig.modelValue,
      current: { ...cBox, isSelected },
      data: allSelected,
    });
    // fire event
    if (typeof onChange === "function") {
      onChange(event);
    }
  };

  const handleDescriptionChange = (
    isSelected,
    value,
    hasDescription,
    description,
  ) => {
    if (hasError) {
      setHasError(false);
    }
    const index = selectedValues.current.findIndex(
      (item) => item.value === value,
    );
    if (index > -1) {
      selectedValues.current[index].description = description;
      const event = new CheckboxSelectEvent({
        modelValue: inputConfig.modelValue,
        current: { isSelected, value, hasDescription, description },
        data: [...selectedValues.current],
      });
      if (typeof onChange === "function") {
        onChange(event);
      }
    }
  };

  //Check if the last two checkboxes with text field are selected
  const appliedStandardsWithText = inputConfig.selectedOptions.filter(
    (el) => el.value === 6 || el.value === 7,
  );

  const appliedStandardsWithTextHasDescriptionLength = appliedStandardsWithText.filter(
    (el) => el.hasDescription,
  ).length;

  //cover two scenarios: when only one of the two options is selected, and when two of the
  //options are selected
  const appliedStandardsWithTextHasDescription =
    appliedStandardsWithText.length === 1
      ? appliedStandardsWithTextHasDescriptionLength === 1
      : appliedStandardsWithTextHasDescriptionLength === 2;

  inputConfig.validate = () => {
    // do not check for validation if input is disabled
    if (inputConfig.isDisabled) {
      return true;
    }

    if (inputConfig.isRequired) {
      if (selectedValues.current.length === 0) {
        setHasError(true);
        setError(error);
        return false;
      }

      if (
        appliedStandardsWithText.length > 0 &&
        !appliedStandardsWithTextHasDescription
      ) {
        setHasError(true);
        setError("");
        return false;
      }

      return true;
    }
    return true;
  };

  return (
    <>
      <div className="multi-checkboxes">
        {checkBoxList?.map((checkbox, index) => {
          return (
            <SingleCheckboxInput
              key={"shbox_" + index}
              onSelect={handleCboxSelected}
              isDisabled={isDisabled}
              group={inputConfig.group}
              checkboxData={checkbox}
              onDescriptionChange={handleDescriptionChange}
            />
          );
        })}
      </div>
      {hasError && <div className={"alert error"}>{error}</div>}
    </>
  );
}
