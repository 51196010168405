import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import "./expert-modal.scss";

interface IDeleteProfileProps {
  open: boolean;
  onCancel: () => void;
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default function DeleteProfileModal(props: IDeleteProfileProps) {
  const { open, onCancel } = props;
  const { t } = useTranslation("expert");

  return (
    <ReactModal
      isOpen={open}
      role={"dialog"}
      shouldCloseOnEsc={false}
      style={modalStyle}
      className="responsive-modal-container"
    >
      <div className={"delete-modal-content"}>
        <h4>{t("expert-modal-delete-desc")}</h4>
        <h4>
          {t("expert-modal-delete-desc-contact")}
          <a href="mailto: connect2mk@macedonia2025.com ">
            connect2mk@macedonia2025.com
          </a>{" "}
          or{" "}
          <a href="mailto: bojana@macedonia2025.com">
            bojana@macedonia2025.com
          </a>
        </h4>
        <br></br>
        <div className={"control-button-container"}>
          <button onClick={onCancel}>{t("expert-ok")}</button>
        </div>
      </div>
    </ReactModal>
  );
}
