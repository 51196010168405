export interface IValidation {
  validate: (text: string) => { isValid: boolean; errorMsg: string };
}

export class TextValidation implements IValidation {
  public constructor(init?: Partial<TextValidation>) {
    Object.assign(this, init);
  }

  public maxLength?: number;
  public isEmail?: boolean;
  public isLinkedIn?: boolean;
  public isPhoneNumber?: boolean;
  public areEmailsEqual?: boolean;

  getErrorMsg = (error: string) => {
    switch (error) {
      case "maxLength":
        return `Maximum of ${this.maxLength} characters exceeded`;
      case "isEmail":
        return "Please enter valid email address";
      case "areEmailsEqual":
        return "Emails are not equal";
      case "isLinkedIn":
        return "Please enter a valid LinkedIn profile link";
      case "isPhoneNumber":
        return "Please enter a valid contact number, e.g. +1 800 000000";
      default:
        break;
    }
  };

  public validate = (text: string) => {
    if (this.maxLength !== undefined) {
      if (text.length > this.maxLength) {
        return { isValid: false, errorMsg: this.getErrorMsg("maxLength") };
      }
    }
    if (this.isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(text);
      if (!isValid) {
        return { isValid: false, errorMsg: this.getErrorMsg("isEmail") };
      }
    }

    if (this.areEmailsEqual) {
      return { isValid: false, errorMsg: this.getErrorMsg("areEmailsEqual") };
    }

    if (this.isLinkedIn) {
      const re = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm;
      const isValid = re.test(text);
      if (!isValid) {
        return { isValid: false, errorMsg: this.getErrorMsg("isLinkedIn") };
      }
    }

    if (this.isPhoneNumber) {
      const re = /^(\s{1,3})?\+(\s{1,3})?(?:[0-9]●?(\s{1,3})?){6,17}(\s{1,3})?[0-9](\s{1,3})?$/g;

      const isValid = re.test(text);
      if (!isValid) {
        return { isValid: false, errorMsg: this.getErrorMsg("isPhoneNumber") };
      }
    }

    return { isValid: true, errorMsg: "" };
  };
}
