import React from "react";
import ReactPaginate from "react-paginate";

import "./paginationComponent.scss";

export const PaginationComponent = ({ totalPages, changePage }) => {
  const handlePageChange = (pageObj) => {
    changePage({ pageNumber: pageObj.selected });
  };

  return (
    <div className="pagination">
      {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={totalPages ? totalPages : 1}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          breakClassName={"control"}
          pageClassName={"control"}
          previousClassName={"control"}
          nextClassName={totalPages ? "control" : "disabled"}
          activeClassName={"active"}
        />
      )}
    </div>
  );
};
