import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@material-ui/core";
import Icon from "../../../../assets/images/footer-images/icon.svg";
import Icon2 from "../../../../assets/images/footer-images/icon-1.svg";
import Icon3 from "../../../../assets/images/footer-images/icon-2.svg";

import "./homePageFooter.scss";

const data = [
  {
    id: 1,
    paragraph: "+389 78 900 222",
    secondParagraph: "+389 2 3161 015",
    icon: Icon,
  },
  {
    id: 2,
    paragraph: "bojana@macedonia2025.com",
    secondParagraph: "office@macedonia2025.com",
    icon: Icon2,
  },
  {
    id: 3,
    paragraph: "st. 8ma Udarna Brigrada 20 A",
    secondParagraph: "Skopje, Macedonia",
    icon: Icon3,
  },
];

export const HomePageFooter: React.FC = () => {
  const { t } = useTranslation("homepage");

  return (
    <>
      <Box className="footer-wrapper" style={{ position: "relative" }}>
        <Typography className="footer-heading" variant="h2">
          {t("getInTouchWithUs")}
        </Typography>
        <Paper elevation={1} className="footer-cards-wrapper">
          <div className="line"></div>
          {data.map(({ id, paragraph, secondParagraph, icon }) => (
            <Paper key={id} className="card-wrapper">
              <img className="card-icon" src={icon} alt="Icon" />
              <Typography className="card-paragraph" variant="body2">
                {t(paragraph)}
              </Typography>
              <Typography className="card-paragraph" variant="body2">
                {t(secondParagraph)}
              </Typography>
            </Paper>
          ))}
        </Paper>
      </Box>
      <div className="footer-bg">
        <Box className="primary-color-bg">
          <Typography className="footer-paragraph" variant="body2">
            {t("developedAndDonated")}{" "}
            <a
              className="footer-link"
              target="_blank"
              href="https://intertec.io/"
            >
              intertec.io
            </a>
          </Typography>
        </Box>
      </div>
    </>
  );
};
