export interface ICompanyRequest {
  id: number;
  cognitoId: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber?: string;
  contactEmail: string;
  appliedStandards: {
    appliedStandardId?: number;
    hasDescription?: boolean | undefined;
    description?: string;
  }[];
  export: boolean;
  exportRegions: { id: number }[];
  incomePerYear: number;
  increaseDifficultType: number;
  developmentPhase: string;
  growthPhase: string;
  industries: {
    id?: number;
    name: string;
  }[];
  expertise: {
    id?: number;
    name: string;
  }[];
  location: { id: number };
  address: string;
  postalCodeAndCity: string;
  name: string;
  companySize: string;
  companyEmail: string;
  repeatCompanyEmail: string;
  percentageProduction: number;
  percentageOfExport: number;
  preferenceForRegionExpanding: boolean;
  regions: { id: number }[];
  startup: boolean;
  problemsThatServiceAddresses: string;
  innovativenessOfTheService: string;
  typeOfClientsTargeting: string;
  serviceQualityAssuranceSystem: boolean;
  supportTypes: { id: number }[];
  technologyEquipmentId: number;
  typeServicesProvided: string;
  webLink: string;
  yearsOfOperation: number;
  expectationsFromExpertSupport: string;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
}

export class CompanyRequest implements ICompanyRequest {
  id: number;
  cognitoId: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber?: string;
  contactEmail: string;
  appliedStandards: {
    appliedStandardId?: number;
    hasDescription?: boolean | undefined;
    description?: string;
  }[];
  export: boolean;
  exportRegions: { id: number }[];
  incomePerYear: number;
  increaseDifficultType: number;
  developmentPhase: string;
  growthPhase: string;
  industries: {
    id?: number;
    name: string;
  }[];
  expertise: {
    id?: number;
    name: string;
  }[];
  location: { id: number };
  address: string;
  postalCodeAndCity: string;
  name: string;
  supportTypes: { id: number }[];
  companySize: string;
  companyEmail: string;
  repeatCompanyEmail: string;
  percentageProduction: number;
  percentageOfExport: number;
  preferenceForRegionExpanding: boolean;
  regions: { id: number }[];
  startup: boolean;
  problemsThatServiceAddresses: string;
  innovativenessOfTheService: string;
  typeOfClientsTargeting: string;
  serviceQualityAssuranceSystem: boolean;
  technologyEquipmentId: number;
  typeServicesProvided: string;
  webLink: string;
  yearsOfOperation: number;
  expectationsFromExpertSupport: string;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
}
