import validator from "validator";
import React from "react";
import "./validation-error.scss";

export const required = (value: string) => {
  if (!value) {
    return <span className="validationError">Field is required</span>;
  }
};

export const email = (value) => {
  if (value && !validator.isEmail(value)) {
    return (
      <span className="validationError"> {value} is not a valid email.</span>
    );
  }
};

export const password = (value, props, components) => {
  if (value && value.length < 8) {
    return (
      <span className="validationError">
        The password must be 8 or more characters.
      </span>
    );
  }

  if (value && !/[A-Z]/.test(value)) {
    return (
      <span className="validationError">
        You don't have any capital letters
      </span>
    );
  } else if (
    components.confirmPassword &&
    value !== components.confirmPassword[0].value
  ) {
    return <span className="validationError">Confirm Password</span>;
  }
};

export const isEqual = (value, props, components) => {
  const bothUsed =
    components.password[0].isUsed && components.confirmPassword[0].isUsed;
  const bothChanged =
    components.password[0].isChanged && components.confirmPassword[0].isChanged;
  if (
    bothChanged &&
    bothUsed &&
    components.password[0].value !== components.confirmPassword[0].value
  ) {
    return (
      <div>
        <span className="validationError">Passwords are not equal.</span>
      </div>
    );
  }
};
