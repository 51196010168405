import { AssignmentReturnRounded } from '@material-ui/icons';
import React from 'react';


import './copyright.scss'


const Copyright = () => {

   
    return(  
    <div className="copyright-container">
        <div className="copyright-text-container">
    <p className="copyright-text">
    ©2020 Macedonia2025 </p> <p className="copyright-text"> Developed and donated by{" "}
    <a href="https://intertec.io"> Intertec</a>
  </p></div></div>)
}

export default Copyright;