import React, { useState, useEffect } from "react";
import {
  TextInputConfig,
  TextInputEvent,
} from "./definitions/InputConfigurationSpec";
import "./../../styles/profileForms/shared-profile-styles.scss";

export default function TextInput(props: {
  inputConfig: TextInputConfig;
  onChange: (event: TextInputEvent) => void;
  errorMsg: string;
}) {
  const { inputConfig, onChange, errorMsg } = props;
  const [currValue, setCurrValue] = useState(inputConfig.initValue);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMsg);
  const [isDisabled, setIsDisabled] = useState(inputConfig.isDisabled || false);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    //add dot thousands separator for the income field while the user is typing
    const inpText =
      inputConfig.modelValue === "incomePerYear"
        ? e.currentTarget.value
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : e.currentTarget.value;
    setHasError(false);
    setCurrValue(inpText);
    if (typeof onChange === "function") {
      onChange({ value: inpText, modelValue: inputConfig.modelValue });
    }
  };

  inputConfig.setDisabled = (disabled) => {
    setIsDisabled(disabled);
    inputConfig.isDisabled = disabled;
    if (hasError) {
      setHasError(false);
    }
  };

  useEffect(() => {
    if (inputConfig.isDisabled) {
      if (hasError) {
        setHasError(false);
      }
    }
  }, [inputConfig.isDisabled]);

  inputConfig.validate = () => {
    // do not check for validation id input is disabled
    if (inputConfig.isDisabled) {
      return true;
    }

    if (
      inputConfig.isRequired ||
      // for some inputs like for example phone number field is not required
      // but when we have entered some numbers we need to validate
      (inputConfig.validations && currValue.trim().length > 0)
    ) {
      if (currValue === undefined) {
        setHasError(true);
        setError(errorMsg);
        return false;
      }

      const text = currValue.trim();
      const isValid = text.length > 0;
      if (!isValid) {
        setHasError(true);
        setError(errorMsg);
        return false;
      }

      let isEmailValid = true;
      if (inputConfig.validations !== undefined) {
        inputConfig.validations.forEach((validator) => {
          const result = validator.validate(text);
          if (!result.isValid) {
            setError(result.errorMsg);
            setHasError(true);
            isEmailValid = false;
          }
        });
      }

      return isEmailValid;
    }
    return true;
  };

  return (
    <>
      <input
        type="text"
        name={inputConfig.name}
        disabled={isDisabled}
        onChange={handleInputChange}
        value={currValue}
        className="styled-input"
        placeholder={
          inputConfig.modelValue === "incomePerYear" &&
          "Enter only numbers (non-decimal amount)"
        }
      />
      {hasError && <div className={"alert error"}>{error}</div>}
    </>
  );
}
