import { cognitoAuth } from "../../utilities/auth";

export const forgotPasswordAction = (user) => {
  return {
    type: "FORGOT_PASSWORD",
    payload: cognitoAuth.forgotPassword(user.get("email")),
  };
};

export const deleteError = () => {
  return {
    type: "FORGOT_PASSWORD_CLEANUP",
  };
};
