import axios from "axios";
import { getCognitoId } from "../../utilities/auth";
import {
  COMPANY_GET_DATA,
  COMPANY_GET_STATIC_DATA,
  COMPANY_UPDATE,
  COMPANY_CANCEL_CHANGES,
  COMPANY_DATA_CLEANUP,
  COMPANY_NEW,
  UPDATE_COMPANY_DATA,
  COMPANY_DOCUMENT_DOWNLOAD,
  COMPANY_DOCUMENT_DELETE,
  COMPANY_ALL_DOCUMENTS_DOWNLOAD,
  COMPANY_DOCUMENT_UPLOAD,
  COMPANY_CHANGE_MODE,
  COMPANY_SAVE_CHANGES,
  COMPANY_DOCUMENT_UPLOAD_SAVE,
  COMPANY_FILE_UPLOAD,
} from "./company-profile-types";
import { ICompany } from "../../models/company/company";
import { mapToCompanyRequest } from "../../utils/mappers/company/mapToCompanyRequest";

export const getCompany = () => {
  return {
    type: COMPANY_GET_DATA,
    payload: axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/company?cognitoId=${getCognitoId()}`,
    ),
  };
};

export const getCompanyStaticData = () => {
  return {
    type: COMPANY_GET_STATIC_DATA,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/company/static-data`,
    ),
  };
};

/**
 * Update company in DB
 * @param company
 */
export const updateCompany = (company: ICompany) => {
  company.cognitoId = getCognitoId();
  const requestModel = mapToCompanyRequest(company);
  return {
    type: COMPANY_UPDATE,
    payload: axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/company/update/`,
      {
        ...requestModel,
      },
    ),
  };
};

/**
 * Update company state with form data. In most cases draft data is send to state after
 * success new/update request
 * @param company
 */
export const updateCompanyStateData = (company: ICompany) => {
  return {
    type: UPDATE_COMPANY_DATA,
    payload: { ...company },
  };
};

export const newCompany = (company: ICompany) => {
  company.cognitoId = getCognitoId();
  const requestModel = mapToCompanyRequest(company);
  return {
    type: COMPANY_NEW,
    payload: axios.post(`${process.env.REACT_APP_API_BASE_URL}/company`, {
      ...requestModel,
    }),
  };
};

/**
 * Clean company data to null value
 */
export const cleanupCompany = () => {
  return {
    type: COMPANY_DATA_CLEANUP,
  };
};

export const cancelCompany = () => {
  return {
    type: COMPANY_CANCEL_CHANGES,
  };
};

export const saveCompany = () => {
  return {
    type: COMPANY_SAVE_CHANGES,
  };
};

export const uploadFile = (files) => {
  return {
    type: COMPANY_FILE_UPLOAD,
    payload: files,
  };
};

// Document Downloads

export const getDocuments = () => {
  const cognitoId = getCognitoId();

  return {
    type: COMPANY_ALL_DOCUMENTS_DOWNLOAD,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/download?cognitoId=${cognitoId}`,
    ),
  };
};

// na /uploads/${fileName} dava CORS Missing Allow Origin
export const getDocument = (fileName) => {
  axios
    .get(`https://mk2025.clients.intertec.io/uploads/${fileName}`)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("id", fileName);

      document.body.appendChild(link);
      link.click();
      document.getElementById(fileName).remove();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const uploadDocument = (formData) => {
  const cognitoId = getCognitoId();

  return {
    type: COMPANY_DOCUMENT_UPLOAD,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/uploads?cognitoId=${cognitoId}`,
      formData,
    ),
  };
};

export const uploadDocumentOnSave = () => {
  return {
    type: COMPANY_DOCUMENT_UPLOAD_SAVE,
  };
};

export const deleteDocument = (cognitoId: string, fileName: string) => {
  return {
    type: COMPANY_DOCUMENT_DELETE,
    payload: axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/delete?cognitoId=${cognitoId}&fileName=${fileName}`,
    ),
  };
};
