import { Paper } from "@material-ui/core";
import React from "react";
import Form from "react-validation/build/form";
import AuthBackground from "../../authBgComponent/authBackground";
import Spinner from "../../common/spinner/spinner";

import "./../../loginFormComponent/auth.scss";

const RegistrationConfirmSpinner = () => {
  return (
    <>
      <AuthBackground></AuthBackground>
      <Paper className="auth-form-container">
        <Form className="auth-form">
          <Spinner />
        </Form>
      </Paper>
    </>
  );
};

export default RegistrationConfirmSpinner;
