import { Actions } from "../../utilities/action-payload";
import { IExpertState } from "./expert-profile-entity";
import {
  EXPERT_GET_DATA,
  EXPERT_GET_STATIC_DATA,
  EXPERT_UPDATE,
  EXPERT_DELETE,
  EXPERT_NEW,
  EXPERT_CANCEL_CHANGES,
  UPDATE_EXPERT_DATA,
  EXPERT_DATA_CLEANUP,
  EXPERT_ALL_DOCUMENTS_DOWNLOAD,
  EXPERT_DOCUMENT_DOWNLOAD,
  EXPERT_DOCUMENT_DELETE,
  EXPERT_DOCUMENT_UPLOAD,
  EXPERT_DOCUMENT_UPLOAD_SAVE,
  EXPERT_CHANGE_MODE,
  EXPERT_DOCUMENT_CLEANUP,
  EXPERT_FILE_UPLOAD,
} from "./expert-profile-types";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";
import { EXPERT_DELETE_CLEANUP } from "../adminTables/expertsTableComponent/expertsTableTypes";

const initialState: IExpertState = {
  data: null,
  staticData: null,
  getLoading: false,
  getError: false,
  newLoading: false,
  newStatus: false,
  newError: null,
  firstTimeEdit: false,
  updateLoading: false,
  updateStatus: false,
  updateError: null,
  deleteLoading: false,
  deleteStatus: false,
  deleteError: null,
  error: null,
  cancelDataChanges: false,
  documents: null,
  documentUrl: null,
  hasDocument: false,
  saveDataChanges: null,
  documentLoading: false,
  file: null,
  deleteDocumentError: null,
  documentUploadError: false,
  editMode: null,
  status: null,
};

export type ExpertProfileState = Readonly<typeof initialState>;

export default (
  state: ExpertProfileState = initialState,
  action: Actions,
): ExpertProfileState => {
  /**
   * GET DATA
   */
  switch (action.type) {
    case REQUEST(EXPERT_GET_DATA):
      return {
        ...state,
        getLoading: true,
        getError: null,
        newStatus: false,
      };
    case SUCCESS(EXPERT_GET_DATA):
      return {
        ...state,
        data: action.payload.data,
        hasDocument: action.payload.data && action.payload.data.hasDocument,
        documents: action.payload.data.documents,
        getLoading: false,
        getError: null,
        documentUploadError: false,
      };
    case FAILURE(EXPERT_GET_DATA):
      return {
        ...state,
        data: null,
        getError: action,
        getLoading: false,
      };

    // GET STATIC DATA
    case REQUEST(EXPERT_GET_STATIC_DATA):
      return {
        ...state,
        getError: null,
      };
    case SUCCESS(EXPERT_GET_STATIC_DATA):
      return {
        ...state,
        staticData: action.payload.data,
        getError: null,
      };
    case FAILURE(EXPERT_GET_STATIC_DATA):
      return {
        ...state,
        staticData: null,
        getError: action,
      };

    /**
     * NEW
     */
    case REQUEST(EXPERT_NEW):
      return {
        ...state,
        newLoading: true,
        newError: null,
        newStatus: false,
      };
    case SUCCESS(EXPERT_NEW):
      return {
        ...state,
        newLoading: false,
        newStatus: true,
        newError: null,
      };
    case FAILURE(EXPERT_NEW):
      return {
        ...state,
        newLoading: false,
        newStatus: false,
        newError: action.payload,
      };

    /**
     * UPDATE
     */
    case REQUEST(EXPERT_UPDATE):
      return {
        ...state,
        updateLoading: true,
        updateError: null,
        updateStatus: false,
      };
    case SUCCESS(EXPERT_UPDATE):
      return {
        ...state,
        updateLoading: false,
        updateError: null,
        updateStatus: true,
      };
    case FAILURE(EXPERT_UPDATE):
      return {
        ...state,
        updateLoading: false,
        updateError: action,
        updateStatus: false,
      };

    /**
     * DELETE
     */
    case REQUEST(EXPERT_DELETE):
      return {
        ...state,
        deleteLoading: true,
        deleteStatus: false,
        deleteError: null,
      };
    case SUCCESS(EXPERT_DELETE):
      return {
        ...state,
        data: null,
        deleteLoading: false,
        deleteStatus: true,
        deleteError: null,
      };

    case FAILURE(EXPERT_DELETE):
      return {
        ...state,
        deleteLoading: false,
        deleteStatus: false,
        deleteError: action.payload,
      };

    case FAILURE(EXPERT_DELETE_CLEANUP):
      return {
        ...state,
        deleteLoading: false,
        deleteStatus: false,
        deleteError: null,
      };

    case EXPERT_CANCEL_CHANGES:
      return {
        ...state,
        data: Object.assign({}, state.data),
        /**
         * Every time trigger data change to reflect the forms
         */
        cancelDataChanges: !state.cancelDataChanges,
      };
    case UPDATE_EXPERT_DATA:
      return {
        ...state,
        data: { ...action.payload },
        updateStatus: false,
      };
    case EXPERT_DATA_CLEANUP:
      return { ...initialState };

    case REQUEST(EXPERT_ALL_DOCUMENTS_DOWNLOAD):
      return {
        ...state,
        documentLoading: true,
      };

    case SUCCESS(EXPERT_ALL_DOCUMENTS_DOWNLOAD):
      return {
        ...state,
        hasDocument: true,
        status: action.payload,
        documentLoading: false,
      };

    case FAILURE(EXPERT_ALL_DOCUMENTS_DOWNLOAD):
      return {
        ...state,
        status: action.payload,
        documentLoading: false,
      };

    case REQUEST(EXPERT_DOCUMENT_DOWNLOAD):
      return {
        ...state,
      };

    case SUCCESS(EXPERT_DOCUMENT_DOWNLOAD):
      return {
        ...state,
        hasDocument: true,
        status: action.payload,
      };

    case FAILURE(EXPERT_DOCUMENT_DOWNLOAD):
      return {
        ...state,
        status: action.payload,
      };

    case REQUEST(EXPERT_DOCUMENT_DELETE):
      return {
        ...state,
        documentLoading: true,
      };

    case SUCCESS(EXPERT_DOCUMENT_DELETE):
      return {
        ...state,
        hasDocument: action.payload.data.length > 0 ? true : false,
        status: action.payload,
        documents: action.payload.data,
        documentLoading: false,
        newStatus: false,
        deleteDocumentError: false,
      };

    case FAILURE(EXPERT_DOCUMENT_DELETE):
      return {
        ...state,
        status: action.payload,
        deleteError: true,
        documentLoading: false,
        deleteDocumentError: true,
      };

    case REQUEST(EXPERT_DOCUMENT_UPLOAD):
      return {
        ...state,
        documentLoading: true,
      };

    case SUCCESS(EXPERT_DOCUMENT_UPLOAD):
      return {
        ...state,
        hasDocument: true,
        documents: action.payload.data,
        status: action.payload,
        documentLoading: false,
        editMode: false,
        file: null,
        newStatus: false,
      };

    case FAILURE(EXPERT_DOCUMENT_UPLOAD):
      return {
        ...state,
        status: action.payload,
        documentUploadError: true,
        documentLoading: false,
      };

    // This action triggers the upload hook that checks newStatus
    case EXPERT_DOCUMENT_UPLOAD_SAVE:
      return {
        ...state,
        newStatus: true,
      };

    case EXPERT_CHANGE_MODE:
      return {
        ...state,
        editMode: action.payload,
      };

    case EXPERT_DOCUMENT_CLEANUP:
      return {
        ...initialState,
      };

    case EXPERT_FILE_UPLOAD:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
