import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "../../styles/profileForms/shared-profile-styles.scss";
import { IRootState } from "../../utilities/root-reducer";
import { withTranslation, useTranslation } from "react-i18next";
import compose from "recompose/compose";
import {
  newCompany,
  updateCompany,
  cancelCompany,
  cleanupCompany,
  updateCompanyStateData,
  saveCompany,
  uploadDocumentOnSave,
  uploadDocument,
} from "./company-profile-action";
import {
  DocumentsTab,
  ExportsAndExportReadinessTab,
  TypeOfExpertise,
  TabsNavigation,
  GeneralInfoTab,
  ContactPersonTab,
} from "./Tabs";
import {
  profileTabNavigation,
  TabNavigationEnum,
} from "../../models/company/profile-tab-navigation-enum";
import { ITab } from "./Tabs/definitions/tabs-spec";
import { ICompany, Company } from "../../models/company/company";
import { CompanyFormWorkMode } from "../../models/company/company-form-work-mode";
import TabsStepperNavigation from "./Tabs/tabs-stepper-navigation";
import CompanyProfileToolbar from "./company-profile-toolbar";
import DeleteProfileModal from "./Modals/deleteProfileAndExistingCompaniesModal/delete-profile-modal";
import OldUsersModal from "../common/modals/OldUsersModal";
import CompanyProfileLanding from "./Landing/company-profile-landing";
import { store } from "react-notifications-component";
import {
  notifySuccess,
  notifyError,
} from "../../utils/notifications/app-notification";

interface ICompanyProfileProps extends ITab, StateProps, DispatchProps {}

export function CompanyProfile(props: ICompanyProfileProps) {
  /**
   * State
   */
  const { companyState } = props;

  const dispatch = useDispatch();

  /**
   * GENERAL
   */
  const { t } = useTranslation("profile");

  /**
   * COMPANY DATA MANAGEMENT ////////////////////
   */

  // Keeps the result of the changes in the form before saving
  const [draftCompanyData, setDraftCompanyData] = useState<ICompany>(
    new Company(),
  );
  // Data get in first place to populate the inputs
  // NOTE! Keep this data in order to reset if user click cancel on edit
  const originalCompanyData = useRef<ICompany>(new Company());

  /**
   * TABS HANDLING
   */
  // This marking of the tabs are use only when user is in process of filling the
  // the form data tab by tab. CompanyFormWorkMode is marked as 'New'
  const [formMarkedValidTabs, setFormMarkedValidTabs] = useState<string[]>([]);
  const [formTabsLockForEdit, setFormTabsLockForEdit] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState(
    profileTabNavigation[TabNavigationEnum.General],
  );
  const [tabFormToValidate, setTabFormToValidate] = useState<string>("");
  // keep tab name to change in case you have to do some operations and after that to change
  // example: previous and next states. first forms need to be validated and changed
  const tabNameToChange = useRef("");

  /**
   * MODAL ///////////
   */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOldUsersModal, setShowOldUsersModal] = useState(false);
  const [showExpertiseQuestionModal, setShowExpertiseQuestionModal] = useState(
    false,
  );
  const [showLoadingBar, setShowLoadingBar] = useState(false);

  /**
   * FORM WORKFLOW MANAGEMENT //////////////////////////
   */
  const [formWorkMode, setFormWorkMode] = useState(CompanyFormWorkMode.View);

  const [deletePress, setDeletePress] = useState(false);
  /**
   * CHANGE IN DATA EVENT
   */

  // Already existing users (before update) have companyState.data and the "lastModified" property is null
  const isModified =
    (companyState.data && Object.keys(companyState.data).length === 0) ||
    companyState.data === null
      ? true
      : companyState.data?.hasOwnProperty("lastModified");

  const hasExpertise = companyState.data?.expertise?.length > 0;

  useEffect(() => {
    const data = companyState.data;
    if (hasAccountWithData(data)) {
      setFormWorkMode(CompanyFormWorkMode.View);
    } else {
      setFormWorkMode(CompanyFormWorkMode.Done);
    }
    originalCompanyData.current = { ...data };
    setDraftCompanyData({ ...data });
  }, [companyState.data]);

  /**
   * CREATE NEW COMPANY PROFILE EVENTS
   */
  useEffect(() => {
    if (!companyState.newLoading && companyState.newError) {
      notifyError(
        store,
        t("company-profile-create-title"),
        t("company-profile-create-error"),
      );

      dispatch(cleanupCompany());
      const data = companyState.data;
      if (data.hasDocument) {
        const companyData = {
          ...data,
          hasDocument: true,
        };
        setDraftCompanyData({ ...companyData });
      } else {
        setDraftCompanyData({ ...data });
      }

      setFormWorkMode(CompanyFormWorkMode.Done);
    }
  }, [companyState.newLoading]);

  /**
   * UPDATE COMPANY EVENT
   */
  useEffect(() => {
    if (!companyState.updateLoading && companyState.updateError) {
      if (
        !isModified &&
        activeTab !== profileTabNavigation[TabNavigationEnum.General]
      ) {
        return;
      } else {
        notifyError(
          store,
          t("company-profile-update-title"),
          t("company-profile-update-error"),
        );
      }
    } else if (!companyState.updateLoading && companyState.updateStatus) {
      unlockAllTabs();
      setFormWorkMode(CompanyFormWorkMode.View);
      notifySuccess(
        store,
        t("company-profile-update-title"),
        t("company-profile-update-success"),
      );
      dispatch(updateCompanyStateData({ ...draftCompanyData }));
    }
  }, [companyState.updateLoading]);

  // Initialize upload on company create
  useEffect(() => {
    if (
      companyState.newStatus &&
      companyState.file &&
      typeof draftCompanyData.dateCreated === "undefined"
    ) {
      handleUpload(companyState.file);
    }
  }, [companyState.newStatus]);

  /**
   * Main navigation bar event for delete company
   */
  useEffect(() => {
    document.addEventListener(
      "delete-company-profile",
      handleToolbarOnDeleteClick,
      true,
    );
    return () => {
      document.removeEventListener(
        "delete-company-profile",
        handleToolbarOnDeleteClick,
        true,
      );
    };
  }, []);

  useEffect(() => {
    !isModified && setShowOldUsersModal(true);
    typeof companyState.data?.postalCodeAndCity !== "undefined" &&
      !hasExpertise &&
      setShowExpertiseQuestionModal(true);
  }, []);

  /**
   *
   * @param company Check if account has already set
   */
  const hasAccountWithData = (company: ICompany) => {
    if (company === null || company === undefined) {
      return false;
    }
    return company.name && company.name.length > 0;
  };

  const handleChangeCompanyTab = (tabName) => {
    // prevent rendering components on same tab
    if (tabName === activeTab) {
      return;
    }

    if (formWorkMode === CompanyFormWorkMode.View) {
      tabNameToChange.current = tabName;
      setActiveTab(tabName);
      return;
    }

    if (formWorkMode === CompanyFormWorkMode.Edit) {
      tabNameToChange.current = tabName;
      setTabFormToValidate(activeTab);
    }
  };

  /**
   * TABS MANIPULATION //////////////////
   */

  const hasLockedTabsForEdit = () => {
    return formTabsLockForEdit.length > 0;
  };

  // Unlock single tab
  /**
   * Provided tab will be unlocked and available for click
   * @param tabNameToUnlock
   */
  const unlockTab = (tabNameToUnlock) => {
    const tabIndex = formTabsLockForEdit.indexOf(tabNameToUnlock);
    if (tabIndex === -1) {
      return;
    }
    const lockedTabs = formTabsLockForEdit.filter(
      (tabName) => tabName !== tabNameToUnlock,
    );
    setFormTabsLockForEdit(lockedTabs);
  };

  const unlockAllTabs = () => {
    setFormTabsLockForEdit([]);
  };

  /**
   * All tabs are lock and can not be clicked except the tab provided
   * @param tabNameToLock
   */
  const lockToSingleTab = (tabNameToLock) => {
    const lockedTabs = profileTabNavigation.filter(
      (tabName) => tabName !== tabNameToLock,
    );
    setFormTabsLockForEdit(lockedTabs);
  };

  /**
   * FORM EVENTS CHANGES IN TABS ///////////////////
   */
  const handleDataChangeInGeneralTab = (data: ICompany) => {
    const tabName = profileTabNavigation[TabNavigationEnum.General];

    if (!hasLockedTabsForEdit() && formWorkMode === CompanyFormWorkMode.View) {
      lockToSingleTab(tabName);
      setFormWorkMode(CompanyFormWorkMode.Edit);
    }

    let address;

    if (data.address === "") {
      address = "";
    } else if (!data.address) {
      address = draftCompanyData.address;
    } else {
      if (data.address !== draftCompanyData.address) {
        address = data.address;
      } else {
        address = draftCompanyData.address;
      }
    }

    const companyData = {
      ...draftCompanyData,
      name: data.name,
      location: data.location,
      address,
      industries: data.industries,
      otherServiceActivities: data.otherServiceActivities,
      expertise: data.expertise,
      otherExpertise: data.otherExpertise,
      postalCodeAndCity: data.postalCodeAndCity,
      companySize: data.companySize,
      incomePerYear: data.incomePerYear,
      yearsOfOperation: data.yearsOfOperation,
      webLink: data.webLink,
      typeServicesProvided: data.typeServicesProvided,
      companyEmail: data.companyEmail,
      repeatCompanyEmail: data.repeatCompanyEmail,
    };

    setDraftCompanyData({ ...companyData });
  };

  const handleDataChangeInContactPersonTab = (data: ICompany) => {
    const tabName = profileTabNavigation[TabNavigationEnum.ContactPerson];

    if (!hasLockedTabsForEdit() && formWorkMode === CompanyFormWorkMode.View) {
      lockToSingleTab(tabName);
      setFormWorkMode(CompanyFormWorkMode.Edit);
    }

    const companyData = {
      ...draftCompanyData,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      contactEmail: data.contactEmail,
      contactPhoneNumber: data.contactPhoneNumber,
    };

    setDraftCompanyData({ ...companyData });
  };

  const handleDataChangeInExportsTab = (data: ICompany) => {
    const tabName = profileTabNavigation[TabNavigationEnum.Exports];

    if (!hasLockedTabsForEdit() && formWorkMode === CompanyFormWorkMode.View) {
      lockToSingleTab(tabName);
      setFormWorkMode(CompanyFormWorkMode.Edit);
    }

    const companyData = {
      ...draftCompanyData,
      technologyEquipmentId: data.technologyEquipmentId,
      serviceQualityAssuranceSystem:
        data.serviceQualityAssuranceSystem || false,
      appliedStandards: data.appliedStandards,
      increaseDifficultType: data.increaseDifficultType,
      export: data.export || false,
      exportRegions: data.exportRegions,
      percentageProduction: data.percentageProduction,
      percentageOfExport: data.percentageOfExport,
    };

    setDraftCompanyData({ ...companyData });
  };

  const handleDataChangeInExpertiseTab = (data: ICompany) => {
    const tabName = profileTabNavigation[TabNavigationEnum.Expertise];

    if (!hasLockedTabsForEdit() && formWorkMode === CompanyFormWorkMode.View) {
      lockToSingleTab(tabName);
      setFormWorkMode(CompanyFormWorkMode.Edit);
    }

    let expectationsFromExpertSupport;

    if (data.expectationsFromExpertSupport === "") {
      expectationsFromExpertSupport = "";
    } else if (!data.expectationsFromExpertSupport) {
      expectationsFromExpertSupport =
        draftCompanyData.expectationsFromExpertSupport;
    } else {
      if (
        data.expectationsFromExpertSupport !==
        draftCompanyData.expectationsFromExpertSupport
      ) {
        expectationsFromExpertSupport = data.expectationsFromExpertSupport;
      } else {
        expectationsFromExpertSupport =
          draftCompanyData.expectationsFromExpertSupport;
      }
    }

    const companyData = {
      ...draftCompanyData,
      supportTypes: data.supportTypes,
      preferenceForRegionExpanding: data.preferenceForRegionExpanding || false,
      regions: data.regions,
      startup: data.startup || false,
      problemsThatServiceAddresses: data.problemsThatServiceAddresses,
      innovativenessOfTheService: data.innovativenessOfTheService,
      typeOfClientsTargeting: data.typeOfClientsTargeting,
      developmentPhase: data.developmentPhase,
      growthPhase: data.growthPhase,
      expectationsFromExpertSupport,
    };

    setDraftCompanyData({ ...companyData });
  };

  // DOCUMENT TAB ON CHANGE

  const handleDataChangeinDocumentsTab = (data: ICompany) => {
    const tabName = profileTabNavigation[TabNavigationEnum.AdditionalInfo];
    lockToSingleTab(tabName);
    setFormWorkMode(CompanyFormWorkMode.Edit);
  };

  const handleOnNextClick = (nextTabName) => {
    tabNameToChange.current = nextTabName;
    setTabFormToValidate(activeTab);
    const body = document.querySelector("#root");
    window.scrollTo(0, 0);
  };

  const handleOnPreviousClick = (previousTabName) => {
    setActiveTab(previousTabName);
    window.scrollTo(0, 0);
  };

  const handleOnFinishClick = () => {
    dispatch(
      newCompany({
        ...draftCompanyData,
      }),
    );
    setShowLoadingBar(true);
    setFormWorkMode(CompanyFormWorkMode.Done);

    setFormMarkedValidTabs([]);
    unlockAllTabs();
    window.scrollTo(0, 0);
    setActiveTab(profileTabNavigation[TabNavigationEnum.General]);
  };

  const onNewDocumentUploadSuccess = (data: ICompany) => {
    dispatch(
      newCompany({
        ...draftCompanyData,
        documents: data.documents,
        documentUrl: data.documentUrl,
        hasDocument: data.hasDocument,
      }),
    );
    setFormMarkedValidTabs([]);
    setActiveTab(profileTabNavigation[TabNavigationEnum.General]);
    unlockAllTabs();
    setFormWorkMode(CompanyFormWorkMode.View);
  };

  const onEditDocumentUploadSuccess = (data: ICompany) => {
    setFormMarkedValidTabs([]);
    setActiveTab(profileTabNavigation[TabNavigationEnum.General]);
    unlockAllTabs();
    setFormWorkMode(CompanyFormWorkMode.View);
  };

  const handleTabFormValidation = (tabName: string, isValid: boolean) => {
    if (formWorkMode === CompanyFormWorkMode.New) {
      if (isValid) {
        // unlock the current
        if (formMarkedValidTabs.indexOf(tabName) === -1) {
          setFormMarkedValidTabs([...formMarkedValidTabs, tabName]);
          unlockTab(tabNameToChange.current);
        }
        // set tab change only if tab is different
        if (tabName !== tabNameToChange.current) {
          setActiveTab(tabNameToChange.current);
        }

        if (
          activeTab === profileTabNavigation[TabNavigationEnum.AdditionalInfo]
        ) {
          dispatch(newCompany({ ...draftCompanyData }));
        }
      }
    }

    if (formWorkMode === CompanyFormWorkMode.Edit) {
      if (isValid) {
        dispatch(updateCompany({ ...draftCompanyData }));
      }
    }

    setTabFormToValidate("");
    tabNameToChange.current = "";
  };

  /**
   * DELETE EVENTS //////////////////
   */

  const handleToolbarOnDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalCancel = () => {
    setShowDeleteModal(false);
  };

  const changeDeletePress = () => {
    setDeletePress(!deletePress);
  };

  //UPDATE EXSITING EXPERTS (closing modal and validation)
  const closeOldUsersModal = () => {
    showOldUsersModal && setShowOldUsersModal(false);
    showExpertiseQuestionModal && setShowExpertiseQuestionModal(false);

    setFormWorkMode(CompanyFormWorkMode.Edit);
    setTabFormToValidate(activeTab);
  };

  if (!isModified) {
    if (
      companyState.updateLoading &&
      activeTab === profileTabNavigation[TabNavigationEnum.General]
    ) {
      tabNameToChange.current =
        profileTabNavigation[TabNavigationEnum.ContactPerson];
      setActiveTab(tabNameToChange.current);
      setTabFormToValidate(tabNameToChange.current);
    } else if (
      companyState.updateLoading &&
      activeTab === profileTabNavigation[TabNavigationEnum.ContactPerson] &&
      typeof draftCompanyData.contactFirstName !== "undefined"
    ) {
      tabNameToChange.current =
        profileTabNavigation[TabNavigationEnum.Expertise];
      setActiveTab(tabNameToChange.current);
      setTabFormToValidate(tabNameToChange.current);
    } else if (
      //if the exported region field is empty the fourth tab should be also validated,
      //and the update should be done after the fourth tab, else the update should be on the third tab
      companyState.updateLoading &&
      activeTab === profileTabNavigation[TabNavigationEnum.Expertise] &&
      draftCompanyData.export === true &&
      typeof draftCompanyData.expectationsFromExpertSupport !== "undefined"
    ) {
      tabNameToChange.current = profileTabNavigation[TabNavigationEnum.Exports];
      setActiveTab(tabNameToChange.current);
      setTabFormToValidate(tabNameToChange.current);
    }
  }

  /**
   * TOOLBAR EVENTS //////////////////
   */

  const handleToolbarOnSaveClick = () => {
    setTabFormToValidate(activeTab);
    if (activeTab === profileTabNavigation[TabNavigationEnum.AdditionalInfo]) {
      const companyData = {
        ...draftCompanyData,
        documents: companyState.documents,
      };

      if (typeof companyData.dateCreated !== "undefined") {
        dispatch(uploadDocumentOnSave());
        handleUpload(companyState.file);
      }
      setDraftCompanyData({ ...companyData });

      setFormWorkMode(CompanyFormWorkMode.View);
      unlockAllTabs();
    } else {
      setDraftCompanyData({ ...draftCompanyData });
    }
  };

  const handleToolbarOnCancelClick = () => {
    if (hasLockedTabsForEdit()) {
      dispatch(cancelCompany());
      unlockAllTabs();
      // close the edit mode
      setFormWorkMode(CompanyFormWorkMode.View);
    }
  };

  const handleUpload = (file?: any) => {
    if (file) {
      dispatch(uploadDocument(file));
    }
  };
  /**
   * PROFILE LANDING
   */
  const handleCreateProfile = () => {
    setFormWorkMode(CompanyFormWorkMode.New);
    const generalTabName = profileTabNavigation[TabNavigationEnum.General];
    lockToSingleTab(generalTabName);
    setActiveTab(generalTabName);
  };

  const setWorkModeView = () => {
    setFormWorkMode(CompanyFormWorkMode.View);
  };

  return (
    <>
      {formWorkMode !== CompanyFormWorkMode.Done && (
        <div className="profile-body">
          <CompanyProfileToolbar
            formWorkMode={formWorkMode}
            onDeleteClick={handleToolbarOnDeleteClick}
            onCancelClick={handleToolbarOnCancelClick}
            onSaveClick={handleToolbarOnSaveClick}
          />
          <TabsNavigation
            active={activeTab}
            onChange={handleChangeCompanyTab}
            tabs={profileTabNavigation}
            selectedTabs={formMarkedValidTabs}
            nonClickableTabs={formTabsLockForEdit}
          />
          {/** TABS CONTENT */}
          <GeneralInfoTab
            active={activeTab}
            data={draftCompanyData}
            onChange={handleDataChangeInGeneralTab}
            formWorkMode={formWorkMode}
            validateTab={tabFormToValidate}
            onTabValidation={handleTabFormValidation}
          />
          <ContactPersonTab
            active={activeTab}
            data={draftCompanyData}
            onChange={handleDataChangeInContactPersonTab}
            formWorkMode={formWorkMode}
            validateTab={tabFormToValidate}
            onTabValidation={handleTabFormValidation}
          />
          <TypeOfExpertise
            active={activeTab}
            data={draftCompanyData}
            onChange={handleDataChangeInExpertiseTab}
            formWorkMode={formWorkMode}
            validateTab={tabFormToValidate}
            onTabValidation={handleTabFormValidation}
          />
          <ExportsAndExportReadinessTab
            active={activeTab}
            data={draftCompanyData}
            onChange={handleDataChangeInExportsTab}
            formWorkMode={formWorkMode}
            validateTab={tabFormToValidate}
            onTabValidation={handleTabFormValidation}
          />
          <DocumentsTab
            active={activeTab}
            formWorkMode={formWorkMode}
            setFormWorkMode={setFormWorkMode}
            onChange={handleDataChangeinDocumentsTab}
            data={draftCompanyData}
            onSaveChanges={handleTabFormValidation}
            onNewDocumentUploadSuccess={onNewDocumentUploadSuccess}
            onEditDocumentUploadSuccess={onEditDocumentUploadSuccess}
            changeDeletePress={changeDeletePress}
          />
          <TabsStepperNavigation
            activeTab={activeTab}
            onNext={handleOnNextClick}
            onPrevious={handleOnPreviousClick}
            onFinish={handleOnFinishClick}
            formWorkMode={formWorkMode}
          />
          <DeleteProfileModal
            open={showDeleteModal}
            onCancel={handleDeleteModalCancel}
          />
        </div>
      )}
      {formWorkMode === CompanyFormWorkMode.Done && (
        <>
          <CompanyProfileLanding
            onCreateProfileClick={handleCreateProfile}
            showLoadingBar={showLoadingBar}
            setWorkModeView={setWorkModeView}
          />
        </>
      )}
      {showOldUsersModal && (
        <OldUsersModal
          open={showOldUsersModal}
          onClose={closeOldUsersModal}
          content={t("existing-experts-and-companies-modal")}
        />
      )}
      {showExpertiseQuestionModal && (
        <OldUsersModal
          open={showExpertiseQuestionModal}
          onClose={closeOldUsersModal}
          content={t("company-expertise-modal")}
        />
      )}
    </>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  companyState: storeState.company,
});

const mapDispatchToProps = {
  newCompany,
  updateCompany,
  cancelCompany,
  updateCompanyStateData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("profile"),
  connect(mapStateToProps, mapDispatchToProps),
)(CompanyProfile);
