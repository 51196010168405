import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "./root-reducer";
import { createBrowserHistory } from "history";
import thunkMiddleware from "redux-thunk";
import errorMiddleware from "./error";
import promiseMiddleware from "redux-promise-middleware";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import loggerMiddleware from "./logger";

export const reduxHistory = createBrowserHistory();

const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
  loggerMiddleware,
];
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer(reduxHistory),
    initialState,
    compose(
      applyMiddleware(routerMiddleware(reduxHistory), ...defaultMiddlewares),
    ),
  );
}
