import { ICompany } from "../../../models/company/company";
import { ISidebar, SidebarView } from "../../../models/general/sidebar";
import { generalData } from "../../../models/general/general-data";

export const mapToCompanySidebarView = (
  company: ICompany,
  t: any,
): ISidebar => {
  const sidebar = new SidebarView();

  sidebar.title = company.name;
  sidebar.subtitle = company.location ? company.location.name : "";

  sidebar.tabs = [
    {
      content: mapGeneralDataToContent(company, t),
      id: 0,
      name: "general",
      tlabel: t("sidebar_tab_general"),
    },
    {
      content: mapContactPersonDataToContent(company, t),
      id: 1,
      name: "contactPerson",
      tlabel: t("sidebar_tab_contact_person"),
    },
    {
      content: mapExpertiseDataToContent(company, t),
      id: 2,
      name: "expertise",
      tlabel: t("sidebar_tab_expertise"),
    },
    {
      content: mapExportsDataToContent(company, t),
      id: 3,
      name: "exports",
      tlabel: t("sidebar_tab_exports"),
    },
    {
      content: mapDocumentsDataToContent(company, t),
      id: 4,
      name: "additionalInfo",
      tlabel: t("sidebar_tab_additional_info"),
    },
  ];

  return sidebar;
};

const mapGeneralDataToContent = (
  company: ICompany,
  t: any,
): { name: string; value: string }[] => {
  const supportInIndustries = company.industries.map((item) => item.name);
  const expertise = company.expertise.map((item) => item.name);
  const companySizeLabel = generalData.companySize.find(
    (item) => item.value === company.companySize,
  );

  const allFields = [
    {
      name: t("admin-sidebar-address"),
      value: company.address ? company.address : "",
    },
    {
      name: t("admin-sidebar-postal-code-city"),
      value: company.postalCodeAndCity,
    },
    {
      name: t("admin-sidebar-industry"),
      value:
        supportInIndustries.length > 0 ? supportInIndustries.join(", ") : "",
    },
    {
      name: t("admin-sidebar-expertise"),
      value: expertise.length > 0 ? expertise.join(", ") : "",
    },
    {
      name: t("admin-sidebar-number-of-employees"),
      value: companySizeLabel !== undefined ? companySizeLabel.label : "",
    },
    {
      name: t("admin-sidebar-annual-turnover"),
      value: company.incomePerYear
        ? company.incomePerYear.toLocaleString().replaceAll(",", ".")
        : "",
    },
    {
      name: t("admin-sidebar-years-of-operation"),
      value: company.yearsOfOperation
        ? company.yearsOfOperation.toString()
        : "",
    },
    {
      name: t("admin-sidebar-services-provided"),
      value: company.typeServicesProvided ? company.typeServicesProvided : "",
    },
    {
      name: t("admin-sidebar-website"),
      value: company.webLink ? company.webLink : "",
    },
    {
      name: t("admin-sidebar-company-email"),
      value: company.companyEmail,
    },
  ];

  //filter the fields in order to show only the fulfilled ones for a company
  //(in in the sidebar and in the company details table)
  return allFields.filter((field) => field.value !== "");
};

const mapContactPersonDataToContent = (
  company: ICompany,
  t: any,
): { name: string; value: any }[] => {
  const allFields = [
    {
      name: t("admin-sidebar-company-contact-fisrt-name"),
      value: company.contactFirstName,
    },
    {
      name: t("admin-sidebar-company-contact-last-name"),
      value: company.contactLastName,
    },
    {
      name: t("admin-sidebar-company-contact-email"),
      value: company.contactEmail,
    },
    {
      name: t("admin-sidebar-company-contact-number"),
      value: company.contactPhoneNumber,
    },
  ];
  return allFields;
};

const mapExportsDataToContent = (
  company: ICompany,
  t: any,
): { name: string; value: string }[] => {
  const technologyLabel = generalData.technologyEquipment.filter(
    (item) => item.value === company.technologyEquipmentId,
  );

  let appliedStandardsLabel = "";
  const allFoundStandards = [];

  company.appliedStandards.forEach((selectedStandard) => {
    const foundStandard = generalData.appliedStandards.find(
      (item) => item.value === selectedStandard.appliedStandardId,
    );

    if (foundStandard !== undefined) {
      let standardLabel = foundStandard.label;
      if (foundStandard.showDescriptionInput) {
        standardLabel += ` ${selectedStandard.description}`;
      }
      allFoundStandards.push(standardLabel);
    }
  });

  if (allFoundStandards.length > 0) {
    appliedStandardsLabel = allFoundStandards.join(", ");
  }

  const selectedExportedRegions = company.exportRegions.map((region) => {
    return region.name;
  });

  const exportedRegionsLabel =
    selectedExportedRegions.length > 0
      ? selectedExportedRegions.join(", ")
      : "";

  const increaseDifficultTypeLabel = generalData.increaseDifficultType.find(
    (item) => item.value === company.increaseDifficultType,
  );

  const allFields = [
    {
      name: t("admin-sidebar-company-export"),
      value: company.export ? t("admin-sidebar-Yes") : t("admin-sidebar-No"),
    },
    {
      name: t("admin-sidebar-export-regions"),
      value: exportedRegionsLabel,
    },
    {
      name: t("admin-sidebar-percentage-of-export"),
      value: company.percentageOfExport
        ? company.percentageOfExport.toString()
        : "",
    },
    {
      name: t("admin-sidebar-assurance-system"),
      value: company.serviceQualityAssuranceSystem
        ? t("admin-sidebar-Yes")
        : t("admin-sidebar-No"),
    },
    {
      name: t("admin-sidebar-applied-standards"),
      value: company.appliedStandards ? appliedStandardsLabel : "",
    },
    {
      name: t("admin-sidebar-increase-difficult"),
      value:
        increaseDifficultTypeLabel !== undefined
          ? increaseDifficultTypeLabel.label
          : "",
    },
    {
      name: t("admin-sidebar-technology-equipment"),
      value: technologyLabel.length > 0 ? technologyLabel[0].label : "",
    },
    {
      name: t("admin-sidebar-percentage-production"),
      value: company.percentageProduction
        ? company.percentageProduction.toString()
        : "",
    },
  ];

  //filter the fields in order to show only the fulfilled ones for a company
  //(in in the sidebar and in the company details table)
  return allFields.filter((field) => field.value !== "");
};

const mapExpertiseDataToContent = (
  company: ICompany,
  t: any,
): { name: string; value: string }[] => {
  const typeOfSupportLabel = company.supportTypes.map((item) => {
    return item.name === "Mentoring businesses to improve and grow"
      ? "Mentoring that will help you grow and improve your business"
      : "Support in entering foreign markets/growing internationally";
  });

  const regions = company.regions.map((item) => item.name);

  const developmentPhaseLabel = generalData.developmentPhases.find(
    (item) => item.value === company.developmentPhase,
  );

  const growthPhaseLabel = generalData.growthPhases.find(
    (item) => item.value === company.growthPhase,
  );

  const allFields = [
    {
      name: t("admin-sidebar-required-support"),
      value: company.supportTypes ? typeOfSupportLabel.join(", ") : "",
    },
    {
      name: t("admin-sidebar-expanding-region"),
      value: company.preferenceForRegionExpanding
        ? t("admin-sidebar-Yes")
        : t("admin-sidebar-No"),
    },
    {
      name: t("admin-sidebar-region"),
      value: regions.length > 0 ? regions.join(", ") : "",
    },
    {
      name: t("admin-sidebar-startup"),
      value: company.startup ? t("admin-sidebar-Yes") : t("admin-sidebar-No"),
    },
    {
      name: t("admin-sidebar-problems-that-service-addresses"),
      value: company.problemsThatServiceAddresses
        ? company.problemsThatServiceAddresses
        : "",
    },
    {
      name: t("admin-sidebar-innovativeness-of-the-service"),
      value: company.innovativenessOfTheService
        ? company.innovativenessOfTheService
        : "",
    },
    {
      name: t("admin-sidebar-type-of-clients-targeting"),
      value: company.typeOfClientsTargeting
        ? company.typeOfClientsTargeting
        : "",
    },
    {
      name: t("admin-sidebar-development-phase"),
      value:
        developmentPhaseLabel !== undefined ? developmentPhaseLabel.label : "",
    },
    {
      name: t("admin-sidebar-growth-phase"),
      value: growthPhaseLabel !== undefined ? growthPhaseLabel.label : "",
    },
    {
      name: t("admin-sidebar-expectations-from-expert-support"),
      value: company.expectationsFromExpertSupport
        ? company.expectationsFromExpertSupport
        : "",
    },
  ];

  //filter the fields in order to show only the fulfilled ones for a company
  //(in in the sidebar and in the company details table)
  return allFields.filter((field) => field.value !== "");
};

const mapDocumentsDataToContent = (
  company: ICompany,
  t: any,
): { name: string; value: string; isFile?: boolean; url?: string }[] => {
  const allFields = [
    {
      name: t("admin-sidebar-matched-with-expert"),
      value: company.matchedExpert?.firstName
        ? company.matchedExpert?.lastName
          ? `${company.matchedExpert.firstName} ${company.matchedExpert.lastName}`
          : company.matchedExpert.firstName
        : t("admin-sidebar-No"),
    },
    {
      name: t("admin-sidebar-documents"),
      value: company.hasDocument
        ? company.documents
        : t("admin-sidebar-not-uploaded"),
      isFile: true,
      url: company.hasDocument ? company.documentUrl : null,
    },
  ];

  return allFields;
};
