import React from "react";

const TrashIcon = ({ width = "1em", height = "1em", color = "#000" }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
      fill={color}
    />
  </svg>
);

export default TrashIcon;
