import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import Form from "react-validation/build/form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./../../loginFormComponent/auth.scss";

const SignupFormSuccess = () => {
  const { signupSuccess } = useSelector((state: IRootState) => state.signup);
  const { t } = useTranslation("signup-layout");

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/login");
    }, 3000);
  }, []);

  return (
    <Form className="auth-form">
      <h3 className="auth-title">{t("signupConfirmTitle")}</h3>

      <div className="sp-person"></div>

      <span className="auth-description">{t("signupConfirmSubtitle")}</span>

      {/* <div className="auth-input-container">
      <Link to="/login" className="auth-link">
        <Button className="auth-submit-button">{t("toLogin")}</Button>
      </Link>
    </div> */}
    </Form>
  );
};

export default SignupFormSuccess;
