import axios from "axios";
import {
  MATCHING_FILTER_COMPANIES,
  MATCHING_FILTER_COMPANIES_PAGINATION,
  MATCHING_FETCH_EXPERTS,
  MATCHING_CLEANUP_DATA,
  MATCHING_GET_COMPANY,
  MATCHING_GET_EXPERT,
  MATCHING_EXPORT,
} from "./adminMatchingTypes";
import { downloadDocument } from "../../utilities/download-doc";

export const fetchFilteredCompanies = (filters: {
  regionId: number;
  industryId: number;
  expertiseId: number;
  supportTypeId: number;
}) => {
  let filter = "";

  if (filters.regionId >= 1) {
    filter += `region=${filters.regionId}`;
  }
  if (filters.industryId >= 1) {
    filter += `&industry=${filters.industryId}`;
  }

  if (filters.expertiseId >= 1) {
    filter += `&expertise=${filters.expertiseId}`;
  }

  if (filters.supportTypeId >= 1) {
    filter += `&supportType=${filters.supportTypeId}`;
  }

  return {
    type: MATCHING_FILTER_COMPANIES,
    payload: axios.get(`/search/match?${filter}`),
  };
};

export const fetchFilteredCompaniesPagination = (
  filters: {
    regionId: number;
    industryId: number;
    expertiseId: number;
    supportTypeId: number;
  },
  pageNumber: number,
  pageSize: number,
  isNextPageClicked: boolean,
) => {
  let filter = "";

  if (filters.regionId >= 1) {
    filter += `region=${filters.regionId}`;
  }

  if (filters.industryId >= 1) {
    filter += `&industry=${filters.industryId}`;
  }

  if (filters.expertiseId >= 1) {
    filter += `&expertise=${filters.expertiseId}`;
  }

  if (filters.supportTypeId >= 1) {
    filter += `&supportType=${filters.supportTypeId}`;
  }

  return {
    type: MATCHING_FILTER_COMPANIES_PAGINATION,
    payload: axios.get(
      `/search/match?${filter}&pageNumber=${
        isNextPageClicked ? pageNumber : 0
      }&pageSize=${pageSize}`,
    ),
  };
};

export const getCompany = (id: number) => {
  return {
    type: MATCHING_GET_COMPANY,
    payload: axios.get(`${process.env.REACT_APP_API_BASE_URL}/company/${id}`),
  };
};

export const exportMatchingDocument = () => {
  return {
    type: MATCHING_EXPORT,
    payload: axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/match/export`)
      .then((response) => downloadDocument(response, "matches.csv")),
  };
};

export const fetchMatchingExperts = (
  companyId: number,
  pageNumber: number,
  pageSize: number,
) => {
  return {
    type: MATCHING_FETCH_EXPERTS,
    payload: axios.get(
      `/experts/match/${companyId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    ),
  };
};

export const getExpert = (id: number) => {
  return {
    type: MATCHING_GET_EXPERT,
    payload: axios.get(`${process.env.REACT_APP_API_BASE_URL}/expert/${id}`),
  };
};

/**
 * Cleanup selected company and experts list
 */
export const cleanupData = () => {
  return {
    type: MATCHING_CLEANUP_DATA,
  };
};
