import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "../../../../utilities/root-reducer";
import ReactLoading from "react-loading";
import { PaginationComponent } from "../../../paginationComponent/paginationComponent";
import { IExpert } from "../../../../models/expert/expert";
import { generateUniqId } from "../../../../utils/helpers/general-helper";
import { IPagination, Pagination } from "../../../../models/general/pagination";
import { LightTooltip } from "../../../adminTables/companyTableComponent/companyTableComponent";
import moreInfo from "../../../../assets/images/more-info.png";

export interface IMatchingTable {
  onExpertClick?: (expert: IExpert) => void;
  onPaginationBtnClick: (pageNumber, pageSize) => void;
  getCurrentExpertId: (id: number) => void;
}

export const MatchingTable = (props: IMatchingTable) => {
  const { t } = useTranslation(["admin-matching", "expert"]);
  const { experts, loadingExperts } = useSelector(
    (state: IRootState) => state.adminMatching,
  );
  const { onPaginationBtnClick, getCurrentExpertId } = props;
  const [expertsList, setExpertsList] = useState<IExpert[]>([]);
  const pagination = useRef<IPagination>(new Pagination());

  const industriesType = (industries) => {
    return industries.map((item) => item.name);
  };

  const regionsName = (regions) => {
    return regions.map((item) => item.name);
  };

  const supportType = (supportTypes) => {
    return supportTypes.map((item) => item.name);
  };

  const handleExpertClick = (expert: IExpert) => {
    if (typeof props.onExpertClick === "function") {
      props.onExpertClick(expert);
      getCurrentExpertId(expert.id);
    }
  };

  const handlePageChangeOnClick = ({ pageNumber, pageSize = 10 }) => {
    if (typeof onPaginationBtnClick === "function") {
      onPaginationBtnClick(pageNumber, pageSize);
    }
  };

  useEffect(() => {
    if (experts) {
      if (experts.content) {
        pagination.current = experts;
        setExpertsList([...experts.content] as IExpert[]);
      }
    }
  }, [experts.content, experts as Pagination]);

  return (
    <div className="admin-table-container">
      <table className="admin-table">
        <thead>
          <tr className="admin-head admin-row admin-row-experts">
            <th>{t("admin-tables:table_expert")}</th>
            <th>{t("admin-tables:table_industry")}</th>
            <th>{t("admin-tables:table_expertise")}</th>
            <th>{t("admin-tables:expert_region")}</th>
            <th>{t("admin-tables:table_support_offered")}</th>
          </tr>
        </thead>
        <tbody>
          {expertsList &&
            expertsList.map &&
            expertsList.map((expert) => {
              return (
                <tr
                  key={generateUniqId()}
                  className="admin-row admin-row-experts"
                >
                  <td className={"clickable-cell"}>
                    <LightTooltip
                      title="Click for more details"
                      placement="right"
                    >
                      <p
                        onMouseDown={() => handleExpertClick(expert)}
                        className="expert-name"
                      >
                        {expert.firstName} {expert.lastName}{" "}
                        <img src={moreInfo} alt="more info" />
                      </p>
                    </LightTooltip>
                    <a href={`mailto:${expert.email}`} className="expert-email">
                      {expert.email}
                    </a>
                  </td>
                  <td>{industriesType(expert.industries).join(", ")}</td>
                  <td>{industriesType(expert.expertise)?.join(", ")}</td>
                  <td>{regionsName(expert.regions).join(", ")}</td>
                  <td>{supportType(expert.supportTypes).join(", ")}</td>
                </tr>
              );
            })}
          {!loadingExperts && loadingExperts && (
            <tr className="admin-row admin-row-companies align-center">
              <td colSpan={5}>
                <ReactLoading type={"bars"} color={"#a41e24"} />
              </td>
            </tr>
          )}
          {!loadingExperts && experts.content && experts.content.length === 0 && (
            <tr className="admin-row admin-row-companies align-center">
              <td colSpan={5}>{t("matching-experts-no-results")}</td>
            </tr>
          )}
        </tbody>
      </table>
      {expertsList?.length > 0 && (
        <PaginationComponent
          totalPages={pagination.current.totalPages}
          changePage={handlePageChangeOnClick}
        />
      )}
    </div>
  );
};
