import React from "react";

const DownArrow = () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7443 0.78986L15.2202 0.262116C15.0552 0.0973018 14.8358 0.00650427 14.6012 0.00650427C14.3668 0.00650427 14.1471 0.0973018 13.9822 0.262116L8.00358 6.24096L2.0181 0.255482C1.85341 0.0906675 1.6337 0 1.3993 0C1.16489 0 0.945048 0.0906675 0.780234 0.255482L0.256002 0.779974C-0.085334 1.12105 -0.085334 1.67663 0.256002 2.01771L7.38243 9.16976C7.54712 9.33445 7.76657 9.45048 8.00306 9.45048H8.00579C8.24033 9.45048 8.45978 9.33432 8.62446 9.16976L15.7443 2.03709C15.9092 1.87241 15.9997 1.64632 16 1.41191C16 1.17738 15.9092 0.954414 15.7443 0.78986Z"
      fill="#2A95A5"
    />
  </svg>
);

export default DownArrow;
