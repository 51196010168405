import { Actions } from "../../../utilities/action-payload";
import { REQUEST, FAILURE, SUCCESS } from "../../../utilities/action-types";
import { IExpert } from "../../../models/expert/expert";
import { IPagination, Pagination } from "../../../models/general/pagination";
import {
  EXPERT_FETCH_ALL,
  EXPERT_GET,
  EXPERT_GET_CLEANUP,
  EXPERT_DELETE,
  EXPERT_CLEANUP,
  EXPERT_SEARCH,
  EXPERT_DELETE_CLEANUP,
  EXPERT_FETCH_ALL_WITH_NO_PAGINATION,
} from "./expertsTableTypes";

export interface AdminExpertsState {
  data: IPagination;
  error: object;
  loading: boolean;
  deleteLoading: boolean;
  deleteStatus: boolean;
  getExpertLoading: boolean;
  getExpertStatus: boolean;
  expert: IExpert;
  allExperts: any;
}

const initialState: AdminExpertsState = {
  data: null,
  error: null,
  loading: true,
  deleteStatus: false,
  deleteLoading: false,
  getExpertLoading: false,
  getExpertStatus: false,
  expert: null,
  allExperts: [],
};

export default (
  state: AdminExpertsState = initialState,
  action: Actions,
): AdminExpertsState => {
  switch (action.type) {
    case REQUEST(EXPERT_FETCH_ALL):
      return {
        ...state,
        data: new Pagination(),
        error: null,
        loading: true,
      };
    case FAILURE(EXPERT_FETCH_ALL):
      return {
        ...state,
        data: null,
        error: action.payload,
        loading: false,
      };
    case SUCCESS(EXPERT_FETCH_ALL):
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false,
      };

    case REQUEST(EXPERT_GET):
      return {
        ...state,
        expert: null,
        getExpertLoading: true,
        getExpertStatus: false,
        error: null,
      };
    case FAILURE(EXPERT_GET):
      return {
        ...state,
        expert: null,
        getExpertLoading: false,
        getExpertStatus: false,
        error: action.payload,
      };

    case SUCCESS(EXPERT_GET):
      return {
        ...state,
        expert: action.payload.data,
        getExpertLoading: false,
        getExpertStatus: true,
        error: null,
      };
    case EXPERT_GET_CLEANUP:
      return {
        ...state,
        expert: null,
        getExpertLoading: false,
        getExpertStatus: false,
        error: null,
      };

    case REQUEST(EXPERT_DELETE):
      return {
        ...state,
        deleteStatus: false,
        deleteLoading: true,
        error: null,
      };
    case FAILURE(EXPERT_DELETE):
      return {
        ...state,
        deleteStatus: false,
        error: action.payload,
        deleteLoading: false,
      };
    case SUCCESS(EXPERT_DELETE):
      return {
        ...state,
        deleteStatus: true,
        error: null,
        loading: false,
        deleteLoading: false,
      };

    case EXPERT_DELETE_CLEANUP:
      return {
        ...state,
        data: new Pagination(),
        deleteStatus: false,
        error: null,
        loading: false,
        deleteLoading: false,
      };

    case REQUEST(EXPERT_SEARCH):
      return {
        ...state,
        data: new Pagination(),
        loading: true,
        error: null,
      };
    case FAILURE(EXPERT_SEARCH):
      return {
        ...state,
        data: new Pagination(),
        loading: false,
        error: action.payload,
      };
    case SUCCESS(EXPERT_SEARCH):
      return {
        ...state,
        data:
          new Pagination({ content: [...action.payload.data] }) ||
          new Pagination(),
        loading: false,
        error: null,
      };

    case REQUEST(EXPERT_FETCH_ALL_WITH_NO_PAGINATION):
      return {
        ...state,
        allExperts: null,
        //this is false, because this experts are used for
        //filtering experts in admin, and there shoudn't have loader
        //used in (src/components/adminTables/expertsTableComponent/expertsTableComponent.tsx)
        loading: false,
        error: null,
      };
    case FAILURE(EXPERT_FETCH_ALL_WITH_NO_PAGINATION):
      return {
        ...state,
        allExperts: null,
        loading: false,
        error: action.payload,
      };
    case SUCCESS(EXPERT_FETCH_ALL_WITH_NO_PAGINATION):
      return {
        ...state,
        allExperts: action.payload.data,
        loading: false,
        error: null,
      };

    case EXPERT_CLEANUP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
