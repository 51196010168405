import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchAllExperts,
  fetchAllExpertsWitnNoPagination,
  getExpert,
  expertExport,
} from "./expertsTableActions";
import { IRootState } from "../../../utilities/root-reducer";
import TrashIcon from "../../SVGs/TrashIcon";
import { PaginationComponent } from "../../paginationComponent/paginationComponent";
import Sidebar from "../../sidebarComponent/sidebarComponent";
import { SidebarView, ISidebar } from "../../../models/general/sidebar";
import { IExpert } from "../../../models/expert/expert";
import { IPagination, Pagination } from "../../../models/general/pagination";
import { generateUniqId } from "../../../utils/helpers/general-helper";
import DeleteExpertModal from "./Modals/delete-expert-modal";
import ReactLoading from "react-loading";
import { mapExpertToSidebarView } from "../../../utils/mappers/experts/expertToSidebarView";
import { store } from "react-notifications-component";
import { dateToProperFormat } from "../../common/DatePicker";
import { LightTooltip } from "../companyTableComponent/companyTableComponent";

import { notifyError } from "../../../utils/notifications/app-notification";

import "./expertsTableComponent.scss";

export const ExpertsTableComponent = () => {
  const translation = useTranslation(["admin-tables", "expert"]);
  const { t } = translation;
  const dispatch = useDispatch();
  const { data, error, loading, expert, allExperts } = useSelector(
    (state: IRootState) => state.adminExperts,
  );

  // sidebar
  const selectedExpertData = useRef<IExpert>(null);
  const [selectedExpert, setSelectedExpert] = useState<ISidebar>(null);
  const [showMenu, setShowMenu] = useState(false);

  // list and pagination
  const [expertsTableList, setExpertsTableList] = useState<IExpert[]>([]);
  const pagination = useRef<IPagination>(new Pagination());

  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteExpertId = useRef<string>("");

  // search
  const isSearch = useRef<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");

  // matching tooltip
  const [currentMatchedCompanies, setCurrentMatchedCompanies] = useState<
    string | undefined
  >(undefined);

  // ====== METHODS ==========

  // EXPERT SELECTION
  const handleSelectedExpert = (id: number) => {
    dispatch(getExpert(id));
    setShowMenu(true);
  };

  // DELETE EXPERT

  const handleDeleteExpertRowClick = (id: string) => {
    deleteExpertId.current = id;

    setShowDeleteModal(true);
  };

  const handleDeleteExpertSuccess = () => {
    deleteExpertId.current = "";

    // Go to previous page in case we have more data in DB but in the current
    // page we delete the last item

    setShowDeleteModal(false);

    if (expertsTableList.length === 1 && pagination.current.pageNumber > 0) {
      const pageNumber = pagination.current.pageNumber - 1;
      dispatch(
        fetchAllExperts(pageNumber, pagination.current.pageable.pageSize),
      );
      return;
    }

    dispatch(
      fetchAllExperts(
        pagination.current.pageable.pageNumber,
        pagination.current.pageable.pageSize,
      ),
    );
  };

  const handleDeleteModalOnCancel = () => {
    deleteExpertId.current = "";
    setShowDeleteModal(false);
  };

  //FILTER EXPERTS
  const removeSpacesAndToLowerCase = (inputString: string) =>
    inputString?.replace(/\s+/g, "").trim().toLowerCase();

  const searchQuery = removeSpacesAndToLowerCase(searchTerm);

  const allExpertsWithFullName = allExperts?.map((expert) => ({
    ...expert,
    //add new property for fullname
    fullName: expert.lastName
      ? //we concatenate like this in order to be able to search by lastName + firstName (and vice versa)
        removeSpacesAndToLowerCase(
          expert.firstName + expert.lastName + expert.firstName,
        )
      : //old users have only first name
        removeSpacesAndToLowerCase(expert.firstName),
  }));

  const filteredExperts = allExpertsWithFullName?.filter((el) =>
    //when the expert has more than one last name, works only when searching by name + first last name + second last name or by first last name + second last name + name
    //but we cannot find the expert by name + second last name or by second last name + name
    //(the same logic is for the expert that has more than one first name)
    el.fullName.includes(searchQuery),
  );

  // SEARCH
  const handleSearchInpChange = (event) => {
    const term = event.target.value;
    if (isSearch.current && term.length === 0) {
      setSearchTerm("");
      isSearch.current = false;
      pagination.current.pageNumber = 0;
      dispatch(
        fetchAllExperts(
          pagination.current.pageable.pageNumber,
          pagination.current.pageable.pageSize,
        ),
      );
    } else {
      setSearchTerm(term);
    }
  };

  const handleSearchInpKeypress = (event) => {
    if (event.key === "Enter") {
      if (searchTerm) {
        isSearch.current = true;
        setExpertsTableList(filteredExperts);
      }
    }
  };

  const handleSearchBtnClick = () => {
    if (searchTerm) {
      isSearch.current = true;
      setExpertsTableList(filteredExperts);
    }
  };

  // GET ALL EXPERTS
  const handleFetchingAllExperts = async () => {
    dispatch(fetchAllExpertsWitnNoPagination());
  };

  // PAGINATION
  const handlePageChangeOnClick = ({ pageNumber, pageSize }) => {
    dispatch(fetchAllExperts(pageNumber, pagination.current.pageable.pageSize));
  };

  // SIDEBAR
  const handleSidebarCloseClick = () => {
    setShowMenu(false);
  };

  // EXPORT EXPERTS
  const handleExportExperts = () => dispatch(expertExport());

  useEffect(() => {
    if (expert) {
      const mappedData = mapExpertToSidebarView(expert, t);
      setSelectedExpert(mappedData);
      selectedExpertData.current = { ...expert };
      setShowMenu(true);
    }
  }, [expert]);

  useEffect(() => {
    dispatch(
      fetchAllExperts(
        pagination.current.pageable.pageNumber,
        pagination.current.pageable.pageSize,
      ),
    );
  }, []);

  useEffect(() => {
    if (data) {
      if (data.content) {
        pagination.current = data;
        setExpertsTableList([...(data.content as IExpert[])]);
      }
    }
  }, [data as Pagination, loading]);

  useEffect(() => {
    if (!loading && error) {
      notifyError(
        store,
        t("admin-company-get-data-error-title"),
        t("admin-company-get-data-error-desc"),
      );
    }
  }, [loading]);

  useEffect(() => {
    setShowMenu(false);
  }, [setShowMenu]);

  return (
    <>
      {(expertsTableList.length > 0 || isSearch.current) && (
        <div className="admin-search-wrapper">
          <input
            type="text"
            className="admin-search-field"
            value={searchTerm}
            name="search"
            onChange={handleSearchInpChange}
            onKeyPress={handleSearchInpKeypress}
            placeholder={t("table_search_experts")}
            onFocus={!isSearch.current && handleFetchingAllExperts}
          />
          <button
            className="admin-search-button"
            onClick={handleSearchBtnClick}
          >
            {t("table_search_button")}
          </button>
        </div>
      )}
      <div className="admin-table-container">
        {loading && (
          <div className="align-center">
            <ReactLoading type={"bars"} color={"#a41e24"} />
          </div>
        )}
        {!loading && expertsTableList.length === 0 && (
          <div className="no-list-text-wrapper">
            {isSearch.current ? (
              <p>
                {t("admin-company-no-search-results") +
                  " " +
                  '"' +
                  searchTerm +
                  '"'}
              </p>
            ) : (
              <p>{t("admin-expert-no-results")}</p>
            )}
          </div>
        )}
        {expertsTableList.length > 0 && (
          <>
            <div className="title-btn-wrapper">
              <h2>{t("admin-expert")}</h2>
              <button className="export-button" onClick={handleExportExperts}>
                {t("admin-export")}
              </button>
            </div>
            {selectedExpert && (
              <Sidebar
                sidebarData={selectedExpert}
                showMenu={showMenu}
                onCloseSidebar={handleSidebarCloseClick}
                translation={translation}
                hasDocument={expert && expert.hasDocument}
                profileType="expert"
                currentId={selectedExpertData.current?.id}
              />
            )}
            <table className="admin-table">
              <thead>
                <tr className="admin-head admin-row admin-row-experts">
                  <th>{t("table_expert")}</th>
                  <th>{t("table_industry")}</th>
                  <th>{t("expert_country")}</th>
                  <th>{t("table_support_offered")}</th>
                  <th className="custom-center">{t("admin-created")}</th>
                  <th className="custom-center">{t("admin-last-modified")}</th>
                  <th className="custom-center">
                    {t("table_expert_availability")}
                  </th>
                  <th>{t("table_actions")}</th>
                </tr>
              </thead>
              <tbody>
                {expertsTableList.map((expert) => {
                  return (
                    <tr
                      className="admin-row admin-row-experts single-row"
                      key={expert.id + generateUniqId()}
                      onMouseOver={() =>
                        expert.matchedCompanies?.length > 1
                          ? setCurrentMatchedCompanies(
                              `${expert.matchedCompanies[0]?.name} ...`,
                            )
                          : setCurrentMatchedCompanies(
                              expert.matchedCompanies[0]?.name,
                            )
                      }
                    >
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          {expert.firstName} {expert.lastName}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          {expert.industries && expert.industries.length > 1
                            ? expert.industries[0]?.name + "..."
                            : expert.industries[0]?.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          {expert.country && expert.country.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          {expert.supportTypes &&
                          expert.supportTypes.length > 0 &&
                          expert.supportTypes.length > 1
                            ? expert.supportTypes[0]?.name + "..."
                            : expert.supportTypes[0]?.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell custom"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          <span>{dateToProperFormat(expert.dateCreated)}</span>
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell custom"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          <span>
                            {expert.lastModified
                              ? dateToProperFormat(expert.lastModified)
                              : "N/A"}
                          </span>
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedCompanies === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedCompanies}`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell custom"
                          onClick={() => handleSelectedExpert(expert.id)}
                        >
                          <span>
                            {" "}
                            {expert.numberOfCompanies
                              ? `${expert.matchedCompanies?.length} / ${expert.numberOfCompanies}`
                              : `${expert.matchedCompanies?.length} / N/A`}
                          </span>
                        </td>
                      </LightTooltip>
                      <td>
                        <span
                          className="admin-table-delete"
                          onClick={() =>
                            handleDeleteExpertRowClick(expert.cognitoId)
                          }
                        >
                          <TrashIcon
                            width="1.75em"
                            height="1.75em"
                            color="#a41e24"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {!isSearch.current && (
          <PaginationComponent
            totalPages={pagination.current.totalPages}
            changePage={handlePageChangeOnClick}
          />
        )}
        <DeleteExpertModal
          open={showDeleteModal}
          expertId={deleteExpertId.current}
          onDeleteSuccess={handleDeleteExpertSuccess}
          onCancel={handleDeleteModalOnCancel}
        />
      </div>
    </>
  );
};
