import { ICompany } from "../company/company";
import { IExpert } from "../expert/expert";

export interface IPagination {
  /**
   * Indicated current page number in use
   */
  pageNumber: number;
  content: ICompany[] | IExpert[] | any[];
  totalPages: number;
  pageable: { pageNumber: number; pageSize: number };
}

/**
 * Represents the pagination objects for lists.
 * This object have properties that are the same with the back-end object.
 * note! this is only properties that need by now. If you need more properties
 * check the back-end object.
 */
export class Pagination implements IPagination {
  pageNumber: number;
  content: any[];
  size: number;
  totalPages: number;
  pageable: { pageNumber: number; pageSize: number };
  public constructor(init?: Partial<Pagination>) {
    Object.assign(this, init);
    this.pageable = (init && init.pageable) || {
      pageNumber: 0,
      pageSize: 10,
    };
  }
}
