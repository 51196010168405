export const FETCH_ALL_COMPANIES = "ADMIN_FETCH_ALL_COMPANIES";
export const FETCH_ALL_COMPANIES_NO_PAGINATION =
  "FETCH_ALL_COMPANIES_NO_PAGINATION";
export const COMPANY_CLEANUP = "ADMIN_COMPANY_CLEANUP";
export const COMPANY_DELETE = "ADMIN_COMPANY_DELETE";
export const COMPANY_DELETE_CLEANUP = "COMPANY_DELETE_CLEANUP";
export const COMPANY_SEARCH = "ADMIN_COMPANY_SEARCH";
export const COMPANY_GET = "ADMIN_COMPANY_GET";
export const COMPANY_EXPORT = "ADMIN_COMPANY_EXPORT";
export const COMPANY_GET_CLEANUP = "ADMIN_COMPANY_GET_CLEANUP";
export const COMPANY_GET_AVALIABLE_EXPERTS =
  "ADMIN_COMPANY_GET_AVALIABLE_EXPERT";
export const COMPANY_GET_AVALIABLE_EXPERTS_CLEANUP =
  "ADMIN_COMPANY_GET_AVALIABLE_EXPERTS_CLEANUP";
export const COMPANY_MATCH_EXPERT = "ADMIN_COMPANY_MATCH_EXPERT";
export const COMPANY_UNMATCH_EXPERT = "ADMIN_COMPANY_UNMATCH_EXPERT";
export const COMPANY_MATCH_EXPERT_CLEANUP =
  "ADMIN_COMPANY_MATCH_EXPERT_CLEANUP";
export const COMPANY_UNMATCH_EXPERT_CLEANUP =
  "ADMIN_COMPANY_UNMATCH_EXPERT_CLEANUP";
