import {useTranslation} from "react-i18next";
import {Box, Button, Paper, Typography} from "@material-ui/core";
import React from "react";
import triglavImage from '../../../../assets/images/triglav.png';
import imperialTobaccoImage from '../../../../assets/images/imperial-tobacco.png';
import halkImage from '../../../../assets/images/halk.png';

import './partnerLogos.scss';

export const PartnerLogos = () => {
  const { t } = useTranslation("homepage");

  return (
    <Box className="partner-logos" style={{ position: "relative" }}>
      <a href={'https://www.imperialbrandsplc.com/ '}>
        <img
          src={imperialTobaccoImage}
          alt="Imperial Tobacco"
          className="partner-logo-image"
        />
      </a>
      <a href={'https://www.triglav.mk/'}>
        <img
          src={triglavImage}
          alt="Triglav"
          className="partner-logo-image"
        />
      </a>
      <a href={'https://halkbank.mk/#general'}>
        <img
          src={halkImage}
          alt="Halk"
          className="partner-logo-image"
        />
      </a>
      
      
    </Box>
  );
};