import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import whatWeOfferImage from "../../../../assets/images/whatWeOfferImage.svg";
import whatWeOfferImage2 from "../../../../assets/images/whatWeOfferImage2.svg";
import Illustration from "../../../../assets/images/illustration-2.svg";
import { FeatureComponent } from "../featureComponent/featureComponent";
import "./whatWeOffer.scss";

export const WhatWeOffer: React.FC = () => {
  return (
    <>
      <Box className="what-we-offer-wrapper" style={{ position: "relative" }}>
        <FeatureComponent />
        <Typography className="what-we-offer-heading" variant="h2">
          What we offer
        </Typography>
        <Paper elevation={1} className="what-we-offer-content-wrapper">
          <div className="line"></div>
          <Box className="box-wrap">
            <img
              src={whatWeOfferImage}
              alt="Left"
              className="what-we-offer-image"
            />
            <Typography className="what-we-offer-subheading" variant="h3">
              Mentoring
            </Typography>
            <Typography variant="body2">
              That will help you grow and improve your business.
            </Typography>
          </Box>
          <Box className="box-wrap">
            <img
              src={whatWeOfferImage2}
              alt="Right"
              className="what-we-offer-image"
            />
            <Typography className="what-we-offer-subheading" variant="h3">
              Support
            </Typography>
            <Typography variant="body2">
              In entering foreign markets / growing internationally.
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box className="primary-color-bg">
        <img className="illustration" src={Illustration} alt="SVG Image" />
      </Box>
    </>
  );
};
