import React from "react";
import { useTranslation } from "react-i18next";
import { generateUniqId } from "../../../../utils/helpers/general-helper";
import DescriptionIcon from "@material-ui/icons/Description";
import VisibilityIcon from "@material-ui/icons/Visibility";

import "./../matchingCompanyDetails.scss";

export default function DisplayFields({ fields, companyId = null }) {
  const { t } = useTranslation("admin-matching");

  return fields.content.length === 2 ? (
    <div className="admin-comp-fields custom">
      {fields &&
        fields.content &&
        fields.content.map((field) => (
          <div className="admin-comp-field" key={generateUniqId()}>
            <div className="admin-comp-field-title">{t(field.name)}</div>
            {field.isFile && field.value !== "Not uploaded" ? (
              <div className="admin-comp-field-value-custom-wrapper">
                {fields.content[1].value.map((file: string, idx: number) => (
                  <div className="admin-comp-field-value custom" key={idx}>
                    <DescriptionIcon className="document-icons" />
                    <p>{file}</p>
                    <a
                      className="link"
                      href={`/uploads/company/${companyId}_${file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon className="document-icons" />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <div className="admin-comp-field-value">{field.value}</div>
            )}
          </div>
        ))}
    </div>
  ) : (
    <div className="admin-comp-fields">
      {fields &&
        fields.content &&
        fields.content.map((field) => (
          <div className="admin-comp-field" key={generateUniqId()}>
            <div className="admin-comp-field-title">{t(field.name)}</div>
            <div className="admin-comp-field-value">{field.value}</div>
          </div>
        ))}
    </div>
  );
}
