import React from "react";
import "./validation-error.scss";

interface IValidationErrorProps {
  emailValidation: any;
}

export class ValidationError extends React.Component<IValidationErrorProps> {
  render() {
    const emailValidationError = this.props.emailValidation;
    return (
      <div>
        <span className="validationError">{emailValidationError.message}</span>
      </div>
    );
  }
}
