import { cognitoAuth } from "../../utilities/auth";

export const signupFormAction = (user) => {
  return {
    type: "SUBMIT_SIGNUP",
    payload: cognitoAuth.signUp({
      username: user.get("email"),
      password: user.get("password"),
      attributes: {
        "custom:fullname": user.get("fullName"),
        "custom:profileType": user.get("profileType"),
      },
    }),
  };
};

export const deleteError = () => {
  return {
    type: "SIGNUP_DELETE_ERROR",
  };
};
