import { ISidebar, SidebarView } from "../../../models/general/sidebar";
import { generalData } from "../../../models/general/general-data";
import { IExpert } from "../../../models/expert/expert";

export const mapExpertToSidebarView = (expert: IExpert, t: any): ISidebar => {
  const sidebar = new SidebarView();

  if (!!expert.firstName && !expert.lastName) {
    sidebar.title = expert.firstName || "";
  } else if (!!expert.firstName && !!expert.lastName) {
    sidebar.title = expert.firstName + " " + expert.lastName || "";
  }

  sidebar.subtitle = expert.email ? expert.email : "";

  sidebar.tabs = [
    {
      content: mapGeneralDataToContent(expert, t),
      id: 0,
      name: "general",
      tlabel: t("expert:tab-general"),
    },
    {
      content: mapSupportOfferedToContent(expert, t),
      id: 1,
      name: "support",
      tlabel: t("expert:expert_general_support_tab_info"),
    },
    {
      content: mapDocumentsDataToContent(expert, t),
      id: 2,
      name: "additionalInfo",
      tlabel: t("expert:tab-additionalInfo"),
    },
  ];

  return sidebar;
};

const mapGeneralDataToContent = (
  expert: IExpert,
  t: any,
): { name: string; value: any }[] => {
  const allFields = [
    {
      name: t("expert:expert_sb_job_title"),
      value: expert?.jobTitle,
    },
    {
      name: t("expert:expert_sb_company_name"),
      value: expert?.companyName,
    },
    {
      name: t("expert:expert_sb_country"),
      value: expert?.country.name,
    },
    {
      name: t("expert:expert_sb_phone_number"),
      value: expert?.phoneNumber,
    },
    {
      name: t("expert:expert_sb_linkedin_url"),
      value: expert.linkedinProfile ? expert.linkedinProfile : "",
    },
    {
      name: t("expert:expert_sb_fields_of_interest"),
      value: expert.fieldsOfInterest ? expert.fieldsOfInterest : "",
    },
  ];

  //filter the fields in order to show only the fulfilled ones for an expert
  return allFields.filter((field) => field.value !== "");
};

const mapSupportOfferedToContent = (
  expert: IExpert,
  t: any,
): { name: string; value: string }[] => {
  const supportInIndustries = expert.industries.map((item) => item.name);
  const expertise = expert.expertise.map((item) => item.name);
  const servicesToOffer = expert.supportTypes.map((item) => item.name);
  const regions = expert.regions.map((item) => item.name);
  const numberOfCompaniesLabel = generalData.numberOfCompaniesToWorkWith.filter(
    (item) => item.value === expert.numberOfCompanies,
  );

  const allFields = [
    {
      name: t("expert:expert_sb_services_offer"),
      value: servicesToOffer.length > 0 ? servicesToOffer.join(", ") : "",
    },
    {
      name: t("expert:expert_sb_support_industries"),
      value:
        supportInIndustries.length > 0 ? supportInIndustries.join(", ") : "",
    },
    {
      name: t("expert:expert_sb_expertise"),
      value: expertise.length > 0 ? expertise.join(", ") : "",
    },
    {
      name: t("admin-sidebar-region"),
      value: regions.length > 0 ? regions.join(", ") : "",
    },
    {
      name: t("expert:expert_sb_companies_to_work_with"),
      value:
        numberOfCompaniesLabel.length > 0
          ? numberOfCompaniesLabel[0].label
          : "",
    },
    {
      name: t("expert:expert_sb_working_with_startups"),
      value: expert.workWithStartups
        ? t("expert:expert_sb_yes")
        : t("expert:expert_sb_no"),
    },
    {
      name: t("expert:expert_sb_expectations_from_the_platform"),
      value: expert.expectationsFromThePlatform
        ? expert.expectationsFromThePlatform
        : "",
    },
  ];

  //filter the fields in order to show only the fulfilled ones for an expert
  return allFields.filter((field) => field.value !== "");
};

const mapDocumentsDataToContent = (
  expert: IExpert,
  t: any,
): { name: string; value: string; isFile?: boolean; url?: string }[] => {
  const matchedCompanies = expert.matchedCompanies?.map((item) => item.name);

  const allFields = [
    {
      name: t("table_expert_availability"),
      value: expert.numberOfCompanies
        ? `${expert.matchedCompanies?.length} / ${expert.numberOfCompanies}`
        : `${expert.matchedCompanies?.length} / N/A`,
    },
    {
      name: t("table_expert_matched_with"),
      value: matchedCompanies.length > 0 ? matchedCompanies.join(", ") : "/",
    },
    {
      name: t("admin-sidebar-documents"),
      value: expert.hasDocument
        ? expert.documents
        : t("admin-sidebar-not-uploaded"),
      isFile: true,
      url: expert.hasDocument ? expert.documentUrl : null,
    },
  ];

  return allFields;
};
