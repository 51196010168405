import React, { useState, useEffect } from "react";

import "./matchingCompanyDetails.scss";
import DisplayFields from "./displayFields/displayFields";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { useTranslation } from "react-i18next";
import { SidebarView } from "../../../models/general/sidebar";
import { TabNavigationEnum } from "../../../models/company/profile-tab-navigation-enum";
import { mapToCompanySidebarView } from "../../../utils/mappers/company/companyToSidebarView";

export const MatchingCompanyDetails = () => {
  const [activeTab, setActiveTab] = useState("general");
  const tabIds = [
    "general",
    "contactPerson",
    "expertise",
    "exports",
    "additionalInfo",
  ];
  const [mappedCompanyViewData, setMappedCompanyViewData] = useState<
    SidebarView
  >(null);

  const { loadingCompany, company, companyError } = useSelector(
    (state: IRootState) => state.adminMatching,
  );

  const { t } = useTranslation(["admin-tables", "admin-matching"]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleTabRender = (tabId) => {
    switch (tabId) {
      case "general":
        return (
          <DisplayFields
            fields={mappedCompanyViewData.tabs[TabNavigationEnum.General]}
          />
        );
      case "contactPerson":
        return (
          <DisplayFields
            fields={mappedCompanyViewData.tabs[TabNavigationEnum.ContactPerson]}
          />
        );
      case "expertise":
        return (
          <DisplayFields
            fields={mappedCompanyViewData.tabs[TabNavigationEnum.Expertise]}
          />
        );
      case "exports":
        return (
          <DisplayFields
            fields={mappedCompanyViewData.tabs[TabNavigationEnum.Exports]}
          />
        );
      case "additionalInfo":
        const docData =
          mappedCompanyViewData.tabs[TabNavigationEnum.AdditionalInfo];

        return <DisplayFields fields={docData} companyId={company.id} />;
    }
  };

  useEffect(() => {
    if (company) {
      const mappedData = mapToCompanySidebarView({ ...company }, t);
      setMappedCompanyViewData(mappedData);
      return;
    }
  }, [company]);

  return (
    <>
      {company && (
        <div>
          <div className="admin-comp-details-tabs">
            <button
              className={`admin-comp-tab ${
                activeTab === tabIds[0] ? "active-tab" : null
              }`}
              onClick={() => handleTabChange(tabIds[0])}
            >
              {t("admin-matching:company_tab_general")}
            </button>
            <button
              className={`admin-comp-tab ${
                activeTab === tabIds[1] ? "active-tab" : null
              }`}
              onClick={() => handleTabChange(tabIds[1])}
            >
              {t("admin-matching:company_tab_contact_person")}
            </button>
            <button
              className={`admin-comp-tab ${
                activeTab === tabIds[2] ? "active-tab" : null
              }`}
              onClick={() => handleTabChange(tabIds[2])}
            >
              {t("admin-matching:company_tab_expertise")}
            </button>
            <button
              className={`admin-comp-tab ${
                activeTab === tabIds[3] ? "active-tab" : null
              }`}
              onClick={() => handleTabChange(tabIds[3])}
            >
              {t("admin-matching:company_tab_exports")}
            </button>
            <button
              className={`admin-comp-tab ${
                activeTab === tabIds[4] ? "active-tab" : null
              }`}
              onClick={() => handleTabChange(tabIds[4])}
            >
              {t("admin-matching:company_tab_additional_info")}
            </button>
          </div>
          <div className="admin-comp-details-tab-content">
            {mappedCompanyViewData && (
              <div className="company-info">
                <h2>{mappedCompanyViewData.title}</h2>
                <h3>{mappedCompanyViewData.subtitle}</h3>
              </div>
            )}
            <hr style={{ border: "1px solid #a1a1a122" }} />
            {mappedCompanyViewData && handleTabRender(activeTab)}
          </div>
        </div>
      )}
      {loadingCompany && (
        <div className={"container-loading"}>
          <ReactLoading type={"bars"} color={"#a41e24"} />
        </div>
      )}
      {companyError && (
        <div className={"container-loading"}>
          <div className={"alert error"}>
            {t("admin-matching:matching_loading_single_company_error")}
          </div>
        </div>
      )}
    </>
  );
};
