import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "react-notifications-component";

import { IRootState } from "../../../../utilities/root-reducer";

import { uploadFile } from "../../../companyProfile/company-profile-action";

import { notifyError } from "../../../../utils/notifications/app-notification";

import { CompanyFormWorkMode } from "../../../../models/company/company-form-work-mode";
import { bytesToMegabytes } from "../../expertDocumentUpload/components/document-upload-component";
import CloseIcon from "@material-ui/icons/Close";

import "./../company-document-upload.scss";

export const DocumentUploadComponent = ({
  formWorkMode,
  onUploadSelection,
  data,
  active,
  onNewDocumentUploadSuccess,
  setFormWorkMode,
}) => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();

  const maxFileSize = 10485760;

  const { cancelDataChanges } = useSelector(
    (state: IRootState) => state.company,
  );

  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);

  const filteredFilesWithProperSize = files.filter(
    (el) => el.size < maxFileSize,
  );

  // Clear input on cancel
  useEffect(() => {
    cleanInput();
    setFiles([]);
  }, [cancelDataChanges]);

  //remove files that are exeeding max size
  useEffect(() => {
    if (filteredFilesWithProperSize.length !== files.length) {
      setFiles(filteredFilesWithProperSize);
    }
  }, [filteredFilesWithProperSize]);

  useEffect(() => {
    if (files.length > 0) {
      const fileForm = new FormData();

      files.forEach((file) => fileForm.append("files", file));
      dispatch(uploadFile(fileForm));
    }

    if (files.length === 0 && typeof data.dateCreated !== "undefined") {
      setFormWorkMode(CompanyFormWorkMode.View);
    }
  }, [files]);

  const handleChooseFile = (event) => {
    event.preventDefault();
    setFiles([...files, ...event.target.files]);

    const filesList = event.target.files;

    let filesListArray = Object.keys(filesList).map((key) => filesList[key]);

    const filesWithExeededSize = filesListArray.filter(
      (el) => el.size >= maxFileSize,
    );

    if (filesWithExeededSize.length > 0) {
      filesWithExeededSize.map((el) =>
        notifyError(
          store,
          el.name + " " + t("document-upload-error-title"),
          t("document-upload-error-desc"),
        ),
      );
    }

    // Set to edit mode only if current mode is view
    if (formWorkMode === CompanyFormWorkMode.View) {
      onUploadSelection();
    }
  };

  const cleanInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const deleteFile = (fileIdx) =>
    setFiles(files.filter((_, index) => index !== fileIdx));

  return (
    <>
      <div className="document-upload-wrapper">
        <label className="multi-docs-label" htmlFor="fileUpload">
          {filteredFilesWithProperSize.length === 0
            ? t("browse-files")
            : t("browse-more-files")}
        </label>
        <input
          type="file"
          ref={fileInputRef}
          id="fileUpload"
          className="document-upload-input"
          name="documentName"
          multiple
          onChange={handleChooseFile}
        />
        <span className="document-name">
          {files?.length > 0 ? (
            <div>
              {files.map((file, index) => (
                <div key={index} className="attach-file-wrapper-outer">
                  <div className="attach-file-wrapper-inner">
                    <p className="document-name">{`${
                      file.name
                    } (${bytesToMegabytes(file.size)} MB)`}</p>
                    <CloseIcon
                      className="close-icon"
                      onClick={() => deleteFile(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            t("no-file-selected")
          )}
        </span>
      </div>
    </>
  );
};
