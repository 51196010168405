import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "./company-modals.scss";
import { useTranslation } from "react-i18next";
import Spinner from "../../../common/spinner/spinner";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../../../utilities/root-reducer";
import {
  notifyError,
  notifySuccess,
} from "../../../../utils/notifications/app-notification";
import { store } from "react-notifications-component";
import {
  deleteCompany,
  deleteCompanyCleanup,
} from "../../../adminTables/companyTableComponent/companyTableActions";

interface IDeleteCompanyModalProps {
  open: boolean;
  companyId: string;
  onDeleteSuccess: () => void;
  onCancel: () => void;
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default function DeleteCompanyModal(props: IDeleteCompanyModalProps) {
  const { open, onDeleteSuccess, onCancel, companyId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation("admin-tables");
  const { deleteLoading, deleteStatus, error } = useSelector(
    (state: IRootState) => state.adminCompanies,
  );

  /**
   * // recommended by the author of the package
   */
  ReactModal.setAppElement("body");

  useEffect(() => {
    if (!deleteLoading && deleteStatus) {
      notifySuccess(
        store,
        t("admin-company-delete-notify-title"),
        t("admin-company-delete-success-desc"),
      );
      dispatch(deleteCompanyCleanup());
      onDeleteSuccess();
    } else if (!deleteLoading && error) {
      notifyError(
        store,
        t("admin-company-delete-notify-title"),
        t("admin-company-delete-error-desc"),
      );
    }
  }, [deleteLoading]);

  const handleDeleteClick = () => {
    dispatch(deleteCompany(companyId));
  };

  return (
    <ReactModal
      isOpen={open}
      role={"dialog"}
      shouldCloseOnEsc={false}
      style={modalStyle}
      className="responsive-modal-container"
    >
      <div className={"delete-modal-content"}>
        <h4>{t("admin-company-modal-delete-desc")}</h4>
        <br></br>
        <div className={"control-button-container"}>
          <button
            disabled={deleteLoading}
            className={"button-with-loading"}
            onClick={handleDeleteClick}
          >
            {deleteLoading && <Spinner />}
            {t("admin-company-modal-ok")}
          </button>
          <button
            disabled={deleteLoading}
            onClick={onCancel}
            className={"cancel"}
          >
            {t("admin-company-modal-cancel")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
