import React, { useEffect, useState } from "react";
import "./expert-profile-landing.scss";
import { useTranslation } from "react-i18next";
import { IRootState } from "../../../utilities/root-reducer";
import { useSelector } from "react-redux";
import { ExpertFormWorkMode } from "../../../models/expert/expert-form-work-mode";
import { notifyError } from "../../../utils/notifications/app-notification";
import { store } from "react-notifications-component";
import PageLoading from "../../pageLoading/page-loading";
import { ExpertProfileSummary } from "../Summary/expert-profile-summary";

interface IExpertProfileLandingProps {
  onCreateProfileClick: () => void;
  showLoadingBar: boolean;
  setWorkModeView: (value: ExpertFormWorkMode) => void;
}

export default function ExpertProfileLanding(
  props: IExpertProfileLandingProps,
) {
  const { t } = useTranslation(["expert"]);
  const {
    getLoading,
    getError,
    newStatus,
    newLoading,
    newError,
    documentLoading,
    hasDocument,
    documentUploadError,
  } = useSelector((state: IRootState) => state.expert);

  const [internalFormWorkMode, setInternalFormWorkMode] = useState(
    ExpertFormWorkMode.Done,
  );
  const [showLoading, setShowLoading] = useState(props.showLoadingBar);
  const [documentIsUploading, setDocumentIsUploading] = useState(false);

  useEffect(() => {
    //NEW EXPERT LANDING
    if (!newLoading && !hasDocument && !newStatus) {
      setInternalFormWorkMode(ExpertFormWorkMode.Hidden);
      setShowLoading(false);
      return;
    }

    //EXPERT CREATE AND DOCUMENT UPLOAD SUCCESS
    if (!newStatus && !newLoading && !newError && hasDocument) {
      //File is uploading
      if (documentLoading) {
        setDocumentIsUploading(true);
      }
      if (documentIsUploading) {
        if (!hasDocument && !documentLoading) {
          setInternalFormWorkMode(ExpertFormWorkMode.Summary);
          setShowLoading(false);
          notifyError(
            store,
            t("document-upload-error-desc"),
            t("document-upload-error-summary"),
          );
          return;
        } else if (hasDocument && !documentLoading) {
          //File successfully uploaded
          setInternalFormWorkMode(ExpertFormWorkMode.Summary);
          setShowLoading(false);
        } else if (documentUploadError) {
          setInternalFormWorkMode(ExpertFormWorkMode.Summary);
          setShowLoading(false);
          notifyError(
            store,
            t("document-upload-error-desc"),
            t("document-upload-error-summary"),
          );
        }
      } else {
        setInternalFormWorkMode(ExpertFormWorkMode.Summary);
        setShowLoading(false);
      }
    }
    //SIGN UP ERROR
    if (!newStatus && !newLoading && newError && !hasDocument) {
      setInternalFormWorkMode(ExpertFormWorkMode.Hidden);
      setShowLoading(false);
      notifyError(
        store,
        t("company-profile-update-title"),
        t("company-profile-create-error"),
      );
    }
  }, [
    newStatus,
    newLoading,
    documentLoading,
    documentIsUploading,
    documentUploadError,
    hasDocument,
  ]);
  return (
    <>
      {showLoading && <PageLoading type="bars" />}

      {internalFormWorkMode === ExpertFormWorkMode.Hidden && (
        <div className={"expert-landing"}>
          <div className={"expert-landing-cover"}>
            <div className={"expert-box-layout"}>
              <div className={"expert-box"}>
                <div className={"expert-box-title"}>
                  {t("expert_profile_landing_title")}
                </div>
                <hr></hr>
                <div className={"expert-box-desc"}>
                  {t("export_profile_landing_desc")}
                </div>
                <div className={"control-button-container"}>
                  {!getLoading && getError ? (
                    <div className={"alert error"}>
                      {t("expert-profile-problem-page-load")}
                    </div>
                  ) : (
                    <button onClick={() => props.onCreateProfileClick()}>
                      {t("export_profile_landing_create_lbl")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {internalFormWorkMode === ExpertFormWorkMode.Summary && (
        <ExpertProfileSummary setWorkModeView={props.setWorkModeView} />
      )}
    </>
  );
}
