import React, { useState, useEffect, useRef } from "react";
import { ITab } from "./definitions/tabs-spec";
import { IRootState } from "../../../utilities/root-reducer";
import compose from "recompose/compose";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  profileTabNavigation,
  TabNavigationEnum,
} from "../../../models/company/profile-tab-navigation-enum";
import { ConfigurableInput } from "../../formComponents/configurable-input";
import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  CheckboxSelectEvent,
  YesNoRadioButtonEvent,
  SelectInputEvent,
  TextInputEvent,
} from "../../formComponents/definitions/InputConfigurationSpec";
import { companyToExpertiseTabForm } from "../../../utils/mappers/company/companyToExpertiseTabForm";
import Form from "react-validation/build/form";
import { InputTypeEnum } from "../../../models/forms/input-types-enum";
import { generalData } from "../../../models/general/general-data";

interface ITypeOfExpertiseProps extends ITab, StateProps, DispatchProps {
  data: ICompany;
}

export function TypeOfExpertiseTab(props: ITypeOfExpertiseProps) {
  const { data, active, onChange, validateTab, onTabValidation } = props;
  const [formInputs, setFormInputs] = useState<ConfigurableInputProps[]>([]);
  const formData = useRef<ICompany>({ ...data });
  const { companyState } = props;

  const { staticData } = useSelector((state: IRootState) => state.company);

  useEffect(() => {
    const formInpComponents = companyToExpertiseTabForm(
      data,
      staticData,
      generalData,
    );
    setFormInputs(formInpComponents);
    formData.current = { ...data };
  }, [data]);

  useEffect(() => {
    if (validateTab === profileTabNavigation[TabNavigationEnum.Expertise]) {
      const isValid = validate();
      onTabValidation(validateTab, isValid);
    }
  }, [validateTab]);

  useEffect(() => {
    const formInpComponents = companyToExpertiseTabForm(
      data,
      staticData,
      generalData,
      true,
    );
    setFormInputs(formInpComponents);
  }, [companyState.cancelDataChanges]);

  /**
   * Validate the form for error
   */
  const validate = (): boolean => {
    let isFormValid = true;
    formInputs.forEach((input) => {
      // will trigger error message for error
      const inputIsValid = input.inputConfig.validate();
      if (!inputIsValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  /**
   * EVENTS SECTION
   * @param event
   */
  const handleYesNoEventInput = (event: YesNoRadioButtonEvent) => {
    formData.current[event.modelValue] = event.answer;
    onChange({ ...formData.current });
  };

  const handleCheckboxListEventInput = (event: CheckboxSelectEvent) => {
    formData.current[event.modelValue] = event.data.map((item) => ({
      id: item.value,
      hasDescription: item.hasDescription,
      description: item.description,
    }));

    onChange({ ...formData.current });
  };

  const handleSelectEventInput = (event: SelectInputEvent) => {
    if (event.name === "developmentPhase" || event.name === "growthPhase") {
      formData.current[event.modelValue] = event.selectedOption.value;
    } else {
      formData.current[event.modelValue] = event.selectedMultiOptions.map(
        (item) => {
          return {
            id: item.value,
            name: item.label,
          };
        },
      );
    }

    onChange({ ...formData.current });
  };

  const handleTextInput = (event: TextInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current });
  };

  const handleEvent = (component: ConfigurableInputProps) => {
    switch (component.inputConfig.inputType) {
      case InputTypeEnum.YesNoRadioButton:
        component.onInputChange = handleYesNoEventInput;
        break;
      case InputTypeEnum.Select:
        component.onInputChange = handleSelectEventInput;
        break;
      case InputTypeEnum.CheckboxList:
        component.onInputChange = handleCheckboxListEventInput;
        break;
      case InputTypeEnum.Text:
        component.onInputChange = handleTextInput;
        break;
    }
  };

  const renderComponents = () => {
    return (
      <>
        {formInputs.map((component, index) => {
          if (component) {
            handleEvent(component);
          }
          return (
            <>
              {component && (
                <ConfigurableInput
                  key={"comp-" + component.uniqueKey}
                  {...component}
                />
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {active === profileTabNavigation[TabNavigationEnum.Expertise] && (
        <div className="profile-card-body">
          <Form className="profile-form-info">{renderComponents()}</Form>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  companyState: storeState.company,
});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("profile"),
  connect(mapStateToProps, mapDispatchToProps),
)(TypeOfExpertiseTab);
