import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { store } from "react-notifications-component";
import { generateUniqId } from "../../utils/helpers/general-helper";

import {
  fetchFilteredCompanies,
  fetchFilteredCompaniesPagination,
  fetchMatchingExperts,
  getCompany,
  getExpert,
  cleanupData,
  exportMatchingDocument,
} from "./adminMatchingActions";
import {
  fetchAllCompanies,
  fetchAllCompaniesNoPagination,
} from "../adminTables/companyTableComponent/companyTableActions";

import { IRootState } from "../../utilities/root-reducer";
import { MatchingCompanyDetails } from "./matchingCompanyDetails/matchingCompanyDetails";
import { MatchingTable } from "./matchingCompanyDetails/matchingTable/matchingTable";
import { SelectInput } from "../formComponents";
import {
  SelectInputEvent,
  SelectInputConfig,
} from "../formComponents/definitions/InputConfigurationSpec";
import { notifyError } from "../../utils/notifications/app-notification";
import Sidebar from "../sidebarComponent/sidebarComponent";
import { IExpert } from "../../models/expert/expert";
import { ISidebar } from "../../models/general/sidebar";
import { mapExpertToSidebarView } from "../../utils/mappers/experts/expertToSidebarView";
import { Pagination, IPagination } from "../../models/general/pagination";
import { dateToProperFormat } from "../common/DatePicker";
import back from "../../../src/assets/images/go-back.png";

import "./adminMatchingComponent.scss";
import "../../components/adminTables/companyTableComponent/companyTableComponent.scss";
import { PaginationComponent } from "../paginationComponent/paginationComponent";
interface AdminMatchingComponent {
  onGetFilterType: (multiFilters: boolean | null) => void;
}

export const AdminMatchingComponent = (props) => {
  const { onGetFilterType } = props;
  const translation = useTranslation([
    "admin-tables",
    "admin-matching",
    "expert",
  ]);
  const { t } = translation;
  const dispatch = useDispatch();
  const {
    // DATA
    companies,
    filteredCompaniesWithPagination,
    company,
    experts,
    expert,
    /// LOADING
    loadingCompanies,
    loadingExpert,
    loadingCompany,
    loadingExperts,
    // ERROR
    companiesError,
    expertError,
    expertsError,
    companyError,
  } = useSelector((state: IRootState) => state.adminMatching);

  const { data, loading, allCompaniesNoPagination, error } = useSelector(
    (state: IRootState) => state.adminCompanies,
  );

  const { staticData } = useSelector((state: IRootState) => state.company);

  // list and pagination
  const pagination = useRef<IPagination>(new Pagination());
  const filteredPagination = useRef<IPagination>(new Pagination());
  const [showPagination, setShowPagination] = useState(false);

  const [showExpertSidebar, setShowExpertSidebar] = useState<boolean>(false);
  const [expertSidebarData, setExpertSidebarData] = useState<ISidebar>(null);

  const [matchingExpertId, setMatchingExpertId] = useState<null | number>(null);

  /**
   * FILTERS
   */

  const [filters, setFilters] = useState({
    industryId: 0,
    expertiseId: 0,
    regionId: 0,
    supportTypeId: 0,
  });

  //filters for industry, expertise, region, and/or type of support
  const isFiltersActive = Object.values(filters).some((el) => el > 0);

  const [showSearchCompaniesByName, setShowSearchCompaniesByName] = useState(
    false,
  );
  const [showFiltersForCompanies, setShowFiltersForCompanies] = useState(false);
  const [showMatchingContainer, setShowMatchingContainer] = useState(true);
  const [showFiltersDetails, setShowFiltersDetails] = useState(false);

  //radio inputs
  const [isSearchChecked, setIsSearchChecked] = useState(false);
  const [isFiltersChecked, setIsIsFiltersChecked] = useState(false);

  //fix for active class on the pagination
  const [rerenderComponent, setRerenderComponent] = useState(0);

  const [selectedOption, setSelectedOption] = useState(null);

  let isNextPageClicked: boolean;

  useEffect(() => {
    if (isFiltersActive) {
      dispatch(
        fetchFilteredCompaniesPagination(
          filters,
          filteredPagination.current.pageable.pageNumber,
          filteredPagination.current.pageable.pageSize,
          isNextPageClicked,
        ),
      );
      dispatch(fetchFilteredCompanies(filters));
      setRerenderComponent(Math.random());
    }
  }, [isFiltersActive, filters]);

  useEffect(() => {
    if (showSearchCompaniesByName) {
      dispatch(
        fetchAllCompanies(
          pagination.current.pageable.pageNumber,
          pagination.current.pageable.pageSize,
        ),
      );
      dispatch(fetchAllCompaniesNoPagination());
    }
  }, [showSearchCompaniesByName]);

  useEffect(() => {
    if (showFiltersForCompanies) {
      onGetFilterType(showFiltersForCompanies);
    }
  }, [showFiltersForCompanies]);

  useEffect(() => {
    if (isFiltersActive && filteredCompaniesWithPagination) {
      if (filteredCompaniesWithPagination.content) {
        filteredPagination.current = filteredCompaniesWithPagination;
      }

      if (filteredPagination.current.totalPages > 1) {
        setShowPagination(true);
      } else {
        setShowPagination(false);
      }
    }
  }, [filteredCompaniesWithPagination as Pagination, loadingCompanies]);

  useEffect(() => {
    if (data) {
      if (data.content) {
        pagination.current = data;
      }
    }
  }, [data as Pagination, loading]);

  const filtersConfigs = {
    industryConfig: new SelectInputConfig({
      options: staticData?.industries?.slice(0, -1).map((item) => {
        return { value: item.id, label: item.name };
      }),
      modelValue: "industryId",
    }),
    expertiseConfig: new SelectInputConfig({
      options: staticData?.expertise?.slice(0, -1).map((item) => {
        return { value: item.id, label: item.name };
      }),
      modelValue: "expertiseId",
    }),
    regionConfig: new SelectInputConfig({
      options: staticData?.regions?.map((item) => {
        return { value: item.id, label: item.name };
      }),
      modelValue: "regionId",
    }),
    supportTypesConfig: new SelectInputConfig({
      options: [
        {
          id: 1,
          name: "Mentoring that will help you grow and improve your business",
        },
        {
          id: 2,
          name: "Support in entering foreign markets/growing internationally",
        },
      ].map((item) => {
        return { value: item.id, label: item.name };
      }),
      modelValue: "supportTypeId",
    }),
  };

  const handleFilterSelection = (event: SelectInputEvent) => {
    const newFilters = { ...filters };
    newFilters[event.modelValue] = event.selectedOption.value;
    setFilters({ ...newFilters });
    setShowFiltersDetails(true);
  };

  const handleCompanySelect = (event: SelectInputEvent) => {
    if ((event.selectedOption.value as number) >= 1) {
      setSelectedOption(event.selectedOption);
      dispatch(getCompany(event.selectedOption.value as number));
      dispatch(
        fetchMatchingExperts(
          event.selectedOption ? (event.selectedOption.value as number) : 0,
          pagination.current.pageNumber || 0,
          pagination.current.pageable.pageSize,
        ),
      );
      setShowMatchingContainer(false);
      setShowFiltersDetails(false);
    } else {
      dispatch(cleanupData());
    }
  };

  const handleCompanySelectFromTable = (companyId: number) => () => {
    if (companyId) {
      const companyName =
        allCompaniesNoPagination.find((el) => el.id === companyId)?.name ||
        "Invalid company";

      setSelectedOption({ value: companyId, label: companyName });
      dispatch(getCompany(companyId));
      dispatch(
        fetchMatchingExperts(
          companyId,
          pagination.current.pageNumber || 0,
          pagination.current.pageable.pageSize,
        ),
      );
      setShowMatchingContainer(false);
      setShowFiltersDetails(false);
    } else {
      dispatch(cleanupData());
    }
  };

  useEffect(() => {
    if ((!loading && error) || (!loadingCompanies && companiesError)) {
      notifyError(
        store,
        t("matching_loading_companies"),
        t("matching_loading_companies_error"),
      );
    } else if ((!loading && !error) || (!loadingCompanies && !companiesError)) {
      dispatch(cleanupData());
    }
  }, [loading, loadingCompanies, error, companiesError]);

  const handleExpertClick = (expert: IExpert) => {
    const mapExpertData = mapExpertToSidebarView(expert, t);
    setExpertSidebarData(mapExpertData);
    setShowExpertSidebar(true);
  };

  /**
   * SIDEBAR
   */
  const handleSidebarCloseClick = () => {
    setShowExpertSidebar(false);
  };

  /**
   * EXPERTS
   */
  const onPaginationClick = (pageNumber: number, pageSize: number) => {
    dispatch(
      fetchMatchingExperts(
        selectedOption.value as number,
        pageNumber,
        pageSize,
      ),
    );
  };

  const handleChangeSearchByName = () => {
    setIsSearchChecked(true);
    setIsIsFiltersChecked(false);

    setShowSearchCompaniesByName(true);
    setShowFiltersForCompanies(false);

    //reset filters
    setFilters({
      industryId: 0,
      expertiseId: 0,
      regionId: 0,
      supportTypeId: 0,
    });
    //reset select inputs
    filtersConfigs.industryConfig.selectedOption = undefined;
    filtersConfigs.expertiseConfig.selectedOption = undefined;
    filtersConfigs.regionConfig.selectedOption = undefined;
    filtersConfigs.supportTypesConfig.selectedOption = undefined;
  };

  const handleChangeFilters = () => {
    setIsIsFiltersChecked(true);
    setIsSearchChecked(false);

    setShowFiltersForCompanies(true);
    setShowSearchCompaniesByName(false);
  };

  const handleBackOptions = () => {
    setShowMatchingContainer(true);
    setShowFiltersDetails(true);

    setSelectedOption(null);
  };

  if (filters.industryId > 0) {
    filtersConfigs.industryConfig.selectedOption = {
      value: filters.industryId,
      label: filtersConfigs.industryConfig.options.filter(
        (el) => el.value === filters.industryId,
      )[0].label,
    };
  }

  if (filters.expertiseId > 0) {
    filtersConfigs.expertiseConfig.selectedOption = {
      value: filters.expertiseId,
      label: filtersConfigs.expertiseConfig.options.filter(
        (el) => el.value === filters.expertiseId,
      )[0].label,
    };
  }

  if (filters.regionId > 0) {
    filtersConfigs.regionConfig.selectedOption = {
      value: filters.regionId,
      label: filtersConfigs.regionConfig.options.filter(
        (el) => el.value === filters.regionId,
      )[0].label,
    };
  }

  if (filters.supportTypeId > 0) {
    filtersConfigs.supportTypesConfig.selectedOption = {
      value: filters.supportTypeId,
      label: filtersConfigs.supportTypesConfig.options.filter(
        (el) => el.value === filters.supportTypeId,
      )[0].label,
    };
  }

  const handlePageChangeOnClick = ({ pageNumber, pageSize }) => {
    dispatch(
      fetchAllCompanies(pageNumber, pagination.current.pageable.pageSize),
    );
  };

  const handlePageChangeFilteredOnClick = ({ pageNumber, pageSize }) => {
    isNextPageClicked = true;
    dispatch(
      fetchFilteredCompaniesPagination(
        filters,
        pageNumber,
        filteredPagination.current.pageable.pageSize,
        isNextPageClicked,
      ),
    );
  };

  // Matching export
  const handleMatchingExport = () => dispatch(exportMatchingDocument());

  return (
    <>
      {showMatchingContainer && (
        <div className="admin-matching-container">
          <div className="title-btn-wrapper">
            <h2>{t("title_filters")}</h2>
            <button className="export-button" onClick={handleMatchingExport}>
              {t("admin-export")}
            </button>
          </div>
          <hr style={{ border: "1px solid #a1a1a122" }} />
          <p className="company-details-desc">
            {t("admin-matching:title_see_company_details")}
          </p>
          <div className="radio-inputs-wrapper">
            <input
              type="radio"
              id="searchByName"
              checked={isSearchChecked}
              onChange={handleChangeSearchByName}
            />
            <label htmlFor="searchByName" className="company-label">
              {t("admin-matching:search_company_by_name")}
            </label>
            <input
              type="radio"
              id="filters"
              checked={isFiltersChecked}
              onChange={handleChangeFilters}
            />
            <label htmlFor="filters">
              {t("admin-matching:filters_for_companies")}
            </label>
          </div>
          {showFiltersForCompanies && (
            <>
              <br></br>
              <h3>{t("admin-matching:title_filter_companies_by")}</h3>
              <div className="admin-matching-fields">
                <div className={"matching-field"}>
                  <div className={"matching-field-lbl"}>{t("industry")}</div>
                  <SelectInput
                    onSelect={handleFilterSelection}
                    inputConfig={filtersConfigs.industryConfig}
                  />
                </div>
                <div className={"matching-field"}>
                  <div className={"matching-field-lbl"}>{t("expertise")}</div>
                  <SelectInput
                    onSelect={handleFilterSelection}
                    inputConfig={filtersConfigs.expertiseConfig}
                  />
                </div>
                <div className={"matching-field"}>
                  <div className={"matching-field-lbl"}>{t("region")}</div>
                  <SelectInput
                    onSelect={handleFilterSelection}
                    inputConfig={filtersConfigs.regionConfig}
                  />
                </div>
                <div className={"matching-field"}>
                  <div className={"matching-field-lbl"}>
                    {t("support_type")}
                  </div>
                  <SelectInput
                    onSelect={handleFilterSelection}
                    inputConfig={filtersConfigs.supportTypesConfig}
                  />
                </div>
              </div>
            </>
          )}
          {showSearchCompaniesByName && (
            <>
              <div className={"matching-field-custom"}>
                <h3>{t("admin-matching:search_company_by_name")}</h3>
                <SelectInput
                  onSelect={handleCompanySelect}
                  inputConfig={
                    new SelectInputConfig({
                      options: allCompaniesNoPagination?.map((item) => {
                        return { value: item.id, label: item.name };
                      }),
                      modelValue: "companyId",
                    })
                  }
                />
              </div>
              <div className="admin-table-container custom">
                <h3 className="custom">
                  {t("admin-matching:search_companies_click")}
                </h3>
                <table className="admin-table">
                  <thead>
                    <tr className="admin-head admin-row admin-column-width">
                      <th>{t("table_name_company")}</th>
                      <th>{t("table_industry")}</th>
                      <th>{t("table_support_needed")}</th>
                      <th>{t("table_region_expand")}</th>
                      <th>{t("table_region_export")}</th>
                      <th className="custom-center">{t("admin-created")}</th>
                      <th className="custom-center">
                        {t("admin-last-modified")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.content?.map((company) => {
                      return (
                        <tr
                          className="admin-row admin-column-width open-details"
                          key={company.id + generateUniqId()}
                          onClick={handleCompanySelectFromTable(company.id)}
                        >
                          <td>{company.name}</td>
                          <td>
                            {company.industries && company.industries.length > 1
                              ? company.industries[0]?.name + "..."
                              : company.industries[0]?.name}
                          </td>

                          <td>
                            {company.supportTypes &&
                            company.supportTypes.length > 1
                              ? company.supportTypes[0]?.name ===
                                "Mentoring businesses to improve and grow"
                                ? "Mentoring that will help you grow and improve your business" +
                                  "..."
                                : "Support in entering foreign markets/growing internationally" +
                                  "..."
                              : company.supportTypes[0]?.name ===
                                "Mentoring businesses to improve and grow"
                              ? "Mentoring that will help you grow and improve your business"
                              : "Support in entering foreign markets/growing internationally"}
                          </td>
                          <td>
                            {company.regions && company.regions.length > 1
                              ? company.regions[0]?.name + "..."
                              : company.regions[0]?.name}
                          </td>
                          <td>
                            {company.exportRegions?.length > 1
                              ? company.exportRegions[0]?.name + "..."
                              : company.exportRegions[0]?.name}
                          </td>
                          <td className="custom-center">
                            {dateToProperFormat(company.dateCreated)}
                          </td>
                          <td className="custom-center">
                            {company.lastModified
                              ? dateToProperFormat(company.lastModified)
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComponent
                  totalPages={pagination.current.totalPages}
                  changePage={handlePageChangeOnClick}
                />
              </div>
            </>
          )}
          {isFiltersActive &&
            filteredCompaniesWithPagination.content?.length === 0 && (
              <div className={"alert error"}>
                {t("matching-companies-no-results")}
              </div>
            )}
          {!loadingCompanies &&
            showFiltersDetails &&
            isFiltersActive &&
            filteredCompaniesWithPagination.content?.length > 0 && (
              <div className="matching-field-custom">
                <h3>{t("admin-matching:title_search_from_the_filtered")}</h3>
                <SelectInput
                  onSelect={handleCompanySelect}
                  inputConfig={
                    new SelectInputConfig({
                      options: companies["content"]?.map((item) => {
                        return { value: item.id, label: item.name };
                      }),
                      modelValue: "companyId",
                    })
                  }
                />
              </div>
            )}
          {isFiltersActive &&
            showFiltersDetails &&
            filteredCompaniesWithPagination.content?.length > 0 && (
              <div className="admin-table-container">
                <>
                  <h3 className="custom">
                    {t("admin-matching:filtered_companies_click")}
                  </h3>
                  <table className="admin-table">
                    <thead>
                      <tr className="admin-head admin-row admin-column-width">
                        <th>{t("table_name_company")}</th>
                        <th>{t("table_industry")}</th>
                        <th>{t("table_support_needed")}</th>
                        <th>{t("table_region_expand")}</th>
                        <th>{t("table_region_export")}</th>
                        <th className="custom-center">{t("admin-created")}</th>
                        <th className="custom-center">
                          {t("admin-last-modified")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCompaniesWithPagination.content?.map(
                        (company) => {
                          return (
                            <tr
                              className="admin-row admin-column-width open-details"
                              key={company.id + generateUniqId()}
                              onClick={handleCompanySelectFromTable(company.id)}
                            >
                              <td>{company.name}</td>
                              <td>
                                {company.industries &&
                                company.industries.length > 1
                                  ? company.industries[0]?.name + "..."
                                  : company.industries[0]?.name}
                              </td>

                              <td>
                                {company.supportTypes &&
                                company.supportTypes.length > 1
                                  ? company.supportTypes[0]?.name ===
                                    "Mentoring businesses to improve and grow"
                                    ? "Mentoring that will help you grow and improve your business" +
                                      "..."
                                    : "Support in entering foreign markets/growing internationally" +
                                      "..."
                                  : company.supportTypes[0]?.name ===
                                    "Mentoring businesses to improve and grow"
                                  ? "Mentoring that will help you grow and improve your business"
                                  : "Support in entering foreign markets/growing internationally"}
                              </td>
                              <td>
                                {company.regions && company.regions.length > 1
                                  ? company.regions[0]?.name + "..."
                                  : company.regions[0]?.name}
                              </td>
                              <td>
                                {company.exportRegions?.length > 1
                                  ? company.exportRegions[0]?.name + "..."
                                  : company.exportRegions[0]?.name}
                              </td>
                              <td className="custom-center">
                                {dateToProperFormat(company.dateCreated)}
                              </td>
                              <td className="custom-center">
                                {company.lastModified
                                  ? dateToProperFormat(company.lastModified)
                                  : "N/A"}
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                  {showPagination && (
                    <div key={rerenderComponent}>
                      <PaginationComponent
                        totalPages={filteredPagination.current.totalPages}
                        changePage={handlePageChangeFilteredOnClick}
                      />
                    </div>
                  )}
                </>
              </div>
            )}
        </div>
      )}
      {(loadingCompany || company || companyError) && !showMatchingContainer && (
        <>
          <span onClick={handleBackOptions} className="go-back-wrapper">
            <img src={back} alt="Go back" />
            <span>{t("admin-matching:go_back")}</span>
          </span>
          <div className="admin-matching-container">
            <h2>{t("title_company")}</h2>
            <hr style={{ border: "1px solid #a1a1a122" }} />
            <MatchingCompanyDetails />
          </div>
        </>
      )}
      {(loadingExperts || company) && !showMatchingContainer && (
        <div className="admin-matching-container">
          <h2>{t("title_experts")}</h2>
          <hr style={{ border: "1px solid #a1a1a122" }} />
          <MatchingTable
            onPaginationBtnClick={onPaginationClick}
            onExpertClick={handleExpertClick}
            getCurrentExpertId={setMatchingExpertId}
          />
        </div>
      )}
      <Sidebar
        sidebarData={expertSidebarData}
        showMenu={showExpertSidebar}
        onCloseSidebar={handleSidebarCloseClick}
        translation={translation}
        hasDocument={
          expertSidebarData &&
          expertSidebarData.tabs[2].content[0].isFile &&
          expertSidebarData.tabs[2].content[0].url !== null
        }
        profileType="expert"
        currentId={matchingExpertId}
      />
    </>
  );
};
