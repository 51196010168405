import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface ITabNavigationProps {
  /**
   * Currently active tab
   */
  active: string;
  onChange: (tab: string) => void;
  /**
   * All tabs for rendering
   */
  tabs: string[];

  /**
   * Set multiple selected tabs
   */
  selectedTabs?: string[];

  /**
   * List of tabs tha user can not click
   */
  nonClickableTabs?: string[];
}

export default function TabsNavigation(props: ITabNavigationProps) {
  const { t } = useTranslation("expert");
  const { active, onChange, tabs, selectedTabs, nonClickableTabs } = props;

  useEffect(() => {}, [selectedTabs]);

  const handleClick = tabName => {
    if (nonClickableTabs && nonClickableTabs.includes(tabName)) {
      return;
    }
    onChange(tabName);
  };

  return (
    <div className="nav-button-container">
      {tabs.map(tabName => {
        return (
          <button
            key={tabName}
            id={"tab-" + tabName}
            onClick={() => handleClick(tabName)}
            className={`profile-button ${
              selectedTabs && selectedTabs.includes(tabName)
                ? "selected-tab"
                : ""
            } 
						${active === tabName ? "active-tab" : ""}
						`}
          >
            {t("tab-" + tabName)}
          </button>
        );
      })}
    </div>
  );
}
