import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";
import { Provider } from "react-redux";
import configureStore, { reduxHistory } from "./utilities/store";
import PublicView from "./views/public-view";
import { Switch, Route } from "react-router-dom";
import AdminView from "./views/admin-view";
import CompanyView from "./views/company-view";
import ExpertView from "./views/expert-view";
import { ConnectedRouter } from "connected-react-router";
import setupAxiosInterceptors from "./utilities/axios-interceptors";
import LoadingBar from "react-redux-loading-bar";
import "./utilities/translation";
import { ThemeProvider } from "@material-ui/core/styles";
import { AppTheme } from "./theme/theme";

const store = configureStore();
setupAxiosInterceptors(reduxHistory);

// TODO: Remove loading bar sample options before going live
ReactDOM.render(
  <Provider store={store}>
    <LoadingBar
      className="loading-bar"
      showFastActions
      updateTime={100}
      maxProgress={95}
      progressIncrease={10}
    />
    <ReactNotification />
    <ThemeProvider theme={AppTheme}>
      <ConnectedRouter history={reduxHistory}>
        <Switch>
          <Route path="/admin" component={AdminView} />
          <Route path="/company" component={CompanyView} />
          <Route path="/expert" component={ExpertView} />
          <Route exact path="/" component={PublicView} />
          {/* default path */}
          <Route component={PublicView} />
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
