import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./error-page.scss";

export const ErrorPage = () => {
  const { t } = useTranslation("error");
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/home");
    }, 4000);
  }, []);

  return (
    <div className="error-page-main-wrapper">
      <span className="back-to-home" onClick={() => history.push("/home")}>
        {t("back-to-home")}
      </span>
      <div className="error-page-container">
        <div className="error-page-text">
          <span className="error-page-description">{t("error-code")}</span>
          <span className="error-page-title">{t("error-page-title")}</span>{" "}
        </div>
      </div>
    </div>
  );
};
