import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCompanyStaticData } from "../../components/companyProfile/company-profile-action";

import "./admin-layout.scss";
import {
  CompanyTableComponent,
  ExpertsTableComponent,
} from "../../components/adminTables";
import { AdminMatchingComponent } from "../../components/adminMatching/adminMatchingComponent";

interface IRouteParams {
  slug?: string;
}

export const AdminLayout = () => {
  let params: IRouteParams = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [multiFilters, setMultiFilters] = useState(false);

  const getFiltersType = (value: boolean | null) => setMultiFilters(value);

  useEffect(() => {
    multiFilters && dispatch(getCompanyStaticData());
  }, [multiFilters]);

  const getComponentBySlug = (slug) => {
    switch (slug) {
      case "company":
        return <CompanyTableComponent />;
      case "experts":
        return <ExpertsTableComponent />;
      case "matching":
        return <AdminMatchingComponent onGetFilterType={getFiltersType} />;
      default:
        history.push("/admin/company");
        break;
    }
  };

  return <div className="admin-layout">{getComponentBySlug(params.slug)}</div>;
};
