import { cognitoAuth } from "../../utilities/auth";

export const loginFormAction = (user) => {
  return {
    type: "LOGIN_SUBMIT",
    payload: cognitoAuth.signIn({
      username: user.get("email"),
      password: user.get("password"),
    }),
  };
};

export const deleteError = () => {
  return {
    type: "LOGIN_DELETE_ERROR",
  };
};
