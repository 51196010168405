import React, { useState, useEffect } from "react";
import { CompanyFormWorkMode } from "../../../models/company/company-form-work-mode";
import {
  TabNavigationEnum,
  profileTabNavigation,
} from "../../../models/company/profile-tab-navigation-enum";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import Spinner from "../../common/spinner/spinner";

interface ITabsStepperNavigation {
  activeTab: string;
  onNext: (tabName: string) => void;
  onPrevious: (tabName: string) => void;
  formWorkMode: CompanyFormWorkMode;
  onFinish: (tabName: string) => void;
}

/**
 * @description
 * @param props
 */
export default function TabsStepperNavigation(props: ITabsStepperNavigation) {
  const { activeTab, onNext, onPrevious, onFinish, formWorkMode } = props;
  const { newLoading } = useSelector((state: IRootState) => state.company);
  /**
   * GENERAL
   */
  const { t } = useTranslation("profile");
  useEffect(() => {}, [formWorkMode]);

  const handleNextClick = () => {
    if (typeof onNext === "function") {
      if (
        activeTab === profileTabNavigation[TabNavigationEnum.AdditionalInfo]
      ) {
        onFinish(profileTabNavigation[TabNavigationEnum.AdditionalInfo]);
      } else {
        const index = profileTabNavigation.indexOf(activeTab);
        if (index !== -1) {
          const nextTabIndex = index + 1;
          onNext(profileTabNavigation[nextTabIndex]);
        }
      }
    }
  };

  const handlePreviousClick = () => {
    if (typeof onPrevious === "function") {
      const index = profileTabNavigation.indexOf(activeTab);
      if (index !== -1) {
        const prevTabIndex = index - 1;
        onPrevious(profileTabNavigation[prevTabIndex]);
      }
    }
  };

  return (
    <>
      {formWorkMode === CompanyFormWorkMode.New && (
        <div className={"tabs-steper control-button-container"}>
          {activeTab !== profileTabNavigation[TabNavigationEnum.General] && (
            <button className={"cancel"} onClick={() => handlePreviousClick()}>
              {t("profile_tab_prev")}
            </button>
          )}
          <button disabled={newLoading} onClick={() => handleNextClick()}>
            {newLoading && <Spinner />}
            {activeTab ===
            profileTabNavigation[TabNavigationEnum.AdditionalInfo]
              ? t("profile_tab_finish")
              : t("profile_tab_next")}
          </button>
        </div>
      )}
    </>
  );
}
