import { Actions } from "../../utilities/action-payload";
import { ILoginForm } from "./login-form-entity";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";
import { LOGIN_SUBMIT, LOGIN_DELETE_ERROR } from "./login-form-types";

const initialState: ILoginForm = {
  email: "",
  password: "",
  errorMessage: "",
  loginSuccess: null,
  loading: false,
  status: null,
};

export type LoginFormState = Readonly<typeof initialState>;

export default (
  state: LoginFormState = initialState,
  action: Actions,
): LoginFormState => {
  switch (action.type) {
    case REQUEST(LOGIN_SUBMIT):
      return {
        ...state,
        errorMessage: null,
        loginSuccess: false,
        loading: true,
      };
    case FAILURE(LOGIN_SUBMIT):
      return {
        ...state,
        errorMessage: action.payload,
        loginSuccess: false,
        loading: false,
      };
    case SUCCESS(LOGIN_SUBMIT):
      return {
        ...state,
        status: "Logged in" + action.payload,
        loginSuccess: true,
        loading: false,
      };
    case LOGIN_DELETE_ERROR:
      return {
        ...state,
        loginSuccess: false,
        loading: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};
