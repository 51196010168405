import React, { useState, ChangeEvent, useEffect } from "react";
import {
  YesNoRadioButtonConfig,
  YesNoRadioButtonEvent,
} from "./definitions/InputConfigurationSpec";

export default function YesNoRadioButton(props: {
  inputConfig: YesNoRadioButtonConfig;
  onSelect: (event: YesNoRadioButtonEvent) => void;
  errorMsg: string;
}) {
  const { inputConfig, onSelect, errorMsg } = props;
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMsg);
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(inputConfig.isDisabled || false);

  inputConfig.setDisabled = (disabled) => {
    setIsDisabled(disabled);
    inputConfig.isDisabled = disabled;
    if (hasError) {
      setHasError(false);
    }
  };

  useEffect(() => {
    setIsChecked(inputConfig.initAnswer);
  }, [inputConfig.initAnswer]);

  const handleRadioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDisabled) {
      const selected = !isChecked;
      if (typeof onSelect === "function") {
        onSelect(
          new YesNoRadioButtonEvent({
            answer: selected,
            modelValue: inputConfig.modelValue,
          }),
        );
      }
      setIsChecked(selected);
    }
  };

  inputConfig.validate = () => {
    // do not check for validation if input is disabled
    if (inputConfig.isDisabled) {
      return true;
    }
    if (inputConfig.isRequired) {
      if (typeof inputConfig.initAnswer === undefined) {
        setHasError(true);
        setError(error);
        return false;
      }
      return true;
    }

    return true;
  };

  const handleAreaClick = (e: React.MouseEvent<HTMLDivElement>, answer) => {
    e.stopPropagation();
    setHasError(false);
    if (!isDisabled) {
      if (answer !== isChecked) {
        if (typeof onSelect === "function") {
          onSelect(
            new YesNoRadioButtonEvent({
              name: inputConfig.name,
              answer,
              modelValue: inputConfig.modelValue,
            }),
          );
        }
        setIsChecked(answer);
      }
    }
  };

  return (
    <>
      <div
        className="yes-no-radio-button"
        onClick={(e) => handleAreaClick(e, true)}
      >
        <div className={"answer-selection " + (isChecked ? "selected" : "")}>
          <label className={"radio"}>
            <input
              type="radio"
              name={inputConfig.modelValue + "-yes-no-btn"}
              checked={isChecked}
              className={"radio-hidden"}
              onChange={handleRadioButtonChange}
            />
            <span className="label"></span>
            {"Yes"}
          </label>
        </div>
        <div
          className={"answer-selection " + (!isChecked ? "selected" : "")}
          onClick={(e) => handleAreaClick(e, false)}
        >
          <label className={"radio"}>
            <input
              type="radio"
              name={inputConfig.modelValue + "-yes-no-btn"}
              checked={!isChecked}
              className={"radio-hidden"}
              onChange={handleRadioButtonChange}
            />
            <span className="label"></span>
            {"No"}
          </label>
        </div>
      </div>
      {hasError && <div className={"alert error"}>{error}</div>}
    </>
  );
}
