import React, { useEffect } from "react";
import "./auth-layout.scss";
import { connect, useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import ExpertProfile from "../../components/expertProfile/expert-profile";
import { useTranslation } from "react-i18next";
import PageLoading from "../../components/pageLoading/page-loading";
import {
  getExpert,
  getExpertStaticData,
} from "../../components/expertProfile/expert-profile-action";

interface IExpertProfileLayoutProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

export const ExpertProfileLayout = () => {
  const { t } = useTranslation("expert");
  const dispatch = useDispatch();
  const { getLoading } = useSelector((state: IRootState) => state.expert);
  useEffect(() => {
    dispatch(getExpert());
    dispatch(getExpertStaticData());
  }, []);
  return (
    <div className="expert-profile-layout" style={{ height: 500 }}>
      {getLoading ? (
        <PageLoading type="bars" loadingText={t("expert-loading-profile")} />
      ) : (
        <ExpertProfile />
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ExpertProfileLayout);
