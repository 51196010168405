import React, { useEffect } from "react";
import "./auth-layout.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import CompanyProfile from "../../components/companyProfile/company-profile";
import {
  getCompany,
  getCompanyStaticData,
} from "../../components/companyProfile/company-profile-action";
import PageLoading from "../../components/pageLoading/page-loading";
import { useTranslation } from "react-i18next";

interface ICompanyProfileLayoutProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

export const CompanyProfileLayout = (props: ICompanyProfileLayoutProps) => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const { getLoading } = useSelector((state: IRootState) => state.company);
  useEffect(() => {
    dispatch(getCompany());
    dispatch(getCompanyStaticData());
  }, []);
  return (
    <div className="company-profile-layout" style={{ height: 500 }}>
      {getLoading ? (
        <PageLoading type="bars" loadingText={t("company-loading-profile")} />
      ) : (
        <CompanyProfile />
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CompanyProfileLayout);
