import { AuthLayout } from "./layouts/auth-layout";
import { HomePage } from "../components/homePage/homePage";

export const PublicRoutes = [
  {
    component: AuthLayout,
    path: "forgot-password",
  },

  {
    component: AuthLayout,
    path: "reset-password",
  },

  {
    component: AuthLayout,
    path: "confirm",
  },
  {
    component: AuthLayout,
    path: "signup",
  },
  {
    component: AuthLayout,
    path: "login",
  },
  {
    component: HomePage,
    path: "home",
  },
];
