import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./sidebarComponent.scss";
import DisplayFields from "./displayFields/displayFields";
import { ISidebar } from "../../models/general/sidebar";
import { generateUniqId } from "../../utils/helpers/general-helper";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import { TabNavigationEnum } from "../../models/company/profile-tab-navigation-enum";
import { ExpertTabNavigationEnum } from "../../models/expert/expert-tab-navigation-enum";
import { Expert } from "../../models/expert/expert";

interface ISidebarProps {
  showMenu: boolean;
  sidebarData: ISidebar;
  onCloseSidebar(): void;
  translation: any;
  hasDocument: boolean;
  profileType?: string;
  currentId: number;
}

const Sidebar = (props: ISidebarProps) => {
  const {
    showMenu,
    onCloseSidebar,
    sidebarData,
    translation,
    hasDocument,
    profileType,
    currentId,
  } = props;
  const { t } = translation;
  const boxId = `sidebar${showMenu ? " active" : ""}`;
  const sidebarRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!showMenu) {
      setActiveTab(0);
    }
  }, [showMenu]);

  const handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(sidebarRef.current);
    if (!domNode || !domNode.contains(event.target)) {
      onCloseSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {sidebarData && (
        <div className={boxId} ref={sidebarRef}>
          <div className="items">
            <div className="item">
              <h1>{sidebarData.title}</h1>
              <h3>{sidebarData.subtitle}</h3>
            </div>
            <CloseIcon className="x-icon" onClick={() => onCloseSidebar()} />
            <div className="item tabs">
              {sidebarData.tabs.map((tab) => {
                return (
                  <>
                    <button
                      key={generateUniqId()}
                      className={`tab${
                        activeTab === tab.id ? " active-tab" : ""
                      }`}
                      onClick={() => setActiveTab(tab.id)}
                    >
                      {t(tab.tlabel)}
                    </button>
                  </>
                );
              })}
            </div>
            <DisplayFields
              fields={sidebarData.tabs[activeTab].content}
              profileType={profileType}
              currentId={currentId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
