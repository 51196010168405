import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@material-ui/core";
import Illustration from "../../../../assets/images/illustration-2.svg";
import Icon from "../../../../assets/images/icon.svg";
import Icon1 from "../../../../assets/images/icon-1.svg";
import Icon2 from "../../../../assets/images/icon-2.svg";
import Icon3 from "../../../../assets/images/icon-3.svg";

import "./howDoesItWork.scss";

const data = [
  {
    id: 1,
    title: "createAcountTitle",
    desc: "createAcountDesc",
    icon: Icon,
  },
  {
    id: 2,
    title: "getMatchedTitle",
    desc: "getMatchedDesc",
    icon: Icon1,
  },
  {
    id: 3,
    title: "approveMatchTitle",
    desc: "approveMatchDesc",
    icon: Icon2,
  },
  {
    id: 4,
    title: "workTogetherTitle",
    desc: "workTogetherDesc",
    icon: Icon3,
  },
];

export const HowDoesItWork: React.FC = () => {
  const { t } = useTranslation("homepage");

  return (
    <>
      <Box className="how-it-works-wrapper" style={{ position: "relative" }}>
        <Typography className="how-it-works-heading" variant="h2">
          How it works
        </Typography>
        <Paper elevation={1} className="how-it-works-cards-wrapper">
          <div className="line"></div>
          {data.map(({ id, title, desc, icon }) => (
            <Paper key={id} className="card-wrapper">
              <img src={icon} alt="Icon" />
              <Typography className="card-heading" variant="h3">
                {t(title)}
              </Typography>
              <Typography className="card-paragraph" variant="body2">
                {t(desc)}
              </Typography>
            </Paper>
          ))}
        </Paper>
      </Box>
      <Box className="primary-color-bg">
        <img className="illustration" src={Illustration} alt="SVG Image" />
      </Box>
    </>
  );
};
