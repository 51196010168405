import axios from "axios";
import {
  FETCH_ALL_COMPANIES,
  FETCH_ALL_COMPANIES_NO_PAGINATION,
  COMPANY_CLEANUP,
  COMPANY_DELETE,
  COMPANY_DELETE_CLEANUP,
  COMPANY_SEARCH,
  COMPANY_GET,
  COMPANY_EXPORT,
  COMPANY_GET_CLEANUP,
  COMPANY_GET_AVALIABLE_EXPERTS,
  COMPANY_GET_AVALIABLE_EXPERTS_CLEANUP,
  COMPANY_MATCH_EXPERT,
  COMPANY_MATCH_EXPERT_CLEANUP,
  COMPANY_UNMATCH_EXPERT,
  COMPANY_UNMATCH_EXPERT_CLEANUP,
} from "./companyTableTypes";
import { downloadDocument } from "../../../utilities/download-doc";

export const fetchAllCompanies = (pageNumber, pageSize) => {
  return {
    type: FETCH_ALL_COMPANIES,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/company?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    ),
  };
};

export const fetchAllCompaniesNoPagination = () => {
  return {
    type: FETCH_ALL_COMPANIES_NO_PAGINATION,
    payload: axios.get(`${process.env.REACT_APP_API_BASE_URL}/company/all`),
  };
};

export const deleteCompany = (cognitoId: string) => {
  return {
    type: COMPANY_DELETE,
    payload: axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/company/delete/${cognitoId}`,
    ),
  };
};

export const deleteCompanyCleanup = () => {
  return {
    type: COMPANY_DELETE_CLEANUP,
  };
};

export const fetchAvailableExperts = (id: number) => {
  return {
    type: COMPANY_GET_AVALIABLE_EXPERTS,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/availableExperts?companyId=${id}`,
    ),
  };
};

export const fetchAvailableExpertsCleanup = () => {
  return {
    type: COMPANY_GET_AVALIABLE_EXPERTS_CLEANUP,
  };
};

export const searchCompany = (name: string) => {
  return {
    type: COMPANY_SEARCH,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/company/name/${name}`,
    ),
  };
};

export const getCompany = (id: number) => {
  return {
    type: COMPANY_GET,
    payload: axios.get(`${process.env.REACT_APP_API_BASE_URL}/company/${id}`),
  };
};

export const companyExport = () => {
  return {
    type: COMPANY_EXPORT,
    payload: axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/company/export`)
      .then((response) => downloadDocument(response, "companies.csv")),
  };
};

export const matchExpertAndStartDate = (
  matchedExpertId: Number,
  companyId: Number,
  startDate: String,
) => {
  const matchingExpertAndStartDate = {
    expert: {
      id: matchedExpertId,
    },
    company: {
      id: companyId,
    },
    collaborationStartDate: startDate,
  };
  return {
    type: COMPANY_MATCH_EXPERT,
    payload: axios.post(`${process.env.REACT_APP_API_BASE_URL}/match`, {
      ...matchingExpertAndStartDate,
    }),
  };
};

export const matchExpertAndStartDateCleanup = () => {
  return {
    type: COMPANY_MATCH_EXPERT_CLEANUP,
  };
};

export const unmatchExpertAndTerminationDate = (
  expertId: Number,
  companyId: Number,
  terminationDate: String,
) => {
  const unmatchingExpertAndTerminationDate = {
    expert: {
      id: expertId,
    },
    company: {
      id: companyId,
    },
    collaborationEndDate: terminationDate,
  };

  return {
    type: COMPANY_UNMATCH_EXPERT,
    payload: axios.put(`${process.env.REACT_APP_API_BASE_URL}/unmatch`, {
      ...unmatchingExpertAndTerminationDate,
    }),
  };
};

export const unmatchExpertAndTerminationDateCleanup = () => {
  return {
    type: COMPANY_UNMATCH_EXPERT_CLEANUP,
  };
};

export const selectedCompanyCleanup = () => {
  return {
    type: COMPANY_GET_CLEANUP,
  };
};

export const companiesCleanup = () => {
  return {
    type: COMPANY_CLEANUP,
  };
};
