import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  MenuItem,
  Drawer,
} from "@material-ui/core";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Logo from "../../../../assets/images/logo.svg";
import "./homePageHeader.scss";

export const HomePageHeader: React.FC = () => {
  const { t } = useTranslation("homepage");

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar className="homepage-header">
      <Toolbar className="header-wrapper">
        <Link to="/">
          <img className="header-logo" src={Logo} alt="Connect2mk Logo" />
        </Link>

        <Box className="hamburger-menu-wrapper">
          <IconButton
            className="hamburger-menu"
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="hamburger-menu"
            aria-haspopup="true"
            onClick={handleDrawerToggle}
          >
            <MenuOutlinedIcon fontSize="large" />
          </IconButton>

          <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
            <Box className="drawer-header">
              <IconButton
                className="drawer-close-button"
                onClick={handleCloseDrawer}
              >
                <CloseOutlinedIcon fontSize="large" className="close-icon" />
              </IconButton>
            </Box>

            <Box className="drawer-body">
              <MenuItem
                className="menu-item"
                onClick={handleCloseDrawer}
                component={Link}
                to="/signup"
              >
                {t("register")}
              </MenuItem>
              <MenuItem
                className="menu-item"
                onClick={handleCloseDrawer}
                component={Link}
                to="/login"
              >
                {t("loginNow")}
              </MenuItem>
            </Box>
          </Drawer>
        </Box>

        <Box className="buttons-wrapper">
          <Button component={Link} to="/signup" variant="outlined">
            {t("register")}
          </Button>
          <Button component={Link} to="/login" variant="contained">
            {t("loginNow")}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
