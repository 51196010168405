import { Actions } from "../../utilities/action-payload";
import { ICompanyProfileState } from "./company-profile-entity";
import {
  COMPANY_GET_DATA,
  COMPANY_GET_STATIC_DATA,
  COMPANY_UPDATE,
  COMPANY_DELETE,
  COMPANY_CANCEL_CHANGES,
  COMPANY_DATA_CLEANUP,
  UPDATE_COMPANY_DATA,
  COMPANY_NEW,
  COMPANY_DOCUMENT_UPLOAD,
  COMPANY_DOCUMENT_DOWNLOAD,
  COMPANY_DOCUMENT_CLEANUP,
  COMPANY_ALL_DOCUMENTS_DOWNLOAD,
  COMPANY_DOCUMENT_DELETE,
  COMPANY_CHANGE_MODE,
  COMPANY_SAVE_CHANGES,
  COMPANY_DOCUMENT_UPLOAD_SAVE,
  COMPANY_FILE_UPLOAD,
} from "./company-profile-types";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";

const initialState: ICompanyProfileState = {
  data: null,
  staticData: null,
  getLoading: false,
  getError: false,
  newLoading: false,
  newStatus: false,
  newError: null,
  editMode: false,
  updateLoading: false,
  updateStatus: false,
  updateError: null,
  deleteLoading: false,
  deleteStatus: false,
  deleteError: null,
  cancelDataChanges: false,
  error: null,
  hasDocument: false,
  status: null,
  documentLoading: false,
  saveDataChanges: false,
  file: null,
  deleteDocumentError: null,
  documentUploadError: false,
  documents: null,
  // -----------------------------------
  // DOCUMENTS TAB
  // form: please attach a PPT used got company pitching

  // END DOCUMENTS TAB
  // -----------------------------------
};

export type CompanyProfileState = Readonly<typeof initialState>;

export default (
  state: CompanyProfileState = initialState,
  action: Actions,
): CompanyProfileState => {
  /**
   * GET DATA
   */
  switch (action.type) {
    case REQUEST(COMPANY_GET_DATA):
      return {
        ...state,
        getLoading: true,
        getError: null,
      };
    case SUCCESS(COMPANY_GET_DATA):
      return {
        ...state,
        data: action.payload.data,
        hasDocument: action.payload.data && action.payload.data.hasDocument,
        documents: action.payload.data.documents,
        getLoading: false,
        getError: null,
      };
    case FAILURE(COMPANY_GET_DATA):
      return {
        ...state,
        data: null,
        getError: action,
        getLoading: false,
      };

    // GET STATIC DATA
    case REQUEST(COMPANY_GET_STATIC_DATA):
      return {
        ...state,
        getError: null,
      };
    case SUCCESS(COMPANY_GET_STATIC_DATA):
      return {
        ...state,
        staticData: action.payload.data,
        getError: null,
      };
    case FAILURE(COMPANY_GET_STATIC_DATA):
      return {
        ...state,
        staticData: null,
        getError: action,
      };

    /**
     * NEW
     */
    case REQUEST(COMPANY_NEW):
      return {
        ...state,
        newLoading: true,
        newError: null,
        newStatus: false,
      };
    case SUCCESS(COMPANY_NEW):
      return {
        ...state,
        newLoading: false,
        newStatus: true,
        newError: null,
      };
    case FAILURE(COMPANY_NEW):
      return {
        ...state,
        newLoading: false,
        newStatus: false,
        newError: action.payload,
      };

    /**
     * UPDATE
     */
    case REQUEST(COMPANY_UPDATE):
      return {
        ...state,
        updateLoading: true,
        updateError: null,
        updateStatus: false,
      };
    case SUCCESS(COMPANY_UPDATE):
      return {
        ...state,
        updateLoading: false,
        updateError: null,
        updateStatus: true,
      };
    case FAILURE(COMPANY_UPDATE):
      return {
        ...state,
        updateLoading: false,
        updateError: action.payload,
        updateStatus: false,
      };

    /**
     * DELETE
     */
    case REQUEST(COMPANY_DELETE):
      return {
        ...state,
        deleteLoading: true,
        deleteStatus: false,
        error: null,
      };
    case SUCCESS(COMPANY_DELETE):
      return {
        ...state,
        data: null,
        deleteLoading: false,
        deleteStatus: true,
        deleteError: null,
      };
    case FAILURE(COMPANY_DELETE):
      return {
        ...state,
        deleteLoading: false,
        deleteStatus: false,
        deleteError: action,
      };
    case COMPANY_CANCEL_CHANGES:
      return {
        ...state,
        data: Object.assign({}, state.data),
        /**
         * Every time trigger data change to reflect the forms
         */
        cancelDataChanges: !state.cancelDataChanges,
      };
    case COMPANY_SAVE_CHANGES:
      return {
        ...state,
        data: Object.assign({}, state.data),
        /**
         * Every time trigger data change to reflect the forms
         */
        saveDataChanges: !state.saveDataChanges,
      };
    case UPDATE_COMPANY_DATA:
      return {
        ...state,
        data: { ...action.payload },
        updateStatus: false,
      };
    case COMPANY_DATA_CLEANUP:
      return { ...initialState };

    case REQUEST(COMPANY_ALL_DOCUMENTS_DOWNLOAD):
      return {
        ...state,
        documentLoading: true,
      };

    case SUCCESS(COMPANY_ALL_DOCUMENTS_DOWNLOAD):
      return {
        ...state,
        hasDocument: true,
        status: action.payload,
        documentLoading: false,
      };

    case FAILURE(COMPANY_ALL_DOCUMENTS_DOWNLOAD):
      return {
        ...state,
        status: action.payload,
        documentLoading: false,
      };

    case REQUEST(COMPANY_DOCUMENT_DOWNLOAD):
      return {
        ...state,
      };

    case SUCCESS(COMPANY_DOCUMENT_DOWNLOAD):
      return {
        ...state,
        hasDocument: true,
        status: action.payload,
      };

    case FAILURE(COMPANY_DOCUMENT_DOWNLOAD):
      return {
        ...state,
        status: action.payload,
      };

    case REQUEST(COMPANY_DOCUMENT_DELETE):
      return {
        ...state,
        documentLoading: true,
      };

    case SUCCESS(COMPANY_DOCUMENT_DELETE):
      return {
        ...state,
        hasDocument: action.payload.data.length > 0 ? true : false,
        documents: action.payload.data,
        status: action.payload,
        documentLoading: false,
        newStatus: false,
        deleteDocumentError: false,
      };

    case FAILURE(COMPANY_DOCUMENT_DELETE):
      return {
        ...state,
        status: action.payload,
        deleteError: true,
        deleteDocumentError: true,
        documentLoading: false,
      };

    case REQUEST(COMPANY_DOCUMENT_UPLOAD):
      return {
        ...state,
        documentLoading: true,
      };

    case SUCCESS(COMPANY_DOCUMENT_UPLOAD):
      return {
        ...state,
        hasDocument: true,
        documents: action.payload.data,
        status: action.payload,
        documentLoading: false,
        editMode: false,
        file: null,
      };

    case FAILURE(COMPANY_DOCUMENT_UPLOAD):
      return {
        ...state,
        status: action.payload,
        documentUploadError: true,
        documentLoading: false,
      };

    // This action triggers the upload hook that checks newStatus
    case COMPANY_DOCUMENT_UPLOAD_SAVE:
      return {
        ...state,
        newStatus: true,
      };

    case COMPANY_CHANGE_MODE:
      return {
        ...state,
        editMode: action.payload,
      };

    case COMPANY_DOCUMENT_CLEANUP:
      return {
        ...initialState,
      };

    case COMPANY_FILE_UPLOAD:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
