import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  TextInputConfig,
} from "../../../components/formComponents/definitions/InputConfigurationSpec";
import { TextValidation } from "../../../components/formComponents/definitions/validation-spec";
import { generateUniqId } from "../../helpers/general-helper";
import CryptoJS from "crypto-js";

export const companyToContactPersonForm = (
  company: ICompany,
  hasUniqueKey?: boolean,
): ConfigurableInputProps[] => {
  // Definition
  const components = {
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhoneNumber: null,
  };

  if (company) {
    components.contactFirstName = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 1,
        name: "contactFirstName",
        validations: [new TextValidation({ maxLength: 100 })],
        isRequired: true,
        modelValue: "contactFirstName",
        classModifier: "half",
        tLabel: "expert_first_name",
        initValue: company.contactFirstName || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("contactFirstName"),
      errorMsg: "Field is required",
    });

    components.contactLastName = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 2,
        name: "contactLastName",
        validations: [new TextValidation({ maxLength: 100 })],
        isRequired: true,
        modelValue: "contactLastName",
        classModifier: "half",
        tLabel: "expert_last_name",
        initValue: company.contactLastName || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("contactLastName"),
      errorMsg: "Field is required",
    });

    components.contactEmail = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 3,
        validations: [new TextValidation({ isEmail: true, maxLength: 50 })],
        name: "contactEmail",
        modelValue: "contactEmail",
        isRequired: true,
        classModifier: "half",
        tLabel: "expert_email",
        initValue: company.contactEmail || "",
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("contactEmail"),
      errorMsg: "Field is required",
    });

    components.contactPhoneNumber = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 4,
        name: "contactPhoneNumber",
        validations: [new TextValidation({ isPhoneNumber: true })],
        modelValue: "contactPhoneNumber",
        isRequired: true,
        classModifier: "half",
        tLabel: "expert_phone_number",
        initValue: company.contactPhoneNumber || "+",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("contactPhoneNumber"),
      errorMsg: "Field is required",
    });
  }

  const allComponents = [
    components.contactFirstName,
    components.contactLastName,
    components.contactEmail,
    components.contactPhoneNumber,
  ];

  return [...allComponents];
};
