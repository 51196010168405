import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { email, password, required } from "../../../utilities/validation";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { ValidationError } from "../../../utilities/validationError";
import {
  forgotPasswordAction,
  deleteError,
} from "./../forgot-password-actions";

import "./../../loginFormComponent/auth.scss";
import "./../forgot-password.scss";
import Spinner from "../../common/spinner/spinner";
import AuthBackground from "../../authBgComponent/authBackground";
import { Paper, Typography } from "@material-ui/core";

export const ForgotPasswordForm = ({ onGetEmailValue }) => {
  const { t } = useTranslation("forgot-password");
  const dispatch = useDispatch();
  const { errorMessage, loading, status, emailSubmitSuccess } = useSelector(
    (state: IRootState) => state.forgotPassword,
  );

  const [emailValue, setEmailValue] = useState("");

  onGetEmailValue(emailValue);

  const handleSubmitForgotPassword = (event) => {
    event.preventDefault();
    const form = event.target;
    const user = new FormData(form);
    dispatch(forgotPasswordAction(user));
  };

  const errorMessageCleanUp = () => {
    dispatch(deleteError());
  };

  return (
    <div className="auth-form-wrapper">
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("forgotPasswordTitle")}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("forgotPasswordSubtitle")}
        </Typography>
      </AuthBackground>
      <Paper
        elevation={1}
        className="auth-form-container forgot-password-form-container"
      >
        <Form
          className="auth-form"
          onSubmit={handleSubmitForgotPassword}
          onChange={errorMessageCleanUp}
        >
          <div className="auth-input-container">
            <Input
              className="auth-input"
              id="EmailAddress"
              type="text"
              name="email"
              placeholder={t("forgotPasswordEmail")}
              validations={[email, required]}
              onChange={(e) => setEmailValue(e.target.value)}
            />
          </div>

          <div className="auth-input-container">
            <Button className="auth-submit-button">
              {t("forgotPasswordSubmit")}
            </Button>
            {errorMessage && <ValidationError emailValidation={errorMessage} />}
          </div>
          <span className="auth-text-container">
            {t("forgotPasswordGoBack")}
            {/* Add function on submit = clean error message */}
            {loading && <Spinner />}
            <Link to="/login" className="auth-link">
              {t("forgotPasswordLogin")}
            </Link>
          </span>
        </Form>
      </Paper>
    </div>
  );
};

export default ForgotPasswordForm;
