export class IExpert {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  country: {
    id: number;
    name?: string;
    code?: string;
  };
  jobTitle: string;
  email: string;
  repeatEmail: string;
  companyName: string;
  workWithStartups: boolean;
  regions: {
    id: number;
    name?: string;
  }[];
  industries: {
    id: number;
    name: string;
  }[];
  otherServiceActivities: string;
  otherExpertise: string;
  supportTypes: {
    id: number;
    name?: string;
  }[];
  numberOfCompanies: number;
  expectationsFromThePlatform: string;
  cognitoId?: string;
  deleted: boolean;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
  linkedinProfile: string;
  fieldsOfInterest: string;
  matchedCompanies: { id: number; name: string }[];
  dateCreated: string;
  lastModified: string;
  expertise: {
    id: number;
    name: string;
  }[];
}

export class Expert implements IExpert {
  public constructor(init?: Partial<Expert>) {
    Object.assign(this, init);
  }
  id: 0;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: {
    id: number;
    name: string;
    code: string;
  };
  jobTitle: string;
  email: string;
  repeatEmail: string;
  companyName: string;
  workWithStartups: boolean;
  regions: {
    id: number;
    name: string;
  }[];
  industries: {
    id: number;
    name: string;
  }[];
  otherServiceActivities: string;
  otherExpertise: string;
  supportTypes: {
    id: number;
    name: string;
  }[];
  numberOfCompanies: 0;
  expectationsFromThePlatform: string;
  cognitoId?: string;
  deleted: boolean;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
  linkedinProfile: string;
  fieldsOfInterest: string;
  matchedCompanies: { id: number; name: string }[];
  dateCreated: string;
  lastModified: string;
  expertise: {
    id: number;
    name: string;
  }[];
}
