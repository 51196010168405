import React from "react";
import { useTranslation } from "react-i18next";

import "./displayFields.scss";
import { generateUniqId } from "../../../utils/helpers/general-helper";
import DescriptionIcon from "@material-ui/icons/Description";
import VisibilityIcon from "@material-ui/icons/Visibility";

export default function DisplayFields({ fields, profileType, currentId }) {
  const { t } = useTranslation("admin-matching");

  return (
    <div className="sidebar-admin-comp-fields">
      {fields?.map((field) => (
        <div className="admin-comp-field" key={generateUniqId()}>
          <div className="admin-comp-field-title">{t(field.name)}</div>
          {field.isFile && field.value !== "Not uploaded" ? (
            profileType === "expert" ? (
              fields[2].value.map((file: string, idx: number) => (
                <div className="admin-comp-field-value custom" key={idx}>
                  <DescriptionIcon className="document-icons" />
                  <p>{file}</p>
                  <a
                    className="link"
                    href={`/uploads/${profileType}/${currentId}_${file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VisibilityIcon className="document-icons" />
                  </a>
                </div>
              ))
            ) : (
              fields[1].value.map((file: string, idx: number) => (
                <div className="admin-comp-field-value custom" key={idx}>
                  <DescriptionIcon className="document-icons" />
                  <p>{file}</p>
                  <a
                    className="link"
                    href={`/uploads/${profileType}/${currentId}_${file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VisibilityIcon className="document-icons" />
                  </a>
                </div>
              ))
            )
          ) : (
            <div className="admin-comp-field-value">{field.value}</div>
          )}
        </div>
      ))}
    </div>
  );
}
