export const EXPERT_NEW = "EXPERT_NEW";
export const EXPERT_UPDATE = "EXPERT_DRAFT_UPDATE";
export const EXPERT_GET_DATA = "EXPERT_GET_DATA";
export const EXPERT_GET_STATIC_DATA = "EXPERT_GET_STATIC_DATA";
export const EXPERT_DATA_CLEANUP = "EXPERT_DATA_CLEANUP";
export const EXPERT_DELETE = "EXPERT_DELETE_DATA";
export const EXPERT_DELETE_CLEANUP = "EXPERT_DELETE_DATA";
export const EXPERT_CANCEL_CHANGES = "EXPERT_CANCEL_DATA";
export const UPDATE_EXPERT_DATA = "UPDATE_EXPERT_DATA";
export const EXPERT_DOCUMENT_UPLOAD = "EXPERT_DOCUMENT_UPLOAD";
export const EXPERT_ALL_DOCUMENTS_DOWNLOAD = "EXPERT_ALL_DOCUMENTS_DOWNLOAD";
export const EXPERT_DOCUMENT_DOWNLOAD = "EXPERT_DOCUMENT_DOWNLOAD";
export const EXPERT_DOCUMENT_DELETE = "EXPERT_DOCUMENT_DELETE";
export const EXPERT_DOCUMENT_CLEANUP = "EXPERT_DOCUMENT_CLEANUP";
export const EXPERT_CHANGE_MODE = "EXPERT_CHANGE_MODE";
export const EXPERT_SAVE_CHANGES = "EXPERT_SAVE_CHANGES";
export const EXPERT_DOCUMENT_UPLOAD_SAVE = "EXPERT_DOCUMENT_UPLOAD_SAVE";
export const EXPERT_FILE_UPLOAD = "EXPERT_FILE_UPLOAD";
