import React from "react";
import { Paper, Typography, Divider, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import featureIllustration from "../../../../assets/images/featureSectionIllustration.svg";
import "./featureComponent.scss";

export const FeatureComponent: React.FC = () => {
  const { t } = useTranslation("homepage");

  return (
    <div className="feature-wrapper">
      <Paper className="feature-section-wrapper">
        <Box className="box-wrap">
          <img
            src={featureIllustration}
            alt="Feature Component Illustration"
            className="feature-component-image"
          />
        </Box>
        <Box className="feature-content-wrapper box-wrap">
          <Typography className="feature-heading" variant="h3">
            {t("featureComponentTitle")}
          </Typography>
          <Typography className="feature-paragraph" variant="body2">
            {t("bringsTogetherParagraph")}
          </Typography>
          <Divider />
          <Typography variant="body2">
            {t("remainsDedicatedParagraph")}
          </Typography>
        </Box>
      </Paper>
    </div>
  );
};
