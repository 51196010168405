import React from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { PublicRoutes } from "../routes/public-routes";
import { IRootState } from "../utilities/root-reducer";
import { connect } from "react-redux";
import { ErrorPage } from "../components/errorPage/error-page";
import { TermsAndConditions } from "../components/termsAndConditions/terms-and-conditions";
import "./../index.scss";
import { PrivacyPolicy } from "../components/privacyPolicy/privacy-policy";

interface IPublicViewProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<any> {}

export class PublicView extends React.Component<IPublicViewProps> {
  render() {
    const path = this.props.match.path;
    const routes = PublicRoutes.map((route, index) => {
      const routeProps = { ...route, path: path + route.path };
      return <Route exact {...routeProps} key={route.path + index} />;
    });

    return (
      <div className="public">
        <React.Suspense fallback="loading..">
          <Switch>
            <Redirect exact from="/" to="/home" />
            {routes}
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route component={ErrorPage} />
          </Switch>
        </React.Suspense>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PublicView);
