export interface IExpertRequest {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  country: {
    id: number;
    name?: string;
    code?: string;
  };
  jobTitle: string;
  email: string;
  repeatEmail: string;
  companyName: string;
  workWithStartups: boolean;
  regions: {
    id: number;
  }[];
  industries: {
    id?: number;
    name: string;
  }[];
  supportTypes: {
    id: number;
    name?: string;
  }[];
  numberOfCompanies: number;
  expectationsFromThePlatform: string;
  cognitoId?: string;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
  linkedinProfile: string;
  fieldsOfInterest: string;
  expertise: {
    id?: number;
    name: string;
  }[];
}

export class ExpertRequest implements IExpertRequest {
  cognitoId?: string;
  companyName: string;
  country: { id: number };
  email: string;
  repeatEmail: string;
  firstName: string;
  lastName: string;
  industries: { name: string }[];
  workWithStartups: boolean;
  jobTitle: string;
  phoneNumber?: string;
  regions: { id: number }[];
  supportTypes: { id: number }[];
  numberOfCompanies: number;
  expectationsFromThePlatform: string;
  hasDocument: boolean;
  documentUrl: string;
  documents: string[];
  linkedinProfile: string;
  fieldsOfInterest: string;
  expertise: {
    id?: number;
    name: string;
  }[];
}
