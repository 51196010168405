import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { store } from "react-notifications-component";

import { useDispatch } from "react-redux";
import { ExpertFormWorkMode } from "../../../models/expert/expert-form-work-mode";
import { cognitoAuth, getCognitoId } from "../../../utilities/auth";
import { notifyError } from "../../../utils/notifications/app-notification";
import { getExpert } from "../expert-profile-action";

import "./expert-profile-summary.scss";

interface IProfileSummary {
  show?: boolean;
  newStatus?: boolean;
  newError?: any;
  setWorkModeView: (value: ExpertFormWorkMode) => void;
}

export const ExpertProfileSummary = (props: IProfileSummary) => {
  const [showSummary, setShowSummary] = useState(true);
  const { show, newStatus, newError, setWorkModeView } = props;
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!newStatus && newError) {
      notifyError(
        store,
        t("company-profile-create-title"),
        t("company-profile-create-error"),
      );
    }
  }, [newStatus]);

  const handleClick = () => {
    dispatch(getExpert());
    setShowSummary(!showSummary);
    setWorkModeView(ExpertFormWorkMode.View);
  };
  return (
    <>
      <div className="profile-summary-container">
        <div className="profile-summary-inner-container">
          <div className="profile-summary-image"></div>
          <h3 className="profile-summary-title">
            {t("profile-summary-title")}
          </h3>
          <button
            className="profile-summary-button"
            type="button"
            onClick={handleClick}
          >
            {t("profile-summary-button")}
          </button>
        </div>
      </div>
    </>
  );
};
