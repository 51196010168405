import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "react-notifications-component";
import {
  fetchAllCompanies,
  getCompany,
  searchCompany,
  companyExport,
  fetchAvailableExperts,
} from "./companyTableActions";
import { IRootState } from "../../../utilities/root-reducer";
import TrashIcon from "../../SVGs/TrashIcon";
import { PaginationComponent } from "../../paginationComponent/paginationComponent";
import Sidebar from "../../sidebarComponent/sidebarComponent";
import { ICompany } from "../../../models/company/company";
import { ISidebar } from "../../../models/general/sidebar";
import { IPagination, Pagination } from "../../../models/general/pagination";
import DeleteCompanyModal from "./Modals/delete-company-modal";
import AddMatchingExpertModal from "./Modals/add-matching-expert-modal";
import RemoveMatchingExpertModal from "./Modals/remove-matching-expert-modal";
import { mapToCompanySidebarView } from "../../../utils/mappers/company/companyToSidebarView";
import { notifyError } from "../../../utils/notifications/app-notification";
import ReactLoading from "react-loading";
import { dateToProperFormat } from "../../common/DatePicker";
import { generateUniqId } from "../../../utils/helpers/general-helper";
import { withStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import "./companyTableComponent.scss";

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#dbd9d9",
    color: theme.palette.common.black,
    boxShadow: theme.shadows[2],
    fontSize: 15,
    padding: theme.spacing(2),
  },
}))(Tooltip);

export const CompanyTableComponent = () => {
  const translation = useTranslation("admin-tables");
  const { t } = translation;
  const dispatch = useDispatch();
  const { data, error, loading, company } = useSelector(
    (state: IRootState) => state.adminCompanies,
  );

  // sidebar
  const selectedCompanyData = useRef<ICompany>(null);
  const [selectedCompany, setSelectedCompany] = useState<ISidebar>(null);
  const [showMenu, setShowMenu] = useState(false);

  // search
  const isSearch = useRef<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");

  // list and pagination
  const [companiesTableList, setCompaniesTableList] = useState<ICompany[]>([]);
  const pagination = useRef<IPagination>(new Pagination());

  // delete modal
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteCompanyId = useRef<string>("");

  //matching experts modals
  const [
    showAddMatchingExpertsModal,
    setShowAddMatchingExpertsModal,
  ] = useState<boolean>(false);

  const [
    showRemoveMatchingExpertsModal,
    setShowRemoveMatchingExpertsModal,
  ] = useState<boolean>(false);

  // company id for matching experts modals
  const matchCompanyId = useRef<number>();

  // matching tooltip
  const [currentMatchedExpert, setCurrentMatchedExpert] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    dispatch(
      fetchAllCompanies(
        pagination.current.pageable.pageNumber,
        pagination.current.pageable.pageSize,
      ),
    );
  }, []);

  useEffect(() => {
    if (data) {
      if (data.content) {
        pagination.current = data;
        setCompaniesTableList([...(data.content as ICompany[])]);
      }
    }
  }, [data as Pagination, loading]);

  useEffect(() => {
    if (company) {
      const mappedData = mapToCompanySidebarView(company, t);
      setSelectedCompany(mappedData);
      selectedCompanyData.current = { ...company };
    }
  }, [company]);

  useEffect(() => {
    if (!loading && error) {
      notifyError(
        store,
        t("admin-company-get-data-error-title"),
        t("admin-company-get-data-error-desc"),
      );
    }
  }, [loading]);

  const handlePageChangeOnClick = ({ pageNumber, pageSize }) => {
    dispatch(
      fetchAllCompanies(pageNumber, pagination.current.pageable.pageSize),
    );
  };

  /**
   * COMPANY SELECTION
   */

  const handleSelectedCompany = (id: number) => {
    dispatch(getCompany(id));
    setShowMenu(true);
  };

  /**
   * SEARCH
   */

  const handleSearchInpChange = (event) => {
    const term = event.target.value;
    if (isSearch.current && term.length === 0) {
      setSearchTerm("");
      isSearch.current = false;
      pagination.current.pageNumber = 0;
      dispatch(
        fetchAllCompanies(
          pagination.current.pageable.pageNumber,
          pagination.current.pageable.pageSize,
        ),
      );
    } else {
      setSearchTerm(term);
    }
  };

  const handleSearchInpKeypress = (event) => {
    if (event.key === "Enter") {
      if (searchTerm.length > 0) {
        isSearch.current = true;
        dispatch(searchCompany(searchTerm));
      }
    }
  };

  const handleSearchBtnClick = () => {
    if (searchTerm.length > 0) {
      isSearch.current = true;
      dispatch(searchCompany(searchTerm));
    }
  };

  /**
   * DELETE COMPANY
   */

  const handleDeleteCompanyRowClick = (id: string) => {
    deleteCompanyId.current = id;
    setShowDeleteModal(true);
  };

  const handleDeleteCompanySuccess = () => {
    deleteCompanyId.current = "";

    // Go to previous page in case we have more data in DB but in the current
    // page we delete the last item
    if (companiesTableList.length === 1 && pagination.current.pageNumber > 0) {
      const pageNumber = pagination.current.pageNumber - 1;
      dispatch(
        fetchAllCompanies(pageNumber, pagination.current.pageable.pageSize),
      );
      return;
    }

    dispatch(
      fetchAllCompanies(
        pagination.current.pageable.pageNumber,
        pagination.current.pageable.pageSize,
      ),
    );

    setShowDeleteModal(false);
  };

  const handleDeleteModalOnCancel = () => {
    deleteCompanyId.current = "";
    setShowDeleteModal(false);
  };

  // MATCHING EXPERTS MODAL
  const handleFetchingAvaliableExperts = (id: number, isChecked: boolean) => {
    matchCompanyId.current = id;

    if (isChecked) {
      dispatch(getCompany(id));
      setShowRemoveMatchingExpertsModal(true);
    } else {
      dispatch(fetchAvailableExperts(id));
      setShowAddMatchingExpertsModal(true);
    }
  };

  const handleMatchingAndUnmatchingExpertSuccess = () => {
    matchCompanyId.current = null;

    // Go to previous page in case we have more data in DB but in the current
    // page we delete the last item
    if (companiesTableList.length === 1 && pagination.current.pageNumber > 0) {
      const pageNumber = pagination.current.pageNumber - 1;
      dispatch(
        fetchAllCompanies(pageNumber, pagination.current.pageable.pageSize),
      );
      return;
    }

    dispatch(
      fetchAllCompanies(
        pagination.current.pageable.pageNumber,
        pagination.current.pageable.pageSize,
      ),
    );

    if (showRemoveMatchingExpertsModal) {
      setShowRemoveMatchingExpertsModal(false);
    } else {
      setShowAddMatchingExpertsModal(false);
    }
  };

  const handleMatchingExpertsModalOnCancel = () => {
    matchCompanyId.current = null;
    if (showRemoveMatchingExpertsModal) {
      setShowRemoveMatchingExpertsModal(false);
    } else {
      setShowAddMatchingExpertsModal(false);
    }
  };

  const handleSidebarCloseClick = () => {
    setShowMenu(false);
  };

  // EXPORT COMPANIES
  const handleExportCompanies = () => dispatch(companyExport());

  return (
    <>
      {/* search field should be shown only when we have companies or when the filter is active,
    (not shown when there are no registered companies) */}
      {(companiesTableList.length > 0 || isSearch.current) && (
        <div className="admin-search-wrapper">
          <input
            type="text"
            className="admin-search-field"
            value={searchTerm}
            name="search"
            onChange={handleSearchInpChange}
            onKeyPress={handleSearchInpKeypress}
            placeholder={t("table_search_company")}
          />
          <button
            className="admin-search-button"
            onClick={handleSearchBtnClick}
          >
            {t("table_search_button")}
          </button>
        </div>
      )}
      <div className="admin-table-container">
        {loading && (
          <div className="align-center">
            <ReactLoading type={"bars"} color={"#a41e24"} />
          </div>
        )}
        {!loading && companiesTableList.length === 0 && (
          <div className="no-list-text-wrapper">
            {isSearch.current ? (
              <p>
                {t("admin-company-no-search-results") +
                  " " +
                  '"' +
                  searchTerm +
                  '"'}
              </p>
            ) : (
              <p>{t("admin-company-no-results")}</p>
            )}
          </div>
        )}
        {companiesTableList.length > 0 && (
          <>
            <div className="title-btn-wrapper">
              <h2>{t("admin-companies")}</h2>
              <button className="export-button" onClick={handleExportCompanies}>
                {t("admin-export")}
              </button>
            </div>
            <Sidebar
              sidebarData={selectedCompany}
              showMenu={showMenu}
              onCloseSidebar={handleSidebarCloseClick}
              translation={translation}
              hasDocument={company && company.hasDocument}
              profileType="company"
              currentId={selectedCompanyData.current?.id}
            />
            <table className="admin-table">
              <thead>
                <tr className="admin-head admin-row admin-row-companies">
                  <th>{t("table_name_company")}</th>
                  <th>{t("table_industry")}</th>
                  <th>{t("table_support_needed")}</th>
                  <th>{t("table_region_expand")}</th>
                  <th>{t("table_region_export")}</th>
                  <th className="custom-center">{t("admin-created")}</th>
                  <th className="custom-center">{t("admin-last-modified")}</th>
                  <th>{t("table_company_matched")}</th>
                  <th>{t("table_actions")}</th>
                </tr>
              </thead>
              <tbody>
                {companiesTableList.map((company) => {
                  return (
                    <tr
                      className="admin-row admin-row-companies single-row"
                      key={company.id + generateUniqId()}
                      onMouseOver={() =>
                        setCurrentMatchedExpert(
                          company.matchedExpert
                            ? `${company.matchedExpert?.firstName} ${
                                company.matchedExpert?.lastName
                                  ? company.matchedExpert.lastName
                                  : ""
                              }`
                            : undefined,
                        )
                      }
                    >
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          {company.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          {company.industries && company.industries.length > 1
                            ? company.industries[0]?.name + "..."
                            : company.industries[0]?.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          {company.supportTypes &&
                          company.supportTypes.length > 1
                            ? company.supportTypes[0]?.name ===
                              "Mentoring businesses to improve and grow"
                              ? "Mentoring that will help you grow and improve your business" +
                                "..."
                              : "Support in entering foreign markets/growing internationally" +
                                "..."
                            : company.supportTypes[0]?.name ===
                              "Mentoring businesses to improve and grow"
                            ? "Mentoring that will help you grow and improve your business"
                            : "Support in entering foreign markets/growing internationally"}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          {company.regions && company.regions.length > 1
                            ? company.regions[0]?.name + "..."
                            : company.regions[0]?.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          {company.exportRegions?.length > 1
                            ? company.exportRegions[0]?.name + "..."
                            : company.exportRegions[0]?.name}
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell custom"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          <span>{dateToProperFormat(company.dateCreated)}</span>
                        </td>
                      </LightTooltip>
                      <LightTooltip
                        title={
                          typeof currentMatchedExpert === "undefined"
                            ? "Not matched"
                            : `Matched with ${currentMatchedExpert} (starting from ${
                                company.collaborationStartDate &&
                                dateToProperFormat(
                                  company.collaborationStartDate,
                                )
                              })`
                        }
                        placement="right"
                      >
                        <td
                          className="clickable-cell custom"
                          onClick={() => handleSelectedCompany(company.id)}
                        >
                          <span>
                            {company.lastModified
                              ? dateToProperFormat(company.lastModified)
                              : "N/A"}
                          </span>
                        </td>
                      </LightTooltip>
                      <td className="center-checkbox">
                        <label
                          className="checkbox-wrapper"
                          onClick={() => {
                            handleFetchingAvaliableExperts(
                              company.id,
                              !!company.matchedExpert,
                            );
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={!!company.matchedExpert}
                            onChange={(e) => e.target.value}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        <span
                          className="admin-table-delete"
                          onClick={() =>
                            handleDeleteCompanyRowClick(company.cognitoId)
                          }
                        >
                          <TrashIcon
                            width="1.75em"
                            height="1.75em"
                            color="#a41e24"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}

        {!isSearch.current && (
          <PaginationComponent
            totalPages={pagination.current.totalPages}
            changePage={handlePageChangeOnClick}
          />
        )}
        <DeleteCompanyModal
          open={showDeleteModal}
          companyId={deleteCompanyId.current}
          onDeleteSuccess={handleDeleteCompanySuccess}
          onCancel={handleDeleteModalOnCancel}
        />
        <AddMatchingExpertModal
          open={showAddMatchingExpertsModal}
          companyId={matchCompanyId.current}
          onMatchingSuccess={handleMatchingAndUnmatchingExpertSuccess}
          onCancel={handleMatchingExpertsModalOnCancel}
        />
        <RemoveMatchingExpertModal
          open={showRemoveMatchingExpertsModal}
          companyId={matchCompanyId.current}
          onMatchingSuccess={handleMatchingAndUnmatchingExpertSuccess}
          onCancel={handleMatchingExpertsModalOnCancel}
        />
      </div>
    </>
  );
};
