import React from "react";
import { connect } from "react-redux";
import "./auth.scss";
import { loginFormAction, deleteError } from "./login-form-actions";
import { IRootState } from "../../utilities/root-reducer";
import {
  Link,
  RouteComponentProps,
  Redirect,
  withRouter,
} from "react-router-dom";
import { withTranslation } from "react-i18next";
import { email, required } from "../../utilities/validation";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { ValidationError } from "../../utilities/validationError";
import compose from "recompose/compose";
import { getProfileType, isAuth } from "../../utilities/auth";
import AuthBackground from "../authBgComponent/authBackground";
import { Paper, Typography } from "@material-ui/core";

interface ILoginFormProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  t: any;
}

export class LoginForm extends React.Component<ILoginFormProps> {
  constructor(props) {
    super(props);

    this.handleSubmitFormLogin = this.handleSubmitFormLogin.bind(this);
    this.handleDeleteErrorMessage = this.handleDeleteErrorMessage.bind(this);
  }

  handleSubmitFormLogin = (event) => {
    event.preventDefault();
    const form = event.target;
    const user = new FormData(form);
    this.props.loginFormAction(user);
  };

  handleDeleteErrorMessage = () => this.props.deleteError();

  componentDidUpdate() {
    if (this.props.loginFormData.loginSuccess) {
      const profileType = getProfileType();
      switch (profileType) {
        case "company":
          this.props.history.push("/company");
          break;
        case "expert":
          this.props.history.push("/expert");
          break;
        case "admin":
          this.props.history.push("/admin");
          break;
        default:
          this.props.history.push("/error");
          break;
      }
    }
  }

  render() {
    if (isAuth()) {
      const profileType = getProfileType();
      switch (profileType) {
        case "company":
          return <Redirect to="/company" />;
        case "expert":
          return <Redirect to="/expert" />;
        case "admin":
          return <Redirect to="/admin" />;
        default:
          return <Redirect to="/error" />;
      }
    }
    const { t } = this.props;
    return (
      <div className="auth-form-wrapper">
        <AuthBackground>
          <Typography className="auth-heading" variant="h1">
            {t("loginAccountTitle")}
          </Typography>
          <Typography className="auth-paragraph" variant="body1">
            {t("loginSmallText")}
          </Typography>
        </AuthBackground>
        <Paper elevation={1} className="auth-form-container">
          <Form
            className="auth-form"
            onSubmit={this.handleSubmitFormLogin}
            onChange={this.handleDeleteErrorMessage}
          >
            <div className="auth-input-container">
              <Input
                className="auth-input"
                id="EmailAddress"
                type="text"
                name="email"
                placeholder={t("loginEmailPlaceholder")}
                validations={[required, email]}
              />
            </div>
            <div className="auth-input-container">
              <Input
                className="auth-input"
                id="password"
                type="password"
                name="password"
                placeholder={t("loginPasswordPlaceholder")}
                validations={[required]}
              />
            </div>
            <div className="auth-subinput-container">
              <Link to="/forgot-password" className="auth-link">
                {t("loginForgetPassword")}
              </Link>
            </div>
            <Button className="auth-submit-button">
              {t("loginSubmitButton")}
            </Button>
            {this.props.loginFormData.errorMessage && (
              <ValidationError
                emailValidation={this.props.loginFormData.errorMessage}
              />
            )}
            <span className="auth-text-container">
              {t("loginDon'tHaveAccount")}
              <Link to="/signup" className="auth-link">
                {t("loginCreateAccount")}
              </Link>
            </span>
          </Form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  loginFormData: storeState.login,
});
const mapDispatchToProps = {
  loginFormAction,
  deleteError,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  withTranslation("login-layout"),
  connect(mapStateToProps, mapDispatchToProps),
)(LoginForm);
