import { combineReducers } from "redux";
import { History } from "history";
import sample, {
  SampleState,
} from "../components/sampleComponent/sample-reducers";
import { connectRouter } from "connected-react-router";
import signup, {
  SignupFormState,
} from "../components/signupFormComponent/signup-form-reducers";
import login, {
  LoginFormState,
} from "../components/loginFormComponent/login-form-reducers";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import user, { UserState } from "./user-reducer";
import company, {
  CompanyProfileState,
} from "../components/companyProfile/company-profile-reducer";
import forgotPassword, {
  ForgotPasswordState,
} from "../components/forgotPassword/forgot-password-reducers";
import adminCompanies, {
  AdminCompaniesState,
} from "../components/adminTables/companyTableComponent/companyTableReducers";
import adminExperts, {
  AdminExpertsState,
} from "../components/adminTables/expertsTableComponent/expertsTableReducers";
import adminMatching, {
  AdminMatchingState,
} from "../components/adminMatching/adminMatchingReducer";
import resetPassword, {
  ResetPasswordState,
} from "../components/resetPassword/reset-password-reducers";
import expert, {
  ExpertProfileState,
} from "../components/expertProfile/expert-profile-reducer";

import registerConfirm, {
  RegistrationConfirmState,
} from "./../components/registrationConfirm/registrationCofirmReducers";

export interface IRootState {
  router: any;
  sample: SampleState;
  signup: SignupFormState;
  loadingBar: any;
  login: LoginFormState;
  user: UserState;
  company: CompanyProfileState;
  forgotPassword: ForgotPasswordState;
  resetPassword: ResetPasswordState;
  expert: ExpertProfileState;
  adminCompanies: AdminCompaniesState;
  adminExperts: AdminExpertsState;
  adminMatching: AdminMatchingState;
  registerConfirm: RegistrationConfirmState;
}

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    sample,
    signup,
    loadingBar,
    login,
    user,
    company,
    forgotPassword,
    expert,
    adminCompanies,
    adminExperts,
    adminMatching,
    resetPassword,
    registerConfirm,
  });

export default rootReducer;
