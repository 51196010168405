import React, { useState, useRef, useEffect } from "react";
import "./form-components.scss";
import {
  SelectInputConfig,
  SelectInputEvent,
} from "./definitions/InputConfigurationSpec";
import Select from "react-select";
import "./../../styles/profileForms/shared-profile-styles.scss";
import "./../customSelectField/customSelectField.scss";

const theme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#000",
      primary25: "#f7f7f7",
      primary50: "#eeeeee",
      primary75: "#dddddd",
    },
  };
};
// Required to override react-select styles
const customSelectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: state.isFocused ? "#000" : "#a9a9a9",
      boxShadow: state.isFocused ? "#000" : "##a9a9a9",
    };
  },
  option: (provided, state) => {
    let color: string;
    let backgroundColor: string;
    if (state.isSelected) {
      backgroundColor = "#eeeeee";
    } else if (state.isFocused) {
      backgroundColor = "#f6f6f6";
    }

    return {
      ...provided,
      color,
      backgroundColor,
      padding: 10,
    };
  },
  dropdownIndicator: (props) => ({
    ...props,
    color: "#a1a1a144",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export default function SelectInput(props: {
  inputConfig: SelectInputConfig;
  onSelect: (event: SelectInputEvent) => void;
  errorMsg?: string;
}) {
  const { inputConfig, onSelect, errorMsg } = props;
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMsg);
  const selectedOptions = useRef(inputConfig.selectedMultiOptions);
  const selectedOption = useRef(
    inputConfig.selectedOption || { value: -1, label: "" },
  );
  const [isDisabled, setIsDisabled] = useState(inputConfig.isDisabled || false);
  const [disabledOptions, setDisabledOptions] = useState(null);

  useEffect(() => {
    setIsDisabled(inputConfig.isDisabled);
    if (inputConfig.isDisabled) {
      if (hasError) {
        setHasError(false);
      }
    }
  }, [inputConfig.isDisabled]);

  inputConfig.setDisabled = (disabled) => {
    setIsDisabled(disabled);
    inputConfig.isDisabled = disabled;
    if (hasError) {
      setHasError(false);
    }
  };

  useEffect(() => {
    if (inputConfig.isMulti && inputConfig.maxSelectedOptions) {
      if (
        inputConfig.selectedMultiOptions.length >=
        inputConfig.maxSelectedOptions
      ) {
        const disabledList = inputConfig.options.map((item) => {
          return {
            value: item.value,
            label: item.label,
            isDisabled: true,
          };
        });
        setDisabledOptions([...disabledList]);
      } else {
        setDisabledOptions(null);
      }
    }
  }, [inputConfig.selectedMultiOptions]);

  const handleSelect = (selected, action) => {
    setHasError(false);
    let selectEvent = new SelectInputEvent();
    if (!inputConfig.isMulti) {
      if (selected) {
        selectEvent = new SelectInputEvent({
          name: inputConfig.name,
          selectedOption: {
            value: selected.value,
            label: selected.label,
          },
          modelValue: inputConfig.modelValue,
          isMulti: inputConfig.isMulti,
        });
        selectedOption.current = { ...selected };
      } else {
        selectEvent = new SelectInputEvent({
          name: inputConfig.name,
          isMulti: inputConfig.isMulti,
          selectedOption: {
            value: -1,
            label: "",
          },
          modelValue: inputConfig.modelValue,
        });
        selectedOption.current = null;
      }

      if (typeof onSelect === "function") {
        onSelect(selectEvent);
      }
    } else {
      // cleared selected options
      if (!selected) {
        selectEvent = new SelectInputEvent({
          name: inputConfig.name,
          selectedMultiOptions: [],
          modelValue: inputConfig.modelValue,
          isMulti: inputConfig.isMulti,
        });
        selectedOptions.current = [];
      } else {
        // have selected options
        selectEvent = new SelectInputEvent({
          name: inputConfig.name,
          modelValue: inputConfig.modelValue,
          isMulti: inputConfig.isMulti,
          selectedMultiOptions: [...selected],
        });
        selectedOptions.current = [...selected];
      }
      if (typeof onSelect === "function") {
        onSelect(selectEvent);
      }
    }
  };

  inputConfig.validate = () => {
    // do not check for validation id input is disabled
    if (inputConfig.isDisabled) {
      return true;
    }

    if (inputConfig.isRequired) {
      if (inputConfig.isMulti) {
        const isMultiValid = selectedOptions.current.length > 0;
        if (!isMultiValid) {
          setHasError(true);
          return false;
        }
        return true;
      } else {
        // single select
        const isSingleSelectValid =
          selectedOption.current !== null &&
          selectedOption.current.value !== -1;
        if (!isSingleSelectValid) {
          setHasError(true);
          return false;
        }
        return true;
      }
    }
    return true;
  };

  return (
    <>
      <div className="custom-select-field-wrapper">
        <label className="custom-select-label">{inputConfig.placeholder}</label>
        <Select
          defaultValue={
            inputConfig.isMulti
              ? inputConfig.selectedMultiOptions
              : inputConfig.selectedOption
          }
          className="custom-select-field"
          isClearable={true}
          isSearchable={true}
          isMulti={inputConfig.isMulti}
          isDisabled={isDisabled}
          name="color"
          options={disabledOptions || inputConfig.options}
          onChange={handleSelect}
          placeholder={inputConfig.placeholder}
          styles={customSelectStyles}
          theme={theme}
        />
      </div>
      {hasError && <div className={"alert error"}>{error}</div>}
    </>
  );
}
