import { Actions } from "../../utilities/action-payload";
import { ISample } from "./sample-entity";

const initialState: ISample = {
  id: 5,
  firstName: "Ova kopce menja stejt",
  lastName: "",
  counter: 0,
};

export type SampleState = Readonly<typeof initialState>;

export default (
  state: SampleState = initialState,
  action: Actions,
): SampleState => {
  switch (action.type) {
    case "SAMPLE_ACTION":
      return {
        ...state,
        firstName: action.payload,
        counter: state.counter + 1,
      };
    default:
      return state;
  }
};
