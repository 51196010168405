import { Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import AuthBackground from "../../authBgComponent/authBackground";

import "./../../loginFormComponent/auth.scss";

const RegistrationConfirmFailure = () => {
  const { t } = useTranslation("signup-layout");

  const history = useHistory();

  return (
    <>
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("confirmUserFailureTitle")}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("confirmUserFailureSubtitle")}
        </Typography>
      </AuthBackground>
      <Paper className="auth-form-container forgot-password-form-container">
        <Form className="auth-form">
          <div className="failure-x"></div>
        </Form>
      </Paper>
    </>
  );
};

export default RegistrationConfirmFailure;
