export const privacyEN = [
  {
    title: "This Privacy Policy contains general information about the processing of your personal data by MACEDONIA 2025 Branch in the Republic of Macedonia, based at ul. \"Louis Pasteur\" number 1, floor 2, Skopje - Center, a controller, in case you register on the Platform \"Connect2Mk\" as a natural person - Supplier of Services.",
    class: "indent-none",
  },
  {
    title:
      "The following is the definition of the terms used in this Privacy Policy:",
    class: "indent-none",
  },
  {
    title:
      "\"Connect2Mk\" is a Platform that offers certain Services in the form of intermediation between Creditors of Services (companies) and Suppliers of Services (experts).",
    class: "indent-first",
  },
  {
    title: "\"Supplier of Services\" is a natural person who possesses scientific knowledge in a certain field or has acquired experience in a certain field and offers such knowledge and experience to the Creditor of Services. ",
    class: "indent-first",
  },
  {
    title:
      "\"Creditor of Services\" is any micro, small, medium or large trade company with dominant Macedonian ownership, which demands the knowledge and experience of the Supplier of Services.",
    class: "indent-first",
  },
  {
    title:
      "Collection of personal data",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "In order to register your profile on the \"Connect2Mk\" Platform, it is required that you provide the following personal data:",
    class: "indent-none",
  },
  {
    title:
      "• name and surname,",
    class: "indent-first",
  },
  {
    title:
      "• e-mail address,",
    class: "indent-first",
  },
  {
    title:      "• occupation,",
    class: "indent-first",
  },
  {
    title: "• country of residence,",
    class: "indent-first",
  },
  {
    title:
      "• region of residence.",
    class: "indent-first",
  },
  {
    title:
      "You can also provide the following data, which is not obligatory:",
    class: "indent-none",
  },
  {
    title:
      "• telephone number,",
    class: "indent-first",
  },
  {
    title:
      "• company name.",
    class: "indent-first",
  },
  {
    title: "Also, for the needs of the \"Connect2Mk\" Platform, you may be asked to submit additional documents after your registration on the \"Connect2Mk\" Platform, such as your CV. It is your decision whether you submit the additional documents.",
    class: "indent-none",
  },
  {
    title:
      "If the documents that you additionally submit to us contain personal data that is not listed above, it is necessary that you simultaneously submit a signed statement by which you agree that we process your personal data contained in those documents.",
    class: "indent-none",
  },
  {
    title:
      "If you refuse to submit the documents we deem necessary, we have the right to suspend or delete your account, in accordance with the General Terms of the \"Connect2Mk\" Platform.",
    class: "indent-none",
  },
  {
    title: "Objectives of processing",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title: "We process your personal data only for the purpose of achieving the goals for which the \"Connect2Mk\" Platform was created, i.e your connection with the Creditor of Services, in order to establish mutual cooperation.",
    class: "indent-none"
  },
  {
    title:
      "Users of personal data",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "The activities of personal data processing related to the services offered by the Platform \"Connect2Mk\" are performed by our employees who undertake technical and organizational measures for protection of personal data in order to prevent unauthorized access and their abuse.",
    class: "indent-none",
  },
  {
    title: "We do not share the your personal data with third parties, except during the process of intermediation in order to establish potential cooperation between yourself and potential Creditor of Services, which takes place as follows:",
    class: "indent-none",
  },
  {
    title:
      "If we estimate that a certain Creditor of Services might be interested in your Services, upon your prior consent, we can send them your name and surname, e-mail address, job and company in order to achieve mutual contact.",
    class: "indent-none",
  },
  {
    title:
      "Time period of storage of personal data",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title:
      "We process your personal data for the realization of your interests arising from the registration of the Platform \"Connect2Mk\" as long as you have a profile on the Platform.",
    class: "indent-none",
  },
  {
    title:
      "Rights of the Suppliers of Services",
    class: "indent-none underlined font-weight--bold",
  },
  {
    title: "Regarding the processing of your personal data, your rights arising from the Law on Personal Data Protection (\"Official Gazette of the Republic of Northern Macedonia\" No. 42 of 16.02.2020) are the following:",
    class: "indent-none",
  },
  {
    title:
      "• right to be informed about the processing of personal data;",
    class: "indent-first",
  },
  {
    title:
      "• right to access;",
    class: "indent-first",
  },
  {
    title:
      "• the right to crectification and erasure;",
    class: "indent-first",
  },
  {
    title:
      "• the right to restrict the processing of personal data;",
    class: "indent-first",
  },
  {
    title:
      "• right to object;",
    class: "indent-first",
  },
  {
    title:
      "• the right to submit a request to the Agency for Personal Data Protection, in accordance with Article 97 of the Law on Personal Data Protection, if you believe that we have violated your rights established by the said law.",
    class: "indent-first",
  },
  {
    title:
      "If you need additional information regarding the processing of your personal data, you can contact our Personal Data Protection officer Galena Cunningham at the following e-mail: galena@macedonia2025.com.",
    class: "indent-none",
  },
  {
    title:
      "By using the „Connect2Mk“ Platform, you agree to the terms of this Privacy Policy.",
    class: "indent-none",
  },
  {
    title: "If certain changes are to be made to the Privacy Policy, they will be appcable from the day of their publication on our website.",
    class: "indent-none",
  }
]