import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  SelectInputConfig,
  YesNoRadioButtonConfig,
  CheckboxListConfig,
  SingleCheckbox,
  TextInputConfig,
} from "../../../components/formComponents/definitions/InputConfigurationSpec";
import { IGeneralData } from "../../../models/general/general-data";
import { TextValidation } from "../../../components/formComponents/definitions/validation-spec";
import { generateUniqId } from "../../helpers/general-helper";
import CryptoJS from "crypto-js";
import { DevelopmentPhase } from "../../../models/company/development-phase-enum";
import { GrowthPhase } from "../../../models/company/growth-phase-enum";

export const companyToExpertiseTabForm = (
  company: ICompany,
  staticData: any,
  generalData?: IGeneralData,
  hasUniqueKey?: boolean,
): ConfigurableInputProps[] => {
  const isStartup = company.startup || false;

  // Definition
  const components = {
    supportTypes: null,
    preferenceForRegionExpanding: null,
    regions: null,
    startup: null,
    problemsThatServiceAddresses: null,
    innovativenessOfTheService: null,
    typeOfClientsTargeting: null,
    developmentPhase: null,
    growthPhase: null,
    expectationsFromExpertSupport: null,
  };

  const handleSelectedOptionEnum = (enumString: string) => {
    switch (enumString) {
      case DevelopmentPhase.BusinessAndMarketing:
        return {
          value: "BUSINESS_AND_MARKETING",
          label: "Business and Marketing Strategy Development",
        };
      case DevelopmentPhase.ProductDevelopment:
        return {
          value: "PRODUCT_DEVELOPMENT",
          label: "Product Development",
        };
      case DevelopmentPhase.TestMarketing:
        return {
          value: "TEST_MARKETING",
          label: "Test Marketing, Validate",
        };
      case DevelopmentPhase.Commercialization:
        return {
          value: "COMMERCIALIZATION",
          label: "Commercialization",
        };
      case GrowthPhase.Beginning:
        return {
          value: "BEGINNING",
          label: "At the beginning (no market activity)",
        };
      case GrowthPhase.Launch:
        return {
          value: "LAUNCH",
          label: "Launch",
        };
      case GrowthPhase.EarlyStage:
        return {
          value: "EARLY_STAGE",
          label:
            "Early stage (generate expected revenues, have customers, but need to scale to satisfy the market demand)",
        };
      case GrowthPhase.GrowthStage:
        return {
          value: "GROWTH_STAGE",
          label:
            "Growth stage (a profitable business that seeks ways to expand further)",
        };
      case GrowthPhase.Maturity:
        return {
          value: "MATURITY",
          label: "Maturity",
        };
    }
  };

  if (company) {
    components.supportTypes = new ConfigurableInputProps({
      inputConfig: new CheckboxListConfig({
        index: 1,
        name: "supportTypes",
        isRequired: true,
        modelValue: "supportTypes",
        classModifier: "half",
        tLabel: "companySupportTypes",
        list: [
          new SingleCheckbox({
            value: 1,
            label:
              "Mentoring that will help you grow and improve your business",
          }),
          new SingleCheckbox({
            value: 2,
            label:
              "Support in entering foreign markets/growing internationally",
          }),
        ],
        selectedOptions: company.supportTypes
          ? company.supportTypes.map((item) => {
              return { value: item.id, hasDescription: false };
            })
          : [],
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("supportTypes"),
      errorMsg: "Please select at least one option",
    });

    const isRegionExpanding = company.preferenceForRegionExpanding || false;

    components.preferenceForRegionExpanding = new ConfigurableInputProps({
      inputConfig: new YesNoRadioButtonConfig({
        name: "preferenceForRegionExpanding",
        index: 2,
        isRequired: true,
        modelValue: "preferenceForRegionExpanding",
        classModifier: "full",
        tLabel: "preferenceForRegionExpanding",
        initAnswer: isRegionExpanding,
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("preferenceForRegionExpanding"),
    });

    components.regions = new ConfigurableInputProps({
      inputConfig: new SelectInputConfig({
        index: 3,
        isRequired: true,
        placeholder: "Select all that apply",
        isDisabled: !isRegionExpanding,
        isMulti: true,
        name: "region",
        modelValue: "regions",
        classModifier: "half",
        tLabel: "regions",
        options: staticData?.regions?.map(({ id, name }) => {
          return { value: id, label: name };
        }),
        selectedMultiOptions: company.regions
          ? company.regions.sort().map((item) => {
              return { value: item.id, label: item.name };
            })
          : [],
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("region"),
      errorMsg: "Please select one option",
    });

    components.startup = new ConfigurableInputProps({
      inputConfig: new YesNoRadioButtonConfig({
        name: "startup",
        index: 4,
        modelValue: "startup",
        classModifier: "full",
        tLabel: "companyIsStartup",
        initAnswer: isStartup,
        isRequired: true,
      }),
      uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("startup"),
    });

    if (isStartup) {
      components.problemsThatServiceAddresses = new ConfigurableInputProps({
        inputConfig: new TextInputConfig({
          index: 5,
          isRequired: true,
          name: "problemsThatServiceAddresses",
          validations: [new TextValidation({ maxLength: 255 })],
          modelValue: "problemsThatServiceAddresses",
          classModifier: "full",
          tLabel: "problemsThatServiceAddresses",
          initValue: company.problemsThatServiceAddresses || "",
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("problemsThatServiceAddresses"),
        errorMsg: "Field is required",
      });

      components.innovativenessOfTheService = new ConfigurableInputProps({
        inputConfig: new TextInputConfig({
          index: 6,
          isRequired: true,
          name: "innovativenessOfTheService",
          validations: [new TextValidation({ maxLength: 255 })],
          modelValue: "innovativenessOfTheService",
          classModifier: "full",
          tLabel: "innovativenessOfTheService",
          initValue: company.innovativenessOfTheService || "",
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("innovativenessOfTheService"),
        errorMsg: "Field is required",
      });

      components.typeOfClientsTargeting = new ConfigurableInputProps({
        inputConfig: new TextInputConfig({
          index: 7,
          isRequired: true,
          name: "typeOfClientsTargeting",
          validations: [new TextValidation({ maxLength: 255 })],
          modelValue: "typeOfClientsTargeting",
          classModifier: "full",
          tLabel: "typeOfClientsTargeting",
          initValue: company.typeOfClientsTargeting || "",
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("typeOfClientsTargeting"),
        errorMsg: "Field is required",
      });

      components.developmentPhase = new ConfigurableInputProps({
        inputConfig: new SelectInputConfig({
          index: 8,
          isRequired: true,
          name: "developmentPhase",
          modelValue: "developmentPhase",
          classModifier: "full",
          tLabel: "developmentPhaseOfTheProduct",
          options: generalData.developmentPhases,
          selectedOption: handleSelectedOptionEnum(company.developmentPhase),
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("developmentPhase"),
        errorMsg: "Please select one option",
      });

      components.growthPhase = new ConfigurableInputProps({
        inputConfig: new SelectInputConfig({
          index: 9,
          isRequired: true,
          name: "growthPhase",
          modelValue: "growthPhase",
          classModifier: "full",
          tLabel: "growthPhaseOfTheCompany",
          options: generalData.growthPhases,
          selectedOption: handleSelectedOptionEnum(company.growthPhase),
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("growthPhase"),
        errorMsg: "Please select one option",
      });
    }

    components.expectationsFromExpertSupport = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 10,
        name: "expectationsFromExpertSupport",
        validations: [new TextValidation({ maxLength: 255 })],
        modelValue: "expectationsFromExpertSupport",
        classModifier: "full",
        tLabel: "expectationsFromExpertSupport",
        initValue: company.expectationsFromExpertSupport || "",
        isRequired: true,
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("expectationsFromExpertSupport"),
      errorMsg: "Field is required",
    });
  }

  const allComponents = [
    components.supportTypes,
    components.preferenceForRegionExpanding,
    components.regions,
    components.startup,
    components.expectationsFromExpertSupport,
  ];

  isStartup &&
    //this components should be shown after the fourth field
    allComponents.splice(
      4,
      0,
      components.problemsThatServiceAddresses,
      components.innovativenessOfTheService,
      components.typeOfClientsTargeting,
      components.developmentPhase,
      components.growthPhase,
    );

  return [...allComponents];
};
