import React, { useEffect, useState, useRef } from "react";
import { ITab } from "./definitions/tabs-spec";
import { IRootState } from "../../../utilities/root-reducer";
import compose from "recompose/compose";
import { connect, useSelector } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";
import {
  profileTabNavigation,
  TabNavigationEnum,
} from "../../../models/company/profile-tab-navigation-enum";
import {
  ConfigurableInputProps,
  TextInputEvent,
  NumberInputEvent,
  YesNoRadioButtonEvent,
  CheckboxSelectEvent,
  RadioButtonEvent,
  SelectInputEvent,
} from "../../formComponents/definitions/InputConfigurationSpec";
import { companyToExportsTabForm } from "../../../utils/mappers/company/companyToExportsTabForm";
import { ICompany } from "../../../models/company/company";
import { ConfigurableInput } from "../../formComponents/configurable-input";
import Form from "react-validation/build/form";
import { InputTypeEnum } from "../../../models/forms/input-types-enum";
import { generalData } from "../../../models/general/general-data";
import { generateUniqId } from "../../../utils/helpers/general-helper";

interface IExportsAndExportReadinessProps
  extends ITab,
    StateProps,
    DispatchProps {}

export function ExportsAndExportReadinessTab(
  props: IExportsAndExportReadinessProps,
) {
  const { t } = useTranslation("profile");
  const { data, active, onChange, validateTab, onTabValidation } = props;
  const [formInputs, setFormInputs] = useState<ConfigurableInputProps[]>([]);
  const formData = useRef<ICompany>({ ...data });
  const { companyState } = props;

  const { staticData } = useSelector((state: IRootState) => state.company);

  useEffect(() => {
    const formInpComponents = companyToExportsTabForm(
      data,
      staticData,
      generalData,
    );
    setFormInputs(formInpComponents);
    formData.current = { ...data };
  }, [data]);

  useEffect(() => {
    if (validateTab === profileTabNavigation[TabNavigationEnum.Exports]) {
      const isValid = validate();
      onTabValidation(validateTab, isValid);
    }
  }, [validateTab]);

  useEffect(() => {
    const formInpComponents = companyToExportsTabForm(
      data,
      staticData,
      generalData,
      true,
    );
    setFormInputs(formInpComponents);
  }, [companyState.cancelDataChanges]);

  /**
   * Validate the form for error
   */
  const validate = (): boolean => {
    let isFormValid = true;
    formInputs.forEach((input) => {
      // will trigger error message for error
      const inputIsValid = input.inputConfig.validate();
      if (!inputIsValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  /**
   * EVENTS SECTION
   * @param event
   */
  const handleTextInput = (event: TextInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current }, false);
  };

  const handleNumberInput = (event: NumberInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current }, false);
  };

  const handleYesNoEventInput = (event: YesNoRadioButtonEvent) => {
    formData.current[event.modelValue] = event.answer;
    onChange({ ...formData.current });
  };

  const handleCheckboxListEventInput = (event: CheckboxSelectEvent) => {
    const selectedData = event.data.map((item) => {
      return {
        appliedStandardId: item.value,
        hasDescription: item.hasDescription,
        description: item.description,
      };
    });
    formData.current[event.modelValue] = [...selectedData];
    onChange({ ...formData.current });
  };

  const handleRadioBtnListEventInput = (event: RadioButtonEvent) => {
    formData.current[event.modelValue] = event.selectedOption.value;
    onChange({ ...formData.current });
  };

  const handleSelectEventInput = (event: SelectInputEvent) => {
    formData.current[event.modelValue] = event.selectedMultiOptions.map(
      (item) => {
        return {
          id: item.value,
          name: item.label,
        };
      },
    );

    onChange({ ...formData.current });
  };

  const handleEvent = (component: ConfigurableInputProps) => {
    switch (component.inputConfig.inputType) {
      case InputTypeEnum.Number:
        component.onInputChange = handleNumberInput;
        break;
      case InputTypeEnum.Text:
        component.onInputChange = handleTextInput;
        break;
      case InputTypeEnum.YesNoRadioButton:
        component.onInputChange = handleYesNoEventInput;
        break;
      case InputTypeEnum.CheckboxList:
        component.onInputChange = handleCheckboxListEventInput;
        break;
      case InputTypeEnum.RadioButtonsList:
        component.onInputChange = handleRadioBtnListEventInput;
        break;
      case InputTypeEnum.Select:
        component.onInputChange = handleSelectEventInput;
        break;
    }
  };

  const renderComponents = () => {
    return (
      <>
        {formInputs.map((component, index) => {
          if (component) {
            handleEvent(component);
          }
          return (
            <>
              {component && (
                <ConfigurableInput
                  key={"comp-" + component.uniqueKey}
                  {...component}
                />
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {active === profileTabNavigation[TabNavigationEnum.Exports] && (
        <div className="profile-card-body">
          <Form className="profile-form-info">{renderComponents()}</Form>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  companyState: storeState.company,
});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("profile"),
  connect(mapStateToProps, mapDispatchToProps),
)(ExportsAndExportReadinessTab);
