import React from "react";

import { connect } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import { RouteComponentProps, withRouter, useParams } from "react-router-dom";
import SignupForm from "../../components/signupFormComponent/signup-form";
import ImageBanner from "../../components/bannerComponent/image-banner";
import LoginForm from "../../components/loginFormComponent/login-form";
import compose from "recompose/compose";
import ForgotPassword from "../../components/forgotPassword/forgot-password";
import ResetPassword from "../../components/resetPassword/reset-password";
import RegistrationConfirm from "../../components/registrationConfirm/registrationConfirm";
import { ErrorPage } from "../../components/errorPage/error-page";
import Copyright from "../../components/copyrightComponent/copyright";
import { HomePageHeader } from "../../components/homePage/components/homePageHeader/homePageHeader";

interface IAuthLayoutsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

export class AuthLayout extends React.Component<IAuthLayoutsProps> {
  render() {
    return (
      <>
        <HomePageHeader />

        <div className="auth-layout">
          {this.props.history.location.pathname === "/error" && <ErrorPage />}
          {this.props.history.location.pathname === "/login" && <LoginForm />}
          {this.props.history.location.pathname === "/signup" && <SignupForm />}
          {this.props.history.location.pathname === "/forgot-password" && (
            <ForgotPassword />
          )}
          {this.props.location.pathname === "/reset-password" && (
            <ResetPassword />
          )}
          {this.props.location.pathname === "/confirm" && (
            <RegistrationConfirm />
          )}
          <Copyright />
        </div>
      </>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthLayout);
