import React, { useState } from "react";
import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import Illustration from "../../../../assets/images/illustration-2.svg";
import TestimonialsIcon from "../../../../assets/images/testimonials/testimonialsIcon.svg";
import quoteIcon from "../../../../assets/images/testimonials/quoteIcon.svg";
import TestimonialImage from "../../../../assets/images/testimonials/testimonial-img.jpg";
import TestimonialImage2 from "../../../../assets/images/testimonials/testimonial-img-2.jpg";
import TestimonialImage3 from "../../../../assets/images/testimonials/testimonial-img-3.jpg";
import TestimonialImage4 from "../../../../assets/images/testimonials/testimonial-img-4.jpg";
import TestimonialImage5 from "../../../../assets/images/testimonials/testimonial-img-5.png";
import TestimonialImage6 from "../../../../assets/images/testimonials/testimonial-img-6.png";

import "./testimonials.scss";

const TestimonialData = [
  {
    id: 1,
    text:
    "Connect2MK is a valuable platform that helps small and medium businesses gain insights from industry experts on foreign markets. During my discussion with my mentor, I gained important knowledge about the US market and received direct advice on market entry and business growth. I look forward to our continued cooperation and am pleased to be part of this network.",
    name: "Ognen Kao",
    title: "Business Development",
    role: "Business Development",
    avatar: TestimonialImage6,
  },
  {
    id: 2,
    text:
      "Through the Connect2MK platform, we have the opportunity to collaborate with a consultant who, in regular sessions, helps us effectively identify both our strengths and areas for improvement. One of the key benefits for us is the development of action plans after each session, giving us a structured, continuous approach to improving our business and achieving our goals.",
    name: "Vladimir Mihailovski",
    title: "Chief Executive Officer",
    role: "Chief Executive Officer",
    avatar: TestimonialImage5,
  },
  {
    id: 3,
    text:
      "Living in times where knowledge and opportunities are widely distributed across industries and geographies, this is a unique   platform for much needed cross-fertilization of ideas and mutual learning for companies and professionals.",
    name: "Olimpija Zaevska, Connect2MK Expert (Denmark/Macedonia)",
    title: "CEO",
    role:
      "Ph.D. Fellow International Business & Government | Industry 4.0 | Green finance | International development ",
    avatar: TestimonialImage,
  },
  {
    id: 4,
    text:
      "It is instrumental and essential to work with someone who is from Macedonian heritage, who knows and understands the economic position of Macedonia in the  world and who can help our company on the path for growth.",
    name: "Aleksandra Murgoska",
    title: "CEO & Founder",
    role: "CEO & Founder Limitless Solutions DOOEL Skopje",
    avatar: TestimonialImage3,
  },
  {
    id: 5,
    text:
      "Connect2MK is the real thing in the mentorship process for the ones who want to be sure that the advice will come from the right place and be provided by real professionals and entrepreneurs, experts from the diaspora, and as expatriates from   around the world, they know the market inside-out, which can help us grow and prosper in an international environment.",
    name: "Aleksandar Celeski",
    title: "Executive Director",
    role: "Executive Director DUEL Consulting",
    avatar: TestimonialImage2,
  },
  {
    id: 6,
    text:
      "As a Connect2MK expert, I was given the opportunity to connect with a Macedonian company looking to grow and improve its operations. Watching the company grow and succeed has been incredibly rewarding, and I am grateful for the opportunity to make a positive impact in the Macedonian business community. ",
    name: "Aleksandar Orovchanec, Connect2MK Expert (Canada)",
    title: "COO",
    role:
      "P.Eng., MBA 1st degree Learning & Development | Compliance & Product Safety",
    avatar: TestimonialImage4,
  },
];

const Testimonial = ({ testimonial }) => (
  <Paper className="testimonial-card">
    <img src={TestimonialsIcon} className="testimonial-icon" alt="" />
    <Typography variant="body2" className="testimonial-paragraph">
      {testimonial.text}
    </Typography>
    <Box display="flex" alignItems="center">
      <img
        className="testimonial-img"
        src={testimonial.avatar}
        alt={testimonial.name}
      />
      <Box>
        <Typography className="testimonial-name">{testimonial.name}</Typography>
        <Typography className="testimonial-role" variant="body2">
          {testimonial.role}
        </Typography>
      </Box>
    </Box>
  </Paper>
);

export const Testimonials = () => {
  const testimonialsPerPage = 2;

  const [selectedTestimonials, setSelectedTestimonials] = useState(
    TestimonialData.slice(0, testimonialsPerPage),
  );

  const [activeDotIndex, setActiveDotIndex] = useState(0);

  const handleDotClick = (selectedDotIndex) => {
    setSelectedTestimonials(
      TestimonialData.slice(
        selectedDotIndex * testimonialsPerPage,
        selectedDotIndex * testimonialsPerPage + testimonialsPerPage,
      ),
    );
    setActiveDotIndex(selectedDotIndex);
  };

  return (
    <Box className="testimonials-section">
      <Box className="quote-wrapper">
        <img className="quote-icon" src={quoteIcon} alt="" />
        <Typography className="quote-heading">
          “I am proud that through Connect2MK, Macedonia2025 unleashes the power
          of unity, using the brilliance of Macedonian minds to propel the
          excellence of Macedonian businesses into the global arena." <br />
          <br /> - John Bitove Jr.
        </Typography>
        <img
          className="quote-illustration"
          src={Illustration}
          alt="SVG Image"
        />
      </Box>
      <Box className="testimonials-wrapper">
        <Box className="testimonial-cards">
          {selectedTestimonials.map((testimonial, index) => (
            <Box key={index} className="testimonial-box">
              <Testimonial testimonial={testimonial} />
            </Box>
          ))}
        </Box>
        <Box className="navigation-dots">
          {Array(Math.ceil(TestimonialData.length / testimonialsPerPage))
            .fill(undefined)
            .map((_, dotIndex) => (
              <IconButton
                key={dotIndex}
                onClick={() => handleDotClick(dotIndex)}
                className={dotIndex === activeDotIndex ? "active" : ""}
              >
                <div className="navigation-dot" />
              </IconButton>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
