import React from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";

import "../../expertProfile/Modals/expert-modal.scss";

interface IOldUsersProps {
  open: boolean;
  onClose: () => void;
  content: string;
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default function OldUsersModal(props: IOldUsersProps) {
  const { open, onClose, content } = props;
  const { t } = useTranslation("profile");

  return (
    <ReactModal
      isOpen={open}
      role={"dialog"}
      shouldCloseOnEsc={false}
      style={modalStyle}
      className="responsive-modal-container"
    >
      <div className={"delete-modal-content"}>
        <p>{content}</p>
        <br></br>
        <div className={"control-button-container"}>
          <button onClick={onClose}>{t("expert-company-ok")}</button>
        </div>
      </div>
    </ReactModal>
  );
}
