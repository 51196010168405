import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { useTranslation } from "react-i18next";
import Form from "react-validation/build/form";

import "./../../loginFormComponent/auth.scss";
import "./../forgot-password.scss";
import AuthBackground from "../../authBgComponent/authBackground";
import { Paper, Typography } from "@material-ui/core";

const ForgotPasswordSubmit = ({ emailWrittenByUser }) => {
  const { t } = useTranslation("forgot-password");

  const { errorMessage, loading, status, emailSubmitSuccess } = useSelector(
    (state: IRootState) => state.forgotPassword,
  );

  return (
    <div className="auth-form-wrapper">
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("emailSentTitle")}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("checkEmailMessage")}
        </Typography>
      </AuthBackground>
      <Paper
        elevation={1}
        className="auth-form-container forgot-password-form-container"
      >
        <Form className="auth-form">
          <div className="fp-envelope"></div>
          <span className="auth-text-container email-success-link">
            {t("emailSentMessage")}
            <span>
              <b>{emailWrittenByUser}</b>
            </span>
          </span>
        </Form>
      </Paper>
    </div>
  );
};

export default ForgotPasswordSubmit;
