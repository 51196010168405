import { IExpert } from "./../../../models/expert/expert";
import {
  ConfigurableInputProps,
  SelectInputConfig,
  TextInputConfig,
} from "./../../../components/formComponents/definitions/InputConfigurationSpec";
import { TextValidation } from "../../../components/formComponents/definitions/validation-spec";
import CryptoJS from "crypto-js";
import { generateUniqId } from "../../helpers/general-helper";

export const expertsToGeneralTabForm = (
  expert: IExpert,
  staticData: any,
  hasUniqueKey?: boolean,
): ConfigurableInputProps[] => {
  // Definition
  const components = {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    country: null,
    jobTitle: null,
    email: null,
    repeatEmail: null,
    companyName: null,
    linkedinProfile: null,
    fieldsOfInterest: null,
  };

  components.firstName = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 1,
      name: "firstName",
      validations: [new TextValidation({ maxLength: 100 })],
      isRequired: true,
      modelValue: "firstName",
      classModifier: "half",
      tLabel: "expert_first_name",
      initValue: expert.firstName || "",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("firstName"),
    errorMsg: "Field is required",
  });
  components.lastName = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 2,
      name: "lastName",
      validations: [new TextValidation({ maxLength: 100 })],
      isRequired: true,
      modelValue: "lastName",
      classModifier: "half",
      tLabel: "expert_last_name",
      initValue: expert.lastName || "",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("lastName"),
    errorMsg: "Field is required",
  });

  components.email = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 3,
      validations: [new TextValidation({ isEmail: true, maxLength: 50 })],
      name: "email",
      modelValue: "email",
      isRequired: true,
      classModifier: "half",
      tLabel: "expert_email",
      initValue: expert.email || "",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("email"),
    errorMsg: "Field is required",
  });
  components.repeatEmail = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 4,
      validations: [
        new TextValidation({
          areEmailsEqual: expert.email?.trim() !== expert.repeatEmail?.trim(),
        }),
      ],
      name: "repeatEmail",
      modelValue: "repeatEmail",
      isRequired: true,
      classModifier: "half",
      tLabel: "expert_repeat_email",
      initValue: expert.repeatEmail || "",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("repeatEmail"),
    errorMsg: "Field is required",
  });

  components.phoneNumber = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 5,
      name: "phoneNumber",
      validations: [new TextValidation({ isPhoneNumber: true })],
      modelValue: "phoneNumber",
      isRequired: true,
      classModifier: "half",
      tLabel: "expert_phone_number",
      initValue: expert.phoneNumber || "+",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("phoneNumber"),
    errorMsg: "Field is required",
  });

  components.jobTitle = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 6,
      name: "jobTitle",
      validations: [new TextValidation({ maxLength: 200 })],
      modelValue: "jobTitle",
      isRequired: true,
      classModifier: "half",
      tLabel: "expert_job_title",
      initValue: expert.jobTitle || "",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("jobTitle"),
    errorMsg: "Field is required",
  });

  components.companyName = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 7,
      name: "companyName",
      modelValue: "companyName",
      validations: [new TextValidation({ maxLength: 200 })],
      isRequired: true,
      classModifier: "half",
      tLabel: "expert_company_name",
      initValue: expert.companyName || "",
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("companyName"),
    errorMsg: "Field is required",
  });

  components.country = new ConfigurableInputProps({
    inputConfig: new SelectInputConfig({
      index: 8,
      name: "country",
      isRequired: true,
      modelValue: "country",
      classModifier: "half",
      tLabel: "expert_country",
      options: staticData?.countries?.map(({ id, name }) => {
        return { value: id, label: name };
      }),
      selectedOption: expert.country && {
        value: expert.country.id,
        label: expert.country.name,
      },
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("countryId"),
    errorMsg: "Field is required",
  });

  components.linkedinProfile = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 9,
      name: "linkedinProfile",
      modelValue: "linkedinProfile",
      validations: [new TextValidation({ isLinkedIn: true, maxLength: 200 })],
      classModifier: "half",
      tLabel: "expert_linkedin_url",
      initValue: expert.linkedinProfile || "",
    }),
    uniqueKey: hasUniqueKey
      ? generateUniqId()
      : CryptoJS.MD5("linkedinProfile"),
  });

  components.fieldsOfInterest = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 10,
      name: "fieldsOfInterest",
      validations: [new TextValidation({ maxLength: 100 })],
      modelValue: "fieldsOfInterest",
      classModifier: "half",
      tLabel: "expert_fields_of_interest",
      initValue: expert.fieldsOfInterest || "",
    }),
    uniqueKey: hasUniqueKey
      ? generateUniqId()
      : CryptoJS.MD5("fieldsOfInterest"),
  });

  const allComponents = [
    components.firstName,
    components.lastName,
    components.email,
    components.repeatEmail,
    components.phoneNumber,
    components.jobTitle,
    components.companyName,
    components.country,
    components.linkedinProfile,
    components.fieldsOfInterest,
  ];

  return [...allComponents];
};

export const expertsToGeneralTabView = (expert: IExpert, t: Function): any => {
  return [
    {
      name: t("expert_job_title"),
      value: expert.jobTitle,
    },
    {
      name: t("expert_company_name"),
      value: expert.companyName,
    },
    { name: t("expert_country"), value: expert.country.id },

    {
      name: t("expert_phone_number"),
      value: expert.phoneNumber,
    },
    {
      name: t("expert_linkedin_url"),
      value: expert.linkedinProfile,
    },
    {
      name: t("expert_fields_of_interest"),
      value: expert.fieldsOfInterest,
    },
  ];
};
