import { IExpert } from "../../../models/expert/expert";
import {
  ConfigurableInputProps,
  SelectInputConfig,
  YesNoRadioButtonConfig,
  CheckboxListConfig,
  SingleCheckbox,
  RadioButtonsListConfig,
} from "../../../components/formComponents/definitions/InputConfigurationSpec";
import { TextInputConfig } from "./../../../components/formComponents/definitions/InputConfigurationSpec";
import { TextValidation } from "../../../components/formComponents/definitions/validation-spec";
import { IGeneralData } from "../../../models/general/general-data";
import CryptoJS from "crypto-js";
import { generateUniqId } from "../../helpers/general-helper";

export const ExpertsToExportsTabForm = (
  expert: IExpert,
  staticData: any,
  generalData: IGeneralData,
  hasUniqueKey?: boolean,
): ConfigurableInputProps[] => {
  const otherServiceActivities = expert.industries?.some((el) => el.id === 18);
  const otherExpertise = expert.expertise?.some((el) => el.id === 20);

  // Definition
  const components = {
    industries: null,
    expertise: null,
    supportTypes: null,
    otherServiceActivities: otherServiceActivities ? null : undefined,
    otherExpertise: otherExpertise ? null : undefined,
    regions: null,
    workWithStartups: null,
    numberOfCompaniesToWorkWith: null,
    expectationsFromThePlatform: null,
  };

  components.supportTypes = new ConfigurableInputProps({
    inputConfig: new CheckboxListConfig({
      index: 1,
      isRequired: true,
      name: "supportTypes",
      modelValue: "supportTypes",
      classModifier: "full",
      tLabel: "supportTypes",
      list: [
        new SingleCheckbox({
          value: 1,
          label: "Mentoring businesses to improve and grow",
        }),
        new SingleCheckbox({
          value: 2,
          label:
            "Supporting businesses in entering foreign markets/growing internationally",
        }),
      ],
      selectedOptions: expert.supportTypes
        ? expert.supportTypes.map((item) => {
            return { value: item.id, hasDescription: false };
          })
        : [],
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("supportTypes"),
    errorMsg: "Please select at least one option",
  });

  components.industries = new ConfigurableInputProps({
    inputConfig: new SelectInputConfig({
      index: 2,
      isRequired: true,
      isDisabled: false,
      isMulti: true,
      placeholder: "Select all that apply",
      name: "industries",
      modelValue: "industries",
      classModifier: "full",
      tLabel: "expert_industries",
      options: staticData?.industries?.map(({ id, name }) => {
        return { value: id, label: name };
      }),
      selectedMultiOptions: expert.industries
        ? expert.industries.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [],
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("industries"),
    errorMsg: "Please select at least one industry",
  });

  if (otherServiceActivities) {
    components.otherServiceActivities = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 3,
        name: "otherServiceActivities",
        validations: [new TextValidation({ maxLength: 255 })],
        modelValue: "otherServiceActivities",
        isRequired: true,
        classModifier: "full",
        tLabel: "please_specify_which_activities",
        initValue: expert.otherServiceActivities || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("otherServiceActivities"),
      errorMsg: "Field is required",
    });
  }

  components.expertise = new ConfigurableInputProps({
    inputConfig: new SelectInputConfig({
      index: 4,
      isRequired: true,
      isDisabled: false,
      isMulti: true,
      placeholder: "Select all that apply",
      name: "expertise",
      modelValue: "expertise",
      classModifier: "full",
      // maxSelectedOptions: generalData.expertise.length,
      tLabel: "expert-expertise",
      options: staticData?.expertise?.map(({ id, name }) => {
        return { value: id, label: name };
      }),
      selectedMultiOptions: expert.expertise
        ? expert.expertise.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [],
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("expertise"),
    errorMsg: "Please select at least one area of expertise",
  });

  if (otherExpertise) {
    components.otherExpertise = new ConfigurableInputProps({
      inputConfig: new TextInputConfig({
        index: 5,
        name: "otherExpertise",
        validations: [new TextValidation({ maxLength: 255 })],
        modelValue: "otherExpertise",
        isRequired: true,
        classModifier: "full",
        tLabel: "please_specify_expertise",
        initValue: expert.otherExpertise || "",
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("otherExpertise"),
      errorMsg: "Field is required",
    });
  }

  components.regions = new ConfigurableInputProps({
    inputConfig: new SelectInputConfig({
      index: 6,
      isRequired: true,
      isMulti: true,
      placeholder: "Select all that apply",
      name: "regions",
      modelValue: "regions",
      classModifier: "full",
      tLabel: "expert_regions",
      options: staticData?.regions?.map(({ id, name }) => {
        return { value: id, label: name };
      }),
      selectedMultiOptions: expert.regions
        ? expert.regions.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [],
    }),
    uniqueKey: hasUniqueKey ? generateUniqId() : CryptoJS.MD5("region"),
    errorMsg: "Field is required",
  });

  components.workWithStartups = new ConfigurableInputProps({
    inputConfig: new YesNoRadioButtonConfig({
      index: 7,
      isRequired: true,
      name: "workWithStartups",
      modelValue: "workWithStartups",
      classModifier: "full",
      tLabel: "work_with_startups",
      initAnswer: expert.workWithStartups,
    }),
    errorMsg: "Please select one option",
    uniqueKey: hasUniqueKey
      ? generateUniqId()
      : CryptoJS.MD5("workWithStartups"),
  });

  components.numberOfCompaniesToWorkWith = new ConfigurableInputProps({
    inputConfig: new RadioButtonsListConfig({
      index: 8,
      isRequired: true,
      name: "numberOfCompaniesToWorkWith",
      modelValue: "numberOfCompanies",
      classModifier: "full",
      tLabel: "companies_to_work_with",
      list: generalData.numberOfCompaniesToWorkWith,
      selectedOption: {
        value: expert.numberOfCompanies || null,
        label: "",
      },
    }),
    uniqueKey: hasUniqueKey
      ? generateUniqId()
      : CryptoJS.MD5("numberOfCompaniesToWorkWith"),
    errorMsg: "Please select one option",
  });

  components.expectationsFromThePlatform = new ConfigurableInputProps({
    inputConfig: new TextInputConfig({
      index: 9,
      name: "expectationsFromThePlatform",
      validations: [new TextValidation({ maxLength: 255 })],
      modelValue: "expectationsFromThePlatform",
      classModifier: "full",
      tLabel: "expert_expectations_from_engagement",
      initValue: expert.expectationsFromThePlatform || "",
    }),
    uniqueKey: hasUniqueKey
      ? generateUniqId()
      : CryptoJS.MD5("expectationsFromThePlatform"),
  });

  const allComponents = [
    components.supportTypes,
    components.industries,
    components.expertise,
    components.regions,
    components.numberOfCompaniesToWorkWith,
    components.workWithStartups,
    components.expectationsFromThePlatform,
  ];

  otherServiceActivities &&
    //otherServiceActivities component should be shown after the second field
    allComponents.splice(2, 0, components.otherServiceActivities);

  otherExpertise &&
    otherServiceActivities &&
    allComponents.splice(4, 0, components.otherExpertise);
  otherExpertise &&
    !otherServiceActivities &&
    allComponents.splice(3, 0, components.otherExpertise);

  return [...allComponents];
};
