import React from "react";
import { connect } from "react-redux";
import "./landing-page.scss";
import { IRootState } from "../../utilities/root-reducer";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";

interface ILandingPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  t: any;
}

export class LandingPage extends React.Component<ILandingPageProps> {
  render() {
    const { t } = this.props;
    return (
      <div className="landing-page-navigation-bar">
        <img
          src="/images/img-landing-page.png"
          className="image-landing-page"
          alt="mk2025"
        />

        <div className="lading-page-conteinter">
          {/*<Collapse className="landing-page-card">*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}
          {/*      <h1 className="lading-page-title">*/}
          {/*        {t("landingPageCompanyTitle")}*/}
          {/*      </h1>*/}
          {/*      <hr className="landing-page-line"></hr>*/}
          {/*      <p className="landing-page-paragraph">*/}
          {/*        {t("landingPageCompanyText")}*/}
          {/*      </p>*/}
          {/*      <button*/}
          {/*        onClick={() => this.props.history.push("/company")}*/}
          {/*        className="lending-create-profile-button"*/}
          {/*      >*/}
          {/*        {t("landingPageCompanyButton")}*/}
          {/*      </button>*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Collapse>*/}
        </div>
      </div>

      // Epert landig page

      //   <div className="lading-page-conteinter">
      //     <Collapse className="landing-page-card">
      //       <Card>
      //         <CardBody>
      //           <h1 className="lading-page-title">
      //             {" "}
      //             {t("landingPageExpertTitle")}{" "}
      //           </h1>
      //           <hr className="landing-page-line"></hr>
      //           <p className="landing-page-paragraph">
      //             {t("landingPageExpertText")}{" "}
      //           </p>
      //           <button
      //             onClick={() => this.props.history.push("/expert")}
      //             className="lending-create-profile-button"
      //           >
      //             {t("landingPageExpertButton")}
      //           </button>
      //         </CardBody>
      //       </Card>
      //     </Collapse>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  withTranslation("landing-page-company-expert"),
  connect(mapStateToProps, mapDispatchToProps),
)(LandingPage);
