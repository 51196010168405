import { ICompany } from "../../../models/company/company";
import {
  ICompanyRequest,
  CompanyRequest,
} from "../../../models/company/company-request";

export const mapToCompanyRequest = (company: ICompany): ICompanyRequest => {
  const companyRequest = new CompanyRequest();

  companyRequest.id = company.id;
  companyRequest.cognitoId = company.cognitoId;
  companyRequest.contactFirstName = company.contactFirstName;
  companyRequest.contactLastName = company.contactLastName;
  companyRequest.contactEmail = company.contactEmail;
  companyRequest.contactPhoneNumber = company.contactPhoneNumber;
  companyRequest.documents = company.documents;
  companyRequest.documentUrl = company.documentUrl;
  companyRequest.export = company.export || false;
  companyRequest.exportRegions = company.export
    ? company.exportRegions.map((item) => {
        return { id: item.id };
      })
    : [];
  companyRequest.appliedStandards = company.serviceQualityAssuranceSystem
    ? company.appliedStandards.map((item) => {
        return item.description
          ? {
              appliedStandardId: item.appliedStandardId,
              description: item.description,
            }
          : {
              appliedStandardId: item.appliedStandardId,
            };
      })
    : [];
  companyRequest.hasDocument = company.hasDocument;
  //replace all occurrences of dots (thousands separator) with an empty string and tranform it to number
  companyRequest.incomePerYear = parseInt(
    company.incomePerYear?.toString().replaceAll(".", ""),
  );
  companyRequest.increaseDifficultType = company.increaseDifficultType;
  companyRequest.industries = company.industries
    ? company.otherServiceActivities === undefined
      ? company.industries.map((industry) => {
          return { name: industry.name };
        })
      : [
          ...company.industries
            .filter((item) => item.id !== 18)
            .map((industry) => {
              return { name: industry.name };
            }),
          { name: company.otherServiceActivities },
        ]
    : [];
  companyRequest.expertise = company.expertise
    ? company.otherExpertise === undefined
      ? company.expertise.map((expertise) => {
          return { name: expertise.name };
        })
      : [
          ...company.expertise
            .filter((item) => item.id !== 20)
            .map((expertise) => {
              return { name: expertise.name };
            }),
          { name: company.otherExpertise },
        ]
    : [];
  companyRequest.location = { id: company.location ? company.location.id : 0 };
  companyRequest.address = company.address;
  companyRequest.postalCodeAndCity = company.postalCodeAndCity;
  companyRequest.name = company.name;
  companyRequest.companySize = company.companySize;
  companyRequest.percentageProduction = company.export
    ? 0
    : company.percentageProduction;
  companyRequest.percentageOfExport = company.export
    ? company.percentageOfExport
    : 0;
  companyRequest.companySize = company.companySize;
  companyRequest.companyEmail = company.companyEmail;
  companyRequest.repeatCompanyEmail = company.repeatCompanyEmail;
  companyRequest.preferenceForRegionExpanding =
    company.preferenceForRegionExpanding || false;
  companyRequest.regions = company.preferenceForRegionExpanding
    ? company.regions.map((item) => {
        return { id: item.id };
      })
    : [];
  companyRequest.serviceQualityAssuranceSystem =
    company.serviceQualityAssuranceSystem || false;
  companyRequest.supportTypes = company.supportTypes.map((item) => {
    return { id: item.id };
  });
  companyRequest.technologyEquipmentId = company.technologyEquipmentId;
  companyRequest.typeServicesProvided = company.typeServicesProvided;
  companyRequest.webLink = company.webLink;
  companyRequest.yearsOfOperation = company.yearsOfOperation;
  companyRequest.startup = company.startup || false;
  companyRequest.problemsThatServiceAddresses = company.startup
    ? company.problemsThatServiceAddresses
    : null;
  companyRequest.innovativenessOfTheService = company.startup
    ? company.innovativenessOfTheService
    : null;
  companyRequest.typeOfClientsTargeting = company.startup
    ? company.typeOfClientsTargeting
    : null;
  companyRequest.developmentPhase = company.startup
    ? company.developmentPhase
    : null;
  companyRequest.growthPhase = company.startup ? company.growthPhase : null;
  companyRequest.expectationsFromExpertSupport =
    company.expectationsFromExpertSupport;
  companyRequest.hasDocument = company.hasDocument;

  return companyRequest;
};
