import axios from "axios";
import {
  EXPERT_GET_DATA,
  EXPERT_GET_STATIC_DATA,
  EXPERT_UPDATE,
  EXPERT_DELETE,
  EXPERT_CANCEL_CHANGES,
  EXPERT_DELETE_CLEANUP,
  EXPERT_DATA_CLEANUP,
  EXPERT_NEW,
  UPDATE_EXPERT_DATA,
  EXPERT_FILE_UPLOAD,
  EXPERT_ALL_DOCUMENTS_DOWNLOAD,
  EXPERT_DOCUMENT_UPLOAD,
  EXPERT_DOCUMENT_UPLOAD_SAVE,
  EXPERT_DOCUMENT_DELETE,
} from "./expert-profile-types";
import { IExpert } from "../../models/expert/expert";
import { getCognitoId } from "../../utilities/auth";
import { mapToExpertRequest } from "../../utils/mappers/experts/mapToExpertRequest";

export const getExpert = () => {
  return {
    type: EXPERT_GET_DATA,
    payload: axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/expert?cognitoId=${getCognitoId()}`,
    ),
  };
};

export const getExpertStaticData = () => {
  return {
    type: EXPERT_GET_STATIC_DATA,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/expert/static-data`,
    ),
  };
};

export const updateExpertStateData = (expert: IExpert) => {
  return {
    type: UPDATE_EXPERT_DATA,
    payload: { ...expert },
  };
};

export const newExpert = (expert: IExpert) => {
  expert.cognitoId = getCognitoId();
  const requestModel = mapToExpertRequest(expert);
  return {
    type: EXPERT_NEW,
    payload: axios.post(`${process.env.REACT_APP_API_BASE_URL}/expert/`, {
      ...requestModel,
    }),
  };
};

export const updateExpert = (expert: IExpert) => {
  expert.cognitoId = getCognitoId();
  const requestModel = mapToExpertRequest(expert);
  return {
    type: EXPERT_UPDATE,
    payload: axios.put(`${process.env.REACT_APP_API_BASE_URL}/expert/update/`, {
      ...requestModel,
    }),
  };
};

export const cancelExpertChanges = () => {
  return {
    type: EXPERT_CANCEL_CHANGES,
  };
};

/**
 * Clean export data to empty value
 */
export const cleanupExpert = () => {
  return {
    type: EXPERT_DATA_CLEANUP,
  };
};

// DOCUMENT UPLOAD

export const uploadFile = (files) => {
  return {
    type: EXPERT_FILE_UPLOAD,
    payload: files,
  };
};

// Document Downloads

export const getExpertDocuments = () => {
  const cognitoId = getCognitoId();

  return {
    type: EXPERT_ALL_DOCUMENTS_DOWNLOAD,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/download?cognitoId=${cognitoId}`,
    ),
  };
};

// na /uploads/${fileName} dava CORS Missing Allow Origin
export const getExpertDocument = (fileName) => {
  axios
    .get(`https://mk2025.clients.intertec.io/uploads/${fileName}`)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("id", fileName);

      document.body.appendChild(link);
      link.click();
      document.getElementById(fileName).remove();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const uploadExpertDocument = (formData) => {
  const cognitoId = getCognitoId();

  return {
    type: EXPERT_DOCUMENT_UPLOAD,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/uploads?cognitoId=${cognitoId}`,
      formData,
    ),
  };
};

export const uploadExpertDocumentOnSave = () => {
  return {
    type: EXPERT_DOCUMENT_UPLOAD_SAVE,
  };
};

export const deleteExpertDocument = (cognitoId: string, fileName: string) => {
  return {
    type: EXPERT_DOCUMENT_DELETE,
    payload: axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/delete?cognitoId=${cognitoId}&fileName=${fileName}`,
    ),
  };
};
