import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { withTranslation } from "react-i18next";
import Form from "react-validation/build/form";
import compose from "recompose/compose";
import { ITab } from "./definitions/tabs-spec";
import {
  profileTabNavigation,
  TabNavigationEnum,
} from "../../../models/company/profile-tab-navigation-enum";
import { ConfigurableInput } from "../../formComponents/configurable-input";
import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  TextInputEvent,
} from "../../formComponents/definitions/InputConfigurationSpec";
import { companyToContactPersonForm } from "../../../utils/mappers/company/companyToContactPersonForm";
import { InputTypeEnum } from "../../../models/forms/input-types-enum";

interface IContactPersonInfoTab extends ITab, StateProps, DispatchProps {}

const ContactPersonTab = (props: IContactPersonInfoTab) => {
  const { companyState } = props;
  const { data, active, onChange, validateTab, onTabValidation } = props;

  const [formInputs, setFormInputs] = useState<ConfigurableInputProps[]>([]);
  const formData = useRef<ICompany>({ ...data });

  useEffect(() => {
    const formInpComponents = companyToContactPersonForm(data);
    setFormInputs(formInpComponents);
    formData.current = { ...data };
  }, [data]);

  useEffect(() => {
    if (validateTab === profileTabNavigation[TabNavigationEnum.ContactPerson]) {
      const isValid = validate();
      onTabValidation(validateTab, isValid);
    }
  }, [validateTab]);

  useEffect(() => {
    const formInpComponents = companyToContactPersonForm(data, true);
    setFormInputs(formInpComponents);
  }, [companyState.cancelDataChanges]);

  /**
   * Validate the form for error
   */
  const validate = (): boolean => {
    let isFormValid = true;
    formInputs.forEach((input) => {
      // will trigger error message for error
      const inputIsValid = input.inputConfig.validate();
      if (!inputIsValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  /**
   * EVENTS SECTION
   * @param event
   */
  const handleTextInput = (event: TextInputEvent) => {
    formData.current[event.modelValue] = event.value;
    onChange({ ...formData.current });
  };

  const handleEvent = (component: ConfigurableInputProps) => {
    switch (component.inputConfig.inputType) {
      case InputTypeEnum.Text:
        component.onInputChange = handleTextInput;
        break;
    }
  };

  const renderComponents = () => {
    return (
      <>
        {formInputs.map((component) => {
          if (component) {
            handleEvent(component);
          }
          return (
            <>
              {component && (
                <ConfigurableInput
                  key={"comp-" + component.uniqueKey}
                  {...component}
                />
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {active === profileTabNavigation[TabNavigationEnum.ContactPerson] && (
        <div className="profile-card-body">
          <Form className="profile-form-info">{renderComponents()}</Form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  companyState: storeState.company,
});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("profile"),
  connect(mapStateToProps, mapDispatchToProps),
)(ContactPersonTab);
