import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { handleNavigationSignUp } from "../../homePage";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Link,
  IconButton,
} from "@material-ui/core";
import Illustration from "../../../../assets/images/illustration-2.svg";
import CheckIcon from "../../../../assets/images/check-circle-broken.svg";
import ArrowIcon from "../../../../assets/images/arrow-narrow-right.svg";
import "./whoCanJoin.scss";

export const WhoCanJoin = () => {
  const { t } = useTranslation("homepage");

  const history = useHistory();

  return (
    <Box className="who-can-join-wrapper">
      <Box className="box-background">
        <Typography className="who-can-join-heading" variant="h2">
          Who can join?
        </Typography>
        <img
          className="who-can-join-illustration"
          src={Illustration}
          alt="SVG Image"
        />
      </Box>
      <Box className="papper-wrapper">
        <Paper elevation={1} className="who-can-join-papper">
          <Typography variant="h3">Macedonian companies</Typography>
          <Typography className="who-can-join-subheading" variant="h4">
            Seeking support
          </Typography>
          <Typography className="who-can-join-paragraph" variant="body2">
            We welcome business owners, entrepreneurs or professionals who are
            based in Macedonia and are looking for business support. We're open
            to businesses in any sector and of any size - from startups to SMEs
            and large corporations.
          </Typography>
          <List className="list-items-wrapper">
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">Based in Macedonia</Typography>
            </ListItem>
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Aiming to grow your business or make connections on its behalf
              </Typography>
            </ListItem>
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Seeking new international opportunities and market support
              </Typography>
            </ListItem>
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Open to fresh perspectives and willing to listen
              </Typography>
            </ListItem>
          </List>
          <Typography className="who-can-join-paragraph-bottom" variant="body2">
            Join our network and get advice, one-to-one support, and network
            with our experts from all around the world.
          </Typography>
          <Link
            className="who-can-join-link"
            variant="body2"
            onClick={() => handleNavigationSignUp(history, "company")}
          >
            Register company
            <IconButton className="who-can-join-icon">
              <img src={ArrowIcon} alt="" />
            </IconButton>
          </Link>
        </Paper>
        <Paper elevation={1} className="who-can-join-papper">
          <Typography variant="h3">Worldwide business experts</Typography>
          <Typography className="who-can-join-subheading" variant="h4">
            Offering support
          </Typography>
          <Typography className="who-can-join-paragraph" variant="body2">
            We welcome passionate and internationally experienced business
            professionals from Macedonia and from around the world. These
            professionals use their skills, experience and connections to
            support Macedonian businesses.
          </Typography>
          <List className="list-items-wrapper">
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Internationally focused and experienced in key sectors for
                Macedonia
              </Typography>
            </ListItem>
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Successful and inspirational, invested in Macedonia's global
                success
              </Typography>
            </ListItem>
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Open to sharing knowledge, opportunities and contacts
              </Typography>
            </ListItem>
            <ListItem className="list-item">
              <ListItemIcon>
                <img src={CheckIcon} alt="" />
              </ListItemIcon>
              <Typography variant="body2">
                Aware of the altruistic nature of the role
              </Typography>
            </ListItem>
          </List>
          <Typography className="who-can-join-paragraph-bottom" variant="body2">
            Become one of Macedonia's business ambassadors and join a worldwide
            network of passionate entrepreneurs and leaders.
          </Typography>
          <Link
            className="who-can-join-link"
            variant="body2"
            onClick={() => handleNavigationSignUp(history, "expert")}
          >
            Register expert
            <IconButton className="who-can-join-icon">
              <img src={ArrowIcon} alt="" />
            </IconButton>
          </Link>
        </Paper>
      </Box>
    </Box>
  );
};
