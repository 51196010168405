import React from "react";
import { useSelector } from "react-redux";

import "./company-document-upload.scss";
import { IRootState } from "../../../utilities/root-reducer";

import { DocumentUploadComponent } from "./components/document-upload-component";
import { DocumentUploadSuccess } from "./components/document-upload-success";
import Spinner from "../../common/spinner/spinner";

export const CompanyDocumentUpload = (props) => {
  const { documentLoading, hasDocument } = useSelector(
    (state: IRootState) => state.company,
  );

  const {
    t,
    onChange,
    formWorkMode,
    data,
    active,
    onNewDocumentUploadSuccess,
    changeDeletePress,
    setFormWorkMode,
  } = props;

  return (
    <>
      {documentLoading && <Spinner />}
      {hasDocument ? (
        <DocumentUploadSuccess
          changeDeletePress={changeDeletePress}
          formWorkMode={formWorkMode}
          onUploadSelection={() => onChange()}
          setFormWorkMode={setFormWorkMode}
        />
      ) : (
        <>
          <h4 className="document-info-text">{t("companyDocument")}</h4>
          <h4 className="document-info-subtext">{t("companyDocumentSize")}</h4>
          <DocumentUploadComponent
            formWorkMode={formWorkMode}
            onUploadSelection={() => onChange()}
            data={data}
            active={active}
            onNewDocumentUploadSuccess={onNewDocumentUploadSuccess}
            setFormWorkMode={setFormWorkMode}
          />
        </>
      )}
    </>
  );
};
