import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { DocumentUploadComponent } from "./components/document-upload-component";
import { DocumentUploadSuccess } from "./components/document-upload-success";
import Spinner from "../../common/spinner/spinner";

import "./expert-document-upload.scss";

export const ExpertDocumentUpload = (props) => {
  const { documentLoading, hasDocument } = useSelector(
    (state: IRootState) => state.expert,
  );

  const {
    t,
    onChange,
    formWorkMode,
    data,
    active,
    onNewDocumentUploadSuccess,
    showDocumentUploadRequired,
    setShowDocumentUploadRequired,
    setFormWorkMode,
  } = props;

  return (
    <>
      {documentLoading && <Spinner />}
      {hasDocument ? (
        <DocumentUploadSuccess
          formWorkMode={formWorkMode}
          onUploadSelection={() => onChange()}
          setShowDocumentUploadRequired={setShowDocumentUploadRequired}
          showDocumentUploadRequired={showDocumentUploadRequired}
          setFormWorkMode={setFormWorkMode}
        />
      ) : (
        <>
          <h4 className="document-info-text">
            {t("expert-document-upload-title")}
          </h4>
          <h5 className="document-info-subtext">
            {t("expert-document-upload-subtitle")}
          </h5>
          <DocumentUploadComponent
            formWorkMode={formWorkMode}
            onUploadSelection={() => onChange()}
            data={data}
            active={active}
            onNewDocumentUploadSuccess={onNewDocumentUploadSuccess}
            showDocumentUploadRequired={showDocumentUploadRequired}
            setShowDocumentUploadRequired={setShowDocumentUploadRequired}
            setFormWorkMode={setFormWorkMode}
          />
        </>
      )}
    </>
  );
};
