import React from "react";
import { useTranslation } from "react-i18next";
import { CompanyFormWorkMode } from "../../models/company/company-form-work-mode";
import { useSelector } from "react-redux";
import { IRootState } from "../../utilities/root-reducer";
import Spinner from "../common/spinner/spinner";

interface IProfileToolbarProps {
  formWorkMode: CompanyFormWorkMode;
  onSaveClick: () => void;
  onDeleteClick: () => void;
  onCancelClick: () => void;
}

/**
 * Represent toolbar with actions for handling the forms
 */
export default function CompanyProfileToolbar(props: IProfileToolbarProps) {
  const { t } = useTranslation("profile");
  const { onSaveClick, onCancelClick, formWorkMode } = props;
  const { updateLoading } = useSelector((state: IRootState) => state.company);
  return (
    <>
      <div className="control-button-container">
        {formWorkMode === CompanyFormWorkMode.Edit && (
          <div className="control-button-real-container">
            <button
              disabled={updateLoading}
              className="cancel"
              onClick={() => onCancelClick()}
            >
              {t("cancelChanges")}
            </button>

            <button
              disabled={updateLoading}
              className="save"
              onClick={() => onSaveClick()}
            >
              {updateLoading && <Spinner />}
              {t("saveProfile")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
