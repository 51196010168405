export const EXPERT_FETCH_ALL = "ADMIN_EXPERT_FETCH_ALL";
export const EXPERT_FETCH_ALL_WITH_NO_PAGINATION =
  "ADMIN_EXPERT_FETCH_ALL_WITH_NO_PAGINATION";
export const EXPERT_GET = "ADMIN_EXPERT_GET";
export const EXPERT_DELETE = "ADMIN_EXPERT_DELETE";
export const EXPERT_CLEANUP = "ADMIN_EXPERT_CLEANUP";
export const EXPERT_GET_CLEANUP = "ADMIN_EXPERT_GET_CLEANUP";
export const EXPERT_SEARCH = "ADMIN_EXPERT_SEARCH";
export const EXPERT_EXPORT = "ADMIN_EXPERT_EXPORT";
export const EXPERT_DELETE_CLEANUP = "ADMIN_EXPERT_DELETE_CLEANUP";
