import axios from "axios";
import { baseURL } from "./environment";
import {
  isAuth,
  logoutUser,
  getAccessToken,
  cognitoAuth,
  updateToken,
} from "./auth";

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = baseURL;

const setupAxiosInterceptors = (history) => {
  const onRequestSuccess = async (config) => {
    const newSession = await cognitoAuth.currentSession();
    const newToken = newSession.getIdToken();
    if (newToken) {
      updateToken(newToken.getJwtToken());
    }
    const token = isAuth();
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  };
  const onRequestError = (error) => {
    return Promise.reject(error);
  };
  const onResponseSuccess = (response) => response;
  const onResponseError = (error) => {
    const status = error.status || (error.response ? error.response.status : 0);
    if (status === 403 || status === 401) {
      logoutUser();
      window.location.replace("/login");
    }
    return Promise.reject(error.response);
  };
  axios.interceptors.request.use(onRequestSuccess, onRequestError);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
