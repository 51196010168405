import { createTheme } from "@material-ui/core/styles";

const primaryColor = "#B62026";
const whiteColor = "#fff";
const blackColor = "#000";
const grayParagraphColor = "#848894";

export const AppTheme = createTheme({
  typography: {
    fontFamily: "Montserrat-Regular, sans-serif",
    h1: {
      fontFamily: "Montserrat-Bold, sans-serif",
      fontSize: "96px",
      color: whiteColor,
      lineHeight: "117px",
      fontWeight: 700,
      ["@media (max-width:1280px)"]: {
        fontSize: "76px",
        lineHeight: "104px",
      },
      ["@media (max-width:900px)"]: {
        fontSize: "66px",
        lineHeight: "84px",
      },
      ["@media (max-width:600px)"]: {
        fontSize: "56px",
        lineHeight: "64px",
      },
    },
    h2: {
      fontFamily: "Montserrat-Bold, sans-serif",
      fontSize: "60px",
      lineHeight: "73px",
      textAlign: "center",
      color: blackColor,
      fontWeight: 700,
      ["@media (max-width:1280px)"]: {
        fontSize: "54px",
        lineHeight: "65px",
      },
      ["@media (max-width:900px)"]: {
        fontSize: "47px",
        lineHeight: "57px",
      },
      ["@media (max-width:600px)"]: {
        fontSize: "36px",
        lineHeight: "44px",
      },
    },
    h3: {
      fontFamily: "Montserrat-Bold, sans-serif",
      fontSize: "34px",
      lineHeight: "41px",
      marginBottom: "10px",
      color: blackColor,
      fontWeight: 700,
      ["@media (max-width:1280px)"]: {
        fontSize: "30px",
        lineHeight: "37px",
      },
      ["@media (max-width:900px)"]: {
        fontSize: "26px",
        lineHeight: "32px",
      },
      ["@media (max-width:600px)"]: {
        fontSize: "22px",
        lineHeight: "38px",
      },
    },
    h4: {
      fontFamily: "Montserrat-Regular, sans-serif",
      fontSize: "24px",
      lineHeight: "29px",
      color: primaryColor,
      fontWeight: 400,
      ["@media (max-width:1280px)"]: {
        fontSize: "21px",
        lineHeight: "26px",
      },
      ["@media (max-width:900px)"]: {
        fontSize: "18px",
        lineHeight: "22px",
      },
    },
    body1: {
      fontFamily: "Manrope-Regular, sans-serif",
      fontSize: "24px",
      fontWeight: "normal",
      lineHeight: "33px",
      color: "rgba(255, 255, 255, 0.6)",
      ["@media (max-width:1280px)"]: {
        fontSize: "21px",
        lineHeight: "30px",
      },
      ["@media (max-width:900px)"]: {
        fontSize: "18px",
        lineHeight: "22px",
      },
    },
    body2: {
      fontSize: "15px",
      lineHeight: "26px",
      color: grayParagraphColor,
      fontStyle: "normal",

      ["@media (max-width:600px)"]: {
        fontSize: "14px",
        lineHeight: "24px",
      },
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 0px 20px rgba(20, 33, 43, 0.1)",
        borderRadius: "10px",
        padding: "70px 50px",
        ["@media (max-width:600px)"]: {
          padding: "50px 25px",
        },
      },
      root: {
        background: "#fff",
        boxShadow: "0px 0px 20px rgba(20, 33, 43, 0.1)",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: whiteColor,
      },
      root: {
        colorPrimary: whiteColor,
        boxShadow: "0px 0px 20px rgba(20, 33, 43, 0.1)",
        // height: "0",
      },
    },
    MuiButton: {
      root: {
        backgroundColor: primaryColor,
        color: whiteColor,
        borderRadius: "6px",
        padding: "20px 60px",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "22px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "auto",
          boxShadow: "none",
        },
        ["@media (max-width:1280px)"]: {
          fontSize: "13px",
          lineHeight: "19px",
          padding: "15px 30px",
        },
        ["@media (max-width:900px)"]: {
          padding: "15px 20px",
        },
        ["@media (max-width:600px)"]: {
          fontSize: "12px",
          lineHeight: "18px",
          padding: "10px",
        },
      },

      contained: {
        backgroundColor: primaryColor,
        color: whiteColor,
        borderRadius: "6px",
        padding: "20px 60px",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "22px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "auto",
          boxShadow: "none",
        },
        ["@media (max-width:1280px)"]: {
          fontSize: "13px",
          lineHeight: "19px",
          padding: "15px 30px",
        },
        ["@media (max-width:900px)"]: {
          padding: "15px 20px",
        },
        ["@media (max-width:600px)"]: {
          fontSize: "12px",
          lineHeight: "18px",
          padding: "15px 15px",
        },
      },
      outlined: {
        backgroundColor: whiteColor,
        color: primaryColor,
        border: `2px solid ${primaryColor}`,
        borderRadius: "6px",
        padding: "18px 30px",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "22px",
        "&:hover": {
          backgroundColor: primaryColor,
          color: whiteColor,
        },
        ["@media (max-width:1280px)"]: {
          fontSize: "13.5px",
          lineHeight: "19px",
          padding: "15px 20px",
        },
        ["@media (max-width:600px)"]: {
          fontSize: "12px",
          lineHeight: "18px",
          padding: "15px 15px",
        },
      },
    },
  },
});
