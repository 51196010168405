import { Actions } from "../../utilities/action-payload";
import { IRegistrationConfirm } from "./registrationConfirmEntity";
import {
  CONFIRM_REGISTRATION,
  CONFIRM_REGISTRATION_CLEANUP,
} from "./registrationConfirmTypes";
import { REQUEST, SUCCESS, FAILURE } from "../../utilities/action-types";
import { ConfirmRegistrationError } from "../../models/account/confirmRegistration/confirm-registration-error-enum";

const initialState: IRegistrationConfirm = {
  errorMessage: ConfirmRegistrationError.None,
  loading: true,
  verified: null,
  status: "",
};

export type RegistrationConfirmState = Readonly<typeof initialState>;

export default (
  state: RegistrationConfirmState = initialState,
  action: Actions,
): RegistrationConfirmState => {
  switch (action.type) {
    case REQUEST(CONFIRM_REGISTRATION):
      return {
        ...state,
        loading: false,
        status: action.payload,
        verified: false,
        errorMessage: ConfirmRegistrationError.ConfirmationRequest,
      };

    case SUCCESS(CONFIRM_REGISTRATION):
      return {
        ...state,
        status: "User confirmed" + action.payload,
        errorMessage: ConfirmRegistrationError.ConfirmationApproval,
        verified: true,
        loading: false,
      };

    case FAILURE(CONFIRM_REGISTRATION):
      return {
        ...state,
        errorMessage: ConfirmRegistrationError.ConfirmationFailure,
      };
    case CONFIRM_REGISTRATION_CLEANUP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
