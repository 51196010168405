export const profileTabNavigation = [
  "general",
  "contactPerson",
  "expertise",
  "exports",
  "additionalInfo",
];
export enum TabNavigationEnum {
  General = 0,
  ContactPerson,
  Expertise,
  Exports,
  AdditionalInfo,
}
