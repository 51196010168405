import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "../../styles/profileForms/shared-profile-styles.scss";
import { IRootState } from "../../utilities/root-reducer";
import { withTranslation, useTranslation } from "react-i18next";
import compose from "recompose/compose";
import {
  updateExpert,
  cancelExpertChanges,
  cleanupExpert,
  newExpert,
  updateExpertStateData,
  uploadExpertDocumentOnSave,
  uploadExpertDocument,
  getExpert,
} from "./expert-profile-action";
import {
  TypeOfSupportOfferedTab,
  TabsNavigation,
  GeneralInfoTab,
} from "./Tabs";
import { store } from "react-notifications-component";

import TabsStepperNavigation from "./Tabs/tabs-stepper-navigation";
import ExpertProfileLanding from "./Landing/expert-profile-landing";
import {
  notifySuccess,
  notifyError,
} from "../../utils/notifications/app-notification";

import { IExpert, Expert } from "../../models/expert/expert";
import { ExpertFormWorkMode } from "../../models/expert/expert-form-work-mode";
import ExpertProfileToolbar from "./expert-profile-toolbar";
import DeleteExpertProfileModal from "./Modals/delete-expert-modal";
import OldUsersModal from "../common/modals/OldUsersModal";
import {
  expertTabNavigation,
  ExpertTabNavigationEnum,
} from "../../models/expert/expert-tab-navigation-enum";
import { DocumentsTab } from "./Tabs/documents-tab";
import { ITab } from "./Tabs/definitions/tabs-spec";
import { expertsCleanup } from "../adminTables/expertsTableComponent/expertsTableActions";

interface IExpertProfileProfile extends ITab, StateProps, DispatchProps {}

export function ExpertProfile(props: IExpertProfileProfile) {
  /**
   * State
   */
  const { expertState } = props;
  const dispatch = useDispatch();
  /**
   * GENERAL
   */
  const { t } = useTranslation("expert");

  /**
   * COMPANY DATA MANAGEMENT ////////////////////
   */

  // Keeps the result of the changes in the form before saving
  const [draftExpertData, setDraftExpertData] = useState<IExpert>(new Expert());
  // Data get in first place to populate the inputs
  // NOTE! Keep this data in order to reset if user click cancel on edit
  const originalExpertData = useRef<IExpert>(new Expert());

  /**
   * TABS HANDLING
   */
  // This marking of the tabs are use only when user is in process of filling the
  // the form data tab by tab. ExpertFormWorkMode is marked as 'New'
  const [formMarkedValidTabs, setFormMarkedValidTabs] = useState<string[]>([]);
  const [formTabsLockForEdit, setFormTabsLockForEdit] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState(
    expertTabNavigation[ExpertTabNavigationEnum.General],
  );
  const [tabFormToValidate, setTabFormToValidate] = useState<string>("");
  // keep tab name to change in case you have to do some operations and after that to change
  // example: previous and next states. first forms need to be validated and changed
  const tabNameToChange = useRef("");

  /**
   * MODAL ///////////
   */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOldUsersModal, setShowOldUsersModal] = useState(false);
  const [showExpertiseQuestionModal, setShowExpertiseQuestionModal] = useState(
    false,
  );

  /**
   * FORM WORKFLOW MANAGEMENT //////////////////////////
   */
  const [formWorkMode, setFormWorkMode] = useState(ExpertFormWorkMode.View);

  const [showDocumentUploadRequired, setShowDocumentUploadRequired] = useState(
    false,
  );
  const [showLoadingBar, setShowLoadingBar] = useState(false);

  // Already existing users (before update) have expertsState.data and the "lastModified" property is null
  const isModified =
    (expertState.data && Object.keys(expertState.data).length === 0) ||
    expertState.data === null
      ? true
      : expertState.data?.hasOwnProperty("lastModified");

  const hasExpertise = expertState.data?.expertise?.length > 0;

  useEffect(() => {
    const data = expertState.data;
    if (hasAccountWithData(data)) {
      setFormWorkMode(ExpertFormWorkMode.View);
    } else {
      setFormWorkMode(ExpertFormWorkMode.Done);
    }
    originalExpertData.current = { ...data };
    setDraftExpertData({ ...data });
  }, [expertState.data]);

  useEffect(() => {
    document.addEventListener(
      "delete-export-profile",
      handleToolbarOnDeleteClick,
      true,
    );
    return () => {
      document.removeEventListener(
        "delete-export-profile",
        handleToolbarOnDeleteClick,
        true,
      );
    };
  }, []);

  /**
   * CREATE NEW EXPERT PROFILE EVENTS
   */
  useEffect(() => {
    if (!expertState.newLoading && expertState.newError) {
      notifyError(
        store,
        t("expert-profile-create-title"),
        t("expert-profile-create-error"),
      );

      dispatch(cleanupExpert());
      const data = expertState.data;
      setDraftExpertData({ ...data });
    } else if (!expertState.newLoading && !expertState.hasDocument) {
      setActiveTab(expertTabNavigation[ExpertTabNavigationEnum.General]);

      if (!isModified) {
        setFormWorkMode(ExpertFormWorkMode.View);
      } else {
        expertState.data?.repeatEmail
          ? setFormWorkMode(ExpertFormWorkMode.View)
          : setFormWorkMode(ExpertFormWorkMode.Done);
      }
    }
  }, [expertState.newLoading]);

  // DOCUMENT UPLOAD

  useEffect(() => {
    if (expertState.newStatus && expertState.file) {
      handleUpload(expertState.file);
    }
  }, [expertState.newStatus]);

  /**
   * UPDATE EXPERT EVENT
   */
  useEffect(() => {
    if (!expertState.updateLoading && expertState.updateError) {
      if (
        !isModified &&
        activeTab !== expertTabNavigation[ExpertTabNavigationEnum.General]
      ) {
        return;
      } else {
        notifyError(
          store,
          t("expert-profile-update-title"),
          t("expert-profile-update-error"),
        );
      }
    } else if (!expertState.updateLoading && expertState.updateStatus) {
      unlockAllTabs();
      setFormWorkMode(ExpertFormWorkMode.View);
      notifySuccess(
        store,
        t("expert-profile-update-title"),
        t("expert-profile-update-success"),
      );
      dispatch(updateExpertStateData({ ...draftExpertData }));
    }
  }, [expertState.updateLoading]);

  useEffect(() => {
    !isModified && setShowOldUsersModal(true);
    typeof expertState.data?.repeatEmail !== "undefined" &&
      !hasExpertise &&
      setShowExpertiseQuestionModal(true);
  }, []);

  /**
   *
   * @param expert Check if account has already set
   */
  const hasAccountWithData = (expert: IExpert) => {
    if (expert === null) {
      return;
    }
    return expert.firstName && expert.firstName.length > 0;
  };

  const handleChangeExpertTab = (tabName) => {
    // prevent rendering components on same tab
    if (tabName === activeTab) {
      return;
    }

    if (formWorkMode === ExpertFormWorkMode.View) {
      tabNameToChange.current = tabName;
      setActiveTab(tabName);
      return;
    }

    if (formWorkMode === ExpertFormWorkMode.Edit) {
      tabNameToChange.current = tabName;
      setTabFormToValidate(activeTab);
    }
  };

  /**
   * TABS MANIPULATION //////////////////
   */

  const hasLockedTabsForEdit = () => {
    return formTabsLockForEdit.length > 0;
  };

  // Unlock single tab
  /**
   * Provided tab will be unlocked and available for click
   * @param tabNameToUnlock
   */
  const unlockTab = (tabNameToUnlock) => {
    const tabIndex = formTabsLockForEdit.indexOf(tabNameToUnlock);
    if (tabIndex === -1) {
      return;
    }
    const lockedTabs = formTabsLockForEdit.filter(
      (tabName) => tabName !== tabNameToUnlock,
    );
    setFormTabsLockForEdit(lockedTabs);
  };

  const unlockAllTabs = () => {
    setFormTabsLockForEdit([]);
  };

  /**
   * All tabs are lock and can not be clicked except the tab provided
   * @param tabNameToLock
   */
  const lockToSingleTab = (tabNameToLock) => {
    const lockedTabs = expertTabNavigation.filter(
      (tabName) => tabName !== tabNameToLock,
    );
    setFormTabsLockForEdit(lockedTabs);
  };

  /**
   * FORM EVENTS CHANGES IN TABS ///////////////////
   *
   *
   */
  const handleDataChangeInGeneralTab = (data: IExpert) => {
    const tabName = expertTabNavigation[ExpertTabNavigationEnum.General];

    if (!hasLockedTabsForEdit() && formWorkMode === ExpertFormWorkMode.View) {
      lockToSingleTab(tabName);
      setFormWorkMode(ExpertFormWorkMode.Edit);
    }

    let phoneNumber;

    let companyName;

    let linkedinProfile;

    let fieldsOfInterest;

    if (data.phoneNumber === "") {
      phoneNumber = "";
    } else if (!data.phoneNumber) {
      phoneNumber = draftExpertData.phoneNumber;
    } else {
      if (data.phoneNumber !== draftExpertData.phoneNumber) {
        phoneNumber = data.phoneNumber;
      } else {
        phoneNumber = draftExpertData.phoneNumber;
      }
    }

    if (data.companyName === "") {
      companyName = "";
    } else if (!data.companyName) {
      companyName = draftExpertData.companyName;
    } else {
      if (data.companyName !== draftExpertData.companyName) {
        companyName = data.companyName;
      } else {
        companyName = draftExpertData.companyName;
      }
    }

    if (data.linkedinProfile === "") {
      linkedinProfile = "";
    } else if (!data.linkedinProfile) {
      linkedinProfile = draftExpertData.linkedinProfile;
    } else {
      if (data.linkedinProfile !== draftExpertData.linkedinProfile) {
        linkedinProfile = data.linkedinProfile;
      } else {
        linkedinProfile = draftExpertData.linkedinProfile;
      }
    }

    if (data.fieldsOfInterest === "") {
      fieldsOfInterest = "";
    } else if (!data.fieldsOfInterest) {
      fieldsOfInterest = draftExpertData.fieldsOfInterest;
    } else {
      if (data.fieldsOfInterest !== draftExpertData.fieldsOfInterest) {
        fieldsOfInterest = data.fieldsOfInterest;
      } else {
        fieldsOfInterest = draftExpertData.fieldsOfInterest;
      }
    }

    const expertData = {
      ...draftExpertData,
      firstName: data.firstName || draftExpertData.firstName,
      lastName: data.lastName || draftExpertData.lastName,
      email: data.email || draftExpertData.email,
      repeatEmail: data.repeatEmail || draftExpertData.repeatEmail,
      phoneNumber,
      jobTitle: data.jobTitle || draftExpertData.jobTitle,
      companyName,
      country: data.country || draftExpertData.country,
      regions: data.regions || draftExpertData.regions,
      linkedinProfile,
      fieldsOfInterest,
    };
    setDraftExpertData({ ...expertData });
  };

  const handleDataChangeInSupportTab = (data: IExpert) => {
    const tabName = expertTabNavigation[ExpertTabNavigationEnum.Support];
    setDraftExpertData({ ...draftExpertData });
    if (!hasLockedTabsForEdit() && formWorkMode === ExpertFormWorkMode.View) {
      lockToSingleTab(tabName);
      setFormWorkMode(ExpertFormWorkMode.Edit);
    }

    let engagementExpectation;

    if (data.expectationsFromThePlatform === "") {
      engagementExpectation = "";
    } else if (!data.expectationsFromThePlatform) {
      engagementExpectation = draftExpertData.expectationsFromThePlatform;
    } else {
      if (
        data.expectationsFromThePlatform !==
        draftExpertData.expectationsFromThePlatform
      ) {
        engagementExpectation = data.expectationsFromThePlatform;
      } else {
        engagementExpectation = draftExpertData.expectationsFromThePlatform;
      }
    }

    const expertData = {
      ...draftExpertData,
      supportTypes: data.supportTypes || draftExpertData.supportTypes,
      industries: data.industries || draftExpertData.industries,
      otherServiceActivities:
        data.otherServiceActivities || draftExpertData.otherServiceActivities,
      expertise: data.expertise || draftExpertData.expertise,
      otherExpertise: data.otherExpertise || draftExpertData.otherExpertise,
      regions: data.regions || draftExpertData.regions,
      workWithStartups: data.workWithStartups,
      expectationsFromThePlatform: engagementExpectation,
      numberOfCompanies:
        data.numberOfCompanies || draftExpertData.numberOfCompanies,
    };
    setDraftExpertData({ ...expertData });
  };

  const handleDataChangeinDocumentsTab = (data: IExpert) => {
    const tabName = expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo];
    lockToSingleTab(tabName);
    setFormWorkMode(ExpertFormWorkMode.Edit);
  };

  const handleOnNextClick = (nextTabName) => {
    tabNameToChange.current = nextTabName;
    setTabFormToValidate(activeTab);
  };

  const handleOnPreviousClick = (previousTabName) => {
    setActiveTab(previousTabName);
  };

  const hasUploadedDocuments = !!expertState.file;

  const handleOnFinishClick = () => {
    //TODO: CHECK WHETHER THERE IS A FILE ATTACHED

    setShowDocumentUploadRequired(!hasUploadedDocuments);

    if (!hasUploadedDocuments) return;

    dispatch(
      newExpert({
        ...draftExpertData,
      }),
    );

    setShowLoadingBar(true);
    setFormWorkMode(ExpertFormWorkMode.Done);
    setFormMarkedValidTabs([]);
    unlockAllTabs();
    window.scrollTo(0, 0);
    setActiveTab(expertTabNavigation[ExpertTabNavigationEnum.General]);
  };

  const onNewDocumentUploadSuccess = (data: IExpert) => {
    dispatch(
      newExpert({
        ...draftExpertData,
        documents: data.documents,
        documentUrl: data.documentUrl,
        hasDocument: data.hasDocument,
      }),
    );
    setFormMarkedValidTabs([]);
    setActiveTab(expertTabNavigation[ExpertTabNavigationEnum.General]);
    unlockAllTabs();
    setFormWorkMode(ExpertFormWorkMode.View);
  };

  const onEditDocumentUploadSuccess = (data: IExpert) => {
    setFormMarkedValidTabs([]);
    setActiveTab(expertTabNavigation[ExpertTabNavigationEnum.General]);
    unlockAllTabs();
    setFormWorkMode(ExpertFormWorkMode.View);

    if (!hasUploadedDocuments) {
      setShowDocumentUploadRequired(!hasUploadedDocuments);
    }
  };

  const handleTabFormValidation = (tabName: string, isValid: boolean) => {
    if (formWorkMode === ExpertFormWorkMode.New) {
      if (isValid) {
        // unlock the current
        if (formMarkedValidTabs.indexOf(tabName) === -1) {
          setFormMarkedValidTabs([...formMarkedValidTabs, tabName]);
          unlockTab(tabNameToChange.current);
        }
        // set tab change only if tab is different
        if (tabName !== tabNameToChange.current) {
          setActiveTab(tabNameToChange.current);
        }

        // documents is last tab
        if (
          activeTab ===
          expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo]
        ) {
          dispatch(newExpert({ ...draftExpertData }));
        }
      }
    }

    if (formWorkMode === ExpertFormWorkMode.Edit) {
      if (isValid) {
        dispatch(updateExpert({ ...draftExpertData }));
      }
    }

    setTabFormToValidate("");
    tabNameToChange.current = "";
  };

  /**
   * DELETE EVENTS //////////////////
   */

  const handleToolbarOnDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalCancel = () => {
    setShowDeleteModal(false);
  };

  //UPDATE EXSITING EXPERTS (closing modal and validation)
  const closeExpertiseQuestionModal = () => {
    if (showExpertiseQuestionModal) {
      setShowExpertiseQuestionModal(false);
      if(setShowOldUsersModal){
        tabNameToChange.current = expertTabNavigation[ExpertTabNavigationEnum.General];
      } else {
        tabNameToChange.current = expertTabNavigation[ExpertTabNavigationEnum.Support];
      }
      setActiveTab(tabNameToChange.current);
      setTabFormToValidate(tabNameToChange.current);
    }
  };
  
  const closeOldUsersModal = () => {
    if (showOldUsersModal) {
      setShowOldUsersModal(false);
      setFormWorkMode(ExpertFormWorkMode.Edit);
      tabNameToChange.current = expertTabNavigation[ExpertTabNavigationEnum.General];
      setTabFormToValidate(activeTab);
    }
  };

  /**
   * TOOLBAR EVENTS //////////////////
   */

  const handleToolbarOnSaveClick = () => {
    setTabFormToValidate(activeTab);

    if (
      activeTab === expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo]
    ) {
      const expertData = {
        ...draftExpertData,
        documents: expertState.documents,
      };

      setDraftExpertData({ ...expertData });

      if (hasUploadedDocuments) {
        dispatch(uploadExpertDocumentOnSave());
        setFormWorkMode(ExpertFormWorkMode.View);
        unlockAllTabs();
      } else {
        setShowDocumentUploadRequired(!hasUploadedDocuments);
      }
    } else {
      setDraftExpertData({ ...draftExpertData });
    }
  };

  if (!isModified) {
    if (
      expertState.updateLoading &&
      !hasLockedTabsForEdit() &&
      activeTab === expertTabNavigation[ExpertTabNavigationEnum.General]
    ) {
      tabNameToChange.current =
        expertTabNavigation[ExpertTabNavigationEnum.Support];
      setActiveTab(tabNameToChange.current);
      setTabFormToValidate(tabNameToChange.current);
    }
  }

  const handleToolbarOnCancelClick = () => {
    if (hasLockedTabsForEdit()) {
      if (
        activeTab !==
          expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo] ||
        (activeTab ===
          expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo] &&
          hasUploadedDocuments)
      )
        dispatch(cancelExpertChanges());
      unlockAllTabs();
      setFormWorkMode(ExpertFormWorkMode.View);
    } else if (
      activeTab ===
        expertTabNavigation[ExpertTabNavigationEnum.AdditionalInfo] &&
      !hasUploadedDocuments
    ) {
      setShowDocumentUploadRequired(!hasUploadedDocuments);
    }
  };

  const handleToolbarOnEditClick = () => {
    setFormWorkMode(ExpertFormWorkMode.Edit);
  };

  const handleUpload = (file?: any) => {
    if (file) {
      dispatch(uploadExpertDocument(file));
    }
  };

  /**
   * PROFILE LANDING
   */
  const handleCreateProfile = () => {
    setFormWorkMode(ExpertFormWorkMode.New);
    const generalTabName = expertTabNavigation[ExpertTabNavigationEnum.General];
    lockToSingleTab(generalTabName);
    setActiveTab(generalTabName);
  };

  const setWorkModeView = () => {
    setFormWorkMode(ExpertFormWorkMode.View);
  };

  return (
    <>
      {formWorkMode !== ExpertFormWorkMode.Done && (
        <div className="profile-body">
          <ExpertProfileToolbar
            formWorkMode={formWorkMode}
            onDeleteClick={handleToolbarOnDeleteClick}
            onCancelClick={handleToolbarOnCancelClick}
            onSaveClick={handleToolbarOnSaveClick}
            onEditClick={handleToolbarOnEditClick}
          />
          <TabsNavigation
            active={activeTab}
            onChange={handleChangeExpertTab}
            tabs={expertTabNavigation}
            selectedTabs={formMarkedValidTabs}
            nonClickableTabs={formTabsLockForEdit}
          />
          {/** TABS CONTENT */}
          <GeneralInfoTab
            active={activeTab}
            data={draftExpertData}
            onChange={handleDataChangeInGeneralTab}
            formWorkMode={formWorkMode}
            validateTab={tabFormToValidate}
            onTabValidation={handleTabFormValidation}
          />
          <TypeOfSupportOfferedTab
            active={activeTab}
            data={draftExpertData}
            onChange={handleDataChangeInSupportTab}
            formWorkMode={formWorkMode}
            validateTab={tabFormToValidate}
            onTabValidation={handleTabFormValidation}
          />
          <DocumentsTab
            active={activeTab}
            formWorkMode={formWorkMode}
            setFormWorkMode={setFormWorkMode}
            onChange={handleDataChangeinDocumentsTab}
            data={draftExpertData}
            onNewDocumentUploadSuccess={onNewDocumentUploadSuccess}
            onEditDocumentUploadSuccess={onEditDocumentUploadSuccess}
            showDocumentUploadRequired={showDocumentUploadRequired}
            setShowDocumentUploadRequired={setShowDocumentUploadRequired}
          />
          <TabsStepperNavigation
            activeTab={activeTab}
            onNext={handleOnNextClick}
            onPrevious={handleOnPreviousClick}
            onFinish={handleOnFinishClick}
            formWorkMode={formWorkMode}
          />
          <DeleteExpertProfileModal
            open={showDeleteModal}
            onCancel={handleDeleteModalCancel}
          />
        </div>
      )}
      {formWorkMode === ExpertFormWorkMode.Done && (
        <>
          <ExpertProfileLanding
            onCreateProfileClick={handleCreateProfile}
            showLoadingBar={showLoadingBar}
            setWorkModeView={setWorkModeView}
          />
        </>
      )}
      {
        <OldUsersModal
          open={showOldUsersModal}
          onClose={closeOldUsersModal}
          content={t("existing-experts-and-companies-modal")}
        />
      }
      {
        <OldUsersModal
          open={showExpertiseQuestionModal}
          onClose={closeExpertiseQuestionModal}
          content={t("expert-expertise-modal")}
        />
      }
    </>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  expertState: storeState.expert,
});

const mapDispatchToProps = {
  updateExpert,
  cleanupExpert,
  cancelExpertChanges,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("expert"),
  connect(mapStateToProps, mapDispatchToProps),
)(ExpertProfile);
