import React from "react";
import "./homePage.scss";
import { HomePageHeader } from "./components/homePageHeader/homePageHeader";
import { MainSection } from "./components/mainSection/mainSection";
import { WhoCanJoin } from "./components/whoCanJoin/whoCanJoin";
import { HowDoesItWork } from "./components/howDoesItWork/howDoesItWork";
import { HomePageFooter } from "./components/homePageFooter/homePageFooter";
import { WhatWeOffer } from "./components/whatWeOffer/whatWeOffer";
import { Testimonials } from "./components/testimonials/testimonials";
import {PartnerLogos} from "./components/partnerLogos/partnerLogos";

export const handleNavigationSignUp = (history: any, profileType: string) => {
  history.push({
    pathname: "/signup",
    state: { profileType: profileType },
  });
};

export const HomePage = () => {
  return (
    <>
      <div className="homepage-wrapper">
        <HomePageHeader />
        <MainSection />
        <WhatWeOffer />
        <WhoCanJoin />
        <HowDoesItWork />
        <Testimonials />
        <PartnerLogos/>
        <HomePageFooter />
      </div>
    </>
  );
};
