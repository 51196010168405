import { AdminLayout } from "./layouts/admin-layout";

export const AdminRoutes = [
  {
    component: AdminLayout,
    path: "/",
  },
  {
    component: AdminLayout,
    path: "/:slug",
  },
];
