import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "./delete-expert-modal.scss";
import { useTranslation } from "react-i18next";
import Spinner from "../../../common/spinner/spinner";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../../../utilities/root-reducer";
import {
  notifyError,
  notifySuccess,
} from "../../../../utils/notifications/app-notification";
import { store } from "react-notifications-component";
import { deleteExpert, deleteExpertCleanup } from "../expertsTableActions";

interface IDeleteExpertModalProps {
  open: boolean;
  expertId: string;
  onDeleteSuccess: () => void;
  onCancel: () => void;
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 110,
  },
};

export default function DeleteExpertModal(props: IDeleteExpertModalProps) {
  const { open, onDeleteSuccess, onCancel, expertId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation("admin-tables");
  const { deleteLoading, deleteStatus, error } = useSelector(
    (state: IRootState) => state.adminExperts,
  );

  /**
   * // recommended by the author of the package
   */
  ReactModal.setAppElement("body");

  useEffect(() => {
    if (!deleteLoading && deleteStatus) {
      notifySuccess(
        store,
        t("admin-expert-delete-notify-title"),
        t("admin-expert-delete-success-desc"),
      );
      dispatch(deleteExpertCleanup());
      onDeleteSuccess();
    } else if (!deleteLoading && error) {
      notifyError(
        store,
        t("admin-expert-delete-notify-title"),
        t("admin-expert-delete-error-desc"),
      );
    }
  }, [deleteLoading]);

  const handleDeleteClick = () => {
    dispatch(deleteExpert(expertId));
  };

  return (
    <ReactModal
      isOpen={open}
      role={"dialog"}
      shouldCloseOnEsc={false}
      style={modalStyle}
    >
      <div className={"delete-modal-content"}>
        <h4>{t("admin-expert-modal-delete-desc")}</h4>
        <br></br>
        <div className={"control-button-container"}>
          <button
            disabled={deleteLoading}
            className={"button-with-loading"}
            onClick={handleDeleteClick}
          >
            {deleteLoading && <Spinner />}
            {t("admin-company-modal-ok")}
          </button>
          <button
            disabled={deleteLoading}
            onClick={onCancel}
            className={"cancel"}
          >
            {t("admin-company-modal-cancel")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
