import React, { useState, useEffect } from "react";
import {
  NumberInputConfig,
  NumberInputEvent,
} from "./definitions/InputConfigurationSpec";
import "./../../styles/profileForms/shared-profile-styles.scss";

export default function NumberInput(props: {
  inputConfig: NumberInputConfig;
  onChange: (event: NumberInputEvent) => void;
  errorMsg: string;
}) {
  const { inputConfig, onChange, errorMsg } = props;
  const [currValue, setCurrValue] = useState(inputConfig.initValue);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMsg);
  const [isDisabled, setIsDisabled] = useState(inputConfig.isDisabled || false);

  useEffect(() => {
    setIsDisabled(inputConfig.isDisabled);
    if (inputConfig.isDisabled) {
      if (hasError) {
        setHasError(false);
      }
    }
  }, [inputConfig.isDisabled]);

  inputConfig.setDisabled = (disabled) => {
    setIsDisabled(disabled);
    inputConfig.isDisabled = disabled;
    if (hasError) {
      setHasError(false);
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setHasError(false);
    let value = parseFloat(e.currentTarget.value);
    // number range correction
    if (inputConfig.range) {
      if (inputConfig.range.from !== undefined) {
        if (value < inputConfig.range.from) {
          value = inputConfig.range.from;
        }
      }

      if (inputConfig.range.to !== undefined) {
        if (value > inputConfig.range.to) {
          value = inputConfig.range.to;
        }
      }
    }

    if (typeof onChange === "function") {
      onChange({ value, modelValue: inputConfig.modelValue });
    }
    setCurrValue(value);
  };

  inputConfig.validate = () => {
    // do not check for validation if input is disabled
    if (inputConfig.isDisabled) {
      return true;
    }
    if (inputConfig.isRequired) {
      if (currValue === undefined) {
        setHasError(true);
        setError(errorMsg);
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <>
      <input
        type="number"
        name={inputConfig.name}
        disabled={isDisabled}
        onChange={handleInputChange}
        value={currValue}
        className="styled-input"
      />
      {hasError && <div className={"alert error"}>{error}</div>}
    </>
  );
}
