import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { handleNavigationSignUp } from "../../homePage";
import { useHistory } from "react-router-dom";
import Illustration from "../../../../assets/images/illustration.svg";
import Illustration2 from "../../../../assets/images/illustration-2.svg";
import "./mainSection.scss";

export const MainSection = () => {
  const { t } = useTranslation("homepage");
  const history = useHistory();

  return (
    <div className="hero-section">
      <Box className="bg-illustration-wrapper">
        <img className="illustration" src={Illustration2} alt="SVG Image" />
      </Box>
      <div className="content-wrapper">
        <Typography className="homepage-title" variant="h1">
          {t("homePageTitle")}
        </Typography>
        <Typography className="homepage-description" variant="body1">
          {t("homePageParagraph")}
        </Typography>
        <Box className="buttons-wrapper">
          <Button
            className="button-contained-white"
            variant="contained"
            onClick={() => handleNavigationSignUp(history, "company")}
          >
            {t("registerCompany")}
          </Button>

          <Button
            className="button-outlined-white"
            variant="outlined"
            onClick={() => handleNavigationSignUp(history, "expert")}
          >
            {t("registerExpert")}
          </Button>
        </Box>
      </div>
      <div className="image-wrapper">
        <img
          className="hero-section-image"
          src={Illustration}
          alt="Right Image"
        />
      </div>
    </div>
  );
};
