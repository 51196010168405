import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { IRootState } from "../../../../utilities/root-reducer";
import { getCognitoId, getProfileType } from "../../../../utilities/auth";
import DeleteDocumentModal from "../../../companyProfile/Modals/deleteDocumentModal/delete-document-modal";
import {
  deleteExpertDocument,
  uploadFile,
} from "../../../expertProfile/expert-profile-action";

import "../expert-document-upload.scss";
import {
  notifyError,
  notifySuccess,
} from "../../../../utils/notifications/app-notification";
import { store } from "react-notifications-component";
import { ExpertFormWorkMode } from "../../../../models/expert/expert-form-work-mode";
import { bytesToMegabytes } from "./document-upload-component";
import CloseIcon from "@material-ui/icons/Close";

interface IDocumentUploadSuccess {
  formWorkMode: ExpertFormWorkMode;
  setFormWorkMode: (value: ExpertFormWorkMode) => void;
  onUploadSelection: () => void;
  setShowDocumentUploadRequired: (value: boolean) => void;
  showDocumentUploadRequired: boolean;
}

export const DocumentUploadSuccess = (props: IDocumentUploadSuccess) => {
  const { data, cancelDataChanges, documents, status } = useSelector(
    (state: IRootState) => state.expert,
  );

  const {
    formWorkMode,
    onUploadSelection,
    showDocumentUploadRequired,
    setShowDocumentUploadRequired,
    setFormWorkMode,
  } = props;

  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const [profileType, setProfileType] = useState(null);
  const [showDeleteModal, setShowDocumentDeleteModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [
    filteredFilesWithProperSize,
    setFilteredFilesWithProperSize,
  ] = useState([]);
  const [fileName, setFileName] = useState("");
  const [OKDeleteButtonClicked, setOKDeleteButtonClicked] = useState(false);
  const fileInputRef = useRef(null);
  const maxFileSize = 10485760;

  const { deleteDocumentError, hasDocument } = useSelector(
    (state: IRootState) => state.expert,
  );

  // Clear input on cancel
  useEffect(() => {
    cleanInput();
    setFiles([]);
    setFilteredFilesWithProperSize([]);
  }, [cancelDataChanges]);

  //remove files that are exeeding max size
  useEffect(() => {
    if (filteredFilesWithProperSize.length !== files.length) {
      setFiles(filteredFilesWithProperSize);
    }
  }, [filteredFilesWithProperSize]);

  useEffect(() => {
    if (files.length > 0) {
      setFilteredFilesWithProperSize(
        files.filter((el) => el.size < maxFileSize),
      );

      const fileForm = new FormData();

      files.forEach((file) => fileForm.append("files", file));
      dispatch(uploadFile(fileForm));
    }
    if (files.length === 0 && documents?.length > 0) {
      setFormWorkMode(ExpertFormWorkMode.View);
    }
  }, [files]);

  useEffect(() => {
    if (OKDeleteButtonClicked) {
      if (deleteDocumentError === true) {
        notifyError(
          store,
          fileName + " " + t("document-delete-failure-notification-title"),
          t("document-delete-failure-notification-description"),
        );
        setOKDeleteButtonClicked(false);
      }

      if (deleteDocumentError === false) {
        notifySuccess(
          store,
          fileName,
          t("document-delete-success-notification-title"),
        );
        setOKDeleteButtonClicked(false);
      }
    }
  }, [deleteDocumentError, OKDeleteButtonClicked]);

  // empty the arrays when Save changes button is clicked
  useEffect(() => {
    if (status?.status === 201) {
      setFiles([]);
      setFilteredFilesWithProperSize([]);
    }
  }, [status]);

  useEffect(() => {
    hasDocument && setProfileType(getProfileType());
  }, [hasDocument]);

  const onDeleteDocumentSuccess = () => {
    dispatch(deleteExpertDocument(getCognitoId(), fileName));
    setShowDocumentDeleteModal(false);
    setOKDeleteButtonClicked(true);
  };

  const handleDocumentOnDeleteClick = (fileName: string) => {
    setShowDocumentDeleteModal(true);
    setFileName(fileName);
  };

  const handleDocumentDeleteOnCancelClick = () => {
    setShowDocumentDeleteModal(false);
  };

  const cleanInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleChooseFile = (event) => {
    event.preventDefault();
    setFiles([...files, ...event.target.files]);

    const filesList = event.target.files;

    let filesListArray = Object.keys(filesList).map((key) => filesList[key]);

    const filesWithExeededSize = filesListArray.filter(
      (el) => el.size >= maxFileSize,
    );

    if (filesWithExeededSize.length > 0) {
      filesWithExeededSize.map((el) =>
        notifyError(
          store,
          el.name + " " + t("document-upload-error-title"),
          t("document-upload-error-desc"),
        ),
      );
    }

    // Set to edit mode only if current mode is view
    if (formWorkMode === ExpertFormWorkMode.View) {
      onUploadSelection();
    }
  };

  const deleteFile = (fileIdx) =>
    setFiles(files.filter((_, index) => index !== fileIdx));

  const openFileSelector = () => {
    if (fileInputRef) {
      fileInputRef.current.click();
    }
    setShowDocumentUploadRequired(false);
  };

  return (
    <>
      <div className="document-upload-wrapper">
        {formWorkMode === 2 && (
          <div className="docs-container">
            {files.map((file, index) => (
              <div className="document-info" key={index}>
                <InsertDriveFileOutlinedIcon
                  htmlColor="#a41e24"
                  fontSize="large"
                  viewBox="0 0 24 24"
                />
                <p className="document-name">{`${file.name} (${bytesToMegabytes(
                  file.size,
                )} MB)`}</p>
                <CloseIcon
                  className="close-icon"
                  onClick={() => deleteFile(index)}
                />
              </div>
            ))}
          </div>
        )}
        {formWorkMode === 3 && (
          <div className="docs-container">
            {documents?.map((doc, index) => (
              <div className="document-info view-doc" key={index}>
                <div className="title-icon-wrapper">
                  <InsertDriveFileOutlinedIcon
                    htmlColor="#a41e24"
                    fontSize="large"
                    viewBox="0 0 24 24"
                  />
                  <p className="document-name">{doc}</p>
                </div>
                <div className="actions-wrapper">
                  <a
                    className="document-download"
                    href={`/uploads/${profileType}/${data.id}_${doc}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview
                  </a>
                  <span onClick={() => handleDocumentOnDeleteClick(doc)}>
                    Remove
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="document-buttons">
          <input
            type="file"
            ref={fileInputRef}
            id="fileUpload"
            className="document-upload-input"
            name="documentName"
            multiple
            onChange={handleChooseFile}
          />
          <button className="document-download" onClick={openFileSelector}>
            {t("browse-more-files")}
          </button>
        </div>
        {showDeleteModal && (
          <DeleteDocumentModal
            open={showDeleteModal}
            onDeleteSuccess={onDeleteDocumentSuccess}
            onCancel={handleDocumentDeleteOnCancelClick}
          />
        )}
      </div>
      {showDocumentUploadRequired && (
        <p className="document-upload-error">
          {t("expert-document-upload-error")}
        </p>
      )}
    </>
  );
};
