import LandingPage from "../components/landingPage/landig-page-company-expert";
import CompanyProfileLayout from "./layouts/company-profile-layout";
import { CompanyProfileSummary } from "../components/companyProfile/Summary/company-profile-summary";

export const CompanyRoutes = [
  {
    component: LandingPage,
    path: "/landing",
  },
  {
    component: CompanyProfileLayout,
    path: "/",
  },
  {
    component: CompanyProfileSummary,
    path: "/summary",
  },
];
