import { cognitoAuth } from "../../utilities/auth";
import { REQUEST } from "../../utilities/action-types";

export const resetPasswordAction = (username, code, password) => {
  return {
    type: "RESET_PASSWORD",
    payload: cognitoAuth.forgotPasswordSubmit(username, code, password),
  };
};

export const deleteError = () => {
  return {
    type: "RESET_PASSWORD_CLEANUP",
  };
};
