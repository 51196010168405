import { ICompany } from "../../../models/company/company";
import {
  ConfigurableInputProps,
  SelectInputConfig,
  NumberInputConfig,
  RadioButtonsListConfig,
  YesNoRadioButtonConfig,
  CheckboxListConfig,
  SingleCheckbox,
} from "../../../components/formComponents/definitions/InputConfigurationSpec";
import { IGeneralData } from "../../../models/general/general-data";
import CryptoJS from "crypto-js";
import { generateUniqId } from "../../helpers/general-helper";

export const companyToExportsTabForm = (
  company: ICompany,
  staticData: any,
  generalData: IGeneralData,
  hasUniqueKey?: boolean,
): ConfigurableInputProps[] => {
  const companyIsExporting = company.export || false;
  const qualityAssuranceSystem = company.serviceQualityAssuranceSystem || false;

  // Definition
  const components = {
    export: null,
    exportRegions: null,
    percentageProduction: null,
    serviceQualityAssuranceSystem: null,
    appliedStandards: null,
    increaseDifficult: null,
    technologyEquipment: null,
    percentageOfExport: null,
  };

  if (company) {
    components.export = new ConfigurableInputProps({
      inputConfig: new YesNoRadioButtonConfig({
        index: 1,
        name: "export",
        isRequired: true,
        modelValue: "export",
        classModifier: "full",
        tLabel: "companyExport",
        initAnswer: companyIsExporting,
      }),
    });

    if (companyIsExporting) {
      components.exportRegions = new ConfigurableInputProps({
        inputConfig: new SelectInputConfig({
          index: 2,
          name: "exportRegions",
          placeholder: "Select all that apply",
          isRequired: true,
          isMulti: true,
          modelValue: "exportRegions",
          classModifier: "full",
          tLabel: "exportRegions",
          options: staticData?.regions?.map(({ id, name }) => {
            return { value: id, label: name };
          }),
          selectedMultiOptions: company.exportRegions
            ? company.exportRegions.map((item) => {
                return { value: item.id, label: item.name };
              })
            : [],
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("exportRegions"),
        errorMsg: "Please select one option",
      });

      components.percentageOfExport = new ConfigurableInputProps({
        inputConfig: new NumberInputConfig({
          index: 3,
          name: "percentageOfExport",
          modelValue: "percentageOfExport",
          classModifier: "full",
          tLabel: "percentageOfExport",
          initValue: company.percentageOfExport || 0,
          range: { from: 0, to: 100 },
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("percentageOfExport"),
      });
    }

    components.serviceQualityAssuranceSystem = new ConfigurableInputProps({
      inputConfig: new YesNoRadioButtonConfig({
        index: 4,
        isRequired: true,
        name: "serviceQualityAssuranceSystem",
        modelValue: "serviceQualityAssuranceSystem",
        classModifier: "full",
        tLabel: "serviceQualityAssuranceSystem",
        initAnswer: company.serviceQualityAssuranceSystem || false,
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("serviceQualityAssuranceSystem"),
    });

    if (qualityAssuranceSystem) {
      components.appliedStandards = new ConfigurableInputProps({
        inputConfig: new CheckboxListConfig({
          index: 5,
          isRequired: true,
          name: "appliedStandards",
          modelValue: "appliedStandards",
          classModifier: "full",
          tLabel: "appliedStandards",
          list: [
            new SingleCheckbox({ value: 1, label: "ISO 9001" }),
            new SingleCheckbox({ value: 2, label: "ISO 14001" }),
            new SingleCheckbox({ value: 3, label: "ISO TS 16 949" }),
            new SingleCheckbox({ value: 4, label: "ISO 50001" }),
            new SingleCheckbox({ value: 5, label: "IATF 16949" }),
            new SingleCheckbox({
              value: 6,
              label: "Other:",
              showDescriptionInput: true,
            }),
            new SingleCheckbox({
              value: 7,
              label:
                "Implementation is underway, we are introducing a standard for:",
              showDescriptionInput: true,
            }),
          ],
          selectedOptions: company.appliedStandards
            ? company.appliedStandards.map((item) => {
                return {
                  value: item.appliedStandardId,
                  description: item.description,
                  hasDescription: !!item.description,
                };
              })
            : [],
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("appliedStandards"),
        errorMsg: "Please select at least one option",
      });
    }

    components.increaseDifficult = new ConfigurableInputProps({
      inputConfig: new RadioButtonsListConfig({
        name: "increaseDifficult",
        index: 6,
        isRequired: true,
        modelValue: "increaseDifficultType",
        classModifier: "full",
        tLabel: "increaseDifficult",
        list: generalData.increaseDifficultType,
        selectedOption: { value: company.increaseDifficultType, label: "" },
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("increaseDifficultType"),
      errorMsg: "Please select one option",
    });

    components.technologyEquipment = new ConfigurableInputProps({
      inputConfig: new RadioButtonsListConfig({
        index: 7,
        isRequired: true,
        name: "technologyEquipment",
        modelValue: "technologyEquipmentId",
        classModifier: "full",
        tLabel: "technologyEquipment",
        list: generalData.technologyEquipment,
        selectedOption: {
          value: company.technologyEquipmentId || null,
          label: "",
        },
      }),
      uniqueKey: hasUniqueKey
        ? generateUniqId()
        : CryptoJS.MD5("technologyEquipment"),
      errorMsg: "Please select one option",
    });

    if (!companyIsExporting) {
      components.percentageProduction = new ConfigurableInputProps({
        inputConfig: new NumberInputConfig({
          index: 8,
          name: "percentageProduction",
          modelValue: "percentageProduction",
          classModifier: "full",
          tLabel: "percentageProduction",
          initValue: company.percentageProduction || 0,
          range: { from: 0, to: 100 },
        }),
        uniqueKey: hasUniqueKey
          ? generateUniqId()
          : CryptoJS.MD5("percentageProduction"),
      });
    }
  }

  const allComponents = [
    components.export,
    components.serviceQualityAssuranceSystem,
    components.increaseDifficult,
    components.technologyEquipment,
  ];

  companyIsExporting &&
    //this components should be shown after the first field
    allComponents.splice(
      1,
      0,
      components.exportRegions,
      components.percentageOfExport,
    );

  if (qualityAssuranceSystem) {
    //this component should be shown after the fourth/second field
    companyIsExporting
      ? allComponents.splice(4, 0, components.appliedStandards)
      : allComponents.splice(2, 0, components.appliedStandards);
  }

  !companyIsExporting && allComponents.push(components.percentageProduction);

  return [...allComponents];
};
