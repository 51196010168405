import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import ExitToAppIcon from "mdi-react/ExitToAppIcon";
import { logoutDropdownToggle } from "./navigation-bar-action";

import DownArrow from "../SVGs/DownArrow";

import {
  getFullName,
  isAuth,
  logoutUser,
  getProfileType,
} from "../../utilities/auth";

import { IRootState } from "../../utilities/root-reducer";

import "./navigation-bar.scss";
import ReactDOM from "react-dom";

export const NavigationBar = (props) => {
  const { t } = useTranslation("navigation-bar");
  const companyData = useSelector((state: IRootState) => state.company);
  const expertData = useSelector((state: IRootState) => state.expert);
  const history = useHistory();
  const activeLocation = useLocation();
  const userName = getFullName();
  const dropdownRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(null);
  const [flag, setFlag] = useState(null);

  useEffect(() => {
    if (!flag) {
      setIsDropdownVisible(false);
    }
  }, [flag]);

  const dropdownShow = () => {
    if (!isDropdownVisible && !flag) {
      setIsDropdownVisible(true);
      setFlag(true);
    } else {
      setFlag(false);
      onCloseSidebar();
    }
  };
  const onCloseSidebar = () => {
    setIsDropdownVisible(false);
  };

  const handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(dropdownRef.current);
    if (!domNode || !domNode.contains(event.target)) {
      onCloseSidebar();
    }
  };

  const handleLogout = (event) => {
    if (isAuth()) {
      logoutUser();
      history.push("/");
    }
  };

  /**
   * NOTE! This method is for company profile
   */
  const handleOnDeleteCompanyProfileClick = () => {
    const event = new Event("delete-company-profile");
    document.dispatchEvent(event);
    onCloseSidebar();
  };

  const handleOnDeleteExpertProfileClick = () => {
    const event = new Event("delete-export-profile");
    document.dispatchEvent(event);
    onCloseSidebar();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="navbar-container">
      <div className="navbar-wrapper">
        <div className="navbar-logo"></div>

        {getProfileType() === "admin" ? (
          <div className="navbar-links">
            <Link
              className={`navbar-link ${
                activeLocation.pathname === "/admin/company" ? "active" : ""
              }`}
              to="/admin/company"
            >
              Companies
            </Link>
            <Link
              className={`navbar-link ${
                activeLocation.pathname === "/admin/experts" ? "active" : ""
              }`}
              to="/admin/experts"
            >
              Experts
            </Link>
            <Link
              className={`navbar-link ${
                activeLocation.pathname === "/admin/matching" ? "active" : ""
              }`}
              to="/admin/matching"
            >
              Matching
            </Link>
          </div>
        ) : null}
        <div onClick={() => dropdownShow()} className="navbar-user">
          {userName}
          <span className="navbar-arrow">
            <DownArrow />
          </span>

          {isDropdownVisible && (
            <>
              <div
                id="nav-dropdown-elem"
                className="nav-dropdown-menu"
                ref={(dropdown) => (dropdownRef.current = dropdown)}
                onMouseLeave={handleClickOutside}
              >
                {getProfileType() === "company" &&
                  companyData.data &&
                  Object.keys(companyData.data).length !== 0 && (
                    <div
                      className={"nav-dropdown-item delete-profile"}
                      onClick={() => handleOnDeleteCompanyProfileClick()}
                    >
                      <DeleteOutlineIcon className={"nav-dropdown-item-icon"} />
                      <div>{t("navProfileDelete")}</div>
                    </div>
                  )}

                {getProfileType() === "expert" &&
                  expertData.data &&
                  Object.keys(expertData.data).length !== 0 && (
                    <div
                      className={"nav-dropdown-item delete-profile"}
                      onClick={() => handleOnDeleteExpertProfileClick()}
                    >
                      <DeleteOutlineIcon className={"nav-dropdown-item-icon"} />
                      <div>{t("navExpertDelete")}</div>
                    </div>
                  )}
                <div
                  className={"nav-dropdown-item logout-item"}
                  onClick={handleLogout}
                >
                  <ExitToAppIcon className={"nav-dropdown-item-icon"} />
                  <div>{t("navLogout")}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
