import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { signupFormAction, deleteError } from "./signup-form-actions";
import { IRootState } from "../../utilities/root-reducer";
import {
  Link,
  RouteComponentProps,
  withRouter,
  useHistory,
} from "react-router-dom";
import { withTranslation } from "react-i18next";
import { email, password, isEqual } from "../../utilities/validation";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { ValidationError } from "../../utilities/validationError";
import compose from "recompose/compose";
import SignupFormSuccess from "./components/signup-form-success";
import {
  withStyles,
  CheckboxProps,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
} from "@material-ui/core";

import "../loginFormComponent/auth.scss";
import AuthBackground from "../authBgComponent/authBackground";

interface ISignupFormProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  t: any;
}

interface IFormInputs {
  profileType?: string;
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
}

export const SignupForm = (props: ISignupFormProps) => {
  const { t } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonClass, setButtonClass] = useState("auth-signup-submit-disabled");
  const [errorMessage, setErrorMessage] = useState(null);

  const [inputsValues, setInputValues] = useState<IFormInputs>({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAndConditions: false,
    profileType: "company",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputValues((prevInputValue) => ({
      ...prevInputValue,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const history = useHistory();

  const profileType =
    history.location.state && history.location.state["profileType"];

  useEffect(() => {
    if (isDisabled) {
      setButtonClass("auth-signup-submit-disabled");
    } else {
      setButtonClass("auth-signup-submit-button");
    }
  }, [isDisabled]);

  useEffect(() => {
    //first scenario when profile type is filled from homepage,
    //and second scenario when it is not filled (radio buttons)
    if (
      inputsValues?.fullName &&
      inputsValues?.email &&
      inputsValues?.password &&
      inputsValues?.confirmPassword &&
      inputsValues?.termsAndConditions
    ) {
      if (profileType || (!profileType && inputsValues?.profileType)) {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [inputsValues, profileType]);

  useEffect(() => {
    if (profileType === "company") {
      setInputValues((prevInputValue) => ({
        ...prevInputValue,
        profileType: "company",
      }));
    } else {
      setInputValues((prevInputValue) => ({
        ...prevInputValue,
        profileType: "expert",
      }));
    }
  }, []);

  const RedCheckbox = withStyles({
    root: {
      color: "#B62026",
      "&$checked": {
        color: "#B62026",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const checkBoxLabel = (
    <div className="terms-text">
      <span>
        {t("signupTermsText")}
        <Link to="/terms-and-conditions" target="_blank">
          {t("signupTermsLink")}
        </Link>
        <span>&nbsp;{t("and")}&nbsp;</span>
        <Link to="/privacy-policy" target="_blank">
          {t("privacyPolicy")}
        </Link>
      </span>
    </div>
  );

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const user = new FormData(form);

    if (props.signupFormData.errorMessage) {
      setErrorMessage({ message: props.signupFormData.errorMessage.message });
    } else {
      setErrorMessage(null);
      props.signupFormAction(user);
    }
  };

  const handleDeleteErrorMessage = () => props.deleteError();

  useEffect(() => {
    if (props.signupFormData.errorMessage) {
      setErrorMessage({ message: props.signupFormData.errorMessage.message });
    }
  }, [props.signupFormData.errorMessage]);

  return (
    <div className="auth-form-wrapper">
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("signupAccountTitle")}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("signupSmallText")}
        </Typography>
      </AuthBackground>
      <Paper className="auth-form-container">
        {props.signupFormData.signupSuccess ? (
          <SignupFormSuccess />
        ) : (
          <>
            <Form
              className="auth-form"
              onSubmit={handleSubmitForm}
              onChange={handleDeleteErrorMessage}
              data-testid="signup-form-element"
            >
              <div className="auth-input-container">
                <div className="profile-type-wrapper">
                  <label htmlFor="company" className="company-label">
                    {t("signupCompany")}
                    <Input
                      type="radio"
                      id="company"
                      name="profileType"
                      value="company"
                      onChange={handleChange}
                      checked={inputsValues.profileType === "company"}
                    />
                  </label>
                  <label htmlFor="expert" className="expert-label">
                    {t("signupExpert")}
                    <Input
                      type="radio"
                      id="expert"
                      name="profileType"
                      value="expert"
                      onChange={handleChange}
                      checked={inputsValues.profileType === "expert"}
                    />
                  </label>
                </div>
              </div>
              <div className="auth-input-container">
                <Input
                  className="auth-input"
                  id="fullName"
                  type="text"
                  name="fullName"
                  onChange={handleChange}
                  placeholder={
                    inputsValues.profileType === "company"
                      ? t("signupCompanyName")
                      : t("signupExpertName")
                  }
                />
              </div>
              <div className="auth-input-container">
                <Input
                  className="auth-input"
                  id="EmailAddress"
                  type="text"
                  name="email"
                  placeholder={t("signupEmail")}
                  onChange={handleChange}
                  validations={[email]}
                />
              </div>
              <div className="auth-input-container">
                <Input
                  className="auth-input"
                  id="password"
                  type="password"
                  name="password"
                  placeholder={t("signupPassword")}
                  onChange={handleChange}
                  validations={[isEqual, password]}
                />
              </div>
              <div className="auth-input-container">
                <Input
                  className="auth-input"
                  id="confirm-password"
                  type="password"
                  name="confirmPassword"
                  placeholder={t("signupConfirmPassword")}
                  onChange={handleChange}
                  validations={[isEqual]}
                />
              </div>
              <div className="auth-input-container custom">
                <FormControlLabel
                  control={
                    <RedCheckbox
                      checked={!!inputsValues?.termsAndConditions}
                      onChange={handleChange}
                      name="termsAndConditions"
                    />
                  }
                  label={checkBoxLabel}
                />
              </div>
              {/* The second button is imported from react-validation/build/button and is necessary for
            validating the form, but the submission of the form is avaliable 
            when the password is entered and confirmed and we get an error. In order to prevent this
            we use the first button that is disabled until all the inputs are fullfiled and checked. */}
              {isDisabled ? (
                <button disabled={isDisabled} className={buttonClass}>
                  {t("signupSubmitButton")}
                </button>
              ) : (
                <Button disabled={isDisabled} className={buttonClass}>
                  {t("signupSubmitButton")}
                </Button>
              )}
              {errorMessage && (
                <ValidationError emailValidation={errorMessage} />
              )}
              <span className="auth-text-container">
                {t("signupHaveAccount")}
                <Link to="/login" className="auth-link">
                  {t("signupLoginLink")}
                </Link>
              </span>
            </Form>
          </>
        )}
      </Paper>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  signupFormData: storeState.signup,
});
const mapDispatchToProps = {
  signupFormAction,
  deleteError,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  withTranslation("signup-layout"),
  connect(mapStateToProps, mapDispatchToProps),
)(SignupForm);
