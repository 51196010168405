import { IncreaseDifficultType } from "../company/Increase-difficult-type-enum";
import { DevelopmentPhase } from "../company/development-phase-enum";
import { GrowthPhase } from "../company/growth-phase-enum";
import { CompanySize } from "../company/company-size-enum";

export const technologyEquipment = [
  { value: 0, label: "Outdated technology and equipment" },
  {
    value: 1,
    label:
      "Satisfactory, but not in line with the requirements for foreign markets",
  },
  {
    value: 2,
    label:
      "Satisfactory, with minimum conditions to meet the requirements of foreign markets",
  },
  { value: 3, label: "State-of-the-art technology and equipment" },
];

export const appliedStandards = [
  { value: 1, label: "ISO 9001" },
  { value: 2, label: "ISO 14001" },
  { value: 3, label: "ISO TS 16 949" },
  { value: 4, label: "ISO 50001" },
  { value: 5, label: "IATF 16949" },
  {
    value: 6,
    label: "Other:",
    showDescriptionInput: true,
  },
  {
    value: 7,
    label: "Implementation is underway, we are introducing a standard for:",
    showDescriptionInput: true,
  },
];

export const numberOfCompaniesToWorkWith = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];

const increaseDifficultType = [
  { value: IncreaseDifficultType.Easily, label: "Easily" },
  {
    value: IncreaseDifficultType.RelativelyEasily,
    label: "Relatively Easily",
  },
  { value: IncreaseDifficultType.Medium, label: "Medium" },
  {
    value: IncreaseDifficultType.RelativelyDifficult,
    label: "Relatively Difficult",
  },
  {
    value: IncreaseDifficultType.Difficult,
    label: "Difficult",
  },
];

const developmentPhases = [
  {
    value: DevelopmentPhase.BusinessAndMarketing,
    label: "Business and Marketing Strategy Development",
  },
  {
    value: DevelopmentPhase.ProductDevelopment,
    label: "Product Development",
  },
  {
    value: DevelopmentPhase.TestMarketing,
    label: "Test Marketing, Validate",
  },
  {
    value: DevelopmentPhase.Commercialization,
    label: "Commercialization",
  },
];

const growthPhases = [
  {
    value: GrowthPhase.Beginning,
    label: "At the beginning (no market activity)",
  },
  {
    value: GrowthPhase.Launch,
    label: "Launch",
  },
  {
    value: GrowthPhase.EarlyStage,
    label:
      "Early stage (generate expected revenues, have customers, but need to scale to satisfy the market demand)",
  },
  {
    value: GrowthPhase.GrowthStage,
    label:
      "Growth stage (a profitable business that seeks ways to expand further)",
  },
  {
    value: GrowthPhase.Maturity,
    label: "Maturity",
  },
];

const companySize = [
  {
    value: CompanySize.Micro,
    label: "0-9 (micro)",
  },
  {
    value: CompanySize.Small,
    label: "10-49 (small)",
  },
  {
    value: CompanySize.Medium,
    label: "50-249 (medium)",
  },
  {
    value: CompanySize.Large,
    label: "250+ (large)",
  },
];

export interface IGeneralData {
  numberOfCompaniesToWorkWith: { value: number; label: string }[];
  technologyEquipment: { value: number; label: string }[];
  appliedStandards: {
    value: number;
    label: string;
    showDescriptionInput?: boolean;
  }[];
  increaseDifficultType: { value: IncreaseDifficultType; label: string }[];
  developmentPhases: { value: DevelopmentPhase; label: string }[];
  growthPhases: { value: GrowthPhase; label: string }[];
  companySize: { value: CompanySize; label: string }[];
}

export const generalData = {
  technologyEquipment,
  numberOfCompaniesToWorkWith,
  appliedStandards,
  increaseDifficultType,
  developmentPhases,
  growthPhases,
  companySize,
};
