import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../utilities/root-reducer";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";

import "./../../loginFormComponent/auth.scss";
import Spinner from "../../common/spinner/spinner";
import { Paper, Typography } from "@material-ui/core";
import AuthBackground from "../../authBgComponent/authBackground";

const ResetPasswordSuccess = () => {
  const { t } = useTranslation("forgot-password");
  const dispatch = useDispatch();
  const { errorMessage, loading, status, passwordSubmitSuccess } = useSelector(
    (state: IRootState) => state.resetPassword,
  );

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/login");
    }, 3000);
  }, []);

  return (
    <>
      <AuthBackground>
        <Typography className="auth-heading" variant="h1">
          {t("passwordChangedTitle")}
        </Typography>
        <Typography className="auth-paragraph" variant="body1">
          {t("passwordChangedSubtitle")}
        </Typography>
      </AuthBackground>
      <Paper className="auth-form-container">
        <Form className="auth-form">
          <div className="rp-shield"></div>

          <div className="auth-input-container">
            <Link to="/login" className="auth-link">
              <Button className="auth-submit-button">{t("toLogin")}</Button>
            </Link>
          </div>
        </Form>
      </Paper>
    </>
  );
};

export default ResetPasswordSuccess;
