import React from "react";
import "./terms-and-conditions.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import NavigationBar from "../navigationBar/navigation-bar";
import { termsEN } from "./terms-and-conditions-en.data";

export const TermsAndConditions = () => {
  const { t } = useTranslation("terms");

  return (
    <div className="terms-container">
      <NavigationBar />
      <div className="terms-content-container">
        <h3 className="terms-title underlined font-weight--bold">
          {t("termsTitle")}
        </h3>
        <br />

        <span className="terms-content font-weight--bold">
          {t("termsWelcome")}
        </span>
        <br />
        {termsEN.map((item, index) => {
          return <p className={`${item.class}`}>{item.title}</p>;
        })}
      </div>
    </div>
  );
};
