import { Actions } from "../../utilities/action-payload";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";
import { ICompany } from "../../models/company/company";
import { IExpert } from "../../models/expert/expert";
import {
  MATCHING_FILTER_COMPANIES,
  MATCHING_FILTER_COMPANIES_PAGINATION,
  MATCHING_GET_COMPANY,
  MATCHING_FETCH_EXPERTS,
  MATCHING_GET_EXPERT,
  MATCHING_CLEANUP_DATA,
} from "./adminMatchingTypes";
import { IPagination, Pagination } from "../../models/general/pagination";

export interface AdminMatchingState {
  // companies
  companies: ICompany[];
  filteredCompaniesWithPagination: any;
  loadingCompanies: boolean;
  companiesError: any;
  // experts list
  experts: IPagination;
  loadingExperts: boolean;
  expertsError: any;
  // single expert
  expert: IExpert;
  loadingExpert: boolean;
  expertError: any;
  // selected company
  company: ICompany;
  loadingCompany: boolean;
  companyError: any;
}

const initialState: AdminMatchingState = {
  // companies
  companies: [],
  filteredCompaniesWithPagination: [],
  loadingCompanies: false,
  companiesError: null,
  // experts list
  experts: new Pagination({ content: [] as IExpert[] }),
  loadingExperts: false,
  expertsError: null,
  // single expert
  expert: null,
  loadingExpert: false,
  expertError: null,
  // selected company
  company: null,
  loadingCompany: false,
  companyError: null,
};

export default (
  state: AdminMatchingState = initialState,
  action: Actions,
): AdminMatchingState => {
  switch (action.type) {
    // FILTER
    case REQUEST(MATCHING_FILTER_COMPANIES):
      return {
        ...state,
        companiesError: null,
        loadingCompanies: true,
      };
    case SUCCESS(MATCHING_FILTER_COMPANIES):
      return {
        ...state,
        companies: action.payload.data,
        companiesError: null,
        loadingCompanies: false,
      };
    case FAILURE(MATCHING_FILTER_COMPANIES):
      return {
        ...state,
        companiesError: action,
        loadingCompanies: false,
      };

    // FILTER WITH PAGINATION
    case REQUEST(MATCHING_FILTER_COMPANIES_PAGINATION):
      return {
        ...state,
        companiesError: null,
        loadingCompanies: true,
      };
    case SUCCESS(MATCHING_FILTER_COMPANIES_PAGINATION):
      return {
        ...state,
        filteredCompaniesWithPagination: action.payload.data,
        companiesError: null,
        loadingCompanies: false,
      };
    case FAILURE(MATCHING_FILTER_COMPANIES_PAGINATION):
      return {
        ...state,
        companiesError: action,
        loadingCompanies: false,
      };

    // COMPANY
    case REQUEST(MATCHING_GET_COMPANY):
      return {
        ...state,
        company: null,
        companyError: null,
        loadingCompany: true,
      };
    case SUCCESS(MATCHING_GET_COMPANY):
      return {
        ...state,
        company: action.payload.data,
        companyError: null,
        loadingCompany: false,
      };
    case FAILURE(MATCHING_GET_COMPANY):
      return {
        ...state,
        company: null,
        companyError: action,
        loadingCompany: false,
      };
    // EXPERTS
    case REQUEST(MATCHING_FETCH_EXPERTS):
      return {
        ...state,
        experts: new Pagination(),
        expertsError: null,
        loadingExperts: true,
      };
    case SUCCESS(MATCHING_FETCH_EXPERTS):
      return {
        ...state,
        experts: action.payload.data || [],
        expertsError: null,
        loadingExperts: false,
      };
    case FAILURE(MATCHING_FETCH_EXPERTS):
      return {
        ...state,
        experts: new Pagination(),
        expertsError: action.payload,
        loadingExperts: false,
      };

    // SINGLE EXPERT
    case REQUEST(MATCHING_GET_EXPERT):
      return {
        ...state,
        expert: null,
        expertError: null,
        loadingExpert: true,
      };
    case SUCCESS(MATCHING_GET_EXPERT):
      return {
        ...state,
        expert: action.payload.data,
        expertError: null,
        loadingExpert: false,
      };
    case FAILURE(MATCHING_GET_EXPERT):
      return {
        ...state,
        expert: null,
        expertError: action.payload,
        loadingExpert: false,
      };

    case MATCHING_CLEANUP_DATA:
      return {
        ...state,
        expert: null,
        experts: new Pagination({ content: [] as IExpert[] }),
        company: null,
      };

    default:
      return state;
  }
};
