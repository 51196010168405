import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      textAlign: "left",
      marginTop: theme.spacing(1),
      maxWidth: 200,
      marginBottom: theme.spacing(9),
    },
  }),
);

interface IDatePicker {
  onGetSelectedDate: (date: string) => void;
  label: string;
  futureDates: string | boolean;
}

const dateNow = new Date();
const year = dateNow.getFullYear();
// January is 0 by default in JS. Offsetting +1 to fix date for calendar.
const monthWithOffset = dateNow.getUTCMonth() + 1;
const month =
  // Checking if month is < 10 and pre-prending 0 to adjust for date input.
  monthWithOffset.toString().length < 2
    ? `0${monthWithOffset}`
    : monthWithOffset;
const day =
  // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
  dateNow.getUTCDate().toString().length < 2
    ? `0${dateNow.getUTCDate()}`
    : dateNow.getUTCDate();
export const date = `${year}-${month}-${day}`;

export const dateToProperFormat = (date: string) =>
  // make the date to be in (DD, MM, YYYY) format
  `${date?.substring(date?.length - 2)}-${date?.substring(
    5,
    7,
  )}-${date?.substring(0, 4)}`;

export default function DatePicker(props: IDatePicker) {
  const { onGetSelectedDate, label, futureDates } = props;
  const classes = useStyles();

  return (
    <>
      <label htmlFor="date">
        <b>{label}</b>
      </label>
      <div>
        <TextField
          id="date"
          type="date"
          defaultValue={date}
          className={classes.textField}
          onChange={(e) => onGetSelectedDate(e.target.value)}
          InputProps={{
            inputProps: {
              max: futureDates,
            },
          }}
        />
      </div>
    </>
  );
}
