import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import Form from "react-validation/build/form";
import { useSelector, useDispatch } from "react-redux";
import { store } from "react-notifications-component";

import {
  SelectInputEvent,
  SelectInputConfig,
} from "../../../formComponents/definitions/InputConfigurationSpec";
import { IRootState } from "../../../../utilities/root-reducer";
import {
  notifyError,
  notifySuccess,
} from "../../../../utils/notifications/app-notification";
import {
  fetchAvailableExpertsCleanup,
  matchExpertAndStartDate,
  matchExpertAndStartDateCleanup,
} from "../companyTableActions";
import { SelectInput } from "../../../formComponents";
import DatePicker from "../../../common/DatePicker";
import { date } from "../../../common/DatePicker";

import "./company-modals.scss";
interface IAddMatchingExpertModalProps {
  open: boolean;
  companyId: number;
  onMatchingSuccess: () => void;
  onCancel: () => void;
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default function AddMatchingExpertModal(
  props: IAddMatchingExpertModalProps,
) {
  const { open, onMatchingSuccess, onCancel, companyId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation("admin-tables");
  const {
    availableExpertsLoading,
    error,
    availableExperts,
    matchedExpertLoading,
    matchedExpertStatus,
  } = useSelector((state: IRootState) => state.adminCompanies);

  /**
   * // recommended by the author of the package
   */
  ReactModal.setAppElement("body");

  const [selectedOptionId, setSelectedOptionId] = useState(null);

  const [avaliableExpertsConfig, setAvaliableExpertConfig] = useState<
    SelectInputConfig
  >(
    new SelectInputConfig({
      options: [],
      modelValue: "companyId",
    }),
  );

  let selectedDate;

  useEffect(() => {
    if (!availableExpertsLoading && availableExperts) {
      setAvaliableExpertConfig(
        new SelectInputConfig({
          options: availableExperts.map((item) => {
            return {
              value: item.id,
              label: `${item.firstName} ${item.lastName ? item.lastName : ""}`,
            };
          }),
          name: "avaliableExperts",
        }),
      );
    } else if (!availableExpertsLoading && error) {
      notifyError(
        store,
        t("admin_loading_available_experts_error_title"),
        t("admin_loading_available_experts_error_desc"),
      );
    }
  }, [availableExpertsLoading]);

  useEffect(() => {
    if (!matchedExpertLoading && matchedExpertStatus) {
      notifySuccess(
        store,
        t("admin-company-match-with-expert-notify-title"),
        t("admin-company-match-with-expert-success-desc"),
      );
      dispatch(matchExpertAndStartDateCleanup());
      onMatchingSuccess();
    } else if (!matchedExpertLoading && error) {
      notifyError(
        store,
        t("admin-company-match-with-expert-notify-title"),
        t("admin-company-match-with-expert-error-desc"),
      );
    }
  }, [matchedExpertLoading]);

  const getSelectedDate = (date: string) => (selectedDate = date);

  const handleExpertSelect = (event: SelectInputEvent) => {
    if ((event.selectedOption.value as number) >= 1)
      setSelectedOptionId(event.selectedOption.value);
    else dispatch(fetchAvailableExpertsCleanup());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const startMatchingDate = selectedDate === undefined ? date : selectedDate;
    dispatch(
      matchExpertAndStartDate(selectedOptionId, companyId, startMatchingDate),
    );
  };

  return (
    !!availableExperts && (
      <ReactModal
        isOpen={open}
        role={"dialog"}
        shouldCloseOnEsc={false}
        style={modalStyle}
        className="responsive-modal-container-matching"
      >
        {availableExperts?.length !== 0 && (
          <Form className={"delete-modal-content custom"}>
            <div className={"matching-field-select"}>
              <label>
                <b>{t("admin-select-one-of-the-available-experts-modal")}</b>
              </label>
              <SelectInput
                onSelect={handleExpertSelect}
                inputConfig={avaliableExpertsConfig}
              />
            </div>
            <DatePicker
              onGetSelectedDate={getSelectedDate}
              label="Please select starting date"
              futureDates={false}
            />
            <div className={"control-button-container"}>
              <button
                disabled={availableExpertsLoading}
                className={"button-with-loading-matching"}
                onClick={handleSubmit}
              >
                {t("admin-company-modal-match")}
              </button>
              <button
                disabled={availableExpertsLoading}
                onClick={onCancel}
                className={"cancel"}
              >
                {t("admin-company-modal-cancel")}
              </button>
            </div>
          </Form>
        )}
        {availableExperts?.length === 0 && (
          <div className={"delete-modal-content"}>
            <h4>{t("admin-no-available-experts-modal")}</h4>
            <br></br>
            <div className={"control-button-container"}>
              <button
                disabled={availableExpertsLoading}
                className={"button-with-loading-matching"}
                onClick={onCancel}
              >
                {t("admin-company-modal-ok")}
              </button>
            </div>
          </div>
        )}
      </ReactModal>
    )
  );
}
