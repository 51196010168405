export const MATCHING_FILTER_COMPANIES = "MATCHING_FILTER_COMPANIES";
export const MATCHING_FILTER_COMPANIES_PAGINATION =
  "MATCHING_FILTER_COMPANIES_PAGINATION";
export const MATCHING_GET_COMPANY = "MATCHING_GET_COMPANY";
export const MATCHING_FETCH_EXPERTS = "MATCHING_FETCH_EXPERTS";
export const MATCHING_GET_EXPERT = "MATCHING_GET_EXPERT";
/**
 * Cleanup experts and company data
 */
export const MATCHING_CLEANUP_DATA = "MATCHING_CLEANUP_DATA";
export const MATCHING_EXPORT = "MATCHING_EXPORT";
