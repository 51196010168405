import React from "react";
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { CompanyRoutes } from "../routes/company-routes";
import { IRootState } from "../utilities/root-reducer";
import { connect } from "react-redux";
import { getProfileType, isAuth } from "../utilities/auth";
import compose from "recompose/compose";
import NavigationBar from "../components/navigationBar/navigation-bar";

interface ICompanyViewProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<any> {}

export class CompanyView extends React.Component<ICompanyViewProps> {
  render() {
    if (!isAuth()) {
      return <Redirect to="/login" />;
    }
    const profileType = getProfileType();
    if (!profileType || profileType !== "company") {
      return <Redirect to="/error" />;
    }
    const path = this.props.match.path;
    const routes = CompanyRoutes.map((route, index) => {
      const routeProps = { ...route, path: path + route.path };
      return <Route exact {...routeProps} key={route.path + index} />;
    });

    return (
      <div className="company profile">
        <React.Suspense fallback="loading..">
          <NavigationBar />
          <Switch>{routes}</Switch>
        </React.Suspense>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CompanyView);
