/**
 * @description show successfully notification. Note! import { store } from "react-notifications-component"
 * @param store
 * @param title
 * @param desc
 */
export const notifySuccess = (store, title: string, desc: string) => {
  if (!store) {
    return;
  }
  store.addNotification({
    title: `${title}`,
    message: `${desc}`,
    type: "success",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
    },
  });
};

/**
 * @description show error notification. Note! import { store } from "react-notifications-component"
 * @param store
 * @param title
 * @param desc
 */
export const notifyError = async (store, title, desc) => {
  if (!store) {
    return;
  }
  store.addNotification({
    title: `${title}`,
    message: `${desc}`,
    type: "danger",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
    },
  });
};
