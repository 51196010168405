import React from "react";
import { ITab } from "./definitions/tabs-spec";
import { IRootState } from "../../../utilities/root-reducer";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";
import {
  profileTabNavigation,
  TabNavigationEnum,
} from "../../../models/company/profile-tab-navigation-enum";
import { CompanyDocumentUpload } from "../../documentUpload/companyDocumentUpload/company-document-upload";
import { ExpertFormWorkMode } from "../../../models/expert/expert-form-work-mode";

interface IDocumentsTabProps extends ITab, StateProps, DispatchProps {
  onNewDocumentUploadSuccess: any;
  onEditDocumentUploadSuccess: any;
  changeDeletePress: any;
  setFormWorkMode: (value: ExpertFormWorkMode) => void;
}

export function DocumentsTab(props: IDocumentsTabProps) {
  const { t } = useTranslation("profile");

  const {
    active,
    data,
    onChange,
    formWorkMode,
    onNewDocumentUploadSuccess,
    onEditDocumentUploadSuccess,
    changeDeletePress,
    setFormWorkMode,
  } = props;

  return (
    <>
      {active === profileTabNavigation[TabNavigationEnum.AdditionalInfo] && (
        <div className="profile-card-body">
          <CompanyDocumentUpload
            t={t}
            onChange={onChange}
            formWorkMode={formWorkMode}
            setFormWorkMode={setFormWorkMode}
            data={data}
            active={active}
            onNewDocumentUploadSuccess={onNewDocumentUploadSuccess}
            onEditDocumentUploadSuccess={onEditDocumentUploadSuccess}
            changeDeletePress={changeDeletePress}
          />
        </div>
      )}
    </>
  );
}
const mapStateToProps = (storeState: IRootState) => ({
  companyProfileData: storeState.company,
});
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(
  withTranslation("profile"),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentsTab);
