import React from "react";
import { useTranslation } from "react-i18next";
import "./form-components.scss";
import {
  ConfigurableInputProps,
  CheckboxListConfig,
  SelectInputConfig,
  RadioButtonsListConfig,
  YesNoRadioButtonConfig,
  TextInputConfig,
  NumberInputConfig,
} from "./definitions/InputConfigurationSpec";
import { InputTypeEnum } from "../../models/forms/input-types-enum";
import {
  YesNoRadioButton,
  MultiCheckbox,
  MultiRadioButtons,
  SelectInput,
  TextInput,
  NumberInput,
} from "../formComponents";

export function ConfigurableInput(props: ConfigurableInputProps) {
  const { t } = useTranslation("profile");

  const renderInput = () => {
    switch (props.inputConfig.inputType) {
      case InputTypeEnum.CheckboxList:
        return (
          <MultiCheckbox
            inputConfig={props.inputConfig as CheckboxListConfig}
            onChange={props.onInputChange}
            errorMsg={props.errorMsg}
          />
        );

      case InputTypeEnum.Select:
        return (
          <SelectInput
            inputConfig={props.inputConfig as SelectInputConfig}
            onSelect={props.onInputChange}
            errorMsg={props.errorMsg}
          />
        );
      case InputTypeEnum.RadioButtonsList:
        return (
          <MultiRadioButtons
            inputConfig={props.inputConfig as RadioButtonsListConfig}
            onSelect={props.onInputChange}
            errorMsg={props.errorMsg}
          />
        );
      case InputTypeEnum.YesNoRadioButton:
        return (
          <YesNoRadioButton
            inputConfig={props.inputConfig as YesNoRadioButtonConfig}
            onSelect={props.onInputChange}
            errorMsg={props.errorMsg}
          />
        );
      case InputTypeEnum.Text:
        return (
          <TextInput
            inputConfig={props.inputConfig as TextInputConfig}
            onChange={props.onInputChange}
            errorMsg={props.errorMsg}
          />
        );
      case InputTypeEnum.Number:
        return (
          <NumberInput
            inputConfig={props.inputConfig as NumberInputConfig}
            onChange={props.onInputChange}
            errorMsg={props.errorMsg}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`profile-input-container ${props.inputConfig.classModifier}`}
    >
      <label className={"main-input-label"}>
        {`${t(props.inputConfig.tLabel)} `}
        {props.inputConfig.isRequired ? (
          <span style={{ color: "#A22028" }}>*</span>
        ) : (
          <span>({t("companyOptional")})</span>
        )}
      </label>
      {props.inputConfig.inputType === 3 && (
        <label style={{ fontSize: "0.8em" }}>
          <b>{t("multipleAnswersPossible")}</b>
        </label>
      )}
      {renderInput()}
    </div>
  );
}
