import { Actions } from "../../utilities/action-payload";
import { IForgotPassword } from "./forgot-password-entity";
import { REQUEST, FAILURE, SUCCESS } from "../../utilities/action-types";
import { ForgotPasswordError } from "../../models/account/resetPassword/forgot-password-error-enum";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CLEANUP,
} from "./forgot-password-types";

const initialState: IForgotPassword = {
  errorMessage: ForgotPasswordError,
  loading: false,
  status: "",
  emailSubmitSuccess: false,
};

export type ForgotPasswordState = Readonly<typeof initialState>;

export default (
  state: ForgotPasswordState = initialState,
  action: Actions,
): ForgotPasswordState => {
  switch (action.type) {
    case REQUEST(FORGOT_PASSWORD):
      return {
        ...state,
        status: action.payload,
        loading: true,
        errorMessage: ForgotPasswordError.None,
      };

    case SUCCESS(FORGOT_PASSWORD):
      return {
        ...state,
        status: "Email sent" + action.payload,
        emailSubmitSuccess: true,
        errorMessage: ForgotPasswordError.EmailSent,
      };

    case FAILURE(FORGOT_PASSWORD):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FORGOT_PASSWORD_CLEANUP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
