import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "./company-modals.scss";
import { useTranslation } from "react-i18next";
import Form from "react-validation/build/form";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../../../utilities/root-reducer";
import {
  notifyError,
  notifySuccess,
} from "../../../../utils/notifications/app-notification";
import {
  unmatchExpertAndTerminationDate,
  unmatchExpertAndTerminationDateCleanup,
} from "../companyTableActions";
import { store } from "react-notifications-component";
import DatePicker from "../../../common/DatePicker";
import { date } from "../../../common/DatePicker";

interface IRemoveMatchingExpertModalProps {
  open: boolean;
  companyId: number;
  onMatchingSuccess: () => void;
  onCancel: () => void;
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default function RemoveMatchingExpertModal(
  props: IRemoveMatchingExpertModalProps,
) {
  const { open, onMatchingSuccess, onCancel, companyId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation("admin-tables");
  const {
    error,
    company,
    unmatchedExpertLoading,
    unmatchedExpertStatus,
  } = useSelector((state: IRootState) => state.adminCompanies);

  /**
   * // recommended by the author of the package
   */
  ReactModal.setAppElement("body");

  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    if (!unmatchedExpertLoading && unmatchedExpertStatus) {
      notifySuccess(
        store,
        t("admin-company-match-with-expert-notify-title"),
        t("admin-company-unmatch-expert-success-desc"),
      );
      dispatch(unmatchExpertAndTerminationDateCleanup());
      onMatchingSuccess();
    } else if (!unmatchedExpertLoading && error) {
      notifyError(
        store,
        t("admin-company-match-with-expert-notify-title"),
        t("admin-company-ummatch-expert-error-desc"),
      );
    }
  }, [unmatchedExpertLoading]);

  let selectedDate;

  const getSelectedDate = (date: string) => (selectedDate = date);

  const handleSubmit = (e) => {
    e.preventDefault();
    const terminationMatchingDate =
      selectedDate === undefined ? date : selectedDate;
    dispatch(
      unmatchExpertAndTerminationDate(
        company?.matchedExpert.id,
        companyId,
        terminationMatchingDate,
      ),
    );
  };

  return (
    <ReactModal
      isOpen={open}
      role={"dialog"}
      shouldCloseOnEsc={false}
      style={modalStyle}
      className="responsive-modal-container-matching"
    >
      <div className={"delete-modal-content"}>
        {!showDatePicker && (
          <>
            <h4>{t("admin-delete-matched-experts-modal")}</h4>
            <br></br>
            <div className={"control-button-container"}>
              <button
                className={"button-with-loading-matching"}
                onClick={() => setShowDatePicker(true)}
              >
                {t("admin-company-modal-ok")}
              </button>
              <button
                onClick={() => {
                  setShowDatePicker(false);
                  onCancel();
                }}
                className={"cancel"}
              >
                {t("admin-company-modal-cancel")}
              </button>
            </div>
          </>
        )}
        {showDatePicker && (
          <Form className={"delete-modal-content custom"}>
            <DatePicker
              onGetSelectedDate={getSelectedDate}
              label="Please select ending date"
              futureDates={date}
            />
            <div className={"control-button-container"}>
              <button
                className={"button-with-loading-matching"}
                onClick={handleSubmit}
              >
                {t("admin-company-modal-remove")}
              </button>
              <button
                onClick={() => {
                  setShowDatePicker(false);
                  onCancel();
                }}
                className={"cancel"}
              >
                {t("admin-company-modal-cancel")}
              </button>
            </div>
          </Form>
        )}
      </div>
    </ReactModal>
  );
}
