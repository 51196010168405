import React from "react";
import { Box } from "@material-ui/core";
import Illustration from "../../assets/images/illustration-2.svg";
import "./authBackground.scss";

type AuthBackgroundProps = {
  children?: React.ReactNode;
};

const AuthBackground = ({ children }: AuthBackgroundProps) => {
  return (
    <Box className="auth-background">
      <img className="illustration" src={Illustration} alt="SVG Image" />
      <Box className="auth-typography">{children}</Box>
    </Box>
  );
};

export default AuthBackground;
