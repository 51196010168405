import React from "react";
import "./privacy-policy.scss";
import { useTranslation } from "react-i18next";
import NavigationBar from "../navigationBar/navigation-bar";
import { privacyEN } from "./privacy-policy.data";

export const PrivacyPolicy = () => {
  const { t } = useTranslation("terms");

  return (
    <div className="terms-container">
      <NavigationBar />
      <div className="terms-content-container">
        <h3 className="terms-title">{t("privacyTitle")}</h3>
        <br />
        {privacyEN.map((item, index) => {
          return <p className={`${item.class}`}>{item.title}</p>;
        })}
      </div>
    </div>
  );
};
